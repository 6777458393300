import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import DealListedScheduledEvents from '../dealListedScheduledEvents/DealListedScheduledEvents';
import { HeaderTagType } from '@payaca/components/headerTag/HeaderTag';

import * as scheduledEventActions from '@payaca/store/scheduledEvents/scheduledEventsActions';
import { useCustomerForDeal, useDeal } from '@payaca/store/hooks/appState';

import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';

import { DeepPartial } from '@payaca/utilities/types';
import { CreateScheduledEventRequestData } from '@payaca/store/scheduledEvents/scheduledEventsTypes';
import ScheduleContextProvider, {
  ScheduleContext,
} from '../contextProviders/ScheduleContextProvider';
import ScheduleDispatchFilters from '../scheduleDispatchFilters/ScheduleDispatchFilters';

import { getContactsToNotify } from '@payaca/helpers/scheduledEventsHelper';

import { ScheduleDispatchMain } from '../scheduleDispatchMain/ScheduleDispatchMain';
import { Deal } from '@payaca/types/dealTypes';
import { useIsMounted } from '@/hooks/useIsMounted';
import { useHashFragment } from '@/utils/customHooks';
import { useSelector } from '@/api/state';

interface Props {
  dealId: number;
  onDealUpdateSuccess: () => void;
}

const DealScheduledEvents: FC<Props> = ({
  dealId,
  onDealUpdateSuccess,
}: Props): JSX.Element => {
  const customer = useCustomerForDeal(dealId);
  const deal = useDeal(dealId);
  const currentUserId = useSelector((state: any) => state.users.myProfile.id);

  const initialScheduledEvent: DeepPartial<CreateScheduledEventRequestData> =
    useMemo(() => {
      const initialScheduledEvent: DeepPartial<CreateScheduledEventRequestData> =
        {
          dealId,
          includeCustomerInformation: true,
          name: customer?.name,
          userAssignments: [currentUserId],
        };
      const siteAddress = deal?.siteAddresses?.[0];
      if (siteAddress) {
        // default event to have first site address
        initialScheduledEvent.location = {
          id: siteAddress.address.id,
          address: siteAddress.address,
          contacts: siteAddress.contacts,
        };
      }

      initialScheduledEvent.contactsToNotify = getContactsToNotify({
        customer,
        locationContacts: siteAddress?.contacts,
      });

      return initialScheduledEvent;
    }, [deal, customer, currentUserId]);

  return (
    <ScheduleContextProvider
      createEventBase={initialScheduledEvent}
      // Don't allow new events to be created on an archived deal
      allowEventCreation={!deal?.archivedAt}
      hideCreateEventButton
      hiddenFields={['customerIds']}
    >
      <ScheduleTabContent
        dealId={dealId}
        onDealUpdateSuccess={onDealUpdateSuccess}
      />
    </ScheduleContextProvider>
  );
};

export default DealScheduledEvents;

const ScheduleTabContent: FC<{
  onDealUpdateSuccess: () => void;
  dealId: Deal['id'];
}> = ({ onDealUpdateSuccess, dealId }) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  const { listenerSubscribe, listenerUnsubscribe } =
    useContext(ScheduleContext);

  const [isGetting, setIsGetting] = useState(false);

  const getDealScheduledEvents = useCallback(() => {
    setIsGetting(true);
    dispatch(
      scheduledEventActions.requestGetListedScheduledEvents(
        {
          dealId,
          pageSize: 50,
        },
        (events) => {
          if (isMounted.current === false) return;
          setDealScheduledEvents(events);
          setIsGetting(false);
        }
      )
    );
  }, []);

  useEffect(() => {
    getDealScheduledEvents();

    listenerSubscribe(
      'onEventCreated',
      'scheduleTabContent',
      getDealScheduledEvents
    );

    listenerSubscribe(
      'onEventUpdated',
      'scheduleTabContent',
      getDealScheduledEvents
    );

    listenerSubscribe(
      'onEventDeleted',
      'scheduleTabContent',
      getDealScheduledEvents
    );

    return () => {
      listenerUnsubscribe('onEventCreated', 'scheduleTabContent');
      listenerUnsubscribe('onEventUpdated', 'scheduleTabContent');
      listenerUnsubscribe('onEventDeleted', 'scheduleTabContent');
    };
  }, []);

  const [dealScheduledEvents, setDealScheduledEvents] = useState<
    ScheduledEvent[]
  >([]);

  return (
    <div className="@container/scheduletab">
      <div className="@lg/scheduletab:flex-row flex flex-col gap-8 ">
        <div className="@lg/scheduletab:basis-60 shrink-0 grow-0">
          {/* @lg/scheduletab:basis-80  */}
          {!!dealScheduledEvents?.length && (
            <DealListedScheduledEvents
              titleHeaderTagType={HeaderTagType.H3}
              dealScheduledEvents={dealScheduledEvents}
              onDealUpdateSuccess={onDealUpdateSuccess}
              hideTitles={true}
            />
          )}
          {!dealScheduledEvents?.length && !isGetting && (
            <>
              <h3>No Events</h3>
              <p>This Project {"doesn't"} have any Events yet</p>
            </>
          )}
        </div>
        <div className="flex grow flex-col gap-4">
          <ScheduleDispatchFilters />
          <ScheduleDispatchMain />
        </div>
      </div>
    </div>
  );
};
