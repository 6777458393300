import { Reducer } from 'redux';

import { mergeFetchedEntities } from '../utils';

import { ActionType, State } from './materialsListTypes';

export const initialState: State = {
  isPersistingMaterialsList: false,
  isPopulatingDealMaterialsList: false,
  isPersistingMaterialsListMaterial: false,
  isDeletingMaterialsListMaterial: false,
  isPersistingMaterialPurchaseRecord: false,
  isDeletingMaterialPurchaseRecord: false,
  isPersistingPurchaseOrder: false,
  materialsListMaterials: {},
  materialsLists: {},
  materialPurchaseRecords: {},
  materialPurchaseIntents: {},
  purchaseOrders: {},
};

const materialsReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_DELETE_MATERIAL_PURCHASE_RECORD:
      return {
        ...state,
        isDeletingMaterialPurchaseRecord: true,
      };
    case ActionType.DELETE_MATERIAL_PURCHASE_RECORD_SUCCESS:
    case ActionType.DELETE_MATERIAL_PURCHASE_RECORD_FAILURE:
      return {
        ...state,
        isDeletingMaterialPurchaseRecord: false,
      };

    case ActionType.REQUEST_DELETE_MATERIALS_LIST_MATERIAL:
      return {
        ...state,
        isDeletingMaterialsListMaterial: true,
      };
    case ActionType.DELETE_MATERIALS_LIST_MATERIAL_SUCCESS:
    case ActionType.DELETE_MATERIALS_LIST_MATERIAL_FAILURE:
      return {
        ...state,
        isDeletingMaterialsListMaterial: false,
      };

    case ActionType.REQUEST_PERSIST_MATERIALS_LIST_MATERIAL:
      return {
        ...state,
        isPersistingMaterialsListMaterial: true,
      };
    case ActionType.PERSIST_MATERIALS_LIST_MATERIAL_SUCCESS:
    case ActionType.PERSIST_MATERIALS_LIST_MATERIAL_FAILURE:
      return {
        ...state,
        isPersistingMaterialsListMaterial: false,
      };

    case ActionType.REQUEST_PERSIST_MATERIAL_PURCHASE_RECORD:
      return {
        ...state,
        isPersistingMaterialPurchaseRecord: true,
      };
    case ActionType.PERSIST_MATERIAL_PURCHASE_RECORD_SUCCESS:
    case ActionType.PERSIST_MATERIAL_PURCHASE_RECORD_FAILURE:
      return {
        ...state,
        isPersistingMaterialPurchaseRecord: false,
      };

    case ActionType.REQUEST_SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL:
      return {
        ...state,
        isPopulatingDealMaterialsList: true,
      };
    case ActionType.SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL_SUCCESS:
    case ActionType.SYNC_AUTOPOPULATED_MATERIALS_LIST_MATERIALS_FROM_DEAL_FAILURE:
      return {
        ...state,
        isPopulatingDealMaterialsList: false,
      };

    case ActionType.REQUEST_CREATE_DEAL_MATERIALS_LIST:
      return {
        ...state,
        isPersistingMaterialsList: true,
      };
    case ActionType.PERSIST_MATERIALS_LIST_SUCCESS:
    case ActionType.PERSIST_MATERIALS_LIST_FAILURE:
      return {
        ...state,
        isPersistingMaterialsList: false,
      };

    case ActionType.REQUEST_GET_MATERIALS_LIST:
    case ActionType.REQUEST_GET_MATERIALS_LIST_WITH_RELATED_ENTITIES: {
      const fetchedEntity =
        state.materialsLists &&
        state.materialsLists[action.payload.materialsListId];

      return {
        ...state,
        materialsLists: {
          ...state.materialsLists,
          [action.payload.materialsListId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_MATERIALS_LIST_FAILURE: {
      const fetchedEntity =
        state.materialsLists &&
        state.materialsLists[action.payload.materialsListId];

      return {
        ...state,
        materialsLists: {
          ...state.materialsLists,
          [action.payload.materialsListId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.GET_MATERIALS_LIST_SUCCESS: {
      const fetchedEntity =
        state.materialsLists &&
        state.materialsLists[action.payload.materialsListId];

      return {
        ...state,
        materialsLists: {
          ...state.materialsLists,
          [action.payload.materialsListId]: {
            ...fetchedEntity,
            entity: action.payload.materialsList,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.REQUEST_GET_MATERIALS_LIST_MATERIAL:
    case ActionType.REQUEST_GET_MATERIALS_LIST_MATERIAL_WITH_RELATED_ENTITIES: {
      const fetchedEntity =
        state.materialsListMaterials &&
        state.materialsListMaterials[action.payload.materialsListMaterialId];

      return {
        ...state,
        materialsListMaterials: {
          ...state.materialsListMaterials,
          [action.payload.materialsListMaterialId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_MATERIALS_LIST_MATERIAL_FAILURE: {
      const fetchedEntity =
        state.materialsListMaterials &&
        state.materialsListMaterials[action.payload.materialsListMaterialId];

      return {
        ...state,
        materialsListMaterials: {
          ...state.materialsListMaterials,
          [action.payload.materialsListMaterialId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.GET_MATERIALS_LIST_MATERIAL_SUCCESS: {
      const fetchedEntity =
        state.materialsListMaterials &&
        state.materialsListMaterials[action.payload.materialsListMaterialId];

      return {
        ...state,
        materialsListMaterials: {
          ...state.materialsListMaterials,
          [action.payload.materialsListMaterialId]: {
            ...fetchedEntity,
            entity: action.payload.materialsListMaterial,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.REQUEST_GET_MATERIAL_PURCHASE_RECORD: {
      const fetchedEntity =
        state.materialPurchaseRecords &&
        state.materialPurchaseRecords[action.payload.materialPurchaseRecordId];

      return {
        ...state,
        materialPurchaseRecords: {
          ...state.materialPurchaseRecords,
          [action.payload.materialPurchaseRecordId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_MATERIAL_PURCHASE_RECORD_FAILURE: {
      const fetchedEntity =
        state.materialPurchaseRecords &&
        state.materialPurchaseRecords[action.payload.materialPurchaseRecordId];

      return {
        ...state,
        materialPurchaseRecords: {
          ...state.materialPurchaseRecords,
          [action.payload.materialPurchaseRecordId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.GET_MATERIAL_PURCHASE_RECORD_SUCCESS: {
      const fetchedEntity =
        state.materialPurchaseRecords &&
        state.materialPurchaseRecords[action.payload.materialPurchaseRecordId];

      return {
        ...state,
        materialPurchaseRecords: {
          ...state.materialPurchaseRecords,
          [action.payload.materialPurchaseRecordId]: {
            ...fetchedEntity,
            entity: action.payload.materialPurchaseRecord,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.REQUEST_GET_PURCHASE_ORDER: {
      const fetchedEntity =
        state.purchaseOrders &&
        state.purchaseOrders[action.payload.purchaseOrderId];

      return {
        ...state,
        purchaseOrders: {
          ...state.purchaseOrders,
          [action.payload.purchaseOrderId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_PURCHASE_ORDER_FAILURE: {
      const fetchedEntity =
        state.purchaseOrders &&
        state.purchaseOrders[action.payload.purchaseOrderId];

      return {
        ...state,
        purchaseOrders: {
          ...state.purchaseOrders,
          [action.payload.purchaseOrderId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.GET_PURCHASE_ORDER_SUCCESS: {
      const fetchedEntity =
        state.purchaseOrders &&
        state.purchaseOrders[action.payload.purchaseOrderId];

      return {
        ...state,
        purchaseOrders: {
          ...state.purchaseOrders,
          [action.payload.purchaseOrderId]: {
            ...fetchedEntity,
            entity: action.payload.purchaseOrder,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.REQUEST_GET_MATERIAL_PURCHASE_INTENT: {
      const fetchedEntity =
        state.materialPurchaseIntents &&
        state.materialPurchaseIntents[action.payload.materialPurchaseIntentId];

      return {
        ...state,
        materialPurchaseIntents: {
          ...state.materialPurchaseIntents,
          [action.payload.materialPurchaseIntentId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_MATERIAL_PURCHASE_INTENT_FAILURE: {
      const fetchedEntity =
        state.materialPurchaseIntents &&
        state.materialPurchaseIntents[action.payload.materialPurchaseIntentId];

      return {
        ...state,
        materialPurchaseIntents: {
          ...state.materialPurchaseIntents,
          [action.payload.materialPurchaseIntentId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.GET_MATERIAL_PURCHASE_INTENT_SUCCESS: {
      const fetchedEntity =
        state.materialPurchaseIntents &&
        state.materialPurchaseIntents[action.payload.materialPurchaseIntentId];

      return {
        ...state,
        materialPurchaseIntents: {
          ...state.materialPurchaseIntents,
          [action.payload.materialPurchaseIntentId]: {
            ...fetchedEntity,
            entity: action.payload.materialPurchaseIntent,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.REQUEST_PERSIST_PURCHASE_ORDER:
      return {
        ...state,
        isPersistingPurchaseOrder: true,
      };
    case ActionType.PERSIST_PURCHASE_ORDER_FAILURE:
    case ActionType.PERSIST_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        isPersistingPurchaseOrder: false,
      };

    case ActionType.CLEAR_MATERIALS_LISTS:
      return {
        ...state,
        materialsLists: {},
      };

    case ActionType.CLEAR_MATERIALS_LIST_MATERIALS:
      return {
        ...state,
        materialsListMaterials: {},
      };

    case ActionType.CLEAR_MATERIAL_PURCHASE_RECORDS:
      return {
        ...state,
        materialPurchaseRecords: {},
      };

    case ActionType.CLEAR_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrders: {},
      };

    case ActionType.GET_MATERIALS_LIST_MATERIALS_FOR_MATERIALS_LIST_SUCCESS:
      return {
        ...state,
        materialsListMaterials: mergeFetchedEntities({
          cache: state.materialsListMaterials,
          values: action.payload,
        }),
      };

    case ActionType.GET_MATERIALS_LIST_WITH_RELATED_ENTITIES_SUCCESS: {
      const now = new Date();
      return {
        ...state,
        materialsLists: mergeFetchedEntities({
          cache: state.materialsLists,
          values: [action.payload.materialsList],
          now,
        }),
        materialsListMaterials: mergeFetchedEntities({
          cache: state.materialsListMaterials,
          values: action.payload.materialsListMaterials,
          now,
        }),
        purchaseOrders: mergeFetchedEntities({
          cache: state.purchaseOrders,
          values: action.payload.purchaseOrders,
          now,
        }),
        materialPurchaseRecords: mergeFetchedEntities({
          cache: state.materialPurchaseRecords,
          values: action.payload.materialPurchaseRecords,
          now,
        }),
        materialPurchaseIntents: mergeFetchedEntities({
          cache: state.materialPurchaseIntents,
          values: action.payload.materialPurchaseIntents,
          now,
        }),
      };
    }

    case ActionType.GET_MATERIALS_LIST_MATERIAL_WITH_RELATED_ENTITIES_SUCCESS: {
      const now = new Date();
      return {
        ...state,
        materialsListMaterials: mergeFetchedEntities({
          cache: state.materialsListMaterials,
          values: [action.payload.materialsListMaterial],
          now,
        }),
        materialPurchaseRecords: mergeFetchedEntities({
          cache: state.materialPurchaseRecords,
          values: action.payload.materialPurchaseRecords,
          now,
        }),
      };
    }

    default:
      return state;
  }
};

export default materialsReducer;
