import React, { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import Card, { CardProps } from './Card';

export interface IProps extends CardProps<'button'> {}

const CardButton: FC<PropsWithChildren<IProps>> = (props) => {
  const { className, children, ...rest } = props;

  return (
    <Card
      as="button"
      className={twMerge(
        'w-full p-0 text-left hover:cursor-pointer hover:bg-gray-50',
        className
      )}
      {...rest}
    >
      {children}
    </Card>
  );
};

export default Object.assign(CardButton, { ...Card });
