import { AccountsRoles } from './accounts/accounts.roles';
import { AnalyticsRoles } from './analytics/analytics.roles';
import { DashboardRoles } from './dashboard/dashboard.roles';
import { CustomersRoles } from './customers/customers.roles';
import { DealsRoles } from './deals/deals.roles';
import { DocumentsRoles } from './documents/documents.roles';
import { JobRoles } from './jobs/job.roles';
import { LineItemsRoles } from './lineItems/line-items.roles';
import { ScheduledEventsRoles } from './scheduledEvents/scheduled-events.roles';
import { TasksRoles } from './tasks/tasks.roles';
import { InvoicesRoles } from './invoices/invoices.roles';
import { PaymentsRoles } from './payments/payments.roles';
import { PipelinesRoles } from './pipelines/pipelines.roles';
import { AutomationsRoles } from './automations/automations.roles';
import { SuppliersRoles } from './suppliers/suppliers.roles';
import { MaterialsRoles } from './materials/materials.roles';
import { UploadsRoles } from './uploads/uploads.roles';
import { MaterialsListRoles } from './materialsList/materialsList.roles';
import { ProposalsRoles } from './proposals/proposals.roles';
import { TaxRateRoles } from './tax-rates/tax-rates.roles';
import { UserAssignmentsRoles } from './userAssignments/userAssignments.roles';
import { ServicePlanRoles } from './service-plans/service-plans.roles';
import { DispatchRoles } from './dispatch/dispatch.roles';
import { TemplatesRoles } from './templates/templates.roles';
import { TimelogsRoles } from './timelogs/timelogs.roles';
import { IntegratedEmailsRoles } from './integrated-emails/integrated-emails.roles';
import { UserRoles } from './user/user.roles';
import { ContactRoles } from './contacts/contacts.roles';
import { AddressRoles } from './addresses/addresses.roles';

export enum DefaultSystemRoles {
  SUPER_ADMIN = 'Super Admin',
  ADMIN = 'Admin',
  SALES_MANAGER = 'Sales Manager',
  SALES_AGENT = 'Sales Agent',
  FIELD_AGENT = 'Field Agent',
}

export enum SubscriptionLevels {
  SOLO = 'Solo',
  TEAM = 'Team', // deprecated
  BUSINESS = 'Business', // deprecated
  PRO = 'Pro', // deprecrating when we launch new subscriptions - pro v2 and business v2
  BUSINESS_V2 = 'Business_v2',
  PRO_V2 = 'Pro_v2',
}

export type SusbcriptionFeatureName =
  | 'hasZapier'
  | 'hasForms'
  | 'hasCustomAutomationsOnAnyPlan'
  | 'hasDispatch'
  | 'hasSystemManagedMaterials'
  | 'hasCustomAutomations'
  | 'hasClientPortal'
  | 'hasCustomerCommissionMarkup'
  | 'hasAdditionalWorkRequests'
  | 'hasIntegratedEmails'
  | 'hasENAConnectionApplication';

// Once an account's free trial has expired, we only want to grant a limited
// subset of their users' roles. We will use this array to filter a given user's
// roles to only include those that might allow them to purchase a subscription
// and thereby reactivate.
export const expiredTrialRoles = [
  AccountsRoles.EDITOR,
  AccountsRoles.PERSONAL_EDITOR,
  AccountsRoles.USERS_VIEWER,
  AccountsRoles.VIEWER,
  AccountsRoles.SUBSCRIPTION_EDITOR,
  AnalyticsRoles.VIEWER,
  CustomersRoles.VIEWER,
  DashboardRoles.VIEWER,
  DealsRoles.VIEWER,
  DocumentsRoles.VIEWER,
  InvoicesRoles.VIEWER,
  JobRoles.VIEWER,
  LineItemsRoles.GROUP_VIEWER,
  LineItemsRoles.VIEWER,
  MaterialsRoles.LINE_ITEM_MATERIALS_VIEWER,
  MaterialsRoles.SUPPLIER_MATERIALS_VIEWER,
  MaterialsRoles.VIEWER,
  MaterialsListRoles.PURCHASE_ORDER_VIEWER,
  MaterialsListRoles.PURCHASE_RECORD_VIEWER,
  MaterialsListRoles.VIEWER,
  PaymentsRoles.VIEWER,
  PipelinesRoles.VIEWER,
  ProposalsRoles.VIEWER,
  ScheduledEventsRoles.ASSIGNEE_VIEWER,
  ScheduledEventsRoles.VIEWER,
  TasksRoles.ASSIGNEE_VIEWER,
  TasksRoles.VIEWER,
  TaxRateRoles.VIEWER,
  UploadsRoles.VIEWER,
  ServicePlanRoles.VIEWER,
];

export const rolesByAddOnProductNameAndSystemRole: Partial<
  Record<string, Record<DefaultSystemRoles, Array<string>>>
> = {
  'Service Plans': {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [],
    [DefaultSystemRoles.SALES_MANAGER]: [
      ServicePlanRoles.VIEWER,
      ServicePlanRoles.PERIOD_MANAGER,
    ],
    [DefaultSystemRoles.ADMIN]: [
      ServicePlanRoles.VIEWER,
      ServicePlanRoles.PERIOD_MANAGER,
      ServicePlanRoles.DISCOUNT_MANAGER,
    ],
    [DefaultSystemRoles.SUPER_ADMIN]: [
      ServicePlanRoles.VIEWER,
      ServicePlanRoles.PERIOD_MANAGER,
      ServicePlanRoles.PERSISTER,
      ServicePlanRoles.DISCOUNT_MANAGER,
    ],
  },
};
export const rolesBySubscriptionFeatureNameAndSystemRole: Partial<
  Record<SusbcriptionFeatureName, Record<DefaultSystemRoles, Array<string>>>
> = {
  hasSystemManagedMaterials: {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [MaterialsRoles.SYSTEM_MANAGED_VIEWER],
    [DefaultSystemRoles.SALES_MANAGER]: [MaterialsRoles.SYSTEM_MANAGED_VIEWER],
    [DefaultSystemRoles.ADMIN]: [MaterialsRoles.SYSTEM_MANAGED_VIEWER],
    [DefaultSystemRoles.SUPER_ADMIN]: [MaterialsRoles.SYSTEM_MANAGED_VIEWER],
  },
  hasCustomAutomationsOnAnyPlan: {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [],
    [DefaultSystemRoles.SALES_MANAGER]: [],
    [DefaultSystemRoles.ADMIN]: [
      AutomationsRoles.EDITOR,
      AutomationsRoles.CREATOR,
      AutomationsRoles.SIMPLE_EDITOR,
      AutomationsRoles.SIMPLE_CREATOR,
      AutomationsRoles.VIEWER,
    ],
    [DefaultSystemRoles.SUPER_ADMIN]: [
      AutomationsRoles.EDITOR,
      AutomationsRoles.CREATOR,
      AutomationsRoles.SIMPLE_EDITOR,
      AutomationsRoles.SIMPLE_CREATOR,
      AutomationsRoles.VIEWER,
    ],
  },
  hasDispatch: {
    [DefaultSystemRoles.FIELD_AGENT]: [DispatchRoles.VIEWER],
    [DefaultSystemRoles.SALES_AGENT]: [DispatchRoles.VIEWER],
    [DefaultSystemRoles.SALES_MANAGER]: [DispatchRoles.VIEWER],
    [DefaultSystemRoles.ADMIN]: [DispatchRoles.VIEWER],
    [DefaultSystemRoles.SUPER_ADMIN]: [DispatchRoles.VIEWER],
  },
  hasClientPortal: {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [],
    [DefaultSystemRoles.SALES_MANAGER]: [
      DealsRoles.UNSHARE_DEAL_FILES,
      CustomersRoles.CLIENT_PORTAL_SHARE_SETTINGS_MANAGER,
    ],
    [DefaultSystemRoles.ADMIN]: [
      DealsRoles.UNSHARE_DEAL_FILES,
      CustomersRoles.CLIENT_PORTAL_SHARE_SETTINGS_MANAGER,
    ],
    [DefaultSystemRoles.SUPER_ADMIN]: [
      DealsRoles.UNSHARE_DEAL_FILES,
      CustomersRoles.CLIENT_PORTAL_SHARE_SETTINGS_MANAGER,
    ],
  },
  hasCustomerCommissionMarkup: {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [],
    [DefaultSystemRoles.SALES_MANAGER]: [],
    [DefaultSystemRoles.ADMIN]: [CustomersRoles.COMMISSION_DEDUCTION_MANAGER],
    [DefaultSystemRoles.SUPER_ADMIN]: [
      CustomersRoles.COMMISSION_DEDUCTION_MANAGER,
    ],
  },
  hasAdditionalWorkRequests: {
    [DefaultSystemRoles.FIELD_AGENT]: [DealsRoles.ADDITIONAL_WORK_REQUESTER],
    [DefaultSystemRoles.SALES_AGENT]: [DealsRoles.ADDITIONAL_WORK_REQUESTER],
    [DefaultSystemRoles.SALES_MANAGER]: [DealsRoles.ADDITIONAL_WORK_REQUESTER],
    [DefaultSystemRoles.ADMIN]: [DealsRoles.ADDITIONAL_WORK_REQUESTER],
    [DefaultSystemRoles.SUPER_ADMIN]: [DealsRoles.ADDITIONAL_WORK_REQUESTER],
  },
  hasIntegratedEmails: {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [IntegratedEmailsRoles.SENDER],
    [DefaultSystemRoles.SALES_MANAGER]: [IntegratedEmailsRoles.SENDER],
    [DefaultSystemRoles.ADMIN]: [IntegratedEmailsRoles.SENDER],
    [DefaultSystemRoles.SUPER_ADMIN]: [IntegratedEmailsRoles.SENDER],
  },
  hasENAConnectionApplication: {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [],
    [DefaultSystemRoles.SALES_MANAGER]: [],
    [DefaultSystemRoles.ADMIN]: [DealsRoles.COMPLIANCE_FORM_VIEWER],
    [DefaultSystemRoles.SUPER_ADMIN]: [DealsRoles.COMPLIANCE_FORM_VIEWER],
  },
};

// Field Agent Roles
const FieldAgentRolesSolo = [
  AccountsRoles.VIEWER,
  AccountsRoles.PERSONAL_EDITOR,
  AccountsRoles.USERS_VIEWER,
  ScheduledEventsRoles.ASSIGNEE_VIEWER,
  ScheduledEventsRoles.ASSIGNEE_CREATOR,
  ScheduledEventsRoles.ASSIGNEE_EDITOR,
  ScheduledEventsRoles.TAGGER,
  TasksRoles.ASSIGNEE_COMPLETER,
  TasksRoles.ASSIGNEE_VIEWER,
  TasksRoles.CREATOR_DELETER,
  TasksRoles.CREATOR_PERSISTER,

  UploadsRoles.VIEWER,
  UploadsRoles.PERSISTER,
  DocumentsRoles.ASSIGNEE_EDITOR,
  TaxRateRoles.VIEWER,
  MaterialsListRoles.SELF_ASSIGNED_EVENT_DEAL_PURCHASE_ORDER_VIEWER,
  TimelogsRoles.ASSIGNEE_VIEWER,
  TimelogsRoles.ASSIGNEE_CREATOR,
  TimelogsRoles.TYPES_VIEWER,
  TimelogsRoles.ASSIGNEE_UPDATER,
];
const FieldAgentRolesPro = [...FieldAgentRolesSolo];
const FieldAgentRolesProv2 = FieldAgentRolesPro;
const FieldAgentRolesTeam = FieldAgentRolesPro;
const FieldAgentRolesBusiness = [...FieldAgentRolesPro];
const FieldAgentRolesBusinessV2 = [
  ...FieldAgentRolesPro,
  TimelogsRoles.ASSIGNEE_VIEWER,
  TimelogsRoles.ASSIGNEE_CREATOR,
  TimelogsRoles.TYPES_VIEWER,
  TimelogsRoles.ASSIGNEE_UPDATER,
  TasksRoles.EVENT_ASSIGNEE_VIEWER,
  TasksRoles.EVENT_ASSIGNEE_COMPLETER,
  AccountsRoles.CUSTOM_EMAIL_DOMAIN_SENDER,
];

// Sales Agent Roles
const SalesAgentRolesSolo = [
  ...FieldAgentRolesSolo,
  DealsRoles.ASSIGNEE_VIEWER,
  DealsRoles.ASSIGNEE_EDITOR,
  PipelinesRoles.VIEWER,
  DocumentsRoles.VIEWER,
  DocumentsRoles.CREATOR,
  DocumentsRoles.EDITOR,
  InvoicesRoles.VIEWER,
  InvoicesRoles.PERSISTER_SENDER,
  InvoicesRoles.MANAGER,
  JobRoles.CREATOR,
  JobRoles.VIEWER,
  JobRoles.EDITOR,
  ScheduledEventsRoles.DEAL_ASSIGNEE_EDITOR,
  ScheduledEventsRoles.DEAL_ASSIGNEE_VIEWER,
  ScheduledEventsRoles.DEAL_ASSIGNEE_CREATOR,
  CustomersRoles.VIEWER,
  CustomersRoles.EDITOR,
  LineItemsRoles.VIEWER,
  LineItemsRoles.GROUP_VIEWER,
  MaterialsRoles.VIEWER,
  MaterialsRoles.LINE_ITEM_MATERIALS_VIEWER,
  MaterialsRoles.SUPPLIER_MATERIALS_VIEWER,
  MaterialsListRoles.PURCHASE_RECORD_VIEWER,
  MaterialsListRoles.PURCHASE_RECORD_PERSISTER,
  MaterialsListRoles.PURCHASE_RECORD_DELETER,
  MaterialsListRoles.PURCHASE_ORDER_VIEWER,
  MaterialsListRoles.PURCHASE_ORDER_PERSISTER,
  MaterialsListRoles.PURCHASE_ORDER_SENDER,
  MaterialsListRoles.PURCHASE_ORDER_CONFIRMER,
  SuppliersRoles.VIEWER,
  PaymentsRoles.RECORDER,
  PaymentsRoles.VIEWER,
  ProposalsRoles.VIEWER,
  ProposalsRoles.PERSISTER_SENDER,
  ProposalsRoles.MANAGER,
  TasksRoles.DEAL_ASSIGNEE_VIEWER,
  TasksRoles.DEAL_ASSIGNEE_COMPLETER,
  TasksRoles.DEAL_ASSIGNEE_PERSISTER,
  TemplatesRoles.VIEWER,
  MaterialsListRoles.PERSISTER,
  MaterialsListRoles.POPULATOR,
  MaterialsListRoles.VIEWER,
  PipelinesRoles.VIEWER,
  DispatchRoles.PREVIEWER,
  UserRoles.CONNECTION_EDITOR,
  ContactRoles.MANAGER,
  AddressRoles.MANAGER,
];
const SalesAgentRolesPro = [...FieldAgentRolesPro, ...SalesAgentRolesSolo];
const SalesAgentRolesProv2 = [...FieldAgentRolesProv2, ...SalesAgentRolesPro];
const SalesAgentRolesTeam = [...FieldAgentRolesTeam, ...SalesAgentRolesProv2];
const SalesAgentRolesBusiness = [
  ...FieldAgentRolesBusiness,
  ...SalesAgentRolesTeam,
];
const SalesAgentRolesBusinessV2 = [
  ...FieldAgentRolesBusinessV2,
  ...SalesAgentRolesBusiness,
  TemplatesRoles.PROPOSAL_TEMPLATES_USER,
  AccountsRoles.CUSTOM_FIELD_VALUE_MANAGER,
];

// Sales Manager Roles
const SalesManagerRolesSolo = [
  ...SalesAgentRolesSolo,
  ScheduledEventsRoles.VIEWER,
  ScheduledEventsRoles.EDITOR,
  ScheduledEventsRoles.CREATOR,
  CustomersRoles.BULK_IMPORTER,
  CustomersRoles.COLLECTION_MANAGER,
  DealsRoles.VIEWER,
  DealsRoles.EDITOR,
  DocumentsRoles.PREFILL_PREFERENCES_MANAGER,
  LineItemsRoles.CREATOR,
  LineItemsRoles.BASIC_EDITOR,
  LineItemsRoles.EDITOR,
  LineItemsRoles.COLLECTION_MANAGER,
  LineItemsRoles.DELETER,
  LineItemsRoles.GROUP_CREATOR,
  LineItemsRoles.GROUP_EDITOR,
  LineItemsRoles.BULK_IMPORTER,
  MaterialsRoles.PERSISTER,
  MaterialsRoles.ARCHIVER,
  MaterialsRoles.COLLECTION_MANAGER,
  MaterialsRoles.LINE_ITEM_MATERIALS_PERSISTER,
  MaterialsRoles.LINE_ITEM_MATERIALS_DELETER,
  MaterialsRoles.SUPPLIER_MATERIALS_PERSISTER,
  MaterialsRoles.SUPPLIER_MATERIALS_DELETER,
  MaterialsRoles.BULK_IMPORTER,
  MaterialsRoles.SUPPLIER_MATERIALS_BULK_IMPORTER,
  MaterialsListRoles.DELETER,
  SuppliersRoles.COLLECTION_MANAGER,
  ScheduledEventsRoles.BULK_IMPORTER,
  SuppliersRoles.ARCHIVER,
  SuppliersRoles.PERSISTER,
  TasksRoles.COMPLETER,
  TasksRoles.PERSISTER,
  TasksRoles.DELETER,
  TasksRoles.VIEWER,
  TaxRateRoles.PERSISTER,
  TaxRateRoles.DELETER,
  UserAssignmentsRoles.ASSIGNER,
  DispatchRoles.PREVIEWER,
  TimelogsRoles.ASSIGNEE_DELETER,
];
const SalesManagerRolesPro = [...SalesAgentRolesPro, ...SalesManagerRolesSolo];
const SalesManagerRolesTeam = SalesManagerRolesPro;
const SalesManagerRolesBusiness = SalesManagerRolesPro;
const SalesManagerRolesProV2 = SalesManagerRolesPro;
const SalesManagerRolesBusinessV2 = [
  ...SalesManagerRolesPro,
  ...SalesAgentRolesBusinessV2,
  DispatchRoles.VIEWER,
];

// Admin Roles
const AdminRolesSolo = [
  ...SalesManagerRolesSolo,
  InvoicesRoles.LISTED_VIEWER,
  PaymentsRoles.BULK_RECORDER,
  ServicePlanRoles.PREVIEWER,
  TimelogsRoles.PREVIEWER,
  DispatchRoles.PREVIEWER,
  AccountsRoles.EDITOR, // account & user management
  AnalyticsRoles.VIEWER, // business performance page
  DashboardRoles.VIEWER, // dashboard
  TemplatesRoles.PERSISTER,
  TemplatesRoles.DELETER,
  TemplatesRoles.COLLECTION_MANAGER,
  MaterialsListRoles.PURCHASE_ORDER_VOIDER,
  MaterialsListRoles.ISOLATED_PURCHASE_ORDER_PERSISTER,
  MaterialsListRoles.ISOLATED_PURCHASE_ORDER_CONFIRMER,
  MaterialsListRoles.ISOLATED_PURCHASE_ORDER_SENDER,
  MaterialsListRoles.ISOLATED_PURCHASE_ORDER_VOIDER,
  TimelogsRoles.VIEWER,
  TimelogsRoles.UPDATER,
  TimelogsRoles.CREATOR,
  TimelogsRoles.DELETER,
  TimelogsRoles.TYPES_MANAGER,
  TimelogsRoles.COST_ADMINISTRATOR,
  AccountsRoles.USERS_COST_PER_HOUR_EDITOR,
  AccountsRoles.USERS_COST_PER_HOUR_VIEWER,
  CustomersRoles.BULK_EXPORTER,
  AccountsRoles.TAG_MANAGER,
];
const AdminRolesPro = [
  ...SalesManagerRolesPro,
  ...AdminRolesSolo,
  AutomationsRoles.VIEWER,
  AutomationsRoles.DEFAULT_VIEWER,
  AutomationsRoles.DEFAULT_MANAGER,
  UploadsRoles.DELETER,
  PipelinesRoles.CREATOR,
];
const AdminRolesTeam = AdminRolesPro;
const AdminRolesBusiness = AdminRolesPro;
const AdminRolesProV2 = AdminRolesPro;
const AdminRolesBusinessV2 = [
  ...AdminRolesPro,
  ...SalesManagerRolesBusinessV2,
  AccountsRoles.USERS_COST_PER_HOUR_EDITOR,
  AccountsRoles.USERS_COST_PER_HOUR_VIEWER,
  AutomationsRoles.VIEWER,
  AutomationsRoles.CREATOR,
  AutomationsRoles.EDITOR,
  AutomationsRoles.SIMPLE_EDITOR,
  AutomationsRoles.SIMPLE_CREATOR,
  AccountsRoles.CUSTOM_EMAIL_DOMAIN_EDITOR,
  AccountsRoles.CUSTOM_FIELD_GROUP_MANAGER,
];

// Super Admin Roles
const SuperAdminRolesSolo = [
  ...AdminRolesSolo,
  AccountsRoles.SUBSCRIPTION_EDITOR,
];
const SuperAdminRolesPro = [
  ...AdminRolesPro,
  ...SuperAdminRolesSolo,
  PipelinesRoles.EDITOR,
];
const SuperAdminRolesTeam = SuperAdminRolesPro;
const SuperAdminRolesBusiness = SuperAdminRolesPro;
const SuperAdminRolesProV2 = SuperAdminRolesPro;
const SuperAdminRolesBusinessV2 = [
  ...AdminRolesBusinessV2,
  ...SuperAdminRolesBusiness,
];

export const roleInheritanceMappings = {
  [SubscriptionLevels.SOLO]: {
    [DefaultSystemRoles.SUPER_ADMIN]: SuperAdminRolesSolo,
    [DefaultSystemRoles.ADMIN]: AdminRolesSolo,
    [DefaultSystemRoles.SALES_MANAGER]: SalesManagerRolesSolo,
    [DefaultSystemRoles.SALES_AGENT]: SalesAgentRolesSolo,
    [DefaultSystemRoles.FIELD_AGENT]: FieldAgentRolesSolo,
  },
  [SubscriptionLevels.TEAM]: {
    [DefaultSystemRoles.SUPER_ADMIN]: SuperAdminRolesTeam,
    [DefaultSystemRoles.ADMIN]: AdminRolesTeam,
    [DefaultSystemRoles.SALES_MANAGER]: SalesManagerRolesTeam,
    [DefaultSystemRoles.SALES_AGENT]: SalesAgentRolesTeam,
    [DefaultSystemRoles.FIELD_AGENT]: FieldAgentRolesTeam,
  },
  [SubscriptionLevels.BUSINESS]: {
    [DefaultSystemRoles.SUPER_ADMIN]: SuperAdminRolesBusiness,
    [DefaultSystemRoles.ADMIN]: AdminRolesBusiness,
    [DefaultSystemRoles.SALES_MANAGER]: SalesManagerRolesBusiness,
    [DefaultSystemRoles.SALES_AGENT]: SalesAgentRolesBusiness,
    [DefaultSystemRoles.FIELD_AGENT]: FieldAgentRolesBusiness,
  },
  [SubscriptionLevels.PRO]: {
    [DefaultSystemRoles.SUPER_ADMIN]: SuperAdminRolesPro,
    [DefaultSystemRoles.ADMIN]: AdminRolesPro,
    [DefaultSystemRoles.SALES_MANAGER]: SalesManagerRolesPro,
    [DefaultSystemRoles.SALES_AGENT]: SalesAgentRolesPro,
    [DefaultSystemRoles.FIELD_AGENT]: FieldAgentRolesPro,
  },
  [SubscriptionLevels.PRO_V2]: {
    [DefaultSystemRoles.SUPER_ADMIN]: SuperAdminRolesProV2,
    [DefaultSystemRoles.ADMIN]: AdminRolesProV2,
    [DefaultSystemRoles.SALES_MANAGER]: SalesManagerRolesProV2,
    [DefaultSystemRoles.SALES_AGENT]: SalesAgentRolesProv2,
    [DefaultSystemRoles.FIELD_AGENT]: FieldAgentRolesProv2,
  },
  [SubscriptionLevels.BUSINESS_V2]: {
    [DefaultSystemRoles.SUPER_ADMIN]: SuperAdminRolesBusinessV2,
    [DefaultSystemRoles.ADMIN]: AdminRolesBusinessV2,
    [DefaultSystemRoles.SALES_MANAGER]: SalesManagerRolesBusinessV2,
    [DefaultSystemRoles.SALES_AGENT]: SalesAgentRolesBusinessV2,
    [DefaultSystemRoles.FIELD_AGENT]: FieldAgentRolesBusinessV2,
  },
};
