import { FC } from 'react';
import useGetUploads from '@/api/queries/useGetUploads';
import { useParams } from 'react-router-dom';
import _get from 'lodash.get';
import useGetConnectionApplication from '@/api/queries/connectionApplication/useGetConnectionApplication';
import ProjectFileCard from '@/ui/pages/connectionApplicationPage/components/ProjectFileCard';
import { isMultipleFileSelection } from '../utils';

export interface IProps {
  name: string;
  formSection: any;
}

const ProjectFileSelectionReadonly: FC<IProps> = (props) => {
  const { formSection, name } = props;

  /**
   * Router
   */
  const { connectionApplicationId, projectId } = useParams<{
    projectId: string;
    connectionApplicationId: string;
  }>();

  /**
   * Queries
   */
  const { data: persistedFormStateData } = useGetConnectionApplication(
    connectionApplicationId,
    false
  );

  /**
   * Queries
   */
  const { data } = useGetUploads(
    {
      sourceEntities: [
        {
          type: 'deal',
          id: projectId,
        },
      ],
    },
    {
      offset: 0,
      limit: 100,
    }
  );

  const multiple = isMultipleFileSelection(formSection);
  const fieldName = multiple
    ? `${name}.projectFileIDs`
    : `${name}.projectFileID`;

  const value = _get(
    persistedFormStateData?.connectionApplication.data,
    fieldName
  );

  return (
    <ProjectFileCard.Wrapper>
      {data?.uploads?.items
        .filter((file) => value?.includes(file.id))
        .map((file) => (
          <ProjectFileCard
            key={file.id}
            thumbnailUrl={file.thumbnailUrl || ''}
            fileName={file.fileName}
          />
        ))}
    </ProjectFileCard.Wrapper>
  );
};

export default ProjectFileSelectionReadonly;
