import React, { FC } from 'react';
import { formatPrice } from '@payaca/helpers/financeHelper';

export interface ICurrencyValueProps {
  value: number;
  currency: Currency;
}

type Currency = {
  code: string;
  exponent: number;
};

const CurrencyValue: FC<ICurrencyValueProps> = (props) => {
  const { value, currency } = props;

  const f = formatPrice(navigator.language);

  return (
    <>
      {f({
        priceInMinorCurrencyUnits: value,
        exponent: currency.exponent,
        currencyCode: currency.code,
      })}
    </>
  );
};

export default CurrencyValue;
