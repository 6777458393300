import React, { FC, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import EntityTagControl from '@/ui/components/entityTagControl/EntityTagControl';
import { TaggableEntityType } from '@payaca/types/tagTypes';
import customerKeys from '@/api/queries/customers/keyFactory';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Address2Lines from '@payaca/components/address/Address2Lines';
import AddCardButton from '@payaca/components/addCard/AddCardButton';
import FinancialSummaryCard, {
  IProps as IFinancialSummaryProps,
} from '@/ui/components/financialSummaryCard/FinancialSummaryCard';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import useGetCustomer from '@/api/queries/customers/useGetCustomer';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import GoogleMapAnchor from '@payaca/components/googleMapAnchor/GoogleMapAnchor';
import Button from '@payaca/components/plButton/Button';

interface IProps {
  onEditClick?: () => void;
}

const CustomerPageHeaders: FC<IProps> = (props) => {
  const { onEditClick } = props;

  const { customerId } = useParams<{ customerId: string }>();
  const queryClient = useQueryClient();
  const { data: customerData, isLoading } = useGetCustomer(customerId);
  const history = useHistory();
  const { path, url } = useRouteMatch();

  const financialSummary = useMemo<
    IFinancialSummaryProps['financialSummary']
  >(() => {
    const totals = customerData?.customer.totals;

    if (!totals) return;

    return {
      totalProjectValue: totals.projectValue.value,
      currency: totals.acceptedValue.currency,
      toBeInvoiced: totals.acceptedValue.value - totals.sentInvoicedValue.value,
      toBePaid:
        totals.sentInvoicedValue.value - totals.completedPaymentValue.value,
      paid: totals.completedPaymentValue.value,
    };
  }, [customerData]);

  if (isLoading || !customerData) {
    return (
      <div>
        <SkeletonLoader.Title className="w-1/6" />

        <div className="mb-4 mt-5 grid grid-cols-3 gap-4">
          <Card sizeVariant={CardSizeVariant.SM}>
            <Card.Body className="space-y-4">
              <SkeletonLoader.Text className="w-1/2" />
              <SkeletonLoader.Text className="w-1/4" />
              <SkeletonLoader.Text className="w-1/4" />
              <SkeletonLoader.Text className="w-1/4" />
            </Card.Body>
          </Card>

          <Card sizeVariant={CardSizeVariant.SM}>
            <Card.Body className="space-y-4">
              <SkeletonLoader.Text className="w-1/2" />
              <SkeletonLoader.Text className="w-1/4" />
              <SkeletonLoader.Text className="w-1/4" />
              <SkeletonLoader.Text className="w-1/4" />
            </Card.Body>
          </Card>

          <Card sizeVariant={CardSizeVariant.SM}>
            <Card.Body className="space-y-4">
              <SkeletonLoader.Text className="w-1/2" />
              <SkeletonLoader.Text className="w-1/4" />
              <SkeletonLoader.Text className="w-1/4" />
              <SkeletonLoader.Text className="w-1/4" />
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="mb-4 flex items-baseline gap-4">
          <h2>{customerData?.customer.name}</h2>

          <Button variant={EBtnVariant.LinkInline} onClick={onEditClick}>
            Edit
          </Button>
        </div>

        <EntityTagControl
          entityId={+customerId}
          entityType={TaggableEntityType.CUSTOMER}
          entityTags={
            customerData?.customer?.tags.map((tag) => ({
              id: +tag.id,
              tagText: tag.name,
              tagColour: tag.colour || '',
              isSystemManaged: false,
            })) || []
          }
          onEntityTagsChange={() =>
            queryClient.invalidateQueries({
              queryKey: customerKeys.customer(customerId),
            })
          }
        />
      </div>

      <div className="mb-4 grid gap-4 xl:grid-cols-3">
        {customerData?.customer.primaryContact && (
          <Card sizeVariant={CardSizeVariant.SM}>
            <Card.Body>
              <h4>Primary Contact</h4>

              <p>{customerData.customer.primaryContact.name}</p>
              <p>{customerData.customer.primaryContact.description}</p>
              <p>
                <a
                  href={`mailto:${customerData.customer.primaryContact.email}`}
                >
                  {customerData.customer.primaryContact.email}
                </a>
              </p>
              <p>
                <a href={`tel:${customerData.customer.primaryContact.phone}`}>
                  {customerData.customer.primaryContact.phone}
                </a>
              </p>
            </Card.Body>
          </Card>
        )}

        {customerData?.customer.billingAddress ? (
          <Card sizeVariant={CardSizeVariant.SM}>
            <Card.Body>
              <h4>Billing Address</h4>

              <GoogleMapAnchor
                fullLocalAddress={
                  customerData.customer.billingAddress.fullLocalAddress ||
                  undefined
                }
              >
                <Address2Lines address={customerData.customer.billingAddress} />
              </GoogleMapAnchor>
            </Card.Body>
          </Card>
        ) : (
          <AddCardButton
            text="Add Billing Address"
            onClick={() => history.push(`${url}/addresses/new`)}
          />
        )}

        <FinancialSummaryCard
          title="Customer value"
          financialSummary={financialSummary}
        />
      </div>
    </>
  );
};

export default CustomerPageHeaders;
