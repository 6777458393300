import {
  ComponentProps,
  FC,
  PropsWithChildren,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import Input, { InputIcon, InputSizeVariant } from '../plInput/Input';
import { SelectOption, ValueType } from '../plSelect/Select';
import FilterDropdown from './components/FilterDropdown';
import { Table } from './components/Table';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '../plButton/useButtonClassName';
import Button from '../plButton/Button';
import Tooltip from '../plTooltip/Tooltip';
import UntitledIcon from '@payaca/untitled-icons';
import MultiFilterSidebar, {
  IProps as MultiFilterSidebarProps,
} from './components/MultiFilterSidebar';
import NumberOfSelectedFilters from './components/NumberOfSelectedFilters';

const ManageableItemsList_: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="overflow-hidden rounded-lg border bg-white dark:border-gray-700">
      {children}
    </div>
  );
};

export type HeaderBarProps = {
  heading?: string;
  subHeading?: string;
  buttons?: ReactNode;
};
const HeaderBar: FC<HeaderBarProps> = ({ heading, subHeading, buttons }) => {
  if (!heading && !subHeading && !buttons) return null;

  return (
    <div className="prose grid gap-6 border-b border-gray-200 px-6 py-4 md:flex md:items-center md:justify-between">
      <div>
        <h2>{heading}</h2>
        {subHeading && <p>{subHeading}</p>}
      </div>

      {buttons && (
        <div className="shrink-0">
          <div className="inline-flex gap-x-2">{buttons}</div>
        </div>
      )}
    </div>
  );
};

const ActionBar: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex flex-wrap items-center justify-end gap-2 border-b border-gray-200 px-4 py-3 empty:hidden dark:border-gray-700">
      {children}
    </div>
  );
};

const SearchInput: FC<ComponentProps<typeof Input>> = (props) => {
  return (
    <Input
      className="mr-auto"
      type="search"
      aria-label={`Search`}
      leadingElement={<InputIcon name="search-lg.3" />}
      sizeVariant={InputSizeVariant.SM}
      placeholder="Search"
      {...props}
    />
  );
};

const BasicFilter = <
  TValueType extends ValueType,
  TMetaDataType = Record<string, any>,
>({
  options,
  value,
  onChange,
}: {
  options: SelectOption<TValueType, TMetaDataType>[];
  value: TValueType[];
  onChange: (value: TValueType[]) => void;
}) => {
  return (
    <FilterDropdown
      variant={EBtnVariant.White}
      size={EBtnSize.Small}
      colour={EBtnColour.Black}
      filters={options.map((option) => ({
        label: option.label,
        selected: value.includes(option.value),
        onClick: (selected) =>
          selected
            ? onChange([...value, option.value])
            : onChange((value || []).filter((i) => i !== option.value)),
      }))}
    />
  );
};

const AdvancedFilter: FC<
  Pick<MultiFilterSidebarProps, 'options' | 'appliedFilters' | 'onApplyFilters'>
> = ({ appliedFilters, options, onApplyFilters }) => {
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const numberOfSelectedFilters = useMemo(() => {
    return appliedFilters
      ? Object.values(appliedFilters).filter((f: any) => f.length).length
      : 0;
  }, [appliedFilters]);

  return (
    <>
      <Button
        onClick={() => setShowFilterSidebar(true)}
        variant={EBtnVariant.White}
        size={EBtnSize.Small}
        colour={EBtnColour.Black}
      >
        <UntitledIcon name="sliders-01.3" className="h-3.5 w-3.5 -rotate-90" />
        Filter
        {!!numberOfSelectedFilters && (
          <NumberOfSelectedFilters
            numberOfSelectedFilters={numberOfSelectedFilters}
          />
        )}
      </Button>
      <MultiFilterSidebar
        isOpen={showFilterSidebar}
        onClose={() => setShowFilterSidebar(false)}
        options={options}
        appliedFilters={appliedFilters}
        onApplyFilters={onApplyFilters}
      />
    </>
  );
};

const GlobalAction: FC<
  Omit<ComponentProps<typeof Button>, 'onClick'> &
    Pick<
      ComponentProps<typeof Tooltip>,
      'enabled' | 'tooltipContent' | 'onClick'
    >
> = (props) => {
  return (
    <Tooltip as={Button} showArrow size={EBtnSize.Small} {...props}>
      {props.children}
    </Tooltip>
  );
};

const PaginationBar: FC<{
  pageSize: number;
  currentPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}> = ({ pageSize, currentPage, totalItems, onPageChange }) => {
  const minPage = 1;
  const maxPage = Math.ceil(totalItems / pageSize);

  const showing = useMemo(() => {
    let top = pageSize * currentPage;

    if (currentPage === maxPage) {
      top = totalItems;
    }

    const bottom = (currentPage - 1) * pageSize + 1;

    return `Showing ${bottom === top ? bottom : `${bottom} - ${top}`}`;
  }, [currentPage, pageSize, totalItems, maxPage]);

  return (
    <div className="prose grid gap-3 border-t border-gray-200 px-6 py-4 dark:border-gray-700 md:flex md:items-center md:justify-between">
      <div>
        <p>
          {showing} of{' '}
          <span data-testid="total-number-of-results">{totalItems}</span>
        </p>
      </div>

      <div className="inline-flex gap-x-2">
        <Button
          variant={EBtnVariant.White}
          size={EBtnSize.Small}
          colour={EBtnColour.Black}
          disabled={currentPage === minPage}
          onClick={() => {
            onPageChange(currentPage - 1);
          }}
        >
          <UntitledIcon name={'chevron-left'} className="h-4 w-4" /> Prev
        </Button>
        <Button
          variant={EBtnVariant.White}
          size={EBtnSize.Small}
          colour={EBtnColour.Black}
          disabled={currentPage >= maxPage}
          onClick={() => {
            onPageChange(currentPage + 1);
          }}
        >
          Next <UntitledIcon name={'chevron-right'} className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export const ManageableItemsList = Object.assign(ManageableItemsList_, {
  HeaderBar,
  ActionBar: Object.assign(ActionBar, {
    SearchInput,
    BasicFilter,
    AdvancedFilter,
    GlobalAction,
  }),
  PaginationBar,
  Table,
});
