import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import { Lightbox } from 'react-modal-image';

import { JobLineItemAttachment } from '@payaca/types/jobTypes';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import MarkdownLabel from '../markdownLabel/MarkdownLabel';
import { AccountRegions } from '@payaca/types/accountTypes';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import UntitledIcon from '@payaca/untitled-icons';
import Badge from '../plBadge/Badge';
import { createPortal } from 'react-dom';
import Tooltip from '../plTooltip/Tooltip';

const vatFormatter = new Intl.NumberFormat('en-GB');

export type TVariant =
  | 'included'
  | 'optional'
  | 'multipleChoice'
  | 'added'
  | 'removed';

type TProps = {
  tooltipMessage?: string;
  variant: TVariant;
  region: AccountRegions;
  hideItemPrices: boolean;
  hideVat: boolean;
  isSelected?: boolean;
  isEditable: boolean;
  isProposition?: boolean;
  item: JobLineItem;
  attachments: JobLineItemAttachment[];
  onChange?: () => void;
};

const DocumentItem: FunctionComponent<TProps> = ({
  tooltipMessage,
  variant,
  region,
  hideItemPrices,
  hideVat,
  item,
  isSelected = false,
  isEditable,
  isProposition = false,
  attachments,
  onChange,
}: TProps): JSX.Element => {
  const { current: uId } = useRef(uuidv4());
  const [enlargeImage, setEnlargeImage] = useState<{
    url: string;
    fileName: string;
  } | null>(null);

  const totalExcVat = useMemo(() => {
    return item.subtotalIncMarkupDiscount;
  }, [item]);

  const totalIncVat = useMemo(() => {
    return totalExcVat + item.vatTotal;
  }, [totalExcVat, item]);

  const unitPriceBeforeDiscount = useMemo(() => {
    return (
      item.priceIncMarkup +
      Math.round(
        item.priceIncMarkup *
          ((item.isReverseChargeVat ? 0 : item.vatAmount) / 100)
      ) -
      Math.round(item.priceIncMarkup * ((item.cisDeductionRate || 0) / 100))
    );
  }, [item]);

  const cisApplies = useMemo(() => {
    return (
      item.cisDeductionRate !== null && item.cisDeductionRate !== undefined
    );
  }, [item]);

  const openImage = useCallback((itemAttachment: JobLineItemAttachment) => {
    setEnlargeImage({
      url: itemAttachment.originalAttachmentUrl || itemAttachment.attachmentUrl,
      fileName: itemAttachment.fileName,
    });
  }, []);

  const hideVatAndIsProposition = hideVat && isProposition;

  const vatRegionalLabel = useMemo(
    () => getRegionalTextString(region, RegionalStrings.VALUE_ADDED_TAX),
    [region]
  );

  const isActionable = useMemo(() => {
    if (!isEditable) return false;

    if (variant === 'optional') return true;

    return variant === 'multipleChoice' && !isSelected;
  }, [variant, isEditable, isSelected]);

  return (
    <>
      <Tooltip
        tooltipContent={tooltipMessage}
        as="label"
        enabled={!!tooltipMessage}
        htmlFor={uId}
        className={
          'relative flex w-full rounded-lg border-2 border-gray-200 bg-white px-6 py-8 print:mb-3' +
          (isActionable ? ' hover:border-pc-job-brand cursor-pointer' : '') +
          (isSelected ? ' border-pc-job-brand' : '') +
          (isActionable && isSelected
            ? ' hover:border-pc-lighter-job-brand'
            : '')
        }
      >
        {variant === 'multipleChoice' && (
          <input
            id={uId}
            type="radio"
            className="pl-form-radio text-pc-job-brand border-pc-job-brand pointer-events-none my-auto mr-6 border-2"
            name={item.id.toString()}
            disabled={!isEditable}
            checked={isSelected}
            onChange={() => {
              if (isEditable) {
                onChange?.();
              }
            }}
          />
        )}
        {variant === 'optional' && (
          <input
            id={uId}
            type="checkbox"
            className="pl-form-checkbox text-pc-job-brand border-pc-job-brand pointer-events-none my-auto mr-6 border-2"
            name={item.id.toString()}
            disabled={!isEditable}
            checked={isSelected}
            onChange={() => {
              if (isEditable) {
                onChange?.();
              }
            }}
          />
        )}

        <div className="@screen-sm:flex @screen-md:block @screen-lg:flex w-full">
          <div className="flex flex-col">
            {!!attachments?.length && (
              <div className="mb-2 flex flex-wrap gap-3">
                {attachments.map((itemAttachment) => (
                  <img
                    key={itemAttachment.id}
                    className="max-h-32 w-auto max-w-full cursor-pointer rounded-md"
                    src={itemAttachment.attachmentUrl}
                    alt={`Attachment for ${item.description}`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      openImage(itemAttachment);
                    }}
                  />
                ))}
                {enlargeImage ? (
                  createPortal(
                    <Lightbox
                      medium={enlargeImage.url}
                      onClose={() => setEnlargeImage(null)}
                      alt={enlargeImage.fileName}
                    />,
                    document.body
                  )
                ) : (
                  <></>
                )}
              </div>
            )}

            <MarkdownLabel
              className="markdown-font-size-reset"
              markdown={item.description}
            />

            {!!item.discountAmount && item.discountDescription && (
              <p className="m-0 mt-auto pt-2 text-base">
                Discount description: {item.discountDescription}
              </p>
            )}
          </div>

          <div className="ml-auto flex flex-col">
            <table className="ml-auto whitespace-nowrap text-right">
              <tbody>
                {/* Unit price */}
                {!hideItemPrices && item.discountPercentage && (
                  <tr>
                    <td className="p-0">
                      <s className="text-pc-blue-dark text-base text-opacity-60">
                        {currencyPrice(unitPriceBeforeDiscount, region)}
                      </s>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="p-0">
                    {/* total */}
                    <div className="text-base font-medium">
                      {!hideItemPrices
                        ? `${item.quantity} x ${currencyPrice(
                            totalIncVat / item.quantity,
                            region
                          )}`
                        : `Qty ${item.quantity}`}
                    </div>
                  </td>
                </tr>
                {!hideItemPrices && !hideVatAndIsProposition && (
                  <tr>
                    <td className="p-0 text-sm leading-[1.167rem]">
                      {item.isReverseChargeVat && item.vatIncluded ? (
                        <>
                          Reverse charge {vatFormatter.format(item.vatAmount)}%
                        </>
                      ) : (
                        <>
                          {item.vatIncluded
                            ? `Inc ${vatFormatter.format(item.vatAmount)}%`
                            : `No`}{' '}
                          {vatRegionalLabel}
                        </>
                      )}
                    </td>
                  </tr>
                )}
                {/* cis */}
                {cisApplies && (
                  <tr>
                    <td className="p-0 text-sm leading-[1.167rem]">
                      CIS suffered at {item.cisDeductionRate}% deduction rate
                    </td>
                  </tr>
                )}
                {!!item.discountPercentage && (
                  <tr>
                    <td className="p-0 text-sm leading-[1.167rem]">
                      Inc {vatFormatter.format(item.discountPercentage)}%
                      discount
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="p-0 text-right">
                    {(variant === 'optional' || variant === 'multipleChoice') &&
                      !isSelected && (
                        <div className="mt-2 p-0 text-base leading-[1.167rem]">
                          Not selected
                        </div>
                      )}
                    {variant === 'added' && (
                      <Badge variant="soft" colour="teal">
                        Added
                      </Badge>
                    )}
                    {variant === 'removed' && (
                      <Badge variant="soft" colour="red">
                        Removed
                      </Badge>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Tooltip>
    </>
  );
};

export default DocumentItem;
