import React, { useMemo } from 'react';
import { faImage } from '@fortawesome/free-solid-svg-icons';

import BasicField from '@payaca/components/basicField/BasicField';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import FileUploadPersistRemoveControl from '@payaca/components/fileUploadPersistRemoveControl/FileUploadPersistRemoveControl';
import TextareaFieldFormatter from '@payaca/components/textareaField/TextareaFieldFormatter';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { ToolbarColourVariant } from '@payaca/components/textareaField/TextareaFieldFormatter';
import { FormState } from './EditItemControl';

import { LineItemAttachment } from '@payaca/types/lineItemTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import { useSelector } from '../../../api/state';

import { getUserRoles } from '../../../utils/stateAccessors';

import { ImageFileExtensions } from '@payaca/helpers/fileHelper';

import { LineItemsPermissions } from '@payaca/permissions/lineItems/line-items.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

import './EditItemInformation.sass';

export enum RequiredSettingsType {
  REQUIRED = 'required',
  MULTIPLE_CHOICE = 'multiple-choice',
  OPTIONAL = 'optional',
}
export const getRequiredSettingsType = (formState: any) => {
  return formState.isOptional
    ? RequiredSettingsType.OPTIONAL
    : formState.isMultipleChoice
    ? RequiredSettingsType.MULTIPLE_CHOICE
    : RequiredSettingsType.REQUIRED;
};

type Props = {
  formState: FormState;
  updateFormFields: (changedFields: Partial<FormState>) => void;
  formValidationResult: Partial<Record<keyof FormState, FieldValidationResult>>;
};
const EditLineItemInformation = ({
  formState,
  updateFormFields,
  formValidationResult,
}: Props) => {
  const userRoles = useSelector(getUserRoles);

  const userCanEdit = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      LineItemsPermissions.UPDATE_LINE_ITEM,
    ]);
  }, [userRoles]);

  return (
    <ContentPanel className="edit-item-information">
      <>
        <div className="item-title-ref-image">
          <div className="flex-grow">
            <div className="item-ref-quantity-wrapper">
              <BasicField
                className="item-ref-field"
                styleVariant={InputStyleVariant.STANDARD}
                label={'Item reference'}
                value={formState?.name || ''}
                name="name"
                onChange={updateFormFields}
                placeholder="Internal use only"
                isDisabled={!userCanEdit}
              />
            </div>
          </div>
          {/* attachments */}
          <FileUploadPersistRemoveControl
            persistedFiles={
              formState?.attachments?.map((a: LineItemAttachment) => {
                return {
                  identifier: a.id,
                  fileName: a.fileName,
                  thumbnailUrl: a.attachmentUrl,
                };
              }) || []
            }
            persistFile={(file: File) => {
              return new Promise<void>((resolve, reject) => {
                updateFormFields({
                  attachments: [
                    {
                      file: file,
                      fileName: file.name,
                      identifier: file.name,
                    },
                  ] as any,
                });
                resolve();
              });
            }}
            removePersistedFile={(fileIdentifier) => {
              return new Promise<void>((resolve, reject) => {
                updateFormFields({
                  attachments: formState?.attachments?.filter(
                    (x: any) => x.id !== fileIdentifier
                  ),
                });
                resolve();
              });
            }}
            allowFileNameModification={false}
            allowMultipleUploads={false}
            acceptFileTypes={ImageFileExtensions}
            showFileNames={false}
            uploadTriggerIcon={faImage}
            enableDragAndDrop={true}
            isDisabled={!userCanEdit}
          />
        </div>
        <div>
          <ValidatedFieldWrapper
            validationResult={formValidationResult.description}
          >
            <TextareaFieldFormatter
              styleVariant={InputStyleVariant.STANDARD}
              label="Title & description"
              isRequired={true}
              value={formState?.description}
              name="description"
              onChange={updateFormFields}
              toolbarColourVariant={ToolbarColourVariant.WHITE}
              isDisabled={!userCanEdit}
            />
          </ValidatedFieldWrapper>
        </div>
      </>
    </ContentPanel>
  );
};

export default EditLineItemInformation;
