import { FC, useMemo } from 'react';

import Modal, { IProps as ModalProps } from '@payaca/components/plModal/Modal';
import CreateEditTaskControl from '../createEditTaskControl/CreateEditTaskControl';
import useGetTask from '../../../api/queries/tasks/useGetTask';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';

import './CreateEditTaskModal.sass';

type Props = {
  onPersistTaskSuccess?: (taskId: number) => void;
  taskId?: number;
} & Pick<ModalProps, 'isOpen' | 'onClose'>;
const EditTaskModal: FC<Props> = (props) => {
  const { isOpen, onClose, onPersistTaskSuccess, taskId } = props;
  const { data } = useGetTask(taskId);

  const task = useMemo(() => {
    if (!data?.task) {
      return;
    }
    return {
      id: +data.task.id,
      dealId: data?.task.project?.id ? +data?.task.project.id : undefined,
      name: data?.task.name,
      assignedToUserId: data?.task.assignee?.id
        ? +data?.task.assignee.id
        : undefined,
      deadlineDate: data?.task?.deadline
        ? new Date(data?.task.deadline)
        : undefined,
      checklistItems:
        data?.task.__typename === 'ChecklistTask'
          ? data?.task.checklistItems.map((c) => ({
              id: +c.id,
              name: c.name!,
            }))
          : undefined,
      scheduledEventIds: data?.task.events.map((event) => +event.id),
      formTemplateId:
        data?.task.__typename === 'FormTask'
          ? +data?.task.formTemplate.id
          : undefined,
      documentId:
        data?.task.__typename === 'LegacyFormTask'
          ? +data?.task.documentId
          : undefined,
    };
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      className="create-edit-task-modal"
      title="Edit Task"
      onClose={onClose}
      disableBackdropClick={true}
    >
      {!task ? (
        <div className="flex flex-grow justify-center">
          <MiniLoader />
        </div>
      ) : (
        <CreateEditTaskControl
          dealId={data?.task?.project?.id ? +data.task.project.id : undefined}
          task={task}
          onPersistTaskSuccess={onPersistTaskSuccess}
        />
      )}
    </Modal>
  );
};
export default EditTaskModal;
