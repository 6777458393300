import { MaterialsListPermissions } from './materialsList.permissions';
import { MaterialsListRoles } from './materialsList.roles';

export const materialsListMappings = {
  [MaterialsListPermissions.GET_MATERIALS_LISTS]: [MaterialsListRoles.VIEWER],
  [MaterialsListPermissions.CLEAR_MATERIALS_LIST]: [MaterialsListRoles.DELETER],
  [MaterialsListPermissions.GET_MATERIALS_LIST_MATERIALS]: [
    MaterialsListRoles.VIEWER,
  ],
  [MaterialsListPermissions.PERSIST_MATERIALS_LIST]: [
    MaterialsListRoles.PERSISTER,
  ],
  [MaterialsListPermissions.PERSIST_MATERIALS_LIST_MATERIAL]: [
    MaterialsListRoles.VIEWER,
  ],
  [MaterialsListPermissions.DELETE_MATERIALS_LIST_MATERIAL]: [
    MaterialsListRoles.DELETER,
  ],
  [MaterialsListPermissions.POPULATE_MATERIALS_LIST_FROM_DEAL]: [
    MaterialsListRoles.POPULATOR,
  ],
  [MaterialsListPermissions.GET_MATERIAL_PURCHASE_RECORDS]: [
    MaterialsListRoles.PURCHASE_RECORD_VIEWER,
  ],
  [MaterialsListPermissions.PERSIST_MATERIAL_PURCHASE_RECORD]: [
    MaterialsListRoles.PURCHASE_RECORD_PERSISTER,
  ],
  [MaterialsListPermissions.DELETE_MATERIAL_PURCHASE_RECORD]: [
    MaterialsListRoles.PURCHASE_RECORD_DELETER,
  ],
  [MaterialsListPermissions.GET_PURCHASE_ORDERS]: [
    MaterialsListRoles.PURCHASE_ORDER_VIEWER,
  ],
  [MaterialsListPermissions.GET_MATERIAL_PURCHASE_INTENTS]: [
    MaterialsListRoles.PURCHASE_ORDER_VIEWER,
  ],
  [MaterialsListPermissions.PERSIST_PURCHASE_ORDER]: [
    MaterialsListRoles.PURCHASE_ORDER_PERSISTER,
  ],
  [MaterialsListPermissions.PERSIST_MATERIAL_PURCHASE_INTENT]: [
    MaterialsListRoles.PURCHASE_ORDER_PERSISTER,
  ],
  [MaterialsListPermissions.DELETE_MATERIAL_PURCHASE_INTENT]: [
    MaterialsListRoles.PURCHASE_ORDER_PERSISTER,
  ],
  [MaterialsListPermissions.SEND_PURCHASE_ORDER]: [
    MaterialsListRoles.PURCHASE_ORDER_SENDER,
  ],
  [MaterialsListPermissions.VOID_PURCHASE_ORDER]: [
    MaterialsListRoles.PURCHASE_ORDER_VOIDER,
  ],
  [MaterialsListPermissions.CONFIRM_PURCHASE_ORDER]: [
    MaterialsListRoles.PURCHASE_ORDER_CONFIRMER,
  ],
  [MaterialsListPermissions.PERSIST_ISOLATED_PURCHASE_ORDER]: [
    MaterialsListRoles.ISOLATED_PURCHASE_ORDER_PERSISTER,
  ],
  [MaterialsListPermissions.SEND_ISOLATED_PURCHASE_ORDER]: [
    MaterialsListRoles.ISOLATED_PURCHASE_ORDER_SENDER,
  ],
  [MaterialsListPermissions.CONFIRM_ISOLATED_PURCHASE_ORDER]: [
    MaterialsListRoles.ISOLATED_PURCHASE_ORDER_CONFIRMER,
  ],
  [MaterialsListPermissions.VOID_ISOLATED_PURCHASE_ORDER]: [
    MaterialsListRoles.ISOLATED_PURCHASE_ORDER_VOIDER,
  ],
  [MaterialsListPermissions.GET_SELF_ASSIGNED_EVENT_DEAL_PURCHASE_ORDERS]: [
    MaterialsListRoles.SELF_ASSIGNED_EVENT_DEAL_PURCHASE_ORDER_VIEWER,
  ],
};
