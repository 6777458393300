import React, { FunctionComponent, useCallback, useState } from 'react';
import SubscriptionProductsSelectionControl from '../subscriptionProductsSelectionControl/SubscriptionProductsSelectionControl';

import './SelectSubscriptionControl.sass';
import {
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';
import UpdateSubscriptionModal from '../updateSubscriptionModal/UpdateSubscriptionModal';
import PillSelectionField from '@payaca/components/pillSelectionField/PillSelectionField';
import UpdateSubscriptionAdditionalUserSeatsModal from '../updateSubscriptionAdditionalUserSeatsModal/UpdateSubscriptionAdditionalUserSeatsModal';
import { useSelector } from '@/api/state';
import { Nullish } from '@payaca/utilities/types';

type Props = {
  onUpdateSubscriptionSuccess?: (info: {
    subscriptionProduct: Nullish<SubscriptionProduct>;
    recurringInterval: RecurringInterval;
    additionalUserSeats: number;
  }) => void;
};

const SelectSubscriptionControl: FunctionComponent<Props> = ({
  onUpdateSubscriptionSuccess,
}: Props): JSX.Element => {
  const accountSubscription = useSelector(
    (state) => state.subscription.accountSubscription
  );

  const [priceId, setPriceId] = useState<string>();
  const [productId, setProductId] = useState<string>();
  const [showUpdateSubscriptionModal, setShowUpdateSubscriptionModal] =
    useState(false);
  const [recurringInterval, setRecurringInterval] = useState<RecurringInterval>(
    accountSubscription?.recurringInterval || RecurringInterval.MONTH
  );
  const toggleMonthlyPricing = useCallback(() => {
    setRecurringInterval(
      recurringInterval === RecurringInterval.YEAR
        ? RecurringInterval.MONTH
        : RecurringInterval.YEAR
    );
  }, [recurringInterval]);

  const [
    showUpdateSubscriptionAdditionalUserSeatsModal,
    setShowUpdateSubscriptionAdditionalUserSeatsModal,
  ] = useState(false);

  const [subscriptionProduct, setSubscriptionProduct] = useState(
    accountSubscription?.subscriptionProduct
  );

  const additionalUserSeats =
    accountSubscription?.subscriptionInformation.additionalUserSeats ?? 0;

  return (
    <div className="select-subscription-control">
      <div className={'recurring-interval-toggle'}>
        <p>
          Get <strong>2 months free</strong> by choosing{' '}
          <strong>yearly pricing</strong>
        </p>
        <div className={'recurring-interval-toggle-pill'}>
          <PillSelectionField
            name="recurringIntervalToggle"
            value={recurringInterval}
            onChange={toggleMonthlyPricing}
            options={[
              { label: 'Monthly', value: RecurringInterval.MONTH },
              {
                label: 'Yearly',
                value: RecurringInterval.YEAR,
              },
            ]}
            hasBorder={true}
          />
        </div>
      </div>
      <SubscriptionProductsSelectionControl
        recurringInterval={recurringInterval}
        selectedPriceId={accountSubscription?.productPrice?.stripeId}
        additionalUserSeats={0}
        enableEmbeddedUserSeatControl={false}
        onChange={(value) => {
          setPriceId(value.priceId);
          setProductId(value.productId);
          setShowUpdateSubscriptionModal(true);
          setSubscriptionProduct(value.subscriptionProduct);
        }}
        onUpdateExistingSubscription={() =>
          setShowUpdateSubscriptionAdditionalUserSeatsModal(true)
        }
      />
      {priceId && productId && (
        <UpdateSubscriptionModal
          recurringInterval={recurringInterval}
          isOpen={showUpdateSubscriptionModal}
          stripePriceId={priceId}
          stripeProductId={productId}
          onClose={() => setShowUpdateSubscriptionModal(false)}
          onUpdateSubscriptionSuccess={(additionalUserSeats) =>
            onUpdateSubscriptionSuccess?.({
              subscriptionProduct,
              recurringInterval,
              additionalUserSeats,
            })
          }
        />
      )}
      <UpdateSubscriptionAdditionalUserSeatsModal
        isOpen={showUpdateSubscriptionAdditionalUserSeatsModal}
        onClose={() => setShowUpdateSubscriptionAdditionalUserSeatsModal(false)}
        onUpdateSubscriptionSuccess={(additionalUserSeats) =>
          onUpdateSubscriptionSuccess?.({
            subscriptionProduct,
            recurringInterval,
            additionalUserSeats,
          })
        }
      />
    </div>
  );
};

export default SelectSubscriptionControl;
