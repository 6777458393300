import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';
import * as listedSupplierActions from '@payaca/store/listedSuppliers/listedSuppliersActions';
import * as suppliersActions from '@payaca/store/suppliers/suppliersActions';
import { ListedSupplier, SortBy } from '@payaca/types/listedSupplierTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  getMaterial,
  getRegion,
  getSupplierMaterialsForMaterial,
  getSuppliersForMaterial,
} from '@/utils/stateAccessors';
import { faPlus, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldLabel, { LabelStyleVariant } from '@payaca/components/fieldLabel/FieldLabel';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import IconButton from '@payaca/components/button/IconButton';
import InputWrapper, {
  InputStyleVariant,
} from '@payaca/components/inputWrapper/InputWrapper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { useSupplier } from '@payaca/store/hooks/appState';
import { Supplier } from '@payaca/types/supplierTypes';
import CreateEditSupplierModal from '../createEditSupplierModal/CreateEditSupplierModal';
import './MaterialPurchaseRecordSupplierSelectionControl.sass';
import { useSelector } from '@/api/state';

interface Option {
  id: number;
  name: string;
  price?: number;
  isPreferredSupplier: boolean;
}

interface Props {
  materialId: number;
  label?: string;
  selectedSupplierId?: number;
  onChange: (selectedSupplierId?: number | null) => void;
  enableSupplierCreation?: boolean;
  labelStyleVariant?: LabelStyleVariant;
}

const MaterialPurchaseRecordSupplierSelectionControl: FC<Props> = ({
  materialId,
  label,
  enableSupplierCreation = false,
  labelStyleVariant,
  selectedSupplierId,
  onChange,
}: Props): JSX.Element => {
  const [showCreateSupplierModal, setShowCreateSupplierModal] = useState(false);
  const [newSupplierData, setNewSupplierData] = useState({});
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [requiresGetListedSuppliersPage, setRequiresGetListedSuppliersPage] =
    useState(false);
  const [isGettingListedSuppliersPage, setIsGettingListedSuppliersPage] =
    useState(false);
  const [isPersistingSupplier, setIsPersistingSupplier] = useState(false);

  const supplierMaterials = useSelector((state) =>
    getSupplierMaterialsForMaterial(state, materialId)
  );

  const suppliers = useSelector((state) =>
    getSuppliersForMaterial(state, materialId)
  );

  const material = useSelector((state) => getMaterial(state, materialId));

  const supplier = useSupplier(selectedSupplierId);

  const listedSuppliers: ListedSupplier[] = useSelector((state) => {
    return state.listedSuppliers?.listedSuppliersPage?.items || [];
  });

  const requestGetListedSuppliersPage = useCallback(() => {
    setIsGettingListedSuppliersPage(true);
    dispatch(
      listedSupplierActions.requestGetListedSuppliersPage(
        {
          sortDirection: SortDirection.ASCENDING,
          pageSize: 10,
          pageNumber: 1,
          searchTerm: searchTerm,
          sortBy: SortBy.NAME,
        },
        () => setIsGettingListedSuppliersPage(false)
      )
    );
    setRequiresGetListedSuppliersPage(false);
  }, [searchTerm, dispatch]);

  useEffect(() => {
    if (selectedSupplierId) {
      dispatch(suppliersActions.requestGetSupplier(selectedSupplierId));
    }
  }, [selectedSupplierId]);

  useEffect(() => {
    if (requiresGetListedSuppliersPage) {
      requestGetListedSuppliersPage();
    }
  }, [requiresGetListedSuppliersPage]);

  useEffect(() => {
    dispatch(listedSupplierActions.clearListedSuppliersPage());
  }, []);

  const region = useSelector(getRegion);
  const renderOption = useCallback((option: Option) => {
    return (
      <div className="material-purchase-record-supplier-selection-control__supplier-option">
        {option.id === -1 && <IconButton size={'xs'} icon={faPlus} />}
        <span className="name">{option.name}</span>
        {option.isPreferredSupplier && <FontAwesomeIcon icon={faStar} />}
        {typeof option.price === 'number' && (
          <span className="price">{currencyPrice(option.price, region)}</span>
        )}
      </div>
    );
  }, []);

  const handleCreateSupplier = useCallback(
    (supplierName: string) => {
      dispatch(
        suppliersActions.requestPersistSupplier(
          { name: supplierName },
          (supplierId: number) => {
            onChange(supplierId);
          }
        )
      );
    },
    [dispatch, onChange]
  );

  const supplierMaterialOptions = useMemo((): Option[] => {
    return supplierMaterials.map((sm) => {
      const s = suppliers.find((y) => y.id === sm.supplierId);

      return {
        id: sm.supplierId,
        name: s?.name || '',
        price: sm.price,
        isPreferredSupplier: material?.preferredSupplierId === sm.supplierId,
      };
    });
  }, [supplierMaterials, suppliers, material?.preferredSupplierId]);

  const sortedFilteredSupplierMaterialOptions = useMemo((): Option[] => {
    return supplierMaterialOptions
      .filter((x) => {
        if (!searchTerm?.length) return true;
        return x.name?.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .sort((a, b) => {
        return a?.name.localeCompare(b?.name);
      });
  }, [supplierMaterialOptions, searchTerm]);

  const listedSupplierOptions = useMemo((): Option[] => {
    return listedSuppliers
      .filter((s) => !material?.supplierIds.includes(s.id))
      .map((s) => {
        return {
          id: s.id,
          name: s.name,
          price: undefined,
          isPreferredSupplier: false,
        };
      });
  }, [listedSuppliers, material?.supplierIds]);

  const options = useMemo((): Option[] => {
    return [...sortedFilteredSupplierMaterialOptions, ...listedSupplierOptions];
  }, [sortedFilteredSupplierMaterialOptions, listedSupplierOptions]);

  return (
    <div className="material-purchase-record-supplier-selection-control">
      {label && <FieldLabel label={label} styleVariant={labelStyleVariant} />}
      {!selectedSupplierId && (
        <TypeToSearchField
          styleVariant={InputStyleVariant.OUTSIZE}
          onSearchTermChangeTimeout={() =>
            setRequiresGetListedSuppliersPage(true)
          }
          autoHighlight={true}
          onSearchTermChange={setSearchTerm}
          options={
            enableSupplierCreation
              ? [
                  ...options,
                  {
                    id: -1,
                    name: 'Create a new Supplier',
                    isPreferredSupplier: false,
                  } as Option,
                ]
              : options
          }
          isLoadingOptions={
            isGettingListedSuppliersPage || isPersistingSupplier
          }
          renderOption={renderOption}
          disableFilteringInComponent={true}
          onSelectOption={(option?: Option) => {
            if (option?.id === -1 && enableSupplierCreation) {
              setIsPersistingSupplier(true);
              if (searchTerm) {
                handleCreateSupplier(searchTerm);
              } else {
                setNewSupplierData({ name: searchTerm });
                setShowCreateSupplierModal(true);
              }
            } else {
              onChange(option?.id);
            }
          }}
          placeholder="Type here to search Suppliers"
          noOptionsText={'No suppliers found.'}
          onOpen={() => setRequiresGetListedSuppliersPage(true)}
        />
      )}

      {selectedSupplierId && (
        <div className="selected-supplier-container">
          <InputWrapper styleVariant={InputStyleVariant.OUTSIZE}>
            <>
              {supplier && (
                <>
                  <span className="selected-supplier-name">
                    {supplier.name}
                  </span>
                  <Button
                    styleVariant={ButtonStyleVariant.ANCHOR}
                    onClick={() => {
                      onChange(null);
                      setSearchTerm(undefined);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </>
              )}
              {!supplier && <MiniLoader />}
            </>
          </InputWrapper>
        </div>
      )}
      <CreateEditSupplierModal
        isOpen={showCreateSupplierModal}
        onClose={() => {
          setShowCreateSupplierModal(false);
          setIsPersistingSupplier(false);
        }}
        supplier={newSupplierData as Supplier}
        onPersistSupplierSuccess={(supplierId: number) => {
          setIsPersistingSupplier(false);
          onChange(supplierId);
        }}
      />
    </div>
  );
};

export default MaterialPurchaseRecordSupplierSelectionControl;
