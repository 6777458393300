import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as materialActions from '@payaca/store/materials/materialsActions';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import BasicField from '@payaca/components/basicField/BasicField';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import ImageBlock from '@payaca/components/imageBlock/ImageBlock';
import MaterialSuppliersCondensed from '../materialSuppliersCondensed/MaterialSuppliersCondensed';
import { ButtonColourVariant } from '@payaca/components/button/enums';
import IconButton from '@payaca/components/button/IconButton';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import {
  Material,
  LineItemMaterial,
  SupplierMaterial,
} from '@payaca/types/materialTypes';
import { Supplier } from '@payaca/types/supplierTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';

import { getRegion, getUserRoles } from '@/utils/stateAccessors';

import { useSelector } from '@/api/state';

import { useFormState } from '../../../hooks/useFormState';

import './LineItemMaterialControl.sass';

type FormState = {
  id: LineItemMaterial['id'];
  materialQuantity: LineItemMaterial['materialQuantity'];
  lineItemId: LineItemMaterial['lineItemId'];
};
type FormValidationResult = Partial<
  Record<keyof FormState, FieldValidationResult>
>;
interface Props {
  material: Material;
  lineItemMaterial: LineItemMaterial;
  suppliers: Supplier[];
  supplierMaterials: SupplierMaterial[];
  onPersistLineItemMaterialSuccess?: () => void;
  onDeleteLineItemMaterialSuccess?: () => void;
}

const LineItemMaterialControl: FC<Props> = ({
  material,
  suppliers,
  lineItemMaterial,
  supplierMaterials,
  onPersistLineItemMaterialSuccess,
  onDeleteLineItemMaterialSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const region = useSelector(getRegion);
  const userRoles = useSelector(getUserRoles);

  const [isDeletingLineItemMaterial, setIsDeletingLineItemMaterial] =
    useState(false);

  const deleteLineItemMaterial = useCallback(() => {
    setIsDeletingLineItemMaterial(true);
    dispatch(
      materialActions.requestDeleteLineItemMaterial(lineItemMaterial.id, () => {
        setIsDeletingLineItemMaterial(false);
        onDeleteLineItemMaterialSuccess?.();
      })
    );
  }, [onDeleteLineItemMaterialSuccess, lineItemMaterial.id]);
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const clearDynamicFeedbackMessage = useRef<() => void>();

  const persistLineItemMaterial = useCallback(
    (state: FormState) => {
      clearDynamicFeedbackMessage.current?.();
      return new Promise<void>((resolve, reject) => {
        dispatch(
          materialActions.requestPersistLineItemMaterial(state, (error) => {
            if (error) {
              clearDynamicFeedbackMessage.current = showDynamicFeedbackMessage({
                title: `We couldn't save your changes`,
                isCancellable: true,
                feedbackLevel: FeedbackLevel.ERROR,
              });
              reject(error);
            } else {
              onPersistLineItemMaterialSuccess?.();
              clearDynamicFeedbackMessage.current = showDynamicFeedbackMessage({
                title: 'Your changes have been saved',
                lifespanMs: DynamicFeedbackLifespanMs.SHORT,
                feedbackLevel: FeedbackLevel.SUCCESS,
              });
              resolve();
            }
          })
        );
      });
    },
    [onPersistLineItemMaterialSuccess]
  );

  const initialState: FormState = {
    id: lineItemMaterial.id,
    materialQuantity: lineItemMaterial.materialQuantity,
    lineItemId: lineItemMaterial.lineItemId,
  };
  const { formState, updateFormFields, formValidationResult } = useFormState<
    FormState,
    FormValidationResult
  >(
    initialState,
    {},
    {
      autoSaveFn: persistLineItemMaterial,
    }
  );

  if (!lineItemMaterial) return null;

  return (
    <>
      <ResponsiveViewWrapper
        className="line-item-material-control flex-container flex-center"
        downBreakpointSm={750}
      >
        <>
          <div className="line-item-material-control-panel flex-grow">
            <div className="line-item-material-control-panel-inner">
              <ImageBlock imageSrc={material.thumbnailUrl} />
              <div className="material-suppliers-information-container">
                <span className="material-name">
                  <Link to={`/materials/${material.id}`}>{material?.name}</Link>
                </span>
                <MaterialSuppliersCondensed
                  suppliers={suppliers}
                  preferredSupplierId={material.preferredSupplierId}
                  supplierMaterials={supplierMaterials}
                />
              </div>
              {/* quantity */}
              <div className="quantity-container">
                <FieldLabel label={`Qty`} />

                <BasicField
                  value={formState.materialQuantity}
                  name="materialQuantity"
                  styleVariant={InputStyleVariant.OUTSIZE}
                  onChange={(value) => {
                    updateFormFields({
                      materialQuantity: +value.materialQuantity,
                    });
                  }}
                  type="number"
                  additionalInputProps={{ min: 1 }}
                  isDisabled={
                    !userHasRequiredPermission(userRoles, [
                      MaterialsPermissions.PERSIST_LINE_ITEM_MATERIAL,
                    ])
                  }
                />
              </div>

              {/* total cost */}
              <div className="price-container">
                {material.predictedPriceExcludingTax === undefined
                  ? '-'
                  : currencyPrice(
                      material.predictedPriceExcludingTax *
                        lineItemMaterial.materialQuantity,
                      region
                    )}
              </div>
            </div>
          </div>

          {userHasRequiredPermission(userRoles, [
            MaterialsPermissions.DELETE_LINE_ITEM_MATERIAL,
          ]) && (
            <IconButton
              icon={faTimes}
              onClick={deleteLineItemMaterial}
              size={'sm'}
              colourVariant={ButtonColourVariant.WHITE}
              isProcessing={isDeletingLineItemMaterial}
            />
          )}
        </>
      </ResponsiveViewWrapper>
    </>
  );
};

export default LineItemMaterialControl;
