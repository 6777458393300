import React, { FC, useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { PipelinesPermissions } from '@payaca/permissions/pipelines/pipelines.permissions';
import { PipelineBadge } from '@/ui/components/pipelineBadge/PipelineBadge';
import { usePipeline, usePipelines } from '@payaca/store/hooks/appState';
import { requestGetAccountPipelines } from '@payaca/store/pipelines/pipelineActions';
import {
  GetListedDealsRequestData,
  ListedDeal,
  SortBy,
} from '@payaca/types/listedDealTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import { DefaultPipelineStages } from '@payaca/types/pipelineTypes';
import { requestGetListedDealsPage } from '@payaca/store/deals/dealsActions';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import UserAvatar from '@payaca/components/userAvatar/UserAvatar';
import Select, { SelectSizeVariant } from '@payaca/components/plSelect/Select';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { useAccountUser } from '@/utils/storeHooks';
import { AvatarSizeVariant } from '@payaca/components/plAvatar/Avatar';
import {
  getScheduleDefaultPipelineIdLocalStorageKey,
  getScheduleDefaultPipelineStagesLocalStorageKey,
} from '@/helpers/localStorageKeyHelper';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnShapeVariant,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import EmptyState from '@payaca/components/emptyState/EmptyState';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const UnscheduledDeals: FC<{
  onClickDeal?: (deal: ListedDeal) => void;
  externalDealChangeTimestamp?: Date;
}> = ({ onClickDeal, externalDealChangeTimestamp }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { current: initalPipelineId } = useRef(
    localStorage.getItem(getScheduleDefaultPipelineIdLocalStorageKey())
      ? Number(
          localStorage.getItem(getScheduleDefaultPipelineIdLocalStorageKey())
        )
      : 0
  );

  const { current: initialPipelineStages } = useRef(
    localStorage.getItem(getScheduleDefaultPipelineStagesLocalStorageKey())
      ? localStorage
          .getItem(getScheduleDefaultPipelineStagesLocalStorageKey())
          ?.split(',')
      : [DefaultPipelineStages.NEW_LEAD]
  );

  const [getListedDealsRequestData, setGetListedDealsRequestData] =
    useState<GetListedDealsRequestData>({
      sortBy: SortBy.LAST_UPDATED,
      sortDirection: SortDirection.DESCENDING,
      pageSize: 50,
      pageNumber: 1,
      pipelineId: initalPipelineId,
      pipelineStages: initialPipelineStages,
      archived: false,
      excludeDealsWithUpcomingEvents: true,
    });
  const [canLoadMoreDeals, setCanLoadMoreDeals] = useState(false);

  const [listedDeals, setListedDeals] = useState<ListedDeal[]>([]);

  const pipelines = usePipelines();
  const selectedPipeline = usePipeline(getListedDealsRequestData.pipelineId);

  const pipelineDropdownOptions = useMemo(() => {
    return [
      ...pipelines.map((p) => ({
        label: p.title,
        value: p.id,
      })),
    ];
  }, [pipelines]);

  useEffect(() => {
    dispatch(requestGetAccountPipelines());
  }, []);

  useEffect(() => {
    setGetListedDealsRequestData((x) => ({
      ...x,
      pageNumber: 1,
    }));
  }, [externalDealChangeTimestamp]);

  useEffect(() => {
    localStorage.setItem(
      getScheduleDefaultPipelineIdLocalStorageKey(),
      getListedDealsRequestData.pipelineId?.toString() || '0'
    );

    localStorage.setItem(
      getScheduleDefaultPipelineStagesLocalStorageKey(),
      getListedDealsRequestData.pipelineStages?.join(',') ||
        DefaultPipelineStages.NEW_LEAD
    );

    setIsLoading(true);
    dispatch(
      requestGetListedDealsPage(
        getListedDealsRequestData,
        (listedDealsListViewPage) => {
          if (getListedDealsRequestData.pageNumber === 1) {
            setListedDeals(listedDealsListViewPage.items);
          } else {
            setListedDeals((x) => [...x, ...listedDealsListViewPage.items]);
          }

          setCanLoadMoreDeals(
            listedDealsListViewPage.totalItemCount >
              getListedDealsRequestData.pageNumber *
                getListedDealsRequestData.pageSize
          );
          setIsLoading(false);
        }
      )
    );
  }, [getListedDealsRequestData]);

  return (
    <div>
      <PermissionGuard
        renderIfHasPermissions={[
          PipelinesPermissions.GET_PIPELINES_FOR_ACCOUNT,
        ]}
      >
        <div className="flex gap-4 mb-4">
          <div className="grow shrink basis-1/2 min-w-0">
            <Select
              sizeVariant={SelectSizeVariant.SM}
              value={getListedDealsRequestData.pipelineId}
              onChange={(value) =>
                setGetListedDealsRequestData((x) => {
                  const p = pipelines.find((p) => p.id === value);

                  return {
                    ...x,
                    pipelineId: value as number,
                    pipelineStages: [p?.stages[0].title || ''],
                    pageNumber: 1,
                  };
                })
              }
              options={pipelineDropdownOptions}
              CustomOption={(option) => {
                if (option.option.value === 0) {
                  return <PipelineBadge />;
                } else {
                  return <PipelineBadge pipelineId={option.option.value} />;
                }
              }}
              CustomSelected={({ selectedOptions }) => {
                const option = selectedOptions?.length
                  ? selectedOptions[0]
                  : null;
                if (!option) return null;

                if (option.value === 0) {
                  return <PipelineBadge />;
                } else {
                  return (
                    <div className={'pipeline-select-container'}>
                      <PipelineBadge pipelineId={option.value} />
                    </div>
                  );
                }
              }}
              placeholder={'Pipeline'}
            />
          </div>
          <div className="grow shrink basis-1/2 min-w-0">
            <Select
              sizeVariant={SelectSizeVariant.SM}
              multiple={true}
              value={getListedDealsRequestData.pipelineStages}
              onChange={(value) =>
                setGetListedDealsRequestData((x) => {
                  return {
                    ...x,
                    pipelineStages: value,
                    pageNumber: 1,
                  };
                })
              }
              options={
                selectedPipeline?.stages.map((stage) => ({
                  label: stage.title,
                  value: stage.title,
                })) || []
              }
              placeholder={'Pipeline stage'}
              CustomSelected={({ selectedOptions }) => {
                return <>{selectedOptions.map((o) => o.label).join(', ')}</>;
              }}
            />
          </div>
        </div>
      </PermissionGuard>
      {!isLoading && !listedDeals.length && (
        <EmptyState>
          <EmptyState.Body>
            {"You don't have any unscheduled projects matching the filters"}
          </EmptyState.Body>
        </EmptyState>
      )}
      <ul className="list-none m-0 p-0 mb-8">
        {listedDeals.map((listedDeal) => (
          <li key={listedDeal.dealId} className="mb-2 last:mb-0">
            <ListedDealCard
              listedDeal={listedDeal}
              onClick={() => onClickDeal?.(listedDeal)}
            />
          </li>
        ))}
      </ul>
      {canLoadMoreDeals && (
        <div className="flex items-center justify-center">
          <Button
            colour={EBtnColour.Blue}
            onClick={() => {
              if (isLoading) return;
              setGetListedDealsRequestData((x) => ({
                ...x,
                pageNumber: x.pageNumber + 1,
              }));
            }}
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  );
};

const ListedDealCard: FC<{
  listedDeal: ListedDeal;
  onClick?: () => void;
}> = ({ listedDeal, onClick }) => {
  const history = useHistory();
  const user = useAccountUser(listedDeal.assignedToUserId);

  const addressAsString = getAddressAsString(listedDeal.siteAddresses?.[0]);

  return (
    <div onClick={onClick} className={`${onClick ? 'cursor-pointer' : ''}`}>
      <Card
        sizeVariant={CardSizeVariant.SM}
        className={onClick ? `transition-all hover:bg-gray-50` : ''}
      >
        <Card.Body>
          <span className="flex items-baseline gap-x-4 gap-y-1 flex-wrap-reverse">
            <h3 className="text-base mb-0">{listedDeal.customerName}</h3>

            <span className="ml-auto">
              <Link to={`/deals/${listedDeal.dealId}`}>
                <Button
                  size={EBtnSize.XSmall}
                  variant={EBtnVariant.Soft}
                  shape={EBtnShapeVariant.PILL}
                >
                  #{listedDeal.reference}
                </Button>
              </Link>
            </span>
          </span>
          {(addressAsString?.length || !!user) && (
            <div className="flex gap-4 mt-2 items-top">
              <p className="mb-0">{addressAsString}</p>

              {user && (
                <div className="ml-auto">
                  <UserAvatar
                    sizeVariant={AvatarSizeVariant.XS}
                    user={{
                      firstName: user.firstname,
                      lastName: user.lastname,
                      emailAddress: user.email,
                      userColour: user.userColour,
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default UnscheduledDeals;
