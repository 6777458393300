import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import './NavigationSidebar.sass';
import { actions as appActions } from '@/api/app';

import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { DashboardPermissions } from '@payaca/permissions/dashboard/dashboard.permissions';
import { AnalyticsPermissions } from '@payaca/permissions/analytics/analytics.permissons';
import { CustomersPermissions } from '@payaca/permissions/customers/customers.permissions';
import { LineItemsPermissions } from '@payaca/permissions/lineItems/line-items.permissions';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';
import { AutomationsPermissions } from '@payaca/permissions/automations/automations.permissions';
import { SuppliersPermissions } from '@payaca/permissions/suppliers/suppliers.permissions';
import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import { ServicePlanPermissions } from '@payaca/permissions/service-plans/service-plans.permissions';
import { TimelogsPermissions } from '@payaca/permissions/timelogs/timelogs.permissions';
import { TimelogsRoles } from '@payaca/permissions/timelogs/timelogs.roles';
import { ServicePlanRoles } from '@payaca/permissions/service-plans/service-plans.roles';

import NavigationSidebarBase from '@payaca/components/navigationSidebar/NavigationSidebar';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { useTranslation } from '@/i18n';
import { useSelector } from '@/api/state';
import { usePermissions } from '@/hooks/usePermissions';
import { LinkProps, NavLink } from 'react-router-dom';
import UntitledIcon, { TIconName } from '@payaca/untitled-icons';
import Avatar, { AvatarSizeVariant } from '@payaca/components/plAvatar/Avatar';
import Badge from '@payaca/components/plBadge/Badge';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnShapeVariant,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import * as authActions from '@payaca/store/auth/authActions';
import { InvoicesPermissions } from '@payaca/permissions/invoices/invoices.permissions';
import useLogout from '@/hooks/auth/useLogout';

type TNavigationItem = {
  title: string;
  to: LinkProps['to'];
  isDisabled: boolean;
  icon: TIconName;
  hasUpgradeIcon?: boolean;
  requiredPermissions?: string[];
};

type Props = {
  isSmallView?: boolean;
};
const NavigationSidebar: FC<Props> = ({
  isSmallView = false,
}: Props): JSX.Element => {
  const translate = useTranslation('navigation.persistent');
  const dispatch = useDispatch();
  const logout = useLogout();
  const { userHasRequiredRole } = usePermissions();

  const isHidden = useSelector(
    (state: any) => state.app.navigationSidebarIsHidden
  );

  const isCollapsed = useSelector(
    (state: any) => state.app.navigationSidebarIsCollapsed
  );

  const navigationSidebarAutoExpandedAfterDeploy = useSelector((state: any) => {
    return state.app.navigationSidebarAutoExpandedAfterDeploy;
  });

  useEffect(() => {
    if (!navigationSidebarAutoExpandedAfterDeploy) {
      dispatch(appActions.expandNavigationSidebar());
      dispatch(appActions.setNavigationSidebarAutoExpandedAfterDeploy());
    }
  }, [navigationSidebarAutoExpandedAfterDeploy]);

  const accountAccessInformation = useSelector(
    (state) => state.account.accountAccessInformation
  );

  const myProfile = useSelector((state: any) => state.users.myProfile);

  const trialPeriodIsExpired = useMemo(() => {
    return accountAccessInformation
      ? !accountAccessInformation.hasActiveSubscription &&
          accountAccessInformation.isTrialPeriodExpired
      : false;
  }, [accountAccessInformation]);

  const hasExceededUserSeats = useMemo(() => {
    if (!accountAccessInformation) return false;

    return (
      accountAccessInformation.hasActiveSubscription &&
      accountAccessInformation.hasExceededActiveSubscriptionUserSeats
    );
  }, [accountAccessInformation]);

  const menuItems = useMemo<TNavigationItem[]>(
    () => [
      {
        title: translate('dashboard.label'),
        to: '/dashboard',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'home-02.3',
        requiredPermissions: [
          DashboardPermissions.GET_ACTION_REQUIRED_DRAFT_JOBS,
        ],
      },
      {
        // ToDo: projects not deals
        title: translate('deals.label'),
        to: '/deals',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'lightbulb-05.3',
        requiredPermissions: [
          DealsPermissions.GET_LISTED_DEALS,
          DealsPermissions.GET_MY_LISTED_DEALS,
        ],
      },
      {
        title: translate('invoices.label'),
        to: '/invoices',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'file-06.3',
        requiredPermissions: [InvoicesPermissions.GET_LISTED_INVOICES],
      },
      {
        title: translate('servicePlans.label'),
        to: '/service-plans',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'repeat-04.3',
        hasUpgradeIcon: !userHasRequiredRole([ServicePlanRoles.VIEWER]),
        requiredPermissions: [ServicePlanPermissions.PREVIEW_SERVICE_PLANS],
      },
      {
        title: translate('customers.label'),
        to: '/customers',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'users-01.3',
        requiredPermissions: [
          CustomersPermissions.GET_COLLECTION_MANAGEMENT_VIEW,
        ],
      },
      {
        title: translate('schedule.label'),
        to: '/schedule',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'calendar-check-02.3',
        requiredPermissions: [
          ScheduledEventsPermissions.GET_MY_EVENTS,
          ScheduledEventsPermissions.GET_EVENTS,
        ],
      },
      {
        title: translate('tasks.label'),
        to: '/tasks',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'list.3',
        requiredPermissions: [
          TasksPermissions.GET_TASKS,
          TasksPermissions.GET_SELF_ASSIGNED_TASKS,
        ],
      },
      {
        title: translate('timelogs.label'),
        to: '/timelogs',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'clock-stopwatch.3',
        hasUpgradeIcon: !userHasRequiredRole([TimelogsRoles.ASSIGNEE_VIEWER]),
        requiredPermissions: [TimelogsPermissions.PREVIEW_TIMELOGS],
      },
      {
        title: translate('items.label'),
        to: '/items',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'shopping-cart-02.3',
        requiredPermissions: [
          LineItemsPermissions.GET_COLLECTION_MANAGEMENT_VIEW,
        ],
      },
      {
        title: translate('materials.label'),
        to: '/materials',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'file-check-02.3',
        requiredPermissions: [
          MaterialsPermissions.GET_COLLECTION_MANAGEMENT_VIEW,
        ],
      },
      {
        title: translate('suppliers.label'),
        to: '/suppliers',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'truck-01.3',
        requiredPermissions: [
          SuppliersPermissions.GET_COLLECTION_MANAGEMENT_VIEW,
        ],
      },
      {
        title: translate('automations.label'),
        to: '/automations',
        isDisabled: trialPeriodIsExpired,
        icon: 'dataflow-03.3',
        requiredPermissions: [AutomationsPermissions.GET_LISTED_AUTOMATIONS],
      },
      {
        title: translate('analytics.label'),
        to: '/analytics',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'line-chart-up-03.3',
        requiredPermissions: [AnalyticsPermissions.GET_CHART_DATA],
      },
    ],
    [translate, trialPeriodIsExpired, hasExceededUserSeats]
  );

  useEffect(() => {
    if (isSmallView) {
      dispatch(appActions.hideNavigationSidebar());
      dispatch(appActions.expandNavigationSidebar());
    } else {
      dispatch(appActions.showNavigationSidebar());
    }
  }, [isSmallView]);

  return (
    <NavigationSidebarBase
      isCollapsed={isCollapsed}
      isHidden={isHidden}
      isSmallView={isSmallView}
      onToggleIsCollapsed={(setIsCollapsed: boolean) =>
        !setIsCollapsed
          ? dispatch(appActions.expandNavigationSidebar())
          : dispatch(appActions.collapseNavigationSidebar())
      }
      onToggleIsHidden={(setIsHidden: boolean) =>
        !setIsHidden
          ? dispatch(appActions.showNavigationSidebar())
          : dispatch(appActions.hideNavigationSidebar())
      }
    >
      <ul
        className={
          'm-0 list-none space-y-1.5 py-6 transition-[padding]' +
          (isCollapsed ? ' px-3' : ' px-6')
        }
      >
        {menuItems.map((menuItem) => (
          <PermissionGuard
            key={menuItem.title}
            renderIfHasPermissions={menuItem.requiredPermissions}
          >
            <li>
              <NavLink
                className="flex w-full items-center gap-x-3.5 whitespace-nowrap rounded-lg px-2.5 py-2 text-base font-normal text-blue-900 hover:bg-blue-50 hover:no-underline"
                activeClassName="bg-blue-50 !font-medium"
                to={menuItem.to}
              >
                <UntitledIcon className="h-5 w-5" name={menuItem.icon} />{' '}
                {menuItem.title}{' '}
                {menuItem.hasUpgradeIcon && (
                  <Badge size="sm" variant="soft" colour="yellow">
                    <UntitledIcon className="h-3 w-3" name="stars-03.3" />
                  </Badge>
                )}
              </NavLink>
            </li>
          </PermissionGuard>
        ))}
      </ul>

      <div
        className={
          'mt-auto flex w-full items-center border-t pb-2 pt-5' +
          (isCollapsed ? ' px-3' : ' px-5')
        }
      >
        <Avatar
          sizeVariant={
            isCollapsed ? AvatarSizeVariant.XS : AvatarSizeVariant.BASE
          }
          placeholderInitials={`${myProfile.firstname[0]}${myProfile.lastname[0]}`}
        />

        <div
          className={
            'prose ml-3 w-0 flex-[1_1_0px] whitespace-nowrap transition-opacity' +
            (isCollapsed ? ' hidden' : '')
          }
        >
          <p className="overflow-hidden text-ellipsis">
            {myProfile.firstname} {myProfile.lastname}
          </p>
          <Tooltip
            tooltipContent={myProfile.email}
            className="supporting-body !block overflow-hidden text-ellipsis"
          >
            {myProfile.email}
          </Tooltip>
        </div>

        <div className={isCollapsed ? 'hidden' : ''}>
          <Tooltip
            as={Button}
            className="!inline-flex"
            tooltipContent={'Log out'}
            aria-label={'Log out'}
            size={EBtnSize.XSmall}
            shape={EBtnShapeVariant.PILL}
            variant={EBtnVariant.Ghost}
            colour={EBtnColour.Black}
            onClick={logout}
          >
            <UntitledIcon className="h-4 w-4" name="log-out-03.3" />
          </Tooltip>
        </div>
      </div>
    </NavigationSidebarBase>
  );
};

export default NavigationSidebar;
