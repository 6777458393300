import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { actions as usersActions } from '@/api/users';
import * as formActions from '@payaca/store/forms/formsActions';

import { TaskType } from '@payaca/types/taskTypes';
import BasicField from '@payaca/components/basicField/BasicField';
import DateField from '@payaca/components/dateField/DateField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import {
  FieldValidationResult,
  FieldValidator,
} from '@payaca/types/fieldValidationTypes';
import {
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import ChecklistItemsControl from '../checklistItemsControl/ChecklistItemsControl';
import { requestPersistTask } from '@payaca/store/tasks/tasksActions';
import { PersistTaskRequestData } from '@payaca/store/tasks/tasksTypes';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { User } from 'react-iconly';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import PillSelectionField from '@payaca/components/pillSelectionField/PillSelectionField';
import { FormOptionGroups } from '@payaca/types/formTypes';
import { getDocument } from '@/utils/stateAccessors';
import * as documentActions from '@payaca/store/documents/documentActions';
import { useSelector } from '@/api/state';
import TextareaFieldMentionFormatter from '@payaca/components/textareaField/TextareaFieldMentionFormatter';
import { sortedAvailableFormOptions } from '@payaca/helpers/formHelper';
import { useScheduledEvent } from '@payaca/store/hooks/appState';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import Modal from '@payaca/components/plModal/Modal';
import UntitledIcon from '@payaca/untitled-icons';

const isRequiredFieldValidator = getIsRequiredFieldValidator();

const taskNameLengthFieldValidator = getLengthFieldValidator({ max: 250 });
const checklistItemLengthNameFieldValidator = getLengthFieldValidator({
  max: 500,
});

type Props = {
  dealId?: number;
  task?: PersistTaskRequestData;
  onPersistTaskSuccess?: (taskId: number) => void;
};
const CreateEditTaskControl: FunctionComponent<Props> = ({
  task,
  dealId,
  onPersistTaskSuccess,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [checklistItemsCount, setChecklistItemsCount] = useState(0);

  const accountUsers: any[] = useSelector(
    (state: any) => state.users.accountUsers
  );

  const document = useSelector((state) => {
    return task?.documentId && getDocument(state, task.documentId);
  });

  useEffect(() => {
    if (task?.documentId && task.id) {
      dispatch(documentActions.requestGetDocumentForTask(task.id));
    }
  }, [task?.documentId]);

  const isPersistingTask = useSelector((state) => {
    return state.tasks.isPersistingTask;
  });

  useEffect(() => {
    dispatch(usersActions.getAccountUsers());
  }, []);

  const selectItems = useMemo(() => {
    const items = accountUsers
      .filter((x: any) => {
        return !x.deactivatedAt || x.inviteToken;
      })
      .map((user: any) => {
        return {
          label: user.name,
          value: user.id,
        };
      });
    items.push({ label: 'Unassigned', value: null });
    return items;
  }, [accountUsers]);

  useEffect(() => {
    // get available forms
    dispatch(formActions.requestGetAvailableForms());
  }, []);

  const availableForms = useSelector((state) => state.forms.availableForms);

  const availableFormOptions = useMemo(
    () => availableForms && sortedAvailableFormOptions(availableForms),
    [availableForms]
  );

  const scheduledEvent = useScheduledEvent(task?.scheduledEventIds?.[0]);

  const initialFormState = useMemo(() => {
    return {
      id: task?.id,
      dealId: dealId,
      documentId: task?.documentId,
      name: task?.name,
      notes: task?.notes,
      assignedToUserId: task?.assignedToUserId,
      deadlineDate:
        task?.deadlineDate || (scheduledEvent ? scheduledEvent?.endAt : null),
      checklistItems: task?.checklistItems
        ? task.checklistItems.map((x) => {
            return {
              id: x.id,
              name: x.name,
            };
          })
        : [],
      formTemplateId: task?.formTemplateId,
      scheduledEventIds: task?.scheduledEventIds,
      taskType: task?.formTemplateId ? TaskType.FORM : TaskType.CHECKLIST,
      customerId: task?.customerId,
    };
  }, [task, dealId, scheduledEvent]);

  const cannotEditDeadline = useMemo(() => {
    return scheduledEvent
      ? !!scheduledEvent?.endAt && !scheduledEvent.archivedAt
      : false;
  }, [scheduledEvent]);

  const fieldValidators = useMemo(() => {
    const fv: { [fieldName: string]: FieldValidator[] } = {
      name: [isRequiredFieldValidator, taskNameLengthFieldValidator],
    };

    for (let i = 0; i < checklistItemsCount; i++) {
      fv[`checklistItems[${i}].name`] = [
        isRequiredFieldValidator,
        checklistItemLengthNameFieldValidator,
      ];
    }

    return fv;
  }, [checklistItemsCount]);

  const persistTask = useCallback(
    (persistTaskData: PersistTaskRequestData) => {
      dispatch(requestPersistTask(persistTaskData, onPersistTaskSuccess));
    },
    [dispatch, onPersistTaskSuccess]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      setChecklistItemsCount(formState.checklistItems?.length || 0);
      return (
        <React.Fragment>
          <Modal.Body>
            <div className="flex flex-col gap-y-4">
              {!formState.documentId && !document && !formState.id && (
                <PillSelectionField
                  options={[
                    { value: TaskType.CHECKLIST, label: 'Checklist' },
                    { value: TaskType.FORM, label: 'Form' },
                  ]}
                  name="taskType"
                  value={formState.taskType}
                  onChange={(value: { [key: string]: any }) => {
                    const change = { ...value };
                    if (change.taskType !== TaskType.CHECKLIST) {
                      // changing away from checklist - clear checklist items
                      change.checklistItems = [];
                    } else if (change.taskType !== TaskType.FORM) {
                      // changing away from form - clear formTemplateId
                      change.formTemplateId = null;
                    }
                    change.name = '';
                    onFieldChange(change);
                  }}
                  hasBorder={true}
                />
              )}

              {document ? (
                // document form
                <BasicField
                  isDisabled={true}
                  name="documentForm"
                  value={document.title}
                  label="Form"
                  styleVariant={InputStyleVariant.OUTSIZE}
                />
              ) : formState.documentId ? (
                <div className="flex justify-center">
                  <MiniLoader />
                </div>
              ) : null}
              {!formState.documentId &&
                !document &&
                formState.taskType === TaskType.CHECKLIST && (
                  <>
                    {formState.taskType === TaskType.CHECKLIST && (
                      <ValidatedFieldWrapper
                        validationResult={validationState['name']}
                        isTouched={touchedState['name'] || false}
                      >
                        <BasicField
                          isRequired={true}
                          name="name"
                          value={formState.name}
                          label="Task name"
                          onChange={onFieldChange}
                          onTouch={onFieldTouch}
                          styleVariant={InputStyleVariant.OUTSIZE}
                        />
                      </ValidatedFieldWrapper>
                    )}
                    <div>
                      <FieldLabel label={'Checklist'} />
                      <ChecklistItemsControl
                        checklistItems={formState.checklistItems}
                        fieldNamePrefix={'checklistItems'}
                        onChange={onFieldChange}
                        onTouch={onFieldTouch}
                        touchedState={touchedState}
                        validationState={validationState}
                      />
                    </div>
                  </>
                )}
              {!formState.documentId &&
                !document &&
                formState.taskType === TaskType.FORM && (
                  <ValidatedFieldWrapper
                    validationResult={validationState['formTemplateId']}
                    isTouched={touchedState['formTemplateId'] || false}
                  >
                    {availableFormOptions?.length ? (
                      <DropdownField
                        label="Form"
                        value={formState.formTemplateId}
                        name="formTemplateId"
                        options={availableFormOptions}
                        onChange={(value: { [key: string]: any }) => {
                          const change = { ...value };

                          const selectedFormOptionValue =
                            availableFormOptions.find(
                              (i) => i.value === change.formTemplateId
                            );

                          if (
                            formState.taskType === TaskType.FORM &&
                            selectedFormOptionValue
                          ) {
                            change.name = selectedFormOptionValue.label;
                          }

                          onFieldChange(change);
                        }}
                        styleVariant={InputStyleVariant.OUTSIZE}
                        optionGroups={FormOptionGroups}
                      />
                    ) : (
                      <div className="loader-container">
                        <MiniLoader />
                      </div>
                    )}
                  </ValidatedFieldWrapper>
                )}
              {!!accountUsers.length && (
                <PermissionGuard
                  renderIfHasPermissions={[AccountsPermissions.GET_USERS]}
                >
                  <DropdownField
                    options={selectItems}
                    label="Assignee"
                    name="assignedToUserId"
                    onChange={onFieldChange}
                    value={formState.assignedToUserId}
                    styleVariant={InputStyleVariant.OUTSIZE}
                    iconBefore={<User set="light" size="small" />}
                  />
                </PermissionGuard>
              )}
              <DateField
                value={formState.deadlineDate}
                name="deadlineDate"
                label={
                  cannotEditDeadline ? (
                    <>
                      Deadline date
                      <Tooltip
                        tooltipContent={'This is set as the Event end date'}
                        className="flex items-center"
                      >
                        <UntitledIcon
                          name="info-circle.3"
                          className="h-4 w-4 ml-2"
                        />
                      </Tooltip>
                    </>
                  ) : (
                    'Deadline date'
                  )
                }
                onChange={onFieldChange}
                styleVariant={InputStyleVariant.OUTSIZE}
                isDisabled={cannotEditDeadline}
              />
              <TextareaFieldMentionFormatter
                label="Task notes"
                name="notes"
                onChange={onFieldChange}
                value={formState.notes}
                className="[&_.ql-editor]:!min-h-[100px]"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Footer.Actions>
              <Button
                styleVariant={ButtonStyleVariant.OUTSIZE}
                isDisabled={!isValid}
                isProcessing={isPersistingTask}
                onClick={() =>
                  !isPersistingTask &&
                  persistTask(formState as PersistTaskRequestData)
                }
              >
                Save
              </Button>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </React.Fragment>
      );
    },
    [
      accountUsers,
      selectItems,
      isPersistingTask,
      persistTask,
      availableForms,
      document,
      cannotEditDeadline,
    ]
  );

  return (
    <div className="create-edit-task-control">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
      />
    </div>
  );
};

export default CreateEditTaskControl;
