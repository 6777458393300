import React, { FC, useState } from 'react';

import ConfirmModal from '../modal/ConfirmModal';
import SelectItemControl from '../selectItemControl/SelectItemControl';
import Modal from '@payaca/components/modal/Modal';

import { getModal } from '@/helpers/modalHelper';

import './SelectItemModal.sass';

type Props = {
  isOpen: boolean;
  disabledItemIds: number[];
  onClose: () => void;
  onSelectItem: (itemId: number) => void;
};
const SelectItemModal: FC<Props> = ({
  isOpen,
  disabledItemIds,
  onClose,
  onSelectItem,
}: Props): JSX.Element => {
  const [showRepeatedItemConfirmation, setShowRepeatedItemConfirmation] =
    useState(false);
  const [itemId, setItemId] = useState<number>();

  return (
    <>
      <Modal
        isOpen={isOpen}
        title={'Select an Item'}
        onClose={onClose}
        className={'select-item-modal'}
        size="md"
      >
        <SelectItemControl
          onSelectItem={onSelectItem}
          disabledItemIds={disabledItemIds}
          onSelectDisabledItem={(itemId: number) => {
            setItemId(itemId);
            setShowRepeatedItemConfirmation(true);
          }}
        />
      </Modal>
      <ConfirmModal
        {...getModal('ADD_REPEATED_ITEM')}
        onClose={() => {
          setItemId(undefined);
          setShowRepeatedItemConfirmation(false);
        }}
        secondaryAction={() => {
          setItemId(undefined);
          setShowRepeatedItemConfirmation(false);
        }}
        primaryAction={() => {
          onSelectItem(itemId as number);
          setItemId(undefined);
          setShowRepeatedItemConfirmation(false);
        }}
        open={showRepeatedItemConfirmation}
      />
    </>
  );
};

export default SelectItemModal;
