import React, { FC, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getRegion } from '@/utils/stateAccessors';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';

import {
  useMaterialPurchaseRecord,
  usePurchaseOrder,
  useSupplier,
} from '@payaca/store/hooks/appState';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';

import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';

import { requestDeleteMaterialPurchaseRecord } from '@payaca/store/materialsList/materialsListActions';
import { useSelector } from '@/api/state';

import './MaterialPurchaseRecordControl.sass';

interface Props {
  materialPurchaseRecordId: number;
  onDeleteMaterialPurchaseRecordSuccess?: () => void;
}

const MaterialPurchaseRecordControl: FC<Props> = ({
  materialPurchaseRecordId,
  onDeleteMaterialPurchaseRecordSuccess,
}: Props): JSX.Element | null => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const materialPurchaseRecord = useMaterialPurchaseRecord(
    materialPurchaseRecordId
  );
  const [isDeleting, setIsDeleting] = useState(false);

  const supplier = useSupplier(materialPurchaseRecord?.supplierId);
  const purchaseOrder = usePurchaseOrder(
    materialPurchaseRecord?.purchaseOrderId
  );

  const region = useSelector(getRegion);

  const shortDateRegionalFormat = useMemo(
    () => getInternationalMomentDateFormatByRegion(DateFormats.SHORT, region),
    [region]
  );

  if (!materialPurchaseRecord) return null;

  return (
    <div className="material-purchase-record-control">
      <FontAwesomeIcon icon={faCheckCircle} />
      <span>
        <strong>{materialPurchaseRecord?.materialQuantity}</strong> purchased
        {supplier && (
          <>
            {' '}
            from <strong>{supplier.name}</strong>
          </>
        )}
        {materialPurchaseRecord?.price !== null && (
          <>
            {' '}
            for{' '}
            <strong>
              {currencyPrice(materialPurchaseRecord.price, region)}
            </strong>{' '}
            per unit
            {materialPurchaseRecord.isTaxIncluded && (
              <>
                {' '}
                <strong>+ tax</strong>
              </>
            )}
          </>
        )}{' '}
        {materialPurchaseRecord.purchaseOrderId ? (
          <>
            as part of{' '}
            <strong>purchase order {purchaseOrder?.reference}</strong>
          </>
        ) : (
          <>
            on{' '}
            {moment(materialPurchaseRecord.createdAt).format(
              shortDateRegionalFormat
            )}
          </>
        )}
      </span>
      <PermissionGuard
        renderIfHasPermissions={[
          MaterialsListPermissions.DELETE_MATERIAL_PURCHASE_RECORD,
        ]}
      >
        <Button
          isProcessing={isDeleting}
          styleVariant={ButtonStyleVariant.ANCHOR}
          onClick={() => setShowConfirmDeleteModal(true)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </PermissionGuard>
      <Modal
        title="Are you sure you want to delete this purchase record?"
        isOpen={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        actions={
          <Button
            colourVariant={ButtonColourVariant.RED}
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={() => {
              if (isDeleting) return;
              setIsDeleting(true);
              dispatch(
                requestDeleteMaterialPurchaseRecord(
                  materialPurchaseRecordId,
                  () => {
                    setIsDeleting(false);
                    onDeleteMaterialPurchaseRecordSuccess?.();
                    setShowConfirmDeleteModal(false);
                  }
                )
              );
            }}
            isProcessing={isDeleting}
          >
            Delete
          </Button>
        }
      ></Modal>
    </div>
  );
};

export default MaterialPurchaseRecordControl;
