export interface ChecklistItem {
  id: number;
  createdAt: Date;
  updatedAt?: Date;
  name: string;
  completedAt?: Date;
}

export interface Task extends BaseTask, TaskRelationships {
  checklistItems: ChecklistItem[];
}

export interface BaseTask {
  id: number;
  createdAt: Date;
  updatedAt?: Date;
  name: string;
  notes: string;
  completedAt?: Date;
  archivedAt?: Date;
  assignedToUserId?: number;
  createdByUserId?: number;
  deadlineDate?: Date;
}

export interface TaskRelationships {
  dealId?: number;
  scheduledEventIds: number[];
  documentId?: number;
}

export enum TaskType {
  CHECKLIST = 'checklist',
  FORM = 'form',
}
