import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { DefaultSystemRoles } from '@payaca/permissions/default-role.config';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { User } from '@payaca/types/userTypes';
import UserAvatar from '@payaca/components/userAvatar/UserAvatar';
import { AvatarSizeVariant } from '@payaca/components/plAvatar/Avatar';
import Modal from '@payaca/components/plModal/Modal';
import { useGetUser } from '@/api/queries/user/useGetUser';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { UpdateUserInput } from '@/gql/graphql';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import CurrencyInput from '@payaca/components/plInput/CurrencyInput';
import { ColourInput } from '@payaca/components/plColourInput/ColourInput';

import {
  getConditionalValidator,
  getIsRequiredFieldValidator,
  getNumericalRangeFieldValidator,
  getRegexMatchFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { VALID_HEX_COLOUR } from '@payaca/constants/regexConstants';
import Select from '@payaca/components/plSelect/Select';
import Button from '@payaca/components/plButton/Button';
import { useUpdateUser } from '@/api/mutations/user/useUpdateUser';

export const UpdateUserModal: FunctionComponent<{
  userId?: User['id'];
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}> = ({ userId, isOpen, onClose, onSuccess }) => {
  const { data: userData } = useGetUser(userId);
  const [isProcessing, setIsProcessing] = useState(false);

  const { mutateAsync: updateUserMutation, isLoading } = useUpdateUser();

  const initialFormData: Omit<UpdateUserInput, 'userId'> = useMemo(() => {
    return {
      colour: userData?.user.colour,
      costPerHour: userData?.user.costPerHour?.value,
      systemRole: userData?.user.systemRole,
      sortOrder: userData?.user.sortOrder,
    };
  }, [userData]);

  const fieldValidators = useMemo(() => {
    return {
      colour: [
        getIsRequiredFieldValidator(),
        getRegexMatchFieldValidator(VALID_HEX_COLOUR, {
          customErrorMessage: 'Input must be a valid hexadecimal colour string',
        }),
      ],
      costPerHour: [getNumericalRangeFieldValidator(0)],
      sortOrder: [
        getConditionalValidator(
          (formState) => typeof formState.sortOrder === 'number',
          getNumericalRangeFieldValidator(0)
        ),
      ],
    };
  }, []);

  const roleOptions = Object.values(DefaultSystemRoles).map((role) => ({
    label: role,
    value: role,
  }));

  const onSubmit = useCallback(
    async (formState: Omit<UpdateUserInput, 'userId'>) => {
      const updateData: UpdateUserInput = {
        userId: userId?.toString() || '',
      };

      if (formState.colour !== userData?.user.colour) {
        updateData.colour = formState.colour;
      }

      if (formState.costPerHour !== userData?.user.costPerHour?.value) {
        updateData.costPerHour = formState.costPerHour;
      }

      if (formState.systemRole !== userData?.user.systemRole) {
        updateData.systemRole = formState.systemRole;
      }

      if (formState.sortOrder !== userData?.user.sortOrder) {
        updateData.sortOrder = formState.sortOrder;
      }

      setIsProcessing(true);

      await updateUserMutation({
        ...updateData,
      });
      setIsProcessing(false);
      onSuccess?.();
    },
    [userId, onSuccess, userData?.user]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Edit User'}>
      <ValidatedForm
        fieldValidators={fieldValidators}
        initialFormState={initialFormData}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => {
          return (
            <>
              <Modal.Body>
                <div className="flex flex-col gap-4">
                  <Field
                    name="colour"
                    validationState={
                      validationState?.['colour']?.isValid === false &&
                      touchedState?.['colour']
                        ? {
                            isValid: false,
                            validationMessages:
                              validationState?.['colour']?.errors,
                          }
                        : undefined
                    }
                  >
                    <Field.Label>Colour</Field.Label>
                    <div className="flex items-center gap-4">
                      <ColourInput
                        value={formState.colour || ''}
                        onChange={(value) => onFieldChange({ colour: value })}
                        onTouch={() => onFieldTouch('colour')}
                      ></ColourInput>
                      <UserAvatar
                        sizeVariant={AvatarSizeVariant.SM}
                        user={{
                          firstName: userData?.user.firstName || '',
                          lastName: userData?.user.lastName || '',
                          emailAddress: userData?.user.email || '',
                          userColour: formState.colour || '',
                        }}
                      />
                    </div>
                  </Field>
                  {!userData?.user.isPrimaryUser && (
                    <Field name="systemRole">
                      <Field.Label>Role</Field.Label>
                      <Select
                        value={formState.systemRole as DefaultSystemRoles}
                        onChange={(value) => {
                          onFieldChange({ systemRole: value });
                          onFieldTouch('systemRole');
                        }}
                        options={roleOptions}
                      />
                    </Field>
                  )}
                  <PermissionGuard
                    renderIfHasPermissions={[
                      AccountsPermissions.SET_USER_COST_PER_HOUR,
                    ]}
                  >
                    <Field
                      name="costPerHour"
                      validationState={
                        validationState?.['costPerHour']?.isValid === false &&
                        touchedState?.['costPerHour']
                          ? {
                              isValid: false,
                              validationMessages:
                                validationState?.['costPerHour']?.errors,
                            }
                          : undefined
                      }
                    >
                      <Field.Label>Cost per hour</Field.Label>
                      <CurrencyInput
                        value={formState.costPerHour || 0}
                        onChange={(value) =>
                          onFieldChange({ costPerHour: value })
                        }
                        onBlur={() => onFieldTouch('costPerHour')}
                      />
                    </Field>
                  </PermissionGuard>
                  <Field
                    name="sortOrder"
                    validationState={
                      validationState?.['sortOrder']?.isValid === false &&
                      touchedState?.['sortOrder']
                        ? {
                            isValid: false,
                            validationMessages:
                              validationState?.['sortOrder']?.errors,
                          }
                        : undefined
                    }
                  >
                    <Field.Label>Sort order</Field.Label>
                    <Input
                      step={1}
                      type="number"
                      value={formState.sortOrder?.toString()}
                      onChange={(value) =>
                        onFieldChange({
                          sortOrder: value?.length ? Math.floor(+value) : null,
                        })
                      }
                      onBlur={() => onFieldTouch('sortOrder')}
                    />
                  </Field>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Modal.Footer.Actions>
                  <Button
                    isProcessing={isProcessing}
                    disabled={!isValid}
                    onClick={() => {
                      if (isProcessing) return;
                      onSubmit(formState);
                    }}
                  >
                    Save
                  </Button>
                </Modal.Footer.Actions>
              </Modal.Footer>
            </>
          );
        }}
      ></ValidatedForm>
    </Modal>
  );
};
