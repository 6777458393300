import ErrorBoundary from '../errorBoundary/ErrorBoundary';
import React, { FC, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import './MarkdownLabel.sass';
import Button from '../plButton/Button';
import { EBtnSize, EBtnVariant } from '../plButton/useButtonClassName';
import Conditional from '../conditional/Conditional';

export type Props = {
  className?: string;
  markdown: string;
  replacements?: Array<{ string: string; jsx: any }>;
  limitLines?: number;
  truncate?: boolean;
  truncatedClassName?: string;
};
const MarkdownLabel: FC<Props> = ({
  className,
  markdown,
  replacements = [],
  limitLines,
  truncate = false,
  truncatedClassName,
}: Props): JSX.Element => {
  const [isShowingMore, setIsShowingMore] = useState(false);

  // parse markdown to allow empty lines to show
  const [parsedMarkdown, canTruncate] = useMemo(() => {
    const markdownWithReplacements = replacements.reduce((acc, replacement) => {
      return acc.replace(
        new RegExp(`\\[${replacement.string}\\]`, 'g'),
        '```' + replacement.string + '```'
      );
    }, markdown);
    const new_lines: string[] = [];
    const regexp_list_line =
      /^\s*(?:\d+\.|[*+-]) .*(?:\r?\n(?!(?:\d+\.|[*+-]) ).*)*/gm; // https://stackoverflow.com/questions/59515074/z-pcre-equivalent-in-javascript-regex-to-match-all-markdown-list-items
    const regexp_text_line = /^[ \t]*[^\s]+/gm;
    const lines = markdownWithReplacements
      ? markdownWithReplacements.split('\n')
      : [];
    lines.forEach((line: string, i: number) => {
      if (!line.match(regexp_list_line)) {
        // trim if not a list item/bullet
        line = line.trim();
      }
      if (i > 0) {
        const previous_line = lines[i - 1];
        if (
          !previous_line.match(regexp_list_line) &&
          (previous_line.match(regexp_text_line) || previous_line === '')
        ) {
          // previous line is not list (can be text or empty line)
          if (line === '') {
            // line is empty then set previous line to incl \n\n&nbsp; which will show as empty line in markdown
            new_lines[i - 1] = new_lines[i - 1] + '\n\n&nbsp;';
          }
        }
      }
      new_lines.push(line);
    });

    let _parsedMarkdown = new_lines.join('\n');
    if (
      typeof limitLines !== 'undefined' &&
      new_lines.length > limitLines &&
      !isShowingMore
    ) {
      _parsedMarkdown = new_lines.slice(0, limitLines).join('\n') + '\n...';
    }

    return [
      _parsedMarkdown,
      typeof limitLines !== 'undefined' && new_lines.length > limitLines,
    ];
  }, [markdown, limitLines, truncate, isShowingMore]);

  return (
    <>
      <ErrorBoundary>
        <ReactMarkdown
          className={
            'markdown-label' +
            (className ? ` ${className}` : '') +
            (truncate && canTruncate && !isShowingMore && truncatedClassName
              ? ` ${truncatedClassName}`
              : '')
          }
          remarkPlugins={[remarkGfm]}
          linkTarget="_blank"
          components={{
            code: ({ node }) => {
              const replacement = replacements.find(
                // @ts-ignore
                ({ string }) => string === node?.children?.[0]?.value
              );
              return replacement?.jsx;
            },
          }}
        >
          {parsedMarkdown}
        </ReactMarkdown>
      </ErrorBoundary>

      <Conditional condition={truncate && canTruncate}>
        <Button
          className="mt-2"
          onClick={(e) => {
            e.stopPropagation();
            setIsShowingMore((s) => !s);
          }}
          variant={EBtnVariant.LinkInline}
          size={EBtnSize.Small}
        >
          {isShowingMore ? 'Show less' : 'Show more'}
        </Button>
      </Conditional>
    </>
  );
};

export default MarkdownLabel;
