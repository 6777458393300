import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import React, {
  FunctionComponent,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import SubscriptionPaymentMethodControl from '../subscriptionPaymentMethodControl/SubscriptionPaymentMethodControl';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import './UpdatePaymentMethodControl.css';

import { getSubscriptionCostIncludingVat } from '@payaca/helpers/subscriptionHelper';
import * as subscriptionActions from '@payaca/store/subscription/subscriptionActions';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { getRegionalPayacaVATPercentage } from '@payaca/helpers/vatHelper';
import { useSelector } from '@/api/state';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY;

const stripePromise = loadStripe(STRIPE_KEY || '');

type Props = {
  onUpdatePaymentMethod?: () => void;
};

const UpdatePaymentMethodControl: FunctionComponent<Props> = ({
  onUpdatePaymentMethod,
}: Props): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [updatePaymentMethod, setUpdatePaymentMethod] = useState(false);
  const [isCreatingPaymentMethod, setIsCreatingPaymentMethod] = useState(false);
  const isUpdatingPaymentMethod = useSelector(
    (state) => state.subscription.isUpdatingPaymentMethod
  );
  const accountSubscription = useSelector(
    (state) => state.subscription.accountSubscription
  );

  const isPaymentMethodUpdatedSuccessfully = useSelector(
    (state) => state.subscription.isPaymentMethodUpdatedSuccessfully
  );

  const isProcessing = useMemo(() => {
    return isUpdatingPaymentMethod || isCreatingPaymentMethod;
  }, [isUpdatingPaymentMethod, isCreatingPaymentMethod]);

  const initialFormState = useMemo(() => {
    if (!accountSubscription) return {};
    return {
      subscriptionId:
        accountSubscription.subscriptionInformation.stripeSubscriptionId,
      paymentMethodId:
        accountSubscription.subscriptionInformation.stripePaymentMethodId,
    };
  }, [accountSubscription]);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  const taxRatePercentage = useMemo(
    () => getRegionalPayacaVATPercentage(account.region),
    [account]
  );

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      const updatePaymentMethodRequestData = {
        stripePaymentMethodId: formState.paymentMethodId,
        stripeSubscriptionId: formState.subscriptionId,
      };
      dispatch(
        subscriptionActions.requestUpdatePaymentMethod(
          updatePaymentMethodRequestData
        )
      );
      setIsSubmitted(true);
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      isSubmitted &&
      !isUpdatingPaymentMethod &&
      isPaymentMethodUpdatedSuccessfully
    ) {
      onUpdatePaymentMethod && onUpdatePaymentMethod();
    }
  }, [
    isSubmitted,
    isUpdatingPaymentMethod,
    isPaymentMethodUpdatedSuccessfully,
    onUpdatePaymentMethod,
  ]);

  const subscriptionCostIncludingVat = useMemo(() => {
    if (!accountSubscription) return;
    return getSubscriptionCostIncludingVat(
      accountSubscription.subscriptionProduct,
      accountSubscription.recurringInterval,
      accountSubscription.subscriptionInformation.additionalUserSeats,
      taxRatePercentage
    );
  }, [accountSubscription, taxRatePercentage]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <React.Fragment>
          <div className="form-section">
            <div className="update-payment-method-container">
              <Elements stripe={stripePromise}>
                <SubscriptionPaymentMethodControl
                  paymentAmount={subscriptionCostIncludingVat}
                  onPaymentMethodChange={(paymentMethod) => {
                    setIsCreatingPaymentMethod(false);
                    if (paymentMethod) {
                      onSubmit({
                        ...formState,
                        paymentMethodId: paymentMethod?.id,
                      });
                    }
                  }}
                  accountRegion={account.region}
                  renderActions={(
                    handleCreatePaymentMethod: () => void,
                    applePayButton?: JSX.Element
                  ) => {
                    return (
                      <React.Fragment>
                        {applePayButton}

                        <Button
                          styleVariant={ButtonStyleVariant.OUTSIZE}
                          onClick={
                            !isProcessing
                              ? () => {
                                  setIsCreatingPaymentMethod(true);
                                  handleCreatePaymentMethod();
                                }
                              : undefined
                          }
                          iconAfter={faAngleRight}
                          isProcessing={isProcessing}
                        >
                          Update payment method
                        </Button>
                      </React.Fragment>
                    );
                  }}
                />
              </Elements>
            </div>
            {isPaymentMethodUpdatedSuccessfully === false && isSubmitted && (
              <ErrorMessage message="Failed to update your payment method." />
            )}
          </div>
        </React.Fragment>
      );
    },
    [
      history,
      isSubmitted,
      isProcessing,
      updatePaymentMethod,
      isPaymentMethodUpdatedSuccessfully,
      onSubmit,
      subscriptionCostIncludingVat,
    ]
  );

  return (
    <div className="update-payment-method-control">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
      />
    </div>
  );
};

export default UpdatePaymentMethodControl;
