import { FC } from 'react';
import { useParams } from 'react-router';
import _get from 'lodash.get';

import { Props as DynamicField } from '../DynamicViewField';
import useGetConnectionApplication from '@/api/queries/connectionApplication/useGetConnectionApplication';
import Field from '@payaca/components/plField/Field';
import {
  LowCarbonTech,
  SearchDevice,
  SupportingLowCarbonTech,
} from '@payaca/types/deviceTypes';
import { sentenceCase } from '@payaca/utilities/stringUtilities';
import SearchedDeviceDetailsCard from '@/ui/pages/connectionApplicationPage/components/SearchedDeviceDetailsCard';
import useDeviceSearch from '@/api/rest/connectionApplication/useDeviceSearch';

type Props = Omit<DynamicField, 'definitions'> & {
  deviceType: LowCarbonTech | SupportingLowCarbonTech;
};

const DeviceSearchFieldReadonly: FC<Props> = ({
  name,
  fieldSchema,
  deviceType,
}) => {
  /**
   * Router
   */
  const { connectionApplicationId } = useParams<{
    projectId: string;
    connectionApplicationId: string;
  }>();

  /**
   * Queries
   */
  const { data: persistedFormStateData } = useGetConnectionApplication(
    connectionApplicationId,
    false
  );

  const persistedFormState = persistedFormStateData?.connectionApplication.data;

  const selectedDeviceRef = _get(persistedFormState, name);
  const phaseCode = _get(persistedFormState, 'supplyDetails.phaseCode');

  const { data: deviceSearchResults } = useDeviceSearch(
    name,
    deviceType,
    selectedDeviceRef,
    phaseCode
  );

  const selectedDevice = deviceSearchResults?.find(
    (x: SearchDevice) => x.reference === selectedDeviceRef
  );

  return (
    <Field>
      <div className="space-y-2">
        <Field.Label>
          {sentenceCase(fieldSchema.title)}{' '}
          {fieldSchema.json_schema_extra.required ? '*' : ''}
        </Field.Label>

        <p>{selectedDeviceRef}</p>

        {selectedDevice && (
          <SearchedDeviceDetailsCard selectedDevice={selectedDevice} />
        )}

        <Field.Helper>{fieldSchema.json_schema_extra.tooltip}</Field.Helper>
      </div>
    </Field>
  );
};

export default DeviceSearchFieldReadonly;
