import {
  takeEvery,
  call,
  put,
  race,
  delay,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';
import { Req } from '@payaca/helpers/storeHelper';

import {
  SagaConfig,
  ActionType,
  PersistUploadRequestData,
  PersistUploadAndLinkToEntityRequestData,
  PersistUploadsAndLinkToEntity,
  LinkUploadsToEntity,
  GetUploads,
  GetUploadsWithRoleForEntity,
  RenameUpload,
  UnlinkUploadFromEntity,
} from './uploadsTypes';

import {
  clearUploads,
  deleteUploadFailure,
  deleteUploadSuccess,
  getUploadFailure,
  getUploads,
  getUploadsForEntitySuccess,
  getUploadSuccess,
  getUploadsWithRoleForEntity,
  linkUploadsToEntity,
  persistUploadFailure,
  persistUploadsAndLinkToEntity,
  persistUploadSuccess,
  renameUpload,
  unlinkUpload,
} from './uploadsActions';
import { Upload, UploadAttachableEntityType } from '@payaca/types/uploadTypes';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { handleAsyncAction } from '../utils';

const uploadsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handlePersistUpload(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_UPLOAD,
      {
        persistUploadRequestData: PersistUploadRequestData;
        callback?: (uploadId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response } = yield race({
        response: call(persistUpload, action.payload.persistUploadRequestData),
      });

      yield put(persistUploadSuccess());
      action.payload.callback && action.payload.callback(response);
    } catch (error: any) {
      yield put(persistUploadFailure(error as Error));
    }
  }

  const persistUpload = async (
    persistUploadRequestData: PersistUploadRequestData
  ) => {
    const response = await req.postFile(
      `/uploads`,
      {
        file: persistUploadRequestData.file,
        fileName:
          persistUploadRequestData.fileName ||
          persistUploadRequestData.file.name,
      },
      isNativeApp
    );

    if (response.ok) {
      return response.json();
    } else {
      throw new Error(
        `Persist upload failed: ${response.status} ${response.statusText}`
      );
    }
  };

  function* handlePersistUploadAndLinkToEntity(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_UPLOAD_AND_LINK_TO_ENTITY,
      {
        persistUploadAndLinkToEntityRequestData: PersistUploadAndLinkToEntityRequestData;
        callback?: (error: string | null, uploadId?: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response } = yield race({
        response: call(
          persistUploadAndLinkToEntity,
          action.payload.persistUploadAndLinkToEntityRequestData
        ),
      });

      yield put(persistUploadSuccess());
      action.payload.callback && action.payload.callback(null, response);
    } catch (error: any) {
      yield put(persistUploadFailure(error as Error));
      action.payload.callback && action.payload.callback(error.message);
    }
  }

  const persistUploadAndLinkToEntity = async (
    persistUploadAndLinkToEntityRequestData: PersistUploadAndLinkToEntityRequestData
  ) => {
    const response = await req.postFile(
      `/uploads/link_to_entity/${persistUploadAndLinkToEntityRequestData.entityType}/${persistUploadAndLinkToEntityRequestData.entityId}?role=${persistUploadAndLinkToEntityRequestData.entityRole}`,
      {
        file: persistUploadAndLinkToEntityRequestData.file,
        fileName:
          persistUploadAndLinkToEntityRequestData.fileName ||
          persistUploadAndLinkToEntityRequestData.file.name,
      },
      isNativeApp
    );

    if (response.ok) {
      return response.json();
    } else {
      throw new Error(
        `Persist upload failed: ${response.status} ${response.statusText}`
      );
    }
  };

  function* handleDeleteUpload(
    action: PayloadAction<
      ActionType.REQUEST_DELETE_UPLOAD,
      {
        uploadId: number;
        callback?: (error: string | null) => void;
        continueDeleteIfAnyLinkedEntities?: boolean;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          deleteUpload,
          action.payload.uploadId,
          action.payload.continueDeleteIfAnyLinkedEntities
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Delete upload timed out.';
        yield put(deleteUploadFailure(new Error(errorMessage)));
        action.payload.callback && action.payload.callback(errorMessage);
      } else {
        yield put(deleteUploadSuccess(action.payload.uploadId));
        action.payload.callback && action.payload.callback(null);
      }
    } catch (error: any) {
      yield put(deleteUploadFailure(error as Error));
      action.payload.callback && action.payload.callback(error.message);
    }
  }

  const deleteUpload = async (
    uploadId: number,
    continueDeleteIfAnyLinkedEntities?: boolean
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/uploads/${uploadId}`, {
      method: 'DELETE',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
      body: JSON.stringify({
        continueDeleteIfAnyLinkedEntities:
          continueDeleteIfAnyLinkedEntities || false,
      }),
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `Add new upload to entity failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetUpload(
    action: PayloadAction<
      ActionType.REQUEST_GET_UPLOAD,
      {
        uploadId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getUpload, action.payload.uploadId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get upload timed out.';
        yield put(
          getUploadFailure(action.payload.uploadId, new Error(errorMessage))
        );
      } else {
        yield put(getUploadSuccess(response.id, response));
      }
    } catch (error: any) {
      yield put(getUploadFailure(action.payload.uploadId, error));
    }
  }

  const getUpload = async (uploadId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/uploads/${uploadId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get upload failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetUploadsForEntity(
    action: PayloadAction<
      ActionType.REQUEST_GET_UPLOAD,
      {
        entityId: number;
        entityType: UploadAttachableEntityType;
        callback?: (uploads: Upload[]) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getUploadsForEntity,
          action.payload.entityId,
          action.payload.entityType
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getUploadsForEntity request timed out');
      }

      yield put(getUploadsForEntitySuccess(response));
      action.payload?.callback?.(response);
    } catch (error: any) {}
  }

  const getUploadsForEntity = async (
    entityId: number,
    entityType: UploadAttachableEntityType
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/uploads/entity/${entityType}/${entityId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get upload failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const handlePersistUploadsAndLinkToEntity =
    handleAsyncAction<PersistUploadsAndLinkToEntity>(
      persistUploadsAndLinkToEntity,
      async (payload) => {
        try {
          const files = payload.files;
          // create intent for uploads
          const response: { url: string; bucketKey: string }[] = await req
            .post(
              `/uploads/create_uploads_intents`,
              files.map((f) => ({
                fileName: f.fileName,
                fileType: f.type,
              }))
            )
            .then((response) => {
              return response.json();
            });

          // upload to bucket using signed urls
          await Promise.all(
            response.map(async (r, i: number) => {
              await fetch(r.url, {
                method: 'PUT',
                headers: {
                  'content-type': files[i].type,
                },
                body: files[i] as any,
              });
            })
          ).catch(() => {
            throw new Error('uploadUploadFailed');
          });

          // create uploads in db, link to entity and create thumbnails
          const uploads = await req
            .post(
              `/uploads/create_uploads_and_link_to_entity/${payload.entityType}/${payload.entityId}`,
              files.map((f, i) => ({
                fileName: f.fileName,
                bucketKey: response[i].bucketKey,
                fileSize: f.size,
                mimeType: f.type,
              }))
            )
            .then((response) => {
              return response.json();
            })
            .catch(() => {
              throw new Error('uploadCreatesUploadsAndLinkToEntityFailed');
            });

          return uploads;
        } catch (err) {
          console.log(
            `Persist uploads and link to entity failed: ${JSON.stringify(err)}`
          );
          payload.onErrorCallback?.();
        }
      },
      async (response, requestData) => {
        requestData.payload.callback?.(
          response as { id: number; fileName: string; url: string }[]
        );
      }
    );

  const handleLinkUploadsToEntity = handleAsyncAction<LinkUploadsToEntity>(
    linkUploadsToEntity,
    async (payload) => {
      try {
        // create intent for uploads
        const response = await req.post(
          `/uploads/link_to_entity/existing/${payload.payload.entityType}/${payload.payload.entityId}`,
          {
            uploadIds: payload.payload.uploadIds,
            uploadRole: payload.payload.uploadRole,
          }
        );
        return;
      } catch (err) {
        console.log(`Link uploads to entity failed: ${JSON.stringify(err)}`);
        throw err;
      }
    },
    async (response, requestData) => {
      requestData.payload.callback?.();
    },
    async (response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleGetUploads = handleAsyncAction<GetUploads>(
    getUploads,
    async (payload) => {
      try {
        const response = await req.get(
          `/uploads?${payload.uploadIds
            .map((id) => `uploadIds[]=${id}`)
            .join('&')}`
        );
        const result = await response.json();
        return result;
      } catch (err) {
        console.log(`Get uploads failed: ${JSON.stringify(err)}`);
        throw err;
      }
    },
    async (response, requestData) => {
      requestData.payload.callback?.();
    },
    async (response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleGetUploadsWithRoleForEntity =
    handleAsyncAction<GetUploadsWithRoleForEntity>(
      getUploadsWithRoleForEntity,
      async (payload) => {
        const response = await req.get(
          `/uploads/entity/${payload.entityType}/${payload.entityId}?role=${payload.uploadRole}`
        );
        const result = await response.json();
        return { uploads: result };
      },
      async (response, requestData) => {
        requestData.payload.callback?.(response.uploads);
      },
      async (response, requestData) => {
        requestData.payload.onErrorCallback?.();
      }
    );

  const handleRenameUpload = handleAsyncAction<RenameUpload>(
    renameUpload,
    async (payload) => {
      await req.put(`/uploads/${payload.payload.uploadId}`, {
        fileName: payload.payload.fileName,
      });
    },
    async (response, requestData) => {
      requestData.payload.callback?.();
    },
    async (response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleUnlinkUploadFromEntity =
    handleAsyncAction<UnlinkUploadFromEntity>(
      unlinkUpload,
      async (payload) => {
        await req.put(`/uploads/${payload.uploadId}/unlink`, {
          entity: payload.entity,
          deleteUploadIfOrphaned: payload.deleteUploadIfOrphaned,
        });
      },
      async (response, requestData) => {
        requestData.payload.callback?.();
      },
      async (response, requestData) => {
        requestData.payload.onErrorCallback?.();
      }
    );

  function* handleLogout() {
    yield put(clearUploads());
  }

  return function* () {
    yield takeEvery(ActionType.RENAME_UPLOAD_REQUEST, handleRenameUpload);
    yield takeEvery(ActionType.UNLINK_UPLOAD, handleUnlinkUploadFromEntity);
    yield takeEvery(ActionType.REQUEST_PERSIST_UPLOAD, handlePersistUpload);
    yield takeEvery(
      ActionType.REQUEST_PERSIST_UPLOAD_AND_LINK_TO_ENTITY,
      handlePersistUploadAndLinkToEntity
    );
    yield takeLatest(ActionType.REQUEST_DELETE_UPLOAD, handleDeleteUpload);

    yield takeEvery(ActionType.REQUEST_GET_UPLOAD, handleGetUpload);
    yield takeEvery(
      ActionType.REQUEST_GET_UPLOADS_FOR_ENTITY,
      handleGetUploadsForEntity
    );
    yield takeEvery(
      ActionType.PERSIST_UPLOADS_AND_LINK_TO_ENTITY_REQUEST,
      handlePersistUploadsAndLinkToEntity
    );
    yield takeEvery(
      ActionType.LINK_UPLOADS_TO_ENTITY_REQUEST,
      handleLinkUploadsToEntity
    );
    yield takeEvery(ActionType.GET_UPLOADS_REQUEST, handleGetUploads);
    yield takeEvery(
      ActionType.GET_UPLOADS_WITH_ROLE_FOR_ENTITY_REQUEST,
      handleGetUploadsWithRoleForEntity
    );
    yield takeEvery('auth.logout', handleLogout);
  };
};

export default uploadsSagaCreator;
