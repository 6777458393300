import React, { FunctionComponent, useMemo } from 'react';

import { ListedItem } from '@payaca/types/listedItemTypes';

import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';
import EmptyState from '../emptyState/EmptyState';
import ListedItemCard from '../listedItemCard/ListedItemCard';

import './ListedItems.sass';
import { useSelector } from '@/api/state';

type Props = {
  selectedItemIds?: number[];
  disabledItemIds?: number[];
  onClickRow?: (itemId: number) => void;
  onSelectItems?: (itemIds: number[]) => void;
  onUnselectItems?: (itemIds: number[]) => void;
  onSelectPage: (pageNumber: number) => void;
  onSelectPageSize?: (pageSize: number) => void;
  quickActionDefinitions?: {
    duplicate?: {
      actionName: string;
      actionBehaviour: (id: number) => void;
      isActionProcessing?: boolean;
    };
  };
  isInBulkActionsMode?: boolean;
};

const ListedItems: FunctionComponent<Props> = ({
  disabledItemIds = [],
  selectedItemIds = [],
  onClickRow,
  onSelectItems,
  onUnselectItems,
  onSelectPage,
  onSelectPageSize,
  quickActionDefinitions,
  isInBulkActionsMode = false,
}: Props): JSX.Element | null => {
  const listedItemsPage = useSelector((state: any) => {
    return state.listedItems?.listedItemsPage;
  });

  const canSelectItems = useMemo(() => {
    return !!(onSelectItems && onUnselectItems && isInBulkActionsMode);
  }, [isInBulkActionsMode, onUnselectItems, onSelectItems]);

  const isGettingListedItemsPage = useSelector(
    (state: any) => state.listedItems.isGettingListedItemsPage
  );

  const paginationControl = useMemo(() => {
    if (!listedItemsPage) return null;
    return (
      <PaginationControl
        pageNumber={listedItemsPage.pageNumber}
        pageSize={listedItemsPage.pageSize}
        totalItemCount={listedItemsPage.totalItemCount}
        onSelectPage={onSelectPage}
        allowPageSizeSelection={true}
        availablePageSizes={[5, 20, 30, 50, 100]}
        onSelectPageSize={onSelectPageSize}
      />
    );
  }, [listedItemsPage, onSelectPage]);

  return (
    <div className="listed-items">
      {isGettingListedItemsPage && (
        <div className="loader-container">
          <MiniLoader />
        </div>
      )}
      {!isGettingListedItemsPage &&
        !listedItemsPage?.totalItemCount &&
        !listedItemsPage?.totalUnfilteredItemCount && (
          <EmptyState configName={'items'} arrowIndication={false} />
        )}
      {!isGettingListedItemsPage &&
        !listedItemsPage?.totalItemCount &&
        !!listedItemsPage?.totalUnfilteredItemCount && (
          <div className="no-results-container">
            <span>No results found</span>
          </div>
        )}
      {!isGettingListedItemsPage &&
        !!listedItemsPage?.items?.length &&
        listedItemsPage.items.map((listedItem: ListedItem, index: number) => {
          const itemIsSelected = selectedItemIds.includes(listedItem.itemId);
          const itemIsDisabled = disabledItemIds.includes(listedItem.itemId);

          return (
            <div
              key={`listed-item-${index}`}
              className={`listed-item-row${itemIsSelected ? ' selected' : ''}${
                itemIsDisabled ? ' disabled' : ''
              }`}
            >
              <ListedItemCard
                key={`${listedItem.itemId}-${index}`}
                listedItem={listedItem}
                onClick={() => onClickRow && onClickRow(listedItem.itemId)}
                quickActionDefinitions={quickActionDefinitions}
                selectionConfig={
                  onSelectItems && onUnselectItems && canSelectItems
                    ? {
                        isSelected: itemIsSelected,
                        isDisabled: itemIsDisabled,
                        onSelectionChange: (isSelected) => {
                          isSelected
                            ? onUnselectItems &&
                              onUnselectItems([listedItem.itemId])
                            : onSelectItems &&
                              onSelectItems([listedItem.itemId]);
                        },
                      }
                    : undefined
                }
              />
            </div>
          );
        })}
      {paginationControl}
    </div>
  );
};

export default ListedItems;
