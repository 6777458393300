import React, { FC, useState } from 'react';
import { FormAndJSONSchema } from '@/api/rest/connectionApplication/useGetConnectionApplicationSchema';
import { useHistory, useParams } from 'react-router';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import useGetConnectionApplication from '@/api/queries/connectionApplication/useGetConnectionApplication';
import usePostSubmitConnectionApplication from '@/api/rest/connectionApplication/usePostSubmitConnectionApplication';
import {
  defaultFormValues,
  DevicesFieldArrayKeys,
  jsonSchemaWithZodEdgeCasesResolver,
  populateFormDataWithDefaultValues,
  usePersistFormChanges,
} from '@/ui/pages/connectionApplicationPage/utils';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { sentenceCase } from '@payaca/utilities/stringUtilities';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import ReactMarkdown from 'react-markdown';
import FieldSet from '@/ui/pages/connectionApplicationPage/FieldSet';
import DevicesFieldArray from '@/ui/pages/connectionApplicationPage/DevicesFieldArray';
import ProjectFileSelection from '@/ui/pages/connectionApplicationPage/ProjectFileSelection';
import ConnectionApplicationLayout from '@/ui/pages/connectionApplicationPage/components/ConnectionApplicationLayout';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import { DevTool } from '@hookform/devtools';
import { UnionFieldArray } from '@/ui/pages/connectionApplicationPage/ConnectionApplication';

export interface IProps {
  jsonSchema: any;
  formSchema: FormAndJSONSchema;
  subSections: [string, any][];
}

const ConnectionApplicationForm: FC<IProps> = (props) => {
  const { jsonSchema, formSchema, subSections } = props;

  /**
   * Router
   */
  const history = useHistory();
  const { connectionApplicationId, projectId } = useParams<{
    projectId: string;
    connectionApplicationId: string;
  }>();

  /**
   * State
   */
  const { pushToast } = useToastContext();
  const [errorMessage, setErrorMessage] = useState<string>();

  /**
   * Queries
   */
  const { data: persistedFormStateData } = useGetConnectionApplication(
    connectionApplicationId,
    false
  );

  /**
   * Mutations
   */
  const {
    mutate: submitConnectionApplicationMutation,
    isLoading: isSubmittingForm,
  } = usePostSubmitConnectionApplication({
    onSuccess: () => {
      pushToast({
        variant: 'white',
        icon: 'success',
        message:
          'Application submitted successfully. Expect a status update here within 5 minutes.',
      });
      history.push(`/deals/${projectId}/compliance`);
    },
    onError: ({ message }) => {
      setErrorMessage(message);
    },
  });

  const form = useForm<any>({
    mode: 'onChange',
    defaultValues: persistedFormStateData?.connectionApplication.data
      ? populateFormDataWithDefaultValues(
          persistedFormStateData.connectionApplication.data
        )
      : defaultFormValues,
    resolver: jsonSchemaWithZodEdgeCasesResolver(jsonSchema),
  });

  const {
    handleSubmit,
    control,
    register,
    formState: { isDirty },
  } = form;

  const unionFieldArrays: Record<string, UnionFieldArray> = {};

  for (const [key, formSection] of subSections) {
    if (!formSection.items || !formSection.items.oneOf) continue;
    unionFieldArrays[key] = {
      fieldArray: useFieldArray({ control, name: key }),
    };
  }

  const hasChanges = usePersistFormChanges(
    control,
    persistedFormStateData,
    connectionApplicationId
  );

  const onSubmit = (_data: any) => {
    // clear error message
    setErrorMessage(undefined);

    const data = structuredClone(_data);

    submitConnectionApplicationMutation({
      connectionApplicationId,
      data,
    });
  };

  const scrollSections = subSections.map(([key]) => {
    const title = sentenceCase(formSchema.properties[key].title);
    return {
      label: title,
      slug: key,
      render: () => {
        return (
          <Card key={key} sizeVariant={CardSizeVariant.SM}>
            <Card.Body>
              <div className="space-y-4">
                <Card.Title>{title}</Card.Title>
                <p>
                  <ReactMarkdown>
                    {formSchema.properties[key].subtitle}
                  </ReactMarkdown>
                </p>
                {formSchema.properties[key].properties && (
                  <FieldSet
                    data={formSchema.properties[key]}
                    namespace={key}
                    definitions={formSchema.definitions}
                  />
                )}

                {unionFieldArrays[key] &&
                  DevicesFieldArrayKeys.includes(key) && (
                    <DevicesFieldArray
                      schemaSection={formSchema.properties[key]}
                      unionFieldArray={unionFieldArrays[key]}
                      fieldKey={key}
                      definitions={formSchema.definitions}
                    />
                  )}

                {formSchema.properties[key].items?.title === 'AttachmentV1' && (
                  <ProjectFileSelection
                    name={key}
                    formSection={formSchema.properties[key]}
                  />
                )}
              </div>
            </Card.Body>
          </Card>
        );
      },
    };
  });

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit, (errors) => {
          console.error(errors);
        })}
      >
        <ConnectionApplicationLayout
          sections={scrollSections}
          submitProps={{
            isProcessing: isSubmittingForm,
            disabled: isSubmittingForm || hasChanges,
          }}
          status={persistedFormStateData?.connectionApplication.status}
          connectionApplicationStatus={
            persistedFormStateData?.connectionApplication
              .connectionApplicationStatus
          }
        >
          {Object.entries(formSchema.fixedInputs).map(([name, value]) => {
            return (
              <input
                key={name}
                type="hidden"
                readOnly={true}
                {...register(name, {
                  value,
                })}
              />
            );
          })}

          <Card
            className="sticky bottom-0 rounded-b-none"
            sizeVariant={CardSizeVariant.SM}
          >
            <Card.Body className="flex flex-col justify-center gap-2">
              {errorMessage && (
                <Alert
                  colour={EAlertColour.SOFT_RED}
                  className="max-h-[100px] overflow-scroll sm:max-h-none sm:overflow-auto"
                >
                  <p>{errorMessage}</p>
                </Alert>
              )}

              <span className="text-center text-gray-600">
                {isDirty &&
                  (hasChanges ? 'Changes saving...' : 'Changes saved.')}
              </span>
            </Card.Body>
          </Card>
        </ConnectionApplicationLayout>
      </form>

      {import.meta.env.VITE_APP_ENV === 'local' && (
        <DevTool control={control} />
      )}
    </FormProvider>
  );
};

export default ConnectionApplicationForm;
