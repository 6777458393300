// ======================================================================================================
// Validation helper functions
// ======================================================================================================

import get from 'lodash.get';
import isEmail from 'validator/es/lib/isEmail';
import { getFormServiceError } from '@payaca/helpers/errorHelper';

import { StringUtil } from '@/utils';
import {
  AvailableForm,
  FormCategoryType,
  FormOptionGroups,
} from '@payaca/types/formTypes';
import { VALID_PHONE_NUMBER_REGEX } from '@payaca/constants';
import { AccountRegions } from '@payaca/types/accountTypes';

const FieldType = {
  TEXTAREA: 'text-area',
};

const minPasswordLength = 12;

const isBlockedEmail = (emailToCheck: string, blockedEmails: string[]) =>
  !!blockedEmails.find((em) => em === emailToCheck);

const validations = {
  required: {
    validate: (val: string) => !!val && /[\S]/.test(val),
    error: 'This is a required field',
  },
  priceRequired: {
    validate: (val: number) => !!val || val === 0,
    error: 'This is a required field',
  },
  passwordCreate: {
    validate: (password: string) =>
      password &&
      password.length >= minPasswordLength &&
      /\d/.test(password) &&
      /[a-zA-Z]/.test(password),
    error:
      'Password must be at least 12 characters and contain 1 letter and 1 number',
  },
  contactNumber: {
    validate: (phone: string) => {
      return !phone || phone.replace('+', '').length === 12;
    }, // checking 12 as format is 44
    error: 'Not a recognised phone number',
  },
  passwordLength: {
    validate: (password: string) => password.length >= minPasswordLength,
    error: ' ',
  },
  email: {
    validate: (email: string, options: any) =>
      !email ||
      (isEmail(email) &&
        !isBlockedEmail(email, options ? options.blockedEmails : [])),
    error: 'Not a recognised email address',
  },
  emailCurried: (options: any) => ({
    validate: (email: string) =>
      !email ||
      (isEmail(email) &&
        !isBlockedEmail(email, options ? options.blockedEmails : [])),
    error: 'Not a recognised email address',
  }),
  emailNoError: {
    validate: (email: string, options: { blockedEmails: string[] }) =>
      !email ||
      (isEmail(email) &&
        !isBlockedEmail(email, options ? options.blockedEmails : [])),
    error: ' ',
  },
  vatNumber: {
    validate: (vatNumber: string) => !vatNumber || vatNumber.length <= 15, // TODO: should eventually be country based
    error: 'Invalid vat number',
  },
  accountNumber: {
    validate: (accountNumber: string) =>
      !accountNumber || /^[0-9]{8,12}$/.test(accountNumber),
    error: 'Invalid account number',
  },
  accountNumberByRegion: (region: AccountRegions) => {
    if (region === AccountRegions.UK) {
      return {
        validate: (accountNumber: string) =>
          !accountNumber || /^[0-9]{8,12}$/.test(accountNumber),
        error: 'Invalid account number',
      };
    }
    if (region === AccountRegions.CANADA) {
      return {
        validate: (accountNumber: string) =>
          !accountNumber ||
          (accountNumber.length <= 12 &&
            accountNumber.length >= 5 &&
            /^[0-9]+$/.test(accountNumber)),
        error: 'Invalid account number',
      };
    }
    if (region === AccountRegions.NEW_ZEALAND) {
      return {
        validate: (accountNumber: string) =>
          !accountNumber ||
          (accountNumber.length == 16 && /^[0-9]+$/.test(accountNumber)),
        error: 'Invalid account number',
      };
    }
    if (region === AccountRegions.SOUTH_AFRICA) {
      return {
        validate: (accountNumber: string) =>
          !accountNumber ||
          (accountNumber.length <= 11 &&
            accountNumber.length >= 9 &&
            /^[0-9]+$/.test(accountNumber)),
        error: 'Invalid account number',
      };
    }
    if (region === AccountRegions.US) {
      return {
        validate: (accountNumber: string) =>
          !accountNumber ||
          (accountNumber.length <= 12 &&
            accountNumber.length >= 8 &&
            /^[0-9]+$/.test(accountNumber)),
        error: 'Invalid account number',
      };
    }
    if (region === AccountRegions.AUSTRALIA) {
      return {
        validate: (accountNumber: string) =>
          !accountNumber || /^[0-9]+$/.test(accountNumber),
        error: 'Invalid account number',
      };
    }
  },
  sortCode: {
    validate: (sortCode?: string) => !sortCode || /^[0-9]{6}$/.test(sortCode),
    error: 'Invalid sort code',
  },
  sortCodeByRegion: (region: AccountRegions) => {
    if (region === AccountRegions.UK) {
      return {
        validate: (sortCode?: string) =>
          !sortCode || /^[0-9]{6}$/.test(sortCode),
        error: 'Invalid sort code',
      };
    }
    if (region === AccountRegions.CANADA) {
      return {
        validate: (routingNumber: string) =>
          !routingNumber || /^0[0-9]{8}$/.test(routingNumber),
        error: 'Invalid routing number',
      };
    }
    if (region === AccountRegions.NEW_ZEALAND) {
      return {
        validate: (routingNumber: string) =>
          !routingNumber || /^[0-9]{6}$/.test(routingNumber),
        error: 'Invalid BSB',
      };
    }
    if (region === AccountRegions.SOUTH_AFRICA) {
      return {
        validate: (routingNumber: string) =>
          !routingNumber || /^[0-9]{6}$/.test(routingNumber),
        error: 'Invalid branch code',
      };
    }
    if (region === AccountRegions.US) {
      return {
        validate: (routingNumber: string) =>
          !routingNumber || /^[0-9]{9}$/.test(routingNumber),
        error: 'Invalid routing number',
      };
    }
    if (region === AccountRegions.AUSTRALIA) {
      return {
        validate: (routingNumber: string) =>
          !routingNumber || /^[0-9]{6}$/.test(routingNumber),
        error: 'Invalid BSB',
      };
    }
  },
  passwordMatch: {
    validate: (confirmPassword: string, password: string) =>
      confirmPassword === password,
    error: 'Passwords must match',
  },
  dueOrValidDays: {
    validate: (val: number) => !val || val >= 0,
    error: 'Number of days must be more than or equal to zero',
  },
  smsSenderHandle: {
    validate: (val: string) =>
      // Twilio allow only a-z, A-Z, 0-9, +, -, _, &, and spaces and require at least 1 letter
      /^[a-zA-Z0-9 \-_&+]{4,11}$/.test(val) && /[a-zA-Z]/.test(val),
    error:
      'SMS messages must be sent from a label that is alphanumeric, between 4-11 characters and contain at least 1 letter',
  },
  businessContactNumber: {
    validate: (phone: string) => !phone || VALID_PHONE_NUMBER_REGEX.test(phone),
    error: 'Not a recognised phone number',
  },
};

const FormValidations = {
  customer: {
    name: [validations.required],
    email: [validations.email],
    contactNumber: [],
  },
  item: {
    name: [],
    description: [validations.required],
    price: [validations.priceRequired],
  },
  lineItem: {
    description: [validations.required],
    price: [validations.priceRequired],
    quantity: [validations.required],
  },
  forgotPassword: {
    email: [validations.required, validations.email],
  },
  resetPassword: {
    newPassword: [validations.required, validations.passwordCreate],
    confirmPassword: [validations.required, validations.passwordMatch], // match password
  },
  register: {
    firstName: [validations.required],
    lastName: [validations.required],
    email: [validations.required, validations.email],
    password: [validations.required, validations.passwordCreate],
    contactNumber: [validations.required, validations.contactNumber],
  },
  personalDetails: {
    firstName: [validations.required],
    lastName: [validations.required],
    email: [validations.required, validations.email],
    contactNumber: [validations.required, validations.contactNumber],
    currentPassword: [validations.required, validations.passwordLength],
    newPassword: [validations.required, validations.passwordCreate],
  },
  login: {
    email: [validations.required, validations.emailNoError],
    password: [validations.required, validations.passwordLength],
  },
  businessAccount: {
    businessName: [validations.required],
  },
  businessSettings: {
    businessName: [validations.required],
    smsSenderHandle: [validations.required, validations.smsSenderHandle],
    email: [validations.email],
    contactNumber: [validations.businessContactNumber],
    firstLineAddress: [],
    secondLineAddress: [],
    city: [],
    postcode: [],
    vatNumber: [validations.vatNumber],
    paymentTerms: [],
    accountName: [],
    accountNumber: [validations.accountNumber],
    sortCode: [validations.sortCode],
    propositionValidForDays: [validations.dueOrValidDays],
    invoiceDueInDays: [validations.dueOrValidDays],
  },
  bacsDetails: {
    accountName: [validations.required],
    accountNumber: [validations.required, validations.accountNumber],
    sortCode: [validations.required, validations.sortCode],
  },
  verifyAccount: {
    email: [validations.required, validations.email],
  },
  addUser: {
    firstName: [validations.required],
    lastName: [validations.required],
    email: [validations.required, validations.email],
  },
  userInvite: {
    password: [validations.required, validations.passwordCreate],
  },
  setupUser: {
    contactNumber: [validations.required, validations.contactNumber],
  },
};

const ExternalFormValidationErrors: any = {
  email: {
    error:
      'This email looks invalid to us, please double check before continuing',
    allowInvalid: true, // will not block form submission
  },
  contactNumber: {
    error:
      'This phone number looks invalid to us, please double check before continuing',
    allowInvalid: true,
  },
};

const getExternalFieldError = (name: string) => {
  const error = ExternalFormValidationErrors[name];
  return error || null;
};

// errors are assumed to be invalid if there is an error shown
// TODO: ability to customise this on a per field basis
const getFieldErrors = (value: string, validations: any, options: any) => {
  if (validations) {
    return validations.reduce(
      (acc: { error: string; allowInvalid: boolean }[], v: any) =>
        !v.validate(value, options)
          ? acc.concat({ error: v.error, allowInvalid: false })
          : acc,
      []
    );
  }
  return [];
};

const Autocomplete = {
  firstName: 'given-name',
  lastName: 'family-name',
  email: 'email',
  newPassword: 'new-password',
  currentPassword: 'current-password',
};

const getAutoComplete = (name: string) => get(Autocomplete, name, '');

const Placeholder = {
  vatNumber: '123456789',
  paymentTerms: 'e.g. BACS or cheque',
  sortCode: 'e.g. 132457',
  price: ' ',
  quoteNotes: ' ',
  invoiceNotes: ' ',
  businessNotes:
    'Text here will be added to the bottom of every quote & invoice',
};

const getPlaceholder = (name: string) => get(Placeholder, name, '');

const CharacterLimit = {};

const getCharacterLimit = (name: string) => get(CharacterLimit, name, null);

const getManageTermsErrors = (err: any) => {
  const errs = Object.keys(err);
  return errs.reduce((acc, curr, i) => {
    acc = `${acc} ${StringUtil.list(err[curr])} ${getFormServiceError(
      'business',
      curr
    )}`;
    if (i === errs.length - 1) {
      acc = `${acc} Please try again.`;
    } else if (errs.length !== 1) {
      acc = acc + ' ';
    }
    return acc;
  }, '');
};

const isFieldRequired = (
  fieldValidations: { validate: any; error: string }[]
) => {
  return (
    fieldValidations && fieldValidations.find((f) => f === validations.required)
  );
};

export {
  ExternalFormValidationErrors,
  FieldType,
  FormValidations,
  getAutoComplete,
  getCharacterLimit,
  getExternalFieldError,
  getFieldErrors,
  getFormServiceError,
  getPlaceholder,
  getManageTermsErrors,
  validations,
  isFieldRequired,
};
