import { useSelector } from '@/api/state';
import {
  DateFormats,
  getInternationalDateFormatByLocale,
} from '@payaca/helpers/internationalHelper';
import { roundToDecimalPlaces } from '@payaca/helpers/numberHelper';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';
import {
  getTimeDuration,
  roundDateTimeUpToHalfHour,
} from '@payaca/utilities/timeUtilities';
import { FC, useMemo } from 'react';
import './ScheduledEventKeyInformationHeader.sass';
import { addHours, format, isSameDay } from 'date-fns';
import useGetMyRegionPreferences from '../../../api/queries/me/useGetMyRegionPreferences';

type Props = {
  scheduledEvent: ScheduledEvent;
};
const ScheduledEventKeyInformationHeader: FC<Props> = ({
  scheduledEvent,
}: Props): JSX.Element | null => {
  const { data: accountRegionPreferences } = useGetMyRegionPreferences();

  const { eventBeginsAt, eventEndsAt } = useMemo(() => {
    const eventBegins =
      scheduledEvent?.beginAt || roundDateTimeUpToHalfHour(new Date()).toDate();
    return {
      eventBeginsAt: eventBegins,
      eventEndsAt: scheduledEvent?.endAt || addHours(eventBegins, 1),
    };
  }, [scheduledEvent]);

  const duration = useMemo<any>(
    () => getTimeDuration(eventBeginsAt, eventEndsAt),
    [eventBeginsAt, eventEndsAt]
  );

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  const timestampShortDateRegionalFormat = useMemo(
    () =>
      getInternationalDateFormatByLocale(
        DateFormats.SHORT,
        accountRegionPreferences?.locale
      ),
    [account]
  );

  const startsAndFinishesOnSameDay = useMemo(() => {
    return isSameDay(scheduledEvent.beginAt, scheduledEvent.endAt);
  }, [scheduledEvent]);

  const startTime = useMemo(() => {
    return format(scheduledEvent.beginAt, 'h:mmaaa');
  }, [scheduledEvent]);

  const startDate = useMemo(() => {
    return format(scheduledEvent.beginAt, timestampShortDateRegionalFormat);
  }, [scheduledEvent, timestampShortDateRegionalFormat]);

  const endTime = useMemo(() => {
    return format(scheduledEvent.endAt, 'h:mmaaa');
  }, [scheduledEvent]);

  const endDate = useMemo(() => {
    return format(scheduledEvent.endAt, timestampShortDateRegionalFormat);
  }, [scheduledEvent, timestampShortDateRegionalFormat]);

  const startDay = useMemo(() => {
    return format(scheduledEvent.beginAt, 'dd');
  }, [scheduledEvent]);

  const startMonth = useMemo(() => {
    return format(scheduledEvent.beginAt, 'MMM');
  }, [scheduledEvent]);

  return (
    <div className={'scheduled-event-key-information-header flex-container'}>
      <div className="start-date">
        <span className="day">{startDay}</span>
        <span className="month">{startMonth}</span>
      </div>
      <div className="name-duration-container">
        <h1>{scheduledEvent.name}</h1>
        <div className="duration-container flex-container flex-center">
          <div className="time-date">
            <div className="time">
              <strong>{startTime}</strong>
            </div>
            {!startsAndFinishesOnSameDay && (
              <div className="date">
                <small>{startDate}</small>
              </div>
            )}
          </div>
          <span>-</span>
          <div className="time-date">
            <div className="time">
              <strong>{endTime}</strong>
            </div>
            {!startsAndFinishesOnSameDay && (
              <div className="date">
                <small>{endDate}</small>
              </div>
            )}
          </div>
          <span className="duration">
            ({roundToDecimalPlaces(duration.value)} {duration.label})
          </span>
        </div>
      </div>
    </div>
  );
};
export default ScheduledEventKeyInformationHeader;
