import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { actions as usersActions } from '@/api/users';
import { usePrevious } from '@/utils/customHooks';

import {
  ConnectionControl,
  ConnectionContolAlert,
  ConnectionControlBenefitList,
} from '@/ui/components';

import QuickbooksLogo from './quickbooks.svg';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { getUserRoles } from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';

import Modal from '@payaca/components/plModal/Modal';
import { ConfigureAccountingIntegrationControl } from '../configureAccountingIntegrationControl/ConfigureAccountingIntegrationControl';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';

const QUICKBOOKS_CLIENT_ID = import.meta.env.VITE_QUICKBOOKS_CLIENT_ID;

const QuickbooksConnectionControl: FunctionComponent =
  (): JSX.Element | null => {
    const dispatch = useDispatch();
    const [isImportingQuickbooksItems, setIsImportingQuickbooksItems] =
      useState(false);
    const [importItemsMessage, setImportItemsMessage] = useState<string>();
    const [isImportingQuickbooksContacts, setIsImportingQuickbooksContacts] =
      useState(false);
    const [importContactsMessage, setImportContactsMessage] =
      useState<string>();
    const [showSetupModal, setShowSetupModal] = useState(false);

    const isConnectedToQuickbooks = useSelector((state: any) => {
      return state.users.myProfile.accounts[0].integrations?.quickbooks;
    });
    const previousIsConnectedToQuickbooks = usePrevious(
      isConnectedToQuickbooks
    );
    const quickbooksRealmName = useSelector((state: any) => {
      return state.users.myProfile.accounts[0].integrations?.quickbooks
        ?.quickbooksRealmName;
    });
    const userRoles = useSelector(getUserRoles);
    const isAdmin = useMemo(() => {
      return userHasRequiredPermission(userRoles, [
        AccountsPermissions.UPDATE_CONNECTION,
      ]);
    }, [userRoles]);

    const isCisSubcontractor = useSelector(
      (state: any) => state.users.myProfile.accounts[0]?.isCisSubcontractor
    );

    useEffect(() => {
      if (previousIsConnectedToQuickbooks === undefined) return;
      if (isConnectedToQuickbooks && !previousIsConnectedToQuickbooks) {
        setShowSetupModal(true);
      }
    }, [isConnectedToQuickbooks, previousIsConnectedToQuickbooks]);

    const importQuickbooksItems = useCallback(() => {
      setIsImportingQuickbooksItems(true);
      dispatch(
        usersActions.importQuickbooksItems((err: Error, data: any) => {
          if (!err) {
            setImportItemsMessage(
              `Quickbooks items imported; ${data.newQuickbooksItems} new items added, ${data.updatedQuickbooksItems} items updated`
            );
            setIsImportingQuickbooksItems(false);
          } else {
            setImportItemsMessage(
              'An error occurred importing Quickbooks items'
            );
            setIsImportingQuickbooksItems(false);
          }
        })
      );
    }, [dispatch]);

    const importQuickbooksContacts = useCallback(() => {
      setIsImportingQuickbooksContacts(true);
      dispatch(
        usersActions.importQuickbooksCustomers((err: Error, data: any) => {
          if (!err) {
            setImportContactsMessage(
              `Quickbooks customers imported; ${data.newQuickbooksCustomers} new customers added, ${data.matchedQuickbooksCustomers} customers matched`
            );
            setIsImportingQuickbooksContacts(false);
          } else {
            setImportContactsMessage(
              'An error occurred importing Quickbooks contacts'
            );
            setIsImportingQuickbooksContacts(false);
          }
        })
      );
    }, [dispatch]);

    const renderImportButtons = useCallback(() => {
      return (
        <div>
          <div className="my-4">
            {isConnectedToQuickbooks && (
              <div>
                <Button
                  onClick={importQuickbooksItems}
                  isProcessing={isImportingQuickbooksItems}
                  colour={EBtnColour.White}
                  variant={EBtnVariant.White}
                  size={EBtnSize.Small}
                  className="mb-2"
                >
                  Import Quickbook items
                </Button>
                <div>
                  This will transfer all item details from Quickbooks to Payaca
                  where they don‘t already exist.
                </div>
              </div>
            )}
            {importItemsMessage && <div>{importItemsMessage}</div>}
          </div>
          <div className="my-4">
            {isConnectedToQuickbooks && (
              <div>
                <Button
                  onClick={importQuickbooksContacts}
                  isProcessing={isImportingQuickbooksContacts}
                  colour={EBtnColour.White}
                  variant={EBtnVariant.White}
                  size={EBtnSize.Small}
                  className="mb-2"
                >
                  Import Quickbook customers
                </Button>
                <div>
                  This will transfer all customers details from Quickbooks to
                  Payaca where they don‘t already exist.
                </div>
              </div>
            )}
            {importContactsMessage && <div>{importContactsMessage}</div>}
          </div>
        </div>
      );
    }, [
      importContactsMessage,
      importItemsMessage,
      importQuickbooksContacts,
      importQuickbooksItems,
      isConnectedToQuickbooks,
      isImportingQuickbooksContacts,
      isImportingQuickbooksItems,
    ]);

    return (
      <ConnectionControl
        disableConnection={false}
        connectionName="Quickbooks"
        isConnected={isConnectedToQuickbooks}
        allowConnectDisconnect={isAdmin}
        onAddConnection={() =>
          (window.location.href = `https://appcenter.intuit.com/app/connect/oauth2?client_id=${QUICKBOOKS_CLIENT_ID}&scope=com.intuit.quickbooks.accounting%20openid%20profile%20email%20phone%20address&redirect_uri=${window.location.origin}/connections/quickbooks&response_type=code&state=payaca123`)
        }
        onRemoveConnection={(onConnectionRemoved: () => void) =>
          dispatch(usersActions.removeQuickbooksConnection(onConnectionRemoved))
        }
        logo={QuickbooksLogo}
        disconnectionWarningMessage="Your invoices, customers and items will no longer sync with Quickbooks."
      >
        <React.Fragment>
          {/* Benefits */}
          {!isConnectedToQuickbooks && (
            <div>
              <div>
                Get all the benefits of Payaca and Quickbooks with our seamless
                integration.
              </div>
              <ConnectionControlBenefitList
                benefits={[
                  'Send an invoice in Payaca and all details will automatically be transferred to Quickbooks and marked as sent.',
                  'Import all of your customer and item details from Quickbooks to Payaca so they can be added to a quote or invoice with one click.',
                ]}
              />
            </div>
          )}

          {/* Connected quickbooks name */}
          {isConnectedToQuickbooks && (
            <div className="connected-name">
              <p>
                You are connected to{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {quickbooksRealmName}
                </span>
                .
              </p>
            </div>
          )}

          {isAdmin && isConnectedToQuickbooks && (
            <>
              <Button
                className="mb-2"
                onClick={() => setShowSetupModal(true)}
                colour={EBtnColour.White}
                variant={EBtnVariant.White}
              >
                Configure Connection
              </Button>
              <hr />
            </>
          )}

          {/* Import customers/items */}
          {isAdmin && isConnectedToQuickbooks && renderImportButtons()}

          {/* CIS alert */}
          {isConnectedToQuickbooks && isCisSubcontractor && (
            <ConnectionContolAlert>
              You have enabled yourself as a CIS subcontractor in Payaca, but
              our Quickbooks integration does not currently support CIS
              behaviours.
            </ConnectionContolAlert>
          )}

          {/* Setup modal */}
          {showSetupModal && (
            <Modal
              isOpen={showSetupModal}
              onClose={() => setShowSetupModal(false)}
              title="Configure Quickbooks setup"
            >
              <ConfigureAccountingIntegrationControl
                integrationType="quickbooks"
                onSuccess={() => setShowSetupModal(false)}
              />
            </Modal>
          )}
        </React.Fragment>
      </ConnectionControl>
    );
  };

export default QuickbooksConnectionControl;
