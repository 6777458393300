import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import Button from '../../plButton/Button';
import UntitledIcon from '@payaca/untitled-icons';
import Sidebar, { Props as SidebarProps } from '../../plSidebar/Sidebar';
import {
  EBtnShapeVariant,
  EBtnSize,
  EBtnVariant,
} from '../../plButton/useButtonClassName';
import Checkbox from '../../plCheckbox/Checkbox';

export type FilterOption = {
  title: string;
  name: string;
  type: 'checkbox';
  options: { label: string; value: string | number }[];
};
type AppliedFilters = { [key: string]: (string | number)[] | null } | undefined;

export interface IProps extends Pick<SidebarProps, 'isOpen' | 'onClose'> {
  options: FilterOption[];
  appliedFilters?: AppliedFilters;
  onApplyFilters: (filters: AppliedFilters) => void;
}
const MultiFilterSidebar: FC<IProps> = (props) => {
  const { isOpen, onClose, options, appliedFilters, onApplyFilters } = props;
  const [_appliedFilters, _setAppliedFilters] = useState(appliedFilters);

  useEffect(() => {
    _setAppliedFilters(appliedFilters);
  }, [appliedFilters]);

  const onChangeFilterOption = useCallback(
    (filterName: string, filterValue: any) => {
      _setAppliedFilters({
        ..._appliedFilters,
        [filterName]: filterValue,
      });
    },
    [_appliedFilters]
  );

  const renderFilterOption = (filter: FilterOption, i: number) => {
    const currentFilter = _appliedFilters?.[filter.name] || [];
    if (filter.options) {
      switch (filter.type) {
        case 'checkbox':
          return (
            <FilterSection
              title={filter.title}
              key={`filter-${i}`}
              onClearFilter={() => onChangeFilterOption(filter.name, [])}
            >
              {filter.options.map((opt) => {
                return (
                  <div key={`filter-option-${opt.value}`}>
                    <Checkbox
                      label={opt.label}
                      checked={currentFilter.includes(opt.value)}
                      onChange={() => {
                        if (currentFilter.includes(opt.value)) {
                          // remove from filter
                          onChangeFilterOption(
                            filter.name,
                            currentFilter.filter((o: any) => o !== opt.value)
                          );
                        } else {
                          // add to filter
                          onChangeFilterOption(filter.name, [
                            ...currentFilter,
                            opt.value,
                          ]);
                        }
                      }}
                    />
                  </div>
                );
              })}
            </FilterSection>
          );
      }
    }
  };

  const clearAllFilters = useCallback(() => {
    const allEmptyFilters = options.reduce((acc: Record<string, any>, curr) => {
      acc[curr.name] = [];
      return acc;
    }, {});
    onApplyFilters?.(allEmptyFilters);
  }, [options, onApplyFilters]);

  return (
    <Sidebar isOpen={isOpen} onClose={onClose} title="Filter">
      <Sidebar.Body>
        <div className="p-5">{options.map(renderFilterOption)}</div>
      </Sidebar.Body>
      <Sidebar.Footer>
        <div className="flex items-center gap-x-2">
          <Button
            variant={EBtnVariant.White}
            size={EBtnSize.Small}
            className="w-full"
            onClick={() => {
              clearAllFilters();
              onClose?.();
            }}
          >
            <UntitledIcon name="x-close" className="w-2.5 h-2.5" />
            Clear all filters
          </Button>
          <Button
            size={EBtnSize.Small}
            className="w-full"
            onClick={() => {
              onApplyFilters(_appliedFilters);
              onClose?.();
            }}
          >
            Save
          </Button>
        </div>
      </Sidebar.Footer>
    </Sidebar>
  );
};

type FilterSectionProps = {
  title: string;
  onClearFilter?: () => void;
};
const FilterSection: FC<PropsWithChildren<FilterSectionProps>> = ({
  title,
  children,
  onClearFilter,
}) => (
  <div className="pb-5 mb-5 last:pb-0 last:mb-0 border-b last:border-b-0 border-stone-200 dark:border-neutral-700">
    <label className="font-semibold text-xs uppercase text-stone-800 dark:text-neutral-200">
      {title}
    </label>
    <div className="mt-3 grid grid-cols-2">{children}</div>
    <Button
      variant={EBtnVariant.Dashed}
      shape={EBtnShapeVariant.PILL}
      size={EBtnSize.XSmall}
      className="mt-3 py-1.5 px-2.5 inline-flex items-center text-xs font-medium"
      onClick={onClearFilter}
    >
      <UntitledIcon name="x-close" className="w-2 h-2" />
      Clear
    </Button>
  </div>
);
export default MultiFilterSidebar;
