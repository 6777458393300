import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { actions as usersActions } from '@/api/users';

import { usePrevious } from '@/utils/customHooks';

import {
  ConnectionControl,
  ConnectionContolAlert,
  ConnectionControlBenefitList,
} from '@/ui/components';

import XeroLogo from '@/assets/images/xero.svg';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { getUserRoles } from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';

import { ConfigureAccountingIntegrationControl } from '../configureAccountingIntegrationControl/ConfigureAccountingIntegrationControl';
import Modal from '@payaca/components/plModal/Modal';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Button from '@payaca/components/plButton/Button';

const XERO_CLIENT_ID = import.meta.env.VITE_XERO_CLIENT_ID;

const XeroConnectionControl: FunctionComponent = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isImportingXeroItems, setIsImportingXeroItems] = useState(false);
  const [importItemsMessage, setImportItemsMessage] = useState<string>();
  const [isImportingXeroContacts, setIsImportingXeroContacts] = useState(false);
  const [importContactsMessage, setImportContactsMessage] = useState<string>();
  const [showSetupModal, setShowSetupModal] = useState(false);

  const xeroConnection = useSelector((state: any) => {
    return state.users.myProfile.accounts[0].integrations?.xero;
  });
  const hasXeroConnection = useMemo(() => !!xeroConnection, [xeroConnection]);
  const hasXeroConnectionExpired = useMemo(
    () => xeroConnection?.hasRefreshTokenExpired,
    [xeroConnection]
  );
  const previousHasXeroConnection = usePrevious(hasXeroConnection);
  const xeroTenantName = useMemo(
    () => xeroConnection?.xeroTenantName,
    [xeroConnection]
  );
  const defaultRevenueAccountCode = useMemo(
    () => xeroConnection?.xeroDefaultRevenueAccountCode,
    [xeroConnection]
  );
  const isConnectedToXero = useMemo(
    () => hasXeroConnection && !hasXeroConnectionExpired,
    [hasXeroConnection, hasXeroConnectionExpired]
  );
  const userRoles = useSelector(getUserRoles);
  const isAdmin = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      AccountsPermissions.UPDATE_CONNECTION,
    ]);
  }, [userRoles]);
  const xeroAccounts = useSelector((state: any) => {
    return state.users.xeroAccounts;
  });
  const isCisSubcontractor = useSelector(
    (state: any) => state.users.myProfile.accounts[0]?.isCisSubcontractor
  );
  const cisDeductionRate = useSelector(
    (state: any) => state.users.myProfile.accounts[0]?.cisDeductionRate
  );

  useEffect(() => {
    if (previousHasXeroConnection === undefined) {
      return;
    }
    if (isConnectedToXero && !previousHasXeroConnection) {
      setShowSetupModal(true);
    } else if (isConnectedToXero) {
      // connected to xero already, check that xeroDefaultRevenueAccountCode exists
      dispatch(
        usersActions.getXeroAccounts(() => {
          if (!defaultRevenueAccountCode || isNaN(defaultRevenueAccountCode)) {
            setShowSetupModal(true);
          }
        })
      );
    }
  }, [
    defaultRevenueAccountCode,
    dispatch,
    isConnectedToXero,
    previousHasXeroConnection,
  ]);

  const importXeroItems = useCallback(() => {
    setIsImportingXeroItems(true);
    dispatch(
      usersActions.importXeroItems((err: Error, data: any) => {
        if (!err) {
          setImportItemsMessage(
            `Xero items imported; ${data.newXeroItems} new items added, ${data.updatedXeroItems} items updated`
          );
          setIsImportingXeroItems(false);
        } else {
          setImportItemsMessage('An error occurred importing Xero items');
          setIsImportingXeroItems(false);
        }
      })
    );
  }, [dispatch]);

  const importXeroContacts = useCallback(() => {
    setIsImportingXeroContacts(true);
    dispatch(
      usersActions.importXeroContacts((err: Error, data: any) => {
        if (!err) {
          setImportContactsMessage(
            `Xero customers imported; ${data.newXeroCustomers} new customers added, ${data.matchedXeroCustomers} customers matched`
          );
          setIsImportingXeroContacts(false);
        } else {
          setImportContactsMessage('An error occurred importing Xero contacts');
          setIsImportingXeroContacts(false);
        }
      })
    );
  }, [dispatch]);

  const updateXeroSettings = useCallback(
    (newDefaultRevenueAccountCode: string) => {
      dispatch(
        usersActions.updateXeroSettings(
          {
            xeroDefaultRevenueAccountCode: newDefaultRevenueAccountCode,
          },
          () => {
            dispatch(usersActions.getProfile());
          }
        )
      );
    },
    [dispatch]
  );

  const renderImportButtons = useCallback(() => {
    return (
      <div>
        <div className="my-4">
          <div>
            <Button
              onClick={importXeroItems}
              isProcessing={isImportingXeroItems}
              colour={EBtnColour.White}
              variant={EBtnVariant.White}
              size={EBtnSize.Small}
              className="mb-2"
            >
              Import Xero items
            </Button>

            <div>
              This will transfer all item details from Xero to Payaca where they
              don‘t already exist.
            </div>
          </div>
          {importItemsMessage && <div>{importItemsMessage}</div>}
        </div>
        <div className="my-4">
          <div>
            <Button
              onClick={importXeroContacts}
              isProcessing={isImportingXeroContacts}
              colour={EBtnColour.White}
              variant={EBtnVariant.White}
              size={EBtnSize.Small}
              className="mb-2"
            >
              Import Xero customers
            </Button>
            <div>
              This will transfer all customers details from Xero to Payaca where
              they don‘t already exist
            </div>
          </div>
          {importContactsMessage && <div>{importContactsMessage}</div>}
        </div>
      </div>
    );
  }, [
    importContactsMessage,
    importItemsMessage,
    importXeroContacts,
    importXeroItems,
    isImportingXeroContacts,
    isImportingXeroItems,
  ]);

  return (
    <ConnectionControl
      disableConnection={false}
      hasConnectionExpired={hasXeroConnectionExpired}
      connectionName="Xero"
      isConnected={isConnectedToXero}
      allowConnectDisconnect={isAdmin}
      logo={XeroLogo}
      onAddConnection={() =>
        (window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${XERO_CLIENT_ID}&redirect_uri=${window.location.origin}/connections/xero&scope=openid profile email accounting.contacts accounting.settings accounting.transactions offline_access&state=123`)
      }
      onRemoveConnection={(onConnectionRemoved: () => void) => {
        dispatch(usersActions.removeXeroConnection(onConnectionRemoved));
      }}
      disconnectionWarningMessage="Your invoices, customers and items will no longer sync with Xero."
    >
      <>
        {/* Benefits */}
        {!hasXeroConnection && (
          <div>
            <div>
              Get all the benefits of Payaca and Xero with our seamless
              integration.
            </div>

            <ConnectionControlBenefitList
              benefits={[
                'Send an invoice in Payaca and all details will automatically be transferred to Xero and marked as sent.',
                'Import all of your customer and item details from Xero to Payaca so they can be added to a quote or invoice with one click.',
              ]}
            />
          </div>
        )}

        {/* Xero tenant name*/}
        {xeroTenantName && (
          <div className="connected-name">
            <p>
              {!hasXeroConnectionExpired ? (
                <>
                  You are connected to{' '}
                  <span style={{ fontWeight: 'bold' }}>{xeroTenantName}</span>.
                </>
              ) : (
                <>
                  Your connection with{' '}
                  <span style={{ fontWeight: 'bold' }}>{xeroTenantName}</span>{' '}
                  has expired, please click the button below to reconnect.
                </>
              )}
            </p>
          </div>
        )}

        {isAdmin && isConnectedToXero && (
          <>
            <Button
              className="mb-2"
              colour={EBtnColour.White}
              variant={EBtnVariant.White}
              onClick={() => setShowSetupModal(true)}
            >
              Configure Connection
            </Button>
            <hr />
          </>
        )}

        {/* Import customers/items */}
        {isAdmin && isConnectedToXero && renderImportButtons()}

        {/* CIS alert */}
        {isConnectedToXero && isCisSubcontractor && (
          <ConnectionContolAlert>
            <div style={{ fontSize: '0.9rem' }}>
              <p>
                As you have enabled yourself as a CIS subcontractor in Payaca,
                you must have a corresponding account in Xero for us to send
                your CIS labour income to. Please{' '}
                <strong>
                  <a
                    href="https://central.xero.com/s/article/Enable-CIS-in-your-organisation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    follow these instructions
                  </a>
                </strong>{' '}
                to enable CIS for your organisation in Xero if you haven&#39;t
                already.
              </p>
              <p>
                Make sure your CIS subcontractor deduction rates in Xero and
                Payaca match. The subcontractor deduction rate you have set in
                Payaca is <strong>{cisDeductionRate}%</strong>.
              </p>
            </div>
          </ConnectionContolAlert>
        )}

        {/* Setup modal */}
        {showSetupModal && (
          <Modal
            isOpen={showSetupModal}
            onClose={() => setShowSetupModal(false)}
            title="Configure Xero setup"
          >
            <ConfigureAccountingIntegrationControl
              integrationType="xero"
              onSuccess={() => setShowSetupModal(false)}
            />
          </Modal>
        )}
      </>
    </ConnectionControl>
  );
};

export default XeroConnectionControl;
