import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash.get';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import BasicField from '@payaca/components/basicField/BasicField';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import {
  getEmailFieldValidator,
  getIsRequiredFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { User } from '@payaca/types/userTypes';

import { actions as usersActions } from '@/api/users';
import { getFormServiceError } from '@/helpers/formHelper';

import './AddUserForm.css';
import { DefaultSystemRoles } from '@payaca/permissions/default-role.config';
import { useSelector } from '@/api/state';

type Props = {
  onAddUserSuccess?: () => void;
};
const AddUserForm: FC<Props> = ({ onAddUserSuccess }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const accountUsers = useSelector((state: any) => state.users.accountUsers);
  const profile = useSelector((state: any) => state.users.myProfile);

  useEffect(() => {
    dispatch(usersActions.getAccountUsers());
  }, []);

  const existingUserValidator = useCallback(
    (fieldName: string, formState: { [key: string]: any }) => {
      const value = get(formState, fieldName);

      const existingUser = accountUsers.find(
        (accountUser: User) => accountUser.email === value
      );

      let errorMessage: string | null = null;

      if (existingUser) {
        if (existingUser.deactivatedAt) {
          if (existingUser.inviteToken) {
            errorMessage = 'This user has already been invited.';
          } else {
            errorMessage = 'This user has previously been removed.';
          }
        } else {
          errorMessage = 'This user is already active.';
        }
      }

      if (errorMessage) {
        return {
          isValid: false,
          errors: [errorMessage],
        };
      }

      return {
        isValid: true,
      };
    },
    [accountUsers]
  );

  const getUserHasBeenRemoved = useCallback(
    (emailAddress: string) => {
      const existingUser = accountUsers.find(
        (accountUser: User) => accountUser.email === emailAddress
      );

      if (existingUser) {
        if (existingUser.deactivatedAt) {
          if (!existingUser.inviteToken) {
            return true;
          }
        }
      }

      return false;
    },
    [accountUsers]
  );

  const fieldValidators = useMemo(() => {
    const isRequiredFieldValidator = getIsRequiredFieldValidator();
    const emailFieldValidator = getEmailFieldValidator({
      customErrorMessage: 'This field must contain a valid email address',
    });

    return {
      firstName: [isRequiredFieldValidator],
      lastName: [isRequiredFieldValidator],
      email: [
        isRequiredFieldValidator,
        emailFieldValidator,
        existingUserValidator,
      ],
      systemRole: [isRequiredFieldValidator],
    };
  }, [existingUserValidator]);

  const onReactivateUserSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      setIsProcessing(true);
      setErrorMessage(null);

      const existingUser = accountUsers.find(
        (accountUser: User) => accountUser.email === formState.email
      );
      dispatch(
        usersActions.reactivateUser(existingUser.id, (error: any) => {
          setIsProcessing(false);
          if (!error) {
            onAddUserSuccess && onAddUserSuccess();
          } else {
            setErrorMessage('Something went wrong reinstating user');
          }
          dispatch(usersActions.getAccountUsers());
        })
      );
    },
    [onAddUserSuccess, dispatch, accountUsers]
  );
  const onInviteUserSubmit = useCallback(
    (formState: any) => {
      setIsProcessing(true);
      setErrorMessage(null);

      const payload = {
        email: formState.email,
        firstName: formState.firstName,
        lastName: formState.lastName,
        systemRole: formState.systemRole,
      };
      dispatch(
        usersActions.inviteUserToAccount(payload, (error: any) => {
          setIsProcessing(false);
          if (!error) {
            onAddUserSuccess && onAddUserSuccess();
          } else {
            setErrorMessage(getFormServiceError('addUser', error));
          }
          dispatch(usersActions.getAccountUsers());
        })
      );
    },
    [onAddUserSuccess, dispatch]
  );

  const initialState = useMemo(
    () => ({
      systemRole: DefaultSystemRoles.FIELD_AGENT,
    }),
    [DefaultSystemRoles]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      const userHasBeenRemoved = getUserHasBeenRemoved(formState.email);

      return (
        <React.Fragment>
          <ValidatedFieldWrapper
            validationResult={validationState.firstName}
            isTouched={touchedState.firstName || false}
          >
            <BasicField
              name="firstName"
              value={formState.firstName || ''}
              isRequired={true}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              label="First name"
              styleVariant={InputStyleVariant.OUTSIZE}
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={validationState.lastName}
            isTouched={touchedState.lastName || false}
          >
            <BasicField
              name="lastName"
              value={formState.lastName || ''}
              isRequired={true}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              label="Last name"
              styleVariant={InputStyleVariant.OUTSIZE}
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={
              userHasBeenRemoved ? undefined : validationState.email
            }
            isTouched={touchedState.email || false}
          >
            <BasicField
              name="email"
              value={formState.email || ''}
              isRequired={true}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              label="Email address"
              styleVariant={InputStyleVariant.OUTSIZE}
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={validationState.systemRole}
            isTouched={touchedState.systemRole || false}
          >
            <DropdownField
              name={'systemRole'}
              label={'System Role'}
              value={formState.systemRole}
              styleVariant={InputStyleVariant.OUTSIZE}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              isRequired={true}
              options={[
                ...(profile.systemRole === DefaultSystemRoles.SUPER_ADMIN
                  ? [
                      {
                        label: DefaultSystemRoles.SUPER_ADMIN,
                        value: DefaultSystemRoles.SUPER_ADMIN,
                      },
                    ]
                  : []),
                {
                  label: DefaultSystemRoles.ADMIN,
                  value: DefaultSystemRoles.ADMIN,
                },
                {
                  label: DefaultSystemRoles.SALES_MANAGER,
                  value: DefaultSystemRoles.SALES_MANAGER,
                },
                {
                  label: DefaultSystemRoles.SALES_AGENT,
                  value: DefaultSystemRoles.SALES_AGENT,
                },
                {
                  label: DefaultSystemRoles.FIELD_AGENT,
                  value: DefaultSystemRoles.FIELD_AGENT,
                },
              ]}
            />
          </ValidatedFieldWrapper>
          <div className="actions-container flex-container flex-center">
            {errorMessage && <ErrorMessage message={errorMessage} />}
            {!errorMessage && userHasBeenRemoved && (
              <ErrorMessage
                message={`A user with the email address ${formState.email} used to exist but has been removed.`}
              />
            )}
            {userHasBeenRemoved ? (
              <Button
                onClick={() =>
                  !isProcessing && onReactivateUserSubmit(formState)
                }
                isProcessing={isProcessing}
                styleVariant={ButtonStyleVariant.OUTSIZE}
              >
                <span style={{ whiteSpace: 'nowrap' }}>Reinstate user</span>
              </Button>
            ) : (
              <Button
                onClick={() => !isProcessing && onInviteUserSubmit(formState)}
                isDisabled={!isValid}
                isProcessing={isProcessing}
                styleVariant={ButtonStyleVariant.OUTSIZE}
              >
                <span style={{ whiteSpace: 'nowrap' }}>Send invite</span>
              </Button>
            )}
          </div>
        </React.Fragment>
      );
    },
    [
      onInviteUserSubmit,
      onReactivateUserSubmit,
      isProcessing,
      errorMessage,
      getUserHasBeenRemoved,
    ]
  );

  return (
    <div className="add-user-form">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
      />
    </div>
  );
};

export default AddUserForm;
