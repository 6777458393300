import React, {
  FC,
  isValidElement,
  PropsWithChildren,
  ReactElement,
} from 'react';
import clsx from 'clsx';
import { Tab } from '@headlessui/react';

export interface IPanelProps {
  label: string;
  render: (selected: boolean) => ReactElement;
}

const Panel: FC<IPanelProps> = (props) => <></>;

export interface IProps {
  className?: string;
  selectedIndex?: number;
  defaultIndex?: number;
  onChange?: (index: number) => void;
  fullWidth?: boolean;
  variant?: 'default' | 'segment';
}

const Tabs: FC<PropsWithChildren<IProps>> = (props) => {
  const {
    className,
    fullWidth = false,
    selectedIndex,
    defaultIndex,
    onChange,
    variant = 'default',
    children,
  } = props;

  const tabPanels = React.Children.toArray(children).reduce<IPanelProps[]>(
    (acc, child) => {
      if (
        !isValidElement(child) ||
        typeof child.props.label === 'undefined' ||
        typeof child.props.render === 'undefined'
      ) {
        return acc;
      }

      acc.push(child.props);

      return acc;
    },
    []
  );

  return (
    <Tab.Group
      defaultIndex={defaultIndex}
      selectedIndex={selectedIndex}
      onChange={onChange}
    >
      <Tab.List
        className={clsx(
          'prose',
          className,
          variant === 'default' &&
            'prose flex gap-x-1 border-b border-gray-200 dark:border-neutral-700',
          variant === 'segment' &&
            'inline-flex rounded-xl bg-gray-100 p-1.5 transition hover:bg-gray-200'
        )}
      >
        {tabPanels.map((column) => {
          return (
            <Tab
              key={column.label}
              className={clsx(
                variant === 'default' &&
                  'ui-selected:border-blue-600 ui-selected:font-semibold inline-flex cursor-pointer items-center justify-center gap-x-2 whitespace-nowrap border-b-2 border-transparent bg-transparent px-2 py-2.5 text-blue-900 focus:outline-none disabled:pointer-events-none disabled:opacity-50',
                variant === 'segment' &&
                  'ui-selected:bg-white ui-selected:shadow-sm inline-flex cursor-pointer items-center justify-center gap-x-2 rounded-lg bg-transparent px-5 py-2.5 font-medium focus:outline-none disabled:pointer-events-none disabled:opacity-50',
                fullWidth && 'flex-[1_1_0px]'
              )}
            >
              {column.label}
            </Tab>
          );
        })}
      </Tab.List>
      <Tab.Panels className="mt-4">
        {tabPanels.map((column) => {
          return (
            <Tab.Panel key={column.label}>
              {({ selected }) => column.render(selected)}
            </Tab.Panel>
          );
        })}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default Object.assign(Tabs, {
  Panel,
});
