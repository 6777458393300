import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import * as lineItemsActions from '@payaca/store/lineItemsV2/lineItemsActions';
import * as materialActions from '@payaca/store/materials/materialsActions';
import * as listedLineItemMaterialsActions from '@payaca/store/listedMaterialLineItems/listedMaterialILinetemsActions';

import Button from '@payaca/components/button/Button';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import LineItemLineItemMaterials from '../lineItemLineItemMaterials/LineItemLineItemMaterials';
import SearchMaterialsDrawer from '../searchMaterialsDrawer/SearchMaterialsDrawer';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';

import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';

import { LineItem } from '@payaca/types/lineItemTypes';
import { GetListedMaterialLineItemsRequestData } from '@payaca/store/listedMaterialLineItems/listedMaterialLineItemsTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import { SortBy } from '@payaca/types/listedMaterialLineItemTypes';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';

import { useSelector } from '../../../api/state';

import './EditItemMaterials.sass';

type Props = {
  lineItemId: LineItem['id'];
  onPersistLineItemMaterialSuccess: () => void;
};
const EditItemMaterials = ({
  lineItemId,
  onPersistLineItemMaterialSuccess,
}: Props) => {
  const dispatch = useDispatch();
  const [showSearchMaterialsDrawer, setShowSearchMaterialsDrawer] =
    useState(false);
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const clearDynamicFeedbackMessage = useRef<() => void>();

  const listedLineItemMaterialsPage = useSelector((state) => {
    return state.listedMaterialLineItems.listedMaterialLineItemsPage;
  });

  const [
    getListedLineItemMaterialsRequestData,
    setGetListedLineItemMaterialsRequestData,
  ] = useState<GetListedMaterialLineItemsRequestData>({
    pageSize: 30,
    pageNumber: 1,
    lineItemId: lineItemId,
    sortDirection: SortDirection.ASCENDING,
    sortBy: SortBy.MATERIAL_NAME,
  });

  useEffect(() => {
    requestGetListedLineItemMaterialsPage();
  }, [getListedLineItemMaterialsRequestData]);

  const requestGetListedLineItemMaterialsPage = useCallback(() => {
    dispatch(
      listedLineItemMaterialsActions.requestGetListedMaterialLineItemsPage(
        getListedLineItemMaterialsRequestData
      )
    );
  }, [getListedLineItemMaterialsRequestData]);

  const disabledMaterialIds = useMemo(() => {
    return listedLineItemMaterialsPage?.items?.length
      ? listedLineItemMaterialsPage.items?.map((x) => x.materialId)
      : [];
  }, [listedLineItemMaterialsPage]);

  const onPersistLineItemMaterial = (error: string | null) => {
    if (error) {
      clearDynamicFeedbackMessage.current = showDynamicFeedbackMessage({
        title: `We couldn't save your changes`,
        isCancellable: true,
        feedbackLevel: FeedbackLevel.ERROR,
      });
    } else {
      onPersistLineItemMaterialSuccess();
      requestGetListedLineItemMaterialsPage();
      clearDynamicFeedbackMessage.current = showDynamicFeedbackMessage({
        title: 'Your changes have been saved',
        lifespanMs: DynamicFeedbackLifespanMs.SHORT,
        feedbackLevel: FeedbackLevel.SUCCESS,
      });
    }
  };

  return (
    <ContentPanel className="edit-item-materials">
      <div className="item-materials-header flex-container flex-center">
        <h3 className="flex-grow">Materials required</h3>
        <PermissionGuard
          renderIfHasPermissions={[
            MaterialsPermissions.PERSIST_LINE_ITEM_MATERIAL,
          ]}
        >
          <div className="add-material-button-container">
            <Button
              onClick={() => setShowSearchMaterialsDrawer(true)}
              isOutlined={true}
            >
              Add Material
            </Button>
          </div>
        </PermissionGuard>
      </div>
      <LineItemLineItemMaterials
        lineItemId={lineItemId}
        onLineItemUpdateSuccess={() => {
          dispatch(lineItemsActions.requestGetLineItem(lineItemId));
        }}
        requestGetListedLineItemMaterialsPage={
          requestGetListedLineItemMaterialsPage
        }
        setGetListedLineItemMaterialsRequestData={
          setGetListedLineItemMaterialsRequestData
        }
      />
      <SearchMaterialsDrawer
        isOpen={showSearchMaterialsDrawer}
        onClose={() => setShowSearchMaterialsDrawer(false)}
        onAddMaterials={(materialIds) => {
          // add materials to line item
          const materials = materialIds.map((materialId) => ({
            materialId,
            materialQuantity: 1,
            lineItemId,
          }));
          dispatch(
            materialActions.requestPersistLineItemMaterials(
              materials,
              onPersistLineItemMaterial
            )
          );
        }}
        disabledMaterialIds={disabledMaterialIds}
      />
    </ContentPanel>
  );
};

export default EditItemMaterials;
