import React, { FunctionComponent, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import './ManageableMaterialPurchaseRecordList.sass';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import CreateMaterialPurchaseRecordControl from '../createMaterialPurchaseRecordControl/CreateMaterialPurchaseRecordControl';
import {
  useMaterialPurchaseRecordsForMaterialsListMaterial,
  useMaterialsListMaterial,
} from '@payaca/store/hooks/appState';
import MaterialPurchaseRecordControl from '../materialPurchaseRecordControl/MaterialPurchaseRecordControl';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';

type Props = {
  materialsListMaterialId: number;
  onCreateMaterialPurchaseRecordSuccess?: () => void;
  onDeleteMaterialPurchaseRecordSuccess?: () => void;
};

const ManageableMaterialPurchaseRecordList: FunctionComponent<Props> = ({
  materialsListMaterialId,
  onCreateMaterialPurchaseRecordSuccess,
  onDeleteMaterialPurchaseRecordSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const materialsListMaterial = useMaterialsListMaterial(
    materialsListMaterialId
  );

  const materialPurchaseRecords =
    useMaterialPurchaseRecordsForMaterialsListMaterial(materialsListMaterialId);

  const sortedMaterialPurchaseRecords = useMemo(() => {
    return materialPurchaseRecords.sort((a, b) =>
      a.createdAt > b.createdAt ? 1 : -1
    );
  }, [materialPurchaseRecords]);

  if (!materialsListMaterial) return null;

  return (
    <div className="manageable-material-purchase-record-list">
      {!(
        materialsListMaterial.isFullyPurchased ||
        materialsListMaterial.isFullyPurchaseIntended
      ) && (
        <PermissionGuard
          renderIfHasPermissions={[
            MaterialsListPermissions.PERSIST_MATERIAL_PURCHASE_RECORD,
          ]}
        >
          <CreateMaterialPurchaseRecordControl
            materialsListMaterialId={materialsListMaterialId}
            onCreateMaterialPurchaseRecordSuccess={() => {
              dispatch(
                materialsListActions.requestGetMaterialsListMaterialWithRelatedEntities(
                  materialsListMaterialId
                )
              );
              onCreateMaterialPurchaseRecordSuccess &&
                onCreateMaterialPurchaseRecordSuccess();
            }}
          />
        </PermissionGuard>
      )}
      {!!sortedMaterialPurchaseRecords?.length && (
        <ul className="material-purchase-records">
          {sortedMaterialPurchaseRecords.map(
            (materialPurchaseRecord, index) => {
              return (
                <li
                  key={`material-purchase-record-control-${materialPurchaseRecord.id}-${index}`}
                >
                  <MaterialPurchaseRecordControl
                    materialPurchaseRecordId={materialPurchaseRecord.id}
                    onDeleteMaterialPurchaseRecordSuccess={() => {
                      dispatch(
                        materialsListActions.requestGetMaterialsListMaterial(
                          materialsListMaterialId
                        )
                      );
                      onDeleteMaterialPurchaseRecordSuccess &&
                        onDeleteMaterialPurchaseRecordSuccess();
                    }}
                  />
                </li>
              );
            }
          )}
        </ul>
      )}
    </div>
  );
};

export default ManageableMaterialPurchaseRecordList;
