import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import * as subscriptionActions from '@payaca/store/subscription/subscriptionActions';
import * as accountActions from '@payaca/store/account/accountActions';

import CreateSubscriptionPageContent from '@/ui/components/manageSubscription/createSubscriptionPageContent/CreateSubscriptionPageContent';
import ManageSubscriptionPageContent from '@/ui/components/manageSubscription/manageSubscriptionPageContent/ManageSubscriptionPageContent';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import PayacaPhoneLink from '@payaca/components/payacaPhoneLink/PayacaPhoneLink';

import { useSelector } from '@/api/state';

import {
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';
import { Nullish } from '@payaca/utilities/types';

import { trackPurchaseWithAwin } from '@/utils/awin';

import { currencyCodeForRegion } from '@payaca/helpers/currencyHelper';
import { getProductPriceCostExcludingVat } from '@payaca/helpers/subscriptionHelper';

import { useAccount } from '@/utils/storeHooks';

import './ManageSubscriptionPage.sass';
import { useLocation } from 'react-router';
import QueryString from 'qs';

const ManageSubscriptionPage: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch();

  const location = useLocation();

  const { view } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const accountAccessInformation = useSelector(
    (state) => state.account.accountAccessInformation
  );

  const hasActiveSubscription = useMemo(
    () => !!accountAccessInformation?.hasActiveSubscription,
    [accountAccessInformation]
  );

  const [showSelectSubscriptionView, setShowSelectSubscriptionView] =
    useState<boolean>(!hasActiveSubscription || view === 'select-subscription');

  const canBookDemo = useMemo(() => {
    if (!accountAccessInformation) return false;
    return (
      !accountAccessInformation.hasActiveSubscription &&
      !accountAccessInformation.hasInactiveSubscription
    );
  }, [accountAccessInformation]);

  const requiresUpdateBusinessSettingsPromptOnSubscribe = useMemo(() => {
    return canBookDemo;
  }, [canBookDemo]);

  const account = useAccount();

  const onCreateSubscriptionSuccess = useCallback(
    ({
      subscriptionProduct,
      recurringInterval,
      additionalUserSeats,
    }: {
      subscriptionProduct: Nullish<SubscriptionProduct>;
      recurringInterval: RecurringInterval;
      additionalUserSeats: number;
    }) => {
      dispatch(
        accountActions.setRequiresUpdateBusinessSettingsPrompt(
          requiresUpdateBusinessSettingsPromptOnSubscribe
        )
      );
      if (subscriptionProduct) {
        const price =
          recurringInterval === RecurringInterval.MONTH
            ? subscriptionProduct.monthlyPrice
            : subscriptionProduct.annualPrice;
        if (!price) {
          return;
        }
        trackPurchaseWithAwin({
          currency: (currencyCodeForRegion as any)[account.region],
          amountInMajorCurrencyUnits:
            getProductPriceCostExcludingVat(price, additionalUserSeats) / 100,
          orderReference: `${account.id}:${
            subscriptionProduct.stripeId
          }:${Date.now()}`,
        });
      }
    },
    [dispatch, requiresUpdateBusinessSettingsPromptOnSubscribe, account]
  );

  useEffect(() => {
    dispatch(subscriptionActions.requestGetProducts());
    dispatch(subscriptionActions.requestGetAccountSubscription());
  }, []);

  return (
    <AuthenticatedPageWrapper
      className="manage-subscription-page"
      previousPageNavigationConfig={
        hasActiveSubscription && showSelectSubscriptionView
          ? { onClick: () => setShowSelectSubscriptionView(false) }
          : undefined
      }
      title={
        <div className="flex-grow flex-container flex-center title-bar-inner">
          <h1>Manage subscription</h1>
          <div className="phone-number-container flex-container flex-center">
            <span className="need-help">Need help?</span>
            <PayacaPhoneLink showPhoneIcon={true} />
          </div>
        </div>
      }
    >
      <div className="page-content-inner">
        {showSelectSubscriptionView ? (
          <CreateSubscriptionPageContent
            onCreateSubscriptionSuccess={onCreateSubscriptionSuccess}
          />
        ) : (
          <ManageSubscriptionPageContent
            showSelectSubscriptionView={() =>
              setShowSelectSubscriptionView(true)
            }
          />
        )}
      </div>
    </AuthenticatedPageWrapper>
  );
};

export default ManageSubscriptionPage;
