// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import {
  GetPaginatedActivityFeedForProjectInput,
  GetTasksInput,
  GetScheduledEventsForProjectInput,
  PaginationInput,
} from '@/gql/graphql';

const projectKeys = {
  all: ['project'] as const,
  project: (projectId: number) => [...projectKeys.all, projectId] as const,
  activityFeed: (
    projectId: number,
    input?: GetPaginatedActivityFeedForProjectInput
  ) => {
    if (!input) {
      return [...projectKeys.project(projectId), 'activityFeed'] as const;
    }

    return [...projectKeys.project(projectId), 'activityFeed', input] as const;
  },
  overview: (projectId: number) =>
    [...projectKeys.project(projectId), 'overview'] as const,
  customFields: (projectId: number) =>
    [...projectKeys.project(projectId), 'customFields'] as const,
  events: (projectId: number, input?: GetScheduledEventsForProjectInput) => {
    if (!input) {
      return [...projectKeys.project(projectId), 'events'] as const;
    }

    return [...projectKeys.project(projectId), 'events', input] as const;
  },
  tasks: (
    projectId: number,
    input?: GetTasksInput,
    pagination?: PaginationInput
  ) => {
    if (!input) {
      return [...projectKeys.project(projectId), 'tasks'] as const;
    }

    if (!pagination) {
      return [...projectKeys.project(projectId), 'tasks', input] as const;
    }

    return [
      ...projectKeys.project(projectId),
      'tasks',
      input,
      pagination,
    ] as const;
  },
};

export default projectKeys;
