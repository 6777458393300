import React, { FunctionComponent, useMemo, useState } from 'react';
import {
  faCheck,
  faCheckCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { currencyPrice } from '@/helpers/financeHelper';

import Button from '@payaca/components/button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '@payaca/components/button/enums';
import CollapsiblePanel, { CollapsiblePanelStyleVariant } from '@payaca/components/collapsiblePanel/CollapsiblePanel';
import StatusBadge, { StatusBadgeState } from '@payaca/components/statusBadge/StatusBadge';

import {
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';

import { useSelector } from '@/api/state';

import './SubscriptionProductSelectionControl.sass';

type Props = {
  subscriptionProduct: SubscriptionProduct;
  recurringInterval: RecurringInterval;
  isSelected: boolean;
  features?: { label: string; icon?: IconDefinition }[];
  renderCta?: (isSelected: boolean, onSelect: () => void) => JSX.Element;
  onSelect: () => void;
  productDetail?: React.ReactNode;
  isLimitedAvailability?: boolean;
};

const SubscriptionProductSelectionControl: FunctionComponent<Props> = ({
  subscriptionProduct,
  recurringInterval,
  isSelected,
  features,
  renderCta,
  onSelect,
  productDetail,
  isLimitedAvailability = false,
}: Props): JSX.Element => {
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const productPrice = useMemo(() => {
    if (recurringInterval === 'month') {
      return subscriptionProduct.monthlyPrice;
    } else {
      return subscriptionProduct.annualPrice;
    }
  }, [subscriptionProduct, recurringInterval]);

  const canBuyAdditionalUserSeats = useMemo(() => {
    return !!productPrice?.canBuyAdditionalUserSeats;
  }, [productPrice]);

  const monthlyBasicCost = useMemo(() => {
    if (recurringInterval === 'month') {
      return productPrice?.basicCost;
    } else {
      return productPrice ? productPrice.basicCost / 12 : undefined;
    }
  }, [productPrice, recurringInterval]);

  const monthlyAdditionalUserSeatCost = useMemo(() => {
    if (recurringInterval === 'month') {
      return productPrice?.additionalUserSeatCost;
    } else {
      return productPrice?.additionalUserSeatCost
        ? productPrice.additionalUserSeatCost / 12
        : undefined;
    }
  }, [productPrice, recurringInterval]);

  const [showWhatsIncluded, setShowWhatsIncluded] = useState(false);

  return (
    <div className="subscription-product-selection-control-outer">
      <div
        className={`subscription-product-selection-control ${
          isSelected ? 'selected' : ''
        }`}
      >
        <div className="subscription-product-selection-control-inner">
          <div className="product-name-container">
            <h2 className="product-name">{subscriptionProduct.name}</h2>
            {(isLimitedAvailability && (
              <StatusBadge state={StatusBadgeState.GREEN_SUCCESS}>
                Limited availability
              </StatusBadge>
            )) ||
              (subscriptionProduct.discountPercentage && (
                <StatusBadge state={StatusBadgeState.GREEN_SUCCESS}>
                  Discount available
                </StatusBadge>
              ))}
          </div>
          <hr />
          {productPrice && (
            <>
              {!!subscriptionProduct.numberOfUserSeats && (
                <div className="cost-container base-cost-container">
                  <div className="cost-label-container">
                    <span>
                      Cost with{' '}
                      {subscriptionProduct.numberOfUserSeats >= 999
                        ? 'unlimited'
                        : subscriptionProduct.numberOfUserSeats}{' '}
                      users included
                    </span>
                    <small>Per {recurringInterval}</small>
                  </div>
                  <div className="cost-value-container">
                    <div className="cost base-cost">
                      {currencyPrice(
                        productPrice.basicCost,
                        account.region,
                        false
                      )}
                    </div>
                    <small>+ VAT</small>
                  </div>
                </div>
              )}
              {!subscriptionProduct.numberOfUserSeats && (
                <div className="cost-container base-cost-container">
                  <div className="cost-label-container">
                    <span>Cost with 1 user included</span>
                    <small>Per {recurringInterval}</small>
                  </div>
                  <div className="cost-value-container">
                    <div className="cost base-cost">
                      {currencyPrice(
                        productPrice.basicCost +
                          (productPrice.additionalUserSeatCost || 0),
                        account.region,
                        false
                      )}
                    </div>
                    <small>+ VAT</small>
                  </div>
                </div>
              )}
              {productPrice.additionalUserSeatCost && (
                <div className="cost-container user-cost-container">
                  <div className="cost-label-container">
                    <span>Per additional user</span>
                    <small>Per {recurringInterval}</small>
                  </div>
                  <div className="cost-value-container">
                    <div className="cost user-cost">
                      {currencyPrice(
                        productPrice?.additionalUserSeatCost,
                        account.region,
                        false
                      )}
                    </div>
                    <small>+ VAT</small>
                  </div>
                </div>
              )}
            </>
          )}
          {/* {canBuyAdditionalUserSeats && (
            <div className="additional-user-seats-container">
              <h3>Additional user seats</h3>
              <p className="additional-user-seat-cost">
                <strong>
                  {currencyPrice(
                    monthlyAdditionalUserSeatCost || 0,
                    account.region
                  )}
                </strong>{' '}
                {taxRatePercentage > 0 && '+ VAT per seat per month'}
              </p>
              {isSelected && enableEmbeddedUserSeatControl && (
                <QuantityControl
                  isDisabled={!isSelected}
                  value={additionalUserSeats}
                  onChange={onChangeAdditionalUserSeats}
                />
              )}
            </div>
          )} */}
          <div className="button-container">
            {renderCta ? (
              renderCta(isSelected, onSelect)
            ) : (
              <Button
                styleVariant={ButtonStyleVariant.OUTSIZE}
                colourVariant={ButtonColourVariant.PRIMARY}
                isDisabled={isSelected}
                onClick={onSelect}
              >
                {isSelected ? 'Selected' : 'Select'}
              </Button>
            )}
          </div>

          {(!!features?.length || productDetail) && (
            <CollapsiblePanel
              styleVariant={CollapsiblePanelStyleVariant.UNSTYLED}
              title={
                <Button styleVariant={ButtonStyleVariant.ANCHOR}>
                  What&#39;s included
                </Button>
              }
              showControlIcons={false}
              isOpen={showWhatsIncluded}
              onOpen={() => setShowWhatsIncluded(true)}
              onClose={() => setShowWhatsIncluded(false)}
            >
              <div className="product-detail-features-container">
                {productDetail && (
                  <div className="product-detail-container">
                    {productDetail}
                  </div>
                )}
                {features && !!features.length && (
                  <div className="features-container">
                    <ul>
                      {features &&
                        features.map((feature, i) => {
                          return (
                            <li key={`feature-${i}`}>
                              <FontAwesomeIcon icon={feature.icon || faCheck} />
                              <span>{feature.label}</span>
                            </li>
                          );
                        })}
                      {!features && subscriptionProduct.numberOfUserSeats && (
                        <li>
                          <FontAwesomeIcon icon={faCheckCircle} />
                          <span>
                            Includes{' '}
                            <strong>
                              {subscriptionProduct.numberOfUserSeats}
                            </strong>{' '}
                            {subscriptionProduct.numberOfUserSeats === 1
                              ? 'user'
                              : 'users'}
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </CollapsiblePanel>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionProductSelectionControl;
