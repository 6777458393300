import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import * as authActions from '@payaca/store/auth/authActions';

import { ConnectionControl } from '@/ui/components';

import ZapierLogo from './zapier.png';
import { useSelector } from '@/api/state';
import { Link } from 'react-router-dom';

const ZapierConnectionControl: FunctionComponent = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const hasZapierFeature = useSelector((state) => {
    const accountAccessInformation = state.account.accountAccessInformation;
    return !!accountAccessInformation?.features?.hasZapier;
  });

  const zapierConnection = useSelector((state: any) => {
    if (!state.users.myProfile.oAuthConnections) {
      return null;
    }
    return state.users.myProfile.oAuthConnections.find(
      (p: any) => p.clientName === 'Zapier' || p.clientName === 'zapier test'
    );
  });

  return (
    <ConnectionControl
      disableConnection={!hasZapierFeature}
      disableConnectionMessage={
        <p className="text-red-600">
          {zapierConnection ? (
            <>
              Any zaps you have setup will not run, as you do not have the
              correct subscription.{' '}
              <Link to="/upgradeAccount">Upgrade here to resume your zaps</Link>
            </>
          ) : (
            <>
              Available on the Team plan and above.{' '}
              <Link to="/upgradeAccount">
                Upgrade here to start using Zapier
              </Link>
            </>
          )}
        </p>
      }
      connectionName="Zapier"
      isConnected={zapierConnection}
      onRemoveConnection={(onConnectionRemoved: () => void) =>
        dispatch(
          authActions.removeOAuthConnection(
            zapierConnection,
            onConnectionRemoved
          )
        )
      }
      allowConnectDisconnect={true}
      disconnectionError="Sorry, there was an error removing your connection with Zapier. Please try again or head over to your Zapier account and remove your connection with Payaca."
      logo={ZapierLogo}
      onAddConnection={() =>
        window.open(
          import.meta.env.VITE_ENV === 'staging' ||
            import.meta.env.VITE_NODE_ENV === 'development'
            ? 'https://zapier.com/developer/public-invite/125577/2765a5b758bd096b961fbc6995e39164/'
            : 'https://zapier.com/apps/payaca/integrations'
        )
      }
    >
      <div>
        Follow{' '}
        <a
          className="inline-link"
          onClick={() =>
            window.open(
              'https://help.payaca.com/en/articles/4602656-how-to-connect-with-zapier'
            )
          }
        >
          this guide
        </a>{' '}
        to help setup connecting Payaca with your accounting software, tested
        integrations include{' '}
        <a
          className="inline-link"
          onClick={() =>
            window.open(
              'http://help.payaca.com/en/articles/4602767-how-to-connect-to-freshbooks-using-zapier'
            )
          }
        >
          Freshbooks
        </a>
        {', '}
        <a
          className="inline-link"
          onClick={() =>
            window.open(
              'http://help.payaca.com/en/articles/4890064-how-to-connect-to-servicem8-using-zapier'
            )
          }
        >
          ServiceM8
        </a>{' '}
        and <span>Kashflow</span>.
        {zapierConnection && zapierConnection.hasConnectionExpired && (
          <div>
            Your connection with Zapier has expired. Please head over to &#34;My
            Apps&#34; in Zapier and reconnect your account with Payaca.
          </div>
        )}
      </div>
    </ConnectionControl>
  );
};

export default ZapierConnectionControl;
