import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TooltipUI from '@material-ui/core/Tooltip';
import { Download, Upload } from 'react-iconly';

import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import CreateEditMaterialModal from '@/ui/components/createEditMaterialModal/CreateEditMaterialModal';
import ListedMaterialsSearchFilterControl from '@/ui/components/listedMaterialsSearchFilterControl/ListedMaterialsSearchFilterControl';
import BulkUploadModal from '@/ui/components/bulkUploadDownloadModals/BulkUploadModal';
import BulkUpdateModal from '@/ui/components/bulkUploadDownloadModals/BulkUpdateModal';

import { actions as appActions } from '@/api/app';
import * as materialActions from '@payaca/store/materials/materialsActions';

import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import ListedMaterials from '@/ui/components/listedMaterials/ListedMaterials';

import * as listedMaterialsActions from '@payaca/store/listedMaterials/listedMaterialsActions';

import { ManagedBy, SortBy } from '@payaca/types/listedMaterialTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

import { singularPlural } from '@payaca/utilities/stringUtilities';

import {
  getMaterialFilterCategoryIdsLocalStorageKey,
  getMaterialFilterManagedByLocalStorageKey,
  getMaterialFilterSupplierIdsLocalStorageKey,
  getMaterialsFilterPageSizeLocalStorageKey,
} from '@/helpers/localStorageKeyHelper';

import './ListedMaterialsPage.sass';
import { useSelector } from '@/api/state';
import { useHashFragment } from '@/utils/customHooks';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@/i18n';
import { getUserRoles } from '@/utils/stateAccessors';

const materialFilterCategoryIdsLocalStorageKey =
  getMaterialFilterCategoryIdsLocalStorageKey();
const materialFilterSupplierIdsLocalStorageKey =
  getMaterialFilterSupplierIdsLocalStorageKey();
const materialFilterManagedByLocalStorageKey =
  getMaterialFilterManagedByLocalStorageKey();
const materialsFilterPageSizeLocalStorageKey =
  getMaterialsFilterPageSizeLocalStorageKey();

const ListedMaterialsPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const storedCategoryIds = localStorage.getItem(
    materialFilterCategoryIdsLocalStorageKey
  );
  const storedSupplierIds = localStorage.getItem(
    materialFilterSupplierIdsLocalStorageKey
  );
  const storedManagedBy = localStorage.getItem(
    materialFilterManagedByLocalStorageKey
  );
  const storedPageSize = localStorage.getItem(
    materialsFilterPageSizeLocalStorageKey
  );

  const [selectedMaterialIds, setSelectedMaterialIds] = useState<number[]>([]);
  const [showConfirmDeleteMaterialsModal, setShowConfirmDeleteMaterialsModal] =
    useState(false);

  const userRoles = useSelector(getUserRoles);

  const getListedMaterialsRequestData = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchTerm = searchParams.get('term') || '';
    const pageSize = parseInt(
      searchParams.get('count') || storedPageSize || '50'
    );
    const pageNumber = parseInt(searchParams.get('page') || '1');
    const sortBy = (searchParams.get('sort') || SortBy.NAME) as SortBy;
    const sortDirection = (searchParams.get('dir') ||
      SortDirection.ASCENDING) as SortDirection;
    const categoryIds =
      searchParams
        .get('category')
        ?.split(',')
        .filter(Boolean)
        .map((x) => parseInt(x)) ||
      storedCategoryIds
        ?.split(',')
        .filter(Boolean)
        .map((x) => parseInt(x)) ||
      [];
    const supplierIds =
      searchParams
        .get('supplier')
        ?.split(',')
        .filter(Boolean)
        .map((x) => parseInt(x)) ||
      storedSupplierIds
        ?.split(',')
        .filter(Boolean)
        .map((x) => parseInt(x)) ||
      [];
    const managedBy = userHasRequiredPermission(userRoles, [
      MaterialsPermissions.GET_SYSTEM_MANAGED_MATERIALS,
    ])
      ? (searchParams
          .get('source')
          ?.split(',')
          .filter(Boolean) as ManagedBy[]) ||
        (storedManagedBy?.split(',').filter(Boolean) as ManagedBy[]) ||
        ''
      : [];
    return {
      pageSize,
      pageNumber,
      searchTerm,
      sortDirection,
      sortBy,
      categoryIds,
      supplierIds,
      managedBy,
    };
  }, [history.location.search]);

  const isArchivingMaterials = useSelector((state) => {
    return state.materials.isArchivingMaterials;
  });

  const [showCreateMaterialModal, toggleCreateMaterialModal] =
    useHashFragment('#create-material');

  // Uncomment once generic materials import is implemented
  // const [showBulkUpdateModal, toggleBulkUpdateModal] =
  //   useHashFragment('#bulk-update');
  // const [showBulkUploadModal, toggleBulkUploadModal] =
  //   useHashFragment('#bulk-upload');

  useEffect(() => {
    return () => {
      dispatch(listedMaterialsActions.clearListedMaterialsPage());
      dispatch(materialActions.clearArchiveMaterials());
    };
  }, []);

  useEffect(() => {
    requestGetListedMaterialsPage();
  }, [getListedMaterialsRequestData]);

  const requestGetListedMaterialsPage = useCallback(() => {
    dispatch(
      listedMaterialsActions.requestGetListedMaterialsPage(
        getListedMaterialsRequestData
      )
    );
  }, [getListedMaterialsRequestData, dispatch]);

  const materialActionCallback = useCallback(
    (error?: Error) => {
      if (error) {
        dispatch(appActions.showBanner({ type: 'error' }));
      }
      setShowConfirmDeleteMaterialsModal(false);
      setSelectedMaterialIds([]);
      requestGetListedMaterialsPage();
    },
    [requestGetListedMaterialsPage, dispatch]
  );
  const onBulkDeleteMaterials = useCallback(
    (materialIds: number[]) => {
      dispatch(
        materialActions.requestArchiveMaterials(materialIds, () => {
          materialActionCallback();
        })
      );
    },
    [dispatch, materialActionCallback]
  );

  const listedMaterialsTitleBar = useMemo(() => {
    return (
      <div className="listed-materials-title-bar flex-grow">
        <div className="listed-materials-title-bar-inner flex-container flex-center flex-grow">
          <div>
            <h1>Materials</h1>
          </div>
          <PermissionGuard
            renderIfHasPermissions={[MaterialsPermissions.PERSIST_MATERIAL]}
          >
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              onClick={toggleCreateMaterialModal}
            >
              Create Material
            </Button>
          </PermissionGuard>
          <div className="flex-grow"></div>
          {/* Uncomment once generic materials import is implemented */}
          {/*
          <PermissionGuard
            renderIfHasPermissions={[
              MaterialsPermissions.BULK_IMPORT_MATERIALS
            ]}
          >
            <>
              <TooltipUI
                title="Download and update Materials from CSV data"
                arrow
                placement="left"
              >
                <div>
                  <Button
                    onClick={toggleBulkUpdateModal}
                    className="download-button"
                    hasBoxShadow={false}
                  >
                    <Download set="light" />
                  </Button>
                </div>
              </TooltipUI>
              <TooltipUI
                title="Upload Materials from CSV data"
                arrow
                placement="left"
              >
                <div>
                  <Button
                    onClick={toggleBulkUploadModal}
                    className="upload-button"
                    hasBoxShadow={false}
                  >
                    <Upload set="light" />
                  </Button>
                </div>
              </TooltipUI>
            </>
          </PermissionGuard>
          */}
        </div>
      </div>
    );
  }, [history]);

  const onSelectPage = useCallback((pageNumber: number) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', String(pageNumber));
    history.push(`/materials?${searchParams.toString()}`);
  }, []);

  const onSelectPageSize = useCallback((pageSize: number) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', '1');
    searchParams.set('count', String(pageSize));
    localStorage.setItem(
      materialsFilterPageSizeLocalStorageKey,
      String(pageSize)
    );
    history.push(`/materials?${searchParams.toString()}`);
  }, []);

  const navigateToMaterialPage = useCallback(
    (materialId: number) => {
      history.push(`/materials/${materialId}`);
    },
    [history]
  );

  const onClickMaterial = useCallback(
    (materialId: number) => {
      navigateToMaterialPage(materialId);
    },
    [navigateToMaterialPage]
  );

  const clearFilters = () => {
    [
      materialFilterCategoryIdsLocalStorageKey,
      materialFilterSupplierIdsLocalStorageKey,
      materialFilterManagedByLocalStorageKey,
    ].forEach((key) => {
      localStorage.setItem(key, '');
    });
    const searchParams = new URLSearchParams(window.location.search);
    ['page', 'dir', 'sort', 'category', 'supplier', 'source'].map((x) =>
      searchParams.delete(x)
    );
    history.push(`/materials?${searchParams.toString()}`);
  };

  const translate = useTranslation();

  return (
    <>
      <Helmet title={translate('pages.materials.title')} />
      <AuthenticatedPageWrapper
        className="listed-materials-page"
        title={listedMaterialsTitleBar}
      >
        <div className="search-filter-control-container">
          <div className="reset-button-wrapper">
            <Button
              onClick={clearFilters}
              styleVariant={ButtonStyleVariant.ANCHOR}
            >
              Reset filters
            </Button>
          </div>
          <ListedMaterialsSearchFilterControl
            getListedMaterialsRequestData={getListedMaterialsRequestData}
            initSearchTerm={getListedMaterialsRequestData.searchTerm}
            onChange={(value) => {
              const searchParams = new URLSearchParams(window.location.search);
              if (value.searchTerm !== undefined) {
                searchParams.set('term', value.searchTerm);
              }
              if (value.categoryIds) {
                const joined = value.categoryIds.filter(Boolean).join(',');
                localStorage.setItem(
                  materialFilterCategoryIdsLocalStorageKey,
                  joined
                );
                searchParams.set('category', joined);
              }
              if (value.supplierIds) {
                const joined = value.supplierIds.filter(Boolean).join(',');
                localStorage.setItem(
                  materialFilterSupplierIdsLocalStorageKey,
                  joined
                );
                searchParams.set('supplier', joined);
              }
              if (value.managedBy) {
                const joined = Object.values(ManagedBy).every((m) =>
                  value.managedBy.includes(m)
                )
                  ? ''
                  : value.managedBy.filter(Boolean).join(',');
                localStorage.setItem(
                  materialFilterManagedByLocalStorageKey,
                  joined
                );
                searchParams.set('source', joined);
              }
              history.push(`/materials?${searchParams.toString()}`);
            }}
          />
        </div>
        <div className={`listed-materials-container`}>
          <ListedMaterials
            onClickMaterial={onClickMaterial}
            onSelectPage={onSelectPage}
            onSelectPageSize={onSelectPageSize}
            quickActionDefinitions={{
              archive: {
                actionName: 'Delete',
                isActionProcessing: isArchivingMaterials,
                actionBehaviour: (materialId: number) => {
                  setSelectedMaterialIds([materialId]);
                  setShowConfirmDeleteMaterialsModal(true);
                },
              },
            }}
          />
        </div>
        <CreateEditMaterialModal
          isOpen={showCreateMaterialModal}
          onClose={toggleCreateMaterialModal}
          onPersistMaterialSuccess={(materialId: number) => {
            history.push(`/materials/${materialId}`);
          }}
          enableSupplierMaterialInput={true}
        />
        <Modal
          isOpen={showConfirmDeleteMaterialsModal}
          onClose={() => {
            setShowConfirmDeleteMaterialsModal(false);
          }}
          title={`Are you sure you want to delete ${singularPlural(
            selectedMaterialIds.length,
            'Material',
            'Materials'
          )}?`}
          actions={
            <>
              <Button
                styleVariant={ButtonStyleVariant.OUTSIZE}
                onClick={() => {
                  if (isArchivingMaterials) return;
                  onBulkDeleteMaterials(selectedMaterialIds);
                }}
                isProcessing={isArchivingMaterials}
              >
                Delete
              </Button>
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => {
                  setShowConfirmDeleteMaterialsModal(false);
                }}
              >
                Cancel
              </Button>
            </>
          }
        ></Modal>
        {/* Uncomment once generic materials import is implemented */}
        {/*
        <BulkUploadModal
          isOpen={showBulkUploadModal}
          onClose={toggleBulkUploadModal}
          title="Upload Materials"
          dataType="materials"
        ></BulkUploadModal>
        <BulkUpdateModal
          isOpen={showBulkUpdateModal}
          onClose={toggleBulkUpdateModal}
          title="Update Materials"
          dataType="materials"
        ></BulkUpdateModal>
        */}
      </AuthenticatedPageWrapper>
    </>
  );
};

export default ListedMaterialsPage;
