import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Category, Download, Upload } from 'react-iconly';
import TooltipUI from '@material-ui/core/Tooltip';

import { actions as appActions } from '@/api/app';
import * as lineItemActions from '@payaca/store/lineItems/lineItemsActions';
import * as lineItemV2Actions from '@payaca/store/lineItemsV2/lineItemsActions';
import * as itemActions from '@payaca/store/listedItems/listedItemsActions';

import { SortBy } from '@payaca/types/listedItemTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

import ListedItems from '@/ui/components/listedItems/ListedItems';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import Button from '@payaca/components/button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '@payaca/components/button/enums';
import BulkUploadModal from '@/ui/components/bulkUploadDownloadModals/BulkUploadModal';
import BulkUpdateModal from '@/ui/components/bulkUploadDownloadModals/BulkUpdateModal';
import Modal from '@payaca/components/modal/Modal';
import Tooltip from '@payaca/components/tooltip/Tooltip';

import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { LineItemsPermissions } from '@payaca/permissions/lineItems/line-items.permissions';
import ListedItemsSearchFilterControl from '@/ui/components/listedItemsSearchFilterControl/ListedItemsSearchFilterControl';

import './ListedItemsPage.sass';
import { useSelector } from '@/api/state';
import {
  getItemsFilterPageSizeLocalStorageKey,
  getItemsFilterSortDirectionLocalStorageKey,
  getItemsFilterSortByLocalStorageKey,
  getTaxRateIdLocalStorageKey,
} from '@/helpers/localStorageKeyHelper';
import { useHashFragment } from '@/utils/customHooks';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@/i18n';
import { useDefaultTaxRate } from '@payaca/store/hooks/appState';

const taxRateIdLocalStorageKey = getTaxRateIdLocalStorageKey();
const itemsFilterSortByLocalStorageKey = getItemsFilterSortByLocalStorageKey();
const itemsFilterSortDirectionLocalStorageKey =
  getItemsFilterSortDirectionLocalStorageKey();
const itemsFilterPageSizeLocalStorageKey =
  getItemsFilterPageSizeLocalStorageKey();

const ListedItemsPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultTaxRate = useDefaultTaxRate();

  const storedSortBy = localStorage.getItem(itemsFilterSortByLocalStorageKey);
  const storedSortByDirection = localStorage.getItem(
    itemsFilterSortDirectionLocalStorageKey
  );
  const storedPageSize = localStorage.getItem(
    itemsFilterPageSizeLocalStorageKey
  );

  const [isInBulkActionsMode, setIsInBulkActionsMode] = useState(false);
  const [createEditItemId, setCreateEditItemId] = useState<
    number | undefined
  >();
  const [showConfirmDeleteItemsModal, setShowConfirmDeleteItemsModal] =
    useState(false);

  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
  const [hasSubmittedBulkDelete, setHasSubmittedBulkDelete] =
    useState<boolean>(false);

  const getListedItemsRequestData = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchTerm = searchParams.get('term') || '';
    const pageSize = parseInt(
      searchParams.get('count') || storedPageSize || '50'
    );
    const pageNumber = parseInt(searchParams.get('page') || '1');
    const sortBy = (searchParams.get('sort') ||
      storedSortBy ||
      SortBy.NAME) as SortBy;
    const sortDirection = (searchParams.get('dir') ||
      storedSortByDirection ||
      SortDirection.ASCENDING) as SortDirection;
    return {
      pageSize,
      pageNumber,
      searchTerm,
      sortDirection,
      sortBy,
    };
  }, [history.location.search]);

  const isDeletingLineItems = useSelector((state) => {
    return state.lineItems.isDeletingLineItems;
  });

  const [showBulkUploadModal, toggleBulkUploadModal] =
    useHashFragment('#bulk-upload');
  const [showBulkUpdateModal, toggleBulkUpdateModal] =
    useHashFragment('#bulk-update');

  useEffect(() => {
    requestGetListedItemsPage();
  }, [getListedItemsRequestData]);

  const requestGetListedItemsPage = useCallback(() => {
    dispatch(itemActions.requestGetListedItemsPage(getListedItemsRequestData));
  }, [getListedItemsRequestData, dispatch]);

  const itemActionCallback = useCallback(
    (error?: Error) => {
      if (error) {
        dispatch(appActions.showBanner({ type: 'error' }));
      }
      setIsInBulkActionsMode(false);
      setShowConfirmDeleteItemsModal(false);
      setHasSubmittedBulkDelete(false);
      setSelectedItemIds([]);
      requestGetListedItemsPage();
    },
    [requestGetListedItemsPage, dispatch]
  );

  const onSelectItems = useCallback((itemIds: number[]) => {
    setSelectedItemIds((selectedItemIds) => {
      return selectedItemIds
        .concat(itemIds)
        .filter((itemId: number, index: number, array: number[]) => {
          return array.indexOf(itemId) === index; // get unique
        });
    });
  }, []);

  const onUnselectItems = useCallback((itemIds: number[]) => {
    setSelectedItemIds((selectedItemIds) => {
      return selectedItemIds.filter((itemId: number) => {
        return !itemIds.includes(itemId);
      });
    });
  }, []);

  const navigateToItemPage = useCallback(
    (itemId: number) => {
      history.push(`/items/${itemId}`);
    },
    [history]
  );

  const onClickRow = useCallback(
    (itemId: number) => {
      navigateToItemPage(itemId);
    },
    [navigateToItemPage]
  );

  const quickDuplicateItem = (itemId: number) => {
    dispatch(
      lineItemV2Actions.duplicateLineItem.request({
        itemId,
        callback: (newItemId: number) => {
          requestGetListedItemsPage();
          history.push(`items/${newItemId}`);
        },
      })
    );
  };

  const onBulkDeleteItems = useCallback(
    (itemIds: number[]) => {
      dispatch(
        lineItemActions.requestDeleteLineItems(itemIds, itemActionCallback)
      );
      setHasSubmittedBulkDelete(true);
    },
    [dispatch, itemActionCallback]
  );

  const onSelectPage = useCallback((pageNumber: number) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', String(pageNumber));
    history.push(`/items?${searchParams.toString()}`);
  }, []);

  const onSelectPageSize = useCallback((pageSize: number) => {
    localStorage.setItem(
      itemsFilterPageSizeLocalStorageKey,
      pageSize.toString()
    );
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', '1');
    searchParams.set('count', String(pageSize));
    history.push(`/items?${searchParams.toString()}`);
  }, []);

  const listedItemsTitleBar = useMemo(() => {
    return (
      <div className="flex-container flex-center flex-grow">
        <div>
          <h1>
            Items you sell
            <Tooltip text="Items you sell are what you want your Customer to see on their Quote." />
          </h1>
        </div>
        <PermissionGuard
          renderIfHasPermissions={[LineItemsPermissions.ADD_LINE_ITEM]}
        >
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={() => {
              const localStorageTaxRateId = localStorage.getItem(
                taxRateIdLocalStorageKey
              );
              dispatch(
                lineItemV2Actions.requestPersistLineItem(
                  {
                    taxRateId:
                      localStorageTaxRateId?.length &&
                      localStorageTaxRateId !== '0'
                        ? parseInt(localStorageTaxRateId, 10)
                        : defaultTaxRate?.id,
                    price: 0,
                  },
                  (lineItemId) => {
                    history.push(`/items/${lineItemId}`);
                  }
                )
              );
            }}
          >
            Create Item
          </Button>
        </PermissionGuard>
        <PermissionGuard
          renderIfHasPermissions={[LineItemsPermissions.DELETE_LINE_ITEM]}
        >
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => setIsInBulkActionsMode(true)}
          >
            Bulk edit
          </Button>
        </PermissionGuard>
        <div className="flex-grow"></div>
        <PermissionGuard
          renderIfHasPermissions={[LineItemsPermissions.GET_LINE_ITEM_GROUPS]}
        >
          <Button
            onClick={() => history.push('/items/groups')}
            colourVariant={ButtonColourVariant.GREY}
            iconBefore={<Category size={'small'} set={'light'} />}
          >
            Item groups
          </Button>
        </PermissionGuard>
        <PermissionGuard
          renderIfHasPermissions={[LineItemsPermissions.UPDATE_LINE_ITEM]}
        >
          <>
            <TooltipUI
              title="Download and update Items from CSV data"
              arrow
              placement="left"
            >
              <div>
                <Button
                  onClick={toggleBulkUpdateModal}
                  className="download-button"
                  hasBoxShadow={false}
                >
                  <Download set="light" />
                </Button>
              </div>
            </TooltipUI>
            <TooltipUI
              title="Upload Items from CSV data"
              arrow
              placement="left"
            >
              <div>
                <Button
                  onClick={toggleBulkUploadModal}
                  className="upload-button"
                  hasBoxShadow={false}
                >
                  <Upload set="light" />
                </Button>
              </div>
            </TooltipUI>
          </>
        </PermissionGuard>
      </div>
    );
  }, [history]);

  const listedItemsBulkActionsBar = useMemo(() => {
    const isAnyItemsSelected = !!selectedItemIds.length;

    return (
      <div className="flex-container flex-center flex-grow bulk-actions-title-bar">
        <div className="listed-items-title-bar-inner flex-container">
          <div>
            <h1>Items</h1>
          </div>
          <span>
            {isAnyItemsSelected
              ? `You have ${selectedItemIds.length} ${
                  selectedItemIds.length === 1 ? 'row' : 'rows'
                } selected`
              : 'Check the rows you wish to select'}
          </span>
        </div>

        <div className="flex-grow"></div>
        <Button
          isDisabled={!isAnyItemsSelected}
          isProcessing={isDeletingLineItems}
          onClick={() => {
            !isDeletingLineItems && setShowConfirmDeleteItemsModal(true);
          }}
        >
          Delete
        </Button>
        <Button
          styleVariant={ButtonStyleVariant.ANCHOR}
          onClick={() => {
            setIsInBulkActionsMode(false);
            setSelectedItemIds([]);
          }}
        >
          Cancel
        </Button>
      </div>
    );
  }, [selectedItemIds, isDeletingLineItems]);

  const translate = useTranslation();

  return (
    <>
      <Helmet title={translate('pages.items.title')} />
      <AuthenticatedPageWrapper
        className="listed-items-page"
        title={
          isInBulkActionsMode ? listedItemsBulkActionsBar : listedItemsTitleBar
        }
      >
        <div className="search-filter-control-container">
          <ListedItemsSearchFilterControl
            onChange={(value) => {
              const searchParams = new URLSearchParams(window.location.search);
              if (value?.sortBy) {
                localStorage.setItem(
                  itemsFilterSortByLocalStorageKey,
                  value.sortBy
                );
                searchParams.set('sort', value.sortBy);
              }
              if (value?.sortDirection) {
                localStorage.setItem(
                  itemsFilterSortDirectionLocalStorageKey,
                  value.sortDirection
                );
                searchParams.set('dir', value.sortDirection);
              }
              if (value?.searchTerm !== undefined) {
                searchParams.set('term', value.searchTerm);
              }
              searchParams.set('page', '1');
              history.push(`/items?${searchParams.toString()}`);
            }}
            sortDirection={getListedItemsRequestData.sortDirection}
            sortBy={getListedItemsRequestData.sortBy}
            initSearchTerm={getListedItemsRequestData.searchTerm}
          />
        </div>

        <div className={`listed-items-container`}>
          <ListedItems
            selectedItemIds={selectedItemIds}
            onClickRow={isInBulkActionsMode ? undefined : onClickRow}
            onSelectItems={onSelectItems}
            onUnselectItems={onUnselectItems}
            onSelectPage={onSelectPage}
            onSelectPageSize={onSelectPageSize}
            quickActionDefinitions={
              isInBulkActionsMode
                ? {}
                : {
                    duplicate: {
                      actionName: 'Duplicate',
                      actionBehaviour: quickDuplicateItem,
                    },
                  }
            }
            isInBulkActionsMode={isInBulkActionsMode}
          />
        </div>

        {/* Bulk upload modal */}
        <BulkUploadModal
          isOpen={showBulkUploadModal}
          onClose={toggleBulkUploadModal}
          title="Upload items"
          dataType="lineItems"
        ></BulkUploadModal>
        <BulkUpdateModal
          isOpen={showBulkUpdateModal}
          onClose={toggleBulkUpdateModal}
          title="Update items"
          dataType="lineItems"
        ></BulkUpdateModal>

        {/* Confirm delete items modal */}
        <Modal
          isOpen={showConfirmDeleteItemsModal}
          onClose={() => setShowConfirmDeleteItemsModal(false)}
          title={`Are you sure you want to delete ${
            selectedItemIds.length > 1
              ? `${selectedItemIds.length} items`
              : '1 item'
          }?`}
          actions={
            <>
              <Button
                styleVariant={ButtonStyleVariant.OUTSIZE}
                onClick={() => {
                  if (isDeletingLineItems) return;
                  onBulkDeleteItems(selectedItemIds);
                }}
                isProcessing={isDeletingLineItems}
              >
                Delete
              </Button>
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => setShowConfirmDeleteItemsModal(false)}
              >
                Cancel
              </Button>
            </>
          }
        ></Modal>
      </AuthenticatedPageWrapper>
    </>
  );
};

export default ListedItemsPage;
