import { Reducer } from 'redux';
import { mergeFetchedEntities } from '../utils';
import {
  SubscriptionActionTypes,
  SubscriptionState,
} from './subscriptionTypes';

export const initialState: SubscriptionState = {
  products: null,
  addOnProducts: {},
  isGettingProducts: false,
  accountSubscription: null,
  isGettingAccountSubscription: false,
  isCreatingSubscription: false,
  isUpdatingSubscription: false,
  isRestoringSubscription: false,
  isUpdatingPaymentMethod: false,
  isSubscriptionCreatedSuccessfully: undefined,
  isSubscriptionUpdatedSuccessfully: undefined,
  isSubscriptionRestoredSuccessfully: undefined,
  isPaymentMethodUpdatedSuccessfully: undefined,
  isGettingSubscriptionPaymentPreview: false,
  subscriptionPaymentPreview: undefined,
};

const subscriptionReducer: Reducer<SubscriptionState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SubscriptionActionTypes.REQUEST_GET_PRODUCTS:
      return { ...state, isGettingProducts: true };
    case SubscriptionActionTypes.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        products: null,
        isGettingProducts: false,
      };
    case SubscriptionActionTypes.CLEAR_PRODUCTS:
      return { ...state, products: null };
    case SubscriptionActionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        isGettingProducts: false,
      };

    case SubscriptionActionTypes.REQUEST_GET_ACCOUNT_SUBSCRIPTION:
      return { ...state, isGettingAccountSubscription: true };
    case SubscriptionActionTypes.GET_ACCOUNT_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        accountSubscription: null,
        isGettingAccountSubscription: false,
      };
    case SubscriptionActionTypes.CLEAR_ACCOUNT_SUBSCRIPTION:
      return { ...state, accountSubscription: null };
    case SubscriptionActionTypes.GET_ACCOUNT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        accountSubscription: action.payload.accountSubscription,
        isGettingAccountSubscription: false,
      };

    case SubscriptionActionTypes.REQUEST_CREATE_SUBSCRIPTION:
      return {
        ...state,
        isSubscriptionCreatedSuccessfully: undefined,
        isCreatingSubscription: true,
      };
    case SubscriptionActionTypes.CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isSubscriptionCreatedSuccessfully: true,
        isCreatingSubscription: false,
      };
    case SubscriptionActionTypes.CREATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isSubscriptionCreatedSuccessfully: false,
        isCreatingSubscription: false,
      };

    case SubscriptionActionTypes.REQUEST_UPDATE_SUBSCRIPTION:
      return {
        ...state,
        isSubscriptionUpdatedSuccessfully: undefined,
        isUpdatingSubscription: true,
      };
    case SubscriptionActionTypes.UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isSubscriptionUpdatedSuccessfully: true,
        isUpdatingSubscription: false,
      };
    case SubscriptionActionTypes.UPDATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isSubscriptionUpdatedSuccessfully: false,
        isUpdatingSubscription: false,
      };

    case SubscriptionActionTypes.REQUEST_RESTORE_SUBSCRIPTION:
      return {
        ...state,
        isSubscriptionRestoredSuccessfully: undefined,
        isRestoringSubscription: true,
      };
    case SubscriptionActionTypes.RESTORE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isSubscriptionRestoredSuccessfully: true,
        isRestoringSubscription: false,
      };
    case SubscriptionActionTypes.RESTORE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isSubscriptionRestoredSuccessfully: false,
        isRestoringSubscription: false,
      };

    case SubscriptionActionTypes.REQUEST_UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        isPaymentMethodUpdatedSuccessfully: undefined,
        isUpdatingPaymentMethod: true,
      };
    case SubscriptionActionTypes.UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isPaymentMethodUpdatedSuccessfully: true,
        isUpdatingPaymentMethod: false,
      };
    case SubscriptionActionTypes.UPDATE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        isPaymentMethodUpdatedSuccessfully: false,
        isUpdatingPaymentMethod: false,
      };

    case SubscriptionActionTypes.CLEAR_SUBSCRIPTION_PAYMENT_PREVIEW:
      return {
        ...state,
        subscriptionPaymentPreview: undefined,
      };
    case SubscriptionActionTypes.REQUEST_GET_SUBSCRIPTION_PAYMENT_PREVIEW:
      return {
        ...state,
        subscriptionPaymentPreview: undefined,
        isGettingSubscriptionPaymentPreview: true,
      };
    case SubscriptionActionTypes.GET_SUBSCRIPTION_PAYMENT_PREVIEW_SUCCESS:
      return {
        ...state,
        subscriptionPaymentPreview: action.payload.subscriptionPaymentPreview,
        isGettingSubscriptionPaymentPreview: false,
      };
    case SubscriptionActionTypes.GET_SUBSCRIPTION_PAYMENT_PREVIEW_FAILURE:
      return {
        ...state,
        subscriptionPaymentPreview: undefined,
        isGettingSubscriptionPaymentPreview: false,
      };
    case SubscriptionActionTypes.GET_ADD_ON_PRODUCTS_SUCCESS:
      return {
        ...state,
        addOnProducts: mergeFetchedEntities({
          cache: state.addOnProducts,
          values: action.payload,
          idKey: 'publicId',
        }),
      };

    case SubscriptionActionTypes.CLEAR_ADD_ON_PRODUCTS:
      return {
        ...state,
        addOnProducts: initialState.addOnProducts,
      };

    default:
      return state;
  }
};

export default subscriptionReducer;
