import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import Card, { CardProps } from './Card';

export interface IProps extends CardProps<typeof Link> {}

const CardLink: FC<PropsWithChildren<IProps>> = React.forwardRef(
  (props, ref) => {
    const { className, children, ...rest } = props;

    return (
      <Card
        as={Link}
        className={twMerge(
          'font-normal hover:cursor-pointer hover:bg-gray-50 hover:hover:no-underline',
          className
        )}
        {...rest}
        ref={ref}
      >
        {children}
      </Card>
    );
  }
);

export default CardLink;
