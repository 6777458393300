import { Nullish } from "@payaca/utilities/types";

interface SubscriptionProductFeatures {
  numberOfJobsPerMonth?: number;
  numberOfUserSeats?: number;
  hasZapier?: boolean;
  hasForms?: boolean;
  numberOfSmsPerMonth?: number;
  hasCustomAutomations?: boolean;
}
export interface SubscriptionProduct extends SubscriptionProductFeatures {
  stripeId: string;
  name: string;
  description?: string;
  notes?: Nullish<string>;
  monthlyPrice?: ProductPrice;
  annualPrice?: ProductPrice;
  discountPercentage?: Nullish<number>;
  discountDurationInMonths?: Nullish<number>;
  discountRedeemBy?: Nullish<number>;
}

export interface SubscriptionProductInformation
  extends SubscriptionProductFeatures {
  id: number;
  productId: string;
  notes: string;
  mapping: string;
}
export interface TemporarySubscriptionProduct extends SubscriptionProduct {
  expiresAt: string;
}

export interface ProductPrice {
  stripeId: string;
  basicCost: number;
  additionalUserSeatCost?: number;
  canBuyAdditionalUserSeats: boolean;
}

export enum RecurringInterval {
  MONTH = 'month',
  YEAR = 'year',
}
