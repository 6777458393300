import React, { FC, PropsWithChildren, useMemo } from 'react';

import EntityCard from '@payaca/components/entityCard/EntityCard';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';

import { LineItemsPermissions } from '@payaca/permissions/lineItems/line-items.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

import { getRegion, getUserRoles } from '@/utils/stateAccessors';

import { ListedItem } from '@payaca/types/listedItemTypes';

import { useSelector } from '@/api/state';

import './ListedItemCard.sass';

interface Props {
  listedItem: ListedItem;
  onClick?: () => void;
  quickActionDefinitions?: {
    duplicate?: {
      actionName: string;
      actionBehaviour: (recordId: any) => void;
      isActionProcessing?: boolean;
    };
  };
  selectionConfig?: {
    isSelected: boolean;
    isDisabled?: boolean;
    onSelectionChange?: (isSelected?: boolean) => void;
  };
}

const ListedItemCard: FC<PropsWithChildren<Props>> = ({
  listedItem,
  onClick,
  quickActionDefinitions,
  selectionConfig,
}) => {
  const userRoles = useSelector(getUserRoles);
  const region = useSelector(getRegion);

  const quickActions = useMemo(() => {
    const qa: {
      actionName: string;
      actionBehaviour: (recordId: any) => void;
      isActionProcessing?: boolean;
    }[] = [];

    if (
      quickActionDefinitions?.duplicate &&
      userHasRequiredPermission(userRoles, [LineItemsPermissions.ADD_LINE_ITEM])
    ) {
      qa.push(quickActionDefinitions.duplicate);
    }
    return qa;
  }, [quickActionDefinitions, userRoles]);

  return (
    <EntityCard
      className="listed-item-card"
      imageConfig={{
        imageSrc: listedItem.thumbnailUrl,
        renderBlockIfNoImageSrc: true,
      }}
      onClick={onClick}
      quickActionsConfig={{
        recordId: listedItem.itemId,
        quickActions: quickActions,
        renderDisabledIfNoActions: false,
      }}
      selectionConfig={selectionConfig}
    >
      <ResponsiveViewWrapper
        className="listed-item-card-content"
        downBreakpointSm={400}
      >
        {/* name, description */}
        <div className="name-description-container">
          <span className="item-name">{listedItem.name}</span>
          <MarkdownLabel
            markdown={listedItem.description}
            className="item-description"
          />
        </div>

        <div className="price-container">
          {/* price, vat, cis */}
          <h5 className="item-price-total">{listedItem.priceIncludingVat}</h5>
          <span className="item-price">{listedItem.price} ex tax</span>
          {listedItem.cisDeductionRate && (
            <small className="cis-deduction-rate">
              {listedItem.cisDeductionRate}% CIS deduction rate
            </small>
          )}
          {/* </div> */}
        </div>
      </ResponsiveViewWrapper>
    </EntityCard>
  );
};

export default ListedItemCard;
