import React, { FC, useCallback, useMemo } from 'react';
import useGetProjectOverview from '@/api/queries/project/useGetProjectOverview';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import Address2Lines from '@payaca/components/address/Address2Lines';
import { useHashFragment } from '@/utils/customHooks';
import * as dealsActions from '@payaca/store/deals/dealsActions';
import projectKeys from '@/api/queries/project/keyFactory';
import { useDispatch } from 'react-redux';
import FinancialSummaryCard, {
  IProps as IFinancialSummaryProps,
} from '@/ui/components/financialSummaryCard/FinancialSummaryCard';
import SiteAddressModal from '@/ui/pages/dealPage/components/SiteAddressModal';
import AddCardButton from '@payaca/components/addCard/AddCardButton';

export interface IProps {
  onDealUpdateSuccess?: () => void;
}

const ProjectOverviewCards: FC<IProps> = (props) => {
  const { onDealUpdateSuccess } = props;
  const { dealId } = useParams<{ dealId: string }>();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [showDealSiteAddressesModal, toggleDealSiteAddressesModal] =
    useHashFragment('#site-addresses');
  const { data: projectData, isLoading: isProjectLoading } =
    useGetProjectOverview(+dealId);

  const requestGetDeal = useCallback(() => {
    dispatch(dealsActions.requestGetDeal(+dealId));
    queryClient.invalidateQueries({
      queryKey: projectKeys.overview(+dealId),
    });
  }, [dealId, queryClient]);

  const financialSummary = useMemo(() => {
    const totals = projectData?.project.totals;

    if (!totals) return;

    const _financialSummary: IFinancialSummaryProps['financialSummary'] = {
      totalProjectValue: totals.projectValue.value,
      currency: totals.acceptedValue.currency,
    };

    if (projectData.project.version > 1) {
      _financialSummary.toBeInvoiced =
        totals.acceptedValue.value - totals.sentInvoicedValue.value;
      _financialSummary.toBePaid =
        totals.sentInvoicedValue.value - totals.completedPaymentValue.value;
      _financialSummary.paid = totals.completedPaymentValue.value;
    }

    return _financialSummary;
  }, [projectData]);

  if (isProjectLoading) {
    return (
      <div className="flex flex-col gap-4 sm:flex-row">
        <Card className="flex-[1_1_0px]">
          <Card.Body className="space-y-2">
            <SkeletonLoader.Text className="mb-4 w-1/4" />
            <SkeletonLoader.Text />
            <SkeletonLoader.Text />
            <SkeletonLoader.Text />
          </Card.Body>
        </Card>

        <Card className="flex-[1_1_0px]">
          <Card.Body className="space-y-2">
            <SkeletonLoader.Text className="mb-4 w-1/4" />
            <SkeletonLoader.Text />
            <SkeletonLoader.Text />
            <SkeletonLoader.Text />
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4 sm:flex-row-reverse">
        <FinancialSummaryCard
          className="flex-[1_1_0px]"
          title="Project value"
          financialSummary={financialSummary}
        />

        {projectData?.project.addresses.site[0] ? (
          <Card className="flex-[1_1_0px]" sizeVariant={CardSizeVariant.SM}>
            <Card.Body className="flex h-full flex-col">
              <h4>Site Address</h4>

              <Address2Lines
                className="text-sm"
                address={projectData.project.addresses.site[0]}
              />

              {projectData.project.addresses.site.length > 1 && (
                <p className="text-sm">
                  +{projectData.project.addresses.site.length - 1} more
                </p>
              )}

              <div className="mt-auto">
                <Button
                  size={EBtnSize.Small}
                  variant={EBtnVariant.LinkInline}
                  onClick={toggleDealSiteAddressesModal}
                >
                  Edit Address
                </Button>
              </div>
            </Card.Body>
          </Card>
        ) : (
          <AddCardButton
            className="flex-[1_1_0px]"
            onClick={toggleDealSiteAddressesModal}
            text="Add Site Address"
          />
        )}
      </div>

      <SiteAddressModal
        isOpen={!!showDealSiteAddressesModal}
        onClose={toggleDealSiteAddressesModal}
        onDealUpdateSuccessLegacy={onDealUpdateSuccess}
      />
    </>
  );
};

export default ProjectOverviewCards;
