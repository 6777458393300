import React, { FC, useMemo } from 'react';

import Badge from '@payaca/components/badge/Badge';

import {
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';

import './SubscriptionControlHeader.sass';

type Props = {
  selectedProduct: SubscriptionProduct;
  recurringInterval: RecurringInterval;
};
const SubscriptionControlHeader: FC<Props> = ({
  selectedProduct,
  recurringInterval,
}: Props): JSX.Element => {
  const isMostPopularPlan = useMemo(() => {
    return selectedProduct.name === 'Professional';
  }, [selectedProduct]);

  return (
    <div className="subscription-control-header">
      <Badge backgroundColour="#4ea83d" colour="#ffffff">
        Selected plan
      </Badge>
      <h3>{selectedProduct.name}</h3>
      <p>(Billed {recurringInterval === 'month' ? 'monthly' : 'annually'})</p>
    </div>
  );
};
export default SubscriptionControlHeader;
