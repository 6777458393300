import React, { FC } from 'react';
import UntitledIcon from '@payaca/untitled-icons';

export interface IProps {
  className?: string;
  text?: string;
}

const EmptyState: FC<IProps> = (props) => {
  const { className, text = 'No results found' } = props;

  return (
    <div
      className={
        'flex flex-col items-center' + (className ? ' ' + className : '')
      }
    >
      <UntitledIcon
        className="h-8 w-8"
        name="search-refraction.3"
        vectorEffect={'non-scaling-stroke'}
      />
      <span className="mt-2 text-base">{text}</span>
    </div>
  );
};

export default EmptyState;
