import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PublicEntityTemplate } from '@payaca/types/entity-templates';
import { useDispatch } from 'react-redux';
import { SortDirection } from '@payaca/types/listViewTypes';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import './AssigneeTimelogsGrouping.sass';
import EmptyState from '../emptyState/EmptyState';
import {
  GetListedTimelogsQueryParams,
  SortBy,
  ListedTimelogsPage,
  GetAssigneeGroupedListedTimelogsQueryParams,
  AssigneeGroupedListedTimelogs,
  AssigneeTimelogsGrouping as AssigneeTimelogsGroupingType,
} from '@payaca/types/listedTimelogTypes';
import moment from 'moment-timezone';
import {
  getAssigneeGroupedListedTimelogs,
  getListedTimelogsPage,
  getTimelogTypes,
} from '@payaca/store/timelogs/timelogsActions';
import TimelogCard from '../timelogCard/TimelogCard';
import {
  PublicTimelogType,
  TimelogAssignee,
  TimelogLinkedEntity,
} from '@payaca/types/timelogs';
import { actions as usersActions } from '@/api/users';
import { useSelector } from '@/api/state';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getRegion } from '@/utils/stateAccessors';
import {
  DurationUnit,
  getReadableStringFromDurationString,
} from '@payaca/utilities/timeUtilities';
import { useAccountUsers } from '@/utils/storeHooks';
import TimelogFilters from '../timelogFilters/TimelogFilters';
import UserIndicator from '@payaca/components/userIndicator/UserIndicator';
import TimelogRow from './TimelogRow';

type Props = {
  assigneeTimelogsGrouping: AssigneeTimelogsGroupingType;
  onUpdateTimelogSuccess?: () => void;
  onArchiveTimelogSuccess?: () => void;
};

const AssigneeTimelogsGrouping: FC<Props> = ({
  assigneeTimelogsGrouping,
  onUpdateTimelogSuccess,
  onArchiveTimelogSuccess,
}): JSX.Element | null => {
  const users = useAccountUsers();
  const user = users.find(
    (x) =>
      assigneeTimelogsGrouping.assignee.type == 'user' &&
      x.id === +assigneeTimelogsGrouping.assignee.publicId
  );

  const region = useSelector(getRegion);

  return (
    <ResponsiveViewWrapper
      downBreakpointSm={800}
      downBreakpointXs={450}
      className="assignee-timelogs-grouping"
    >
      <div className="assignee-information">
        {user && (
          <div className="assignee-indicator">
            <UserIndicator
              user={{
                firstName: user?.firstname,
                lastName: user?.lastname,
                emailAddress: user?.email,
                userColour: user?.userColour,
              }}
              isUserInformationHoverPopperEnabled={false}
            />
          </div>
        )}
        <span className="assignee-name">
          {assigneeTimelogsGrouping.assignee.firstName}{' '}
          {assigneeTimelogsGrouping.assignee.lastName}
        </span>
      </div>
      <div className="assignee-totals">
        <dl>
          {assigneeTimelogsGrouping.totalDuration && (
            <>
              <dt>Time spent:</dt>
              <dd>
                {getReadableStringFromDurationString(
                  assigneeTimelogsGrouping.totalDuration,
                  { units: [DurationUnit.HOURS, DurationUnit.MINUTES] }
                )}
              </dd>
            </>
          )}
          <dt>Cost:</dt>
          <dd>
            {currencyPrice(
              assigneeTimelogsGrouping.totalCalculatedCost,
              region
            )}
          </dd>
        </dl>
      </div>
      <div className="timelogs">
        <ul>
          {!assigneeTimelogsGrouping.timelogs.length && (
            <li>No timelogs to display</li>
          )}
          {assigneeTimelogsGrouping.timelogs.map((timelog, i) => {
            return (
              <li key={i}>
                <TimelogRow
                  timelog={timelog}
                  onUpdateTimelogSuccess={onUpdateTimelogSuccess}
                  onArchiveTimelogSuccess={onArchiveTimelogSuccess}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </ResponsiveViewWrapper>
  );
};

export default AssigneeTimelogsGrouping;
