import {
  getMaterial,
  getMaterialsListMaterial,
  getRegion,
  getSupplierMaterialsForMaterial,
  getUserRoles,
} from '@/utils/stateAccessors';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import './PurchasableMaterialsListMaterialControl.sass';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import ManageableMaterialPurchaseRecordList from '../manageableMaterialPurchaseRecordList/ManageableMaterialPurchaseRecordList';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import MaterialPurchaseIntentList from '../materialPurchaseIntentList/MaterialPurchaseIntentList';
import { useSelector } from '@/api/state';
import MaterialsListMaterialControl from '../materialsListMaterialControl/MaterialsListMaterialControl';

type Props = {
  materialsListMaterialId: number;
  onDeleteMaterialsListMaterialSuccess?: () => void;
  onUpdateMaterialsListMaterialSuccess?: () => void;
  onCreateMaterialPurchaseRecordSuccess?: () => void;
  onDeleteMaterialPurchaseRecordSuccess?: () => void;
};

const PurchasableMaterialsListMaterialControl: FunctionComponent<Props> = ({
  materialsListMaterialId,
  onDeleteMaterialsListMaterialSuccess,
  onUpdateMaterialsListMaterialSuccess,
  onCreateMaterialPurchaseRecordSuccess,
  onDeleteMaterialPurchaseRecordSuccess,
}: Props): JSX.Element | null => {
  const materialsListMaterial = useSelector((state) =>
    getMaterialsListMaterial(state, materialsListMaterialId)
  );

  const material = useSelector((state) => {
    return materialsListMaterial
      ? getMaterial(state, materialsListMaterial.materialId)
      : undefined;
  });

  if (!materialsListMaterial || !material) return null;

  return (
    <ResponsiveViewWrapper
      downBreakpointSm={700}
      className={`purchasable-materials-list-material-control ${
        materialsListMaterial.isFullyPurchased ? 'fully-purchased' : ''
      }`}
    >
      <>
        <MaterialsListMaterialControl
          materialsListMaterialId={materialsListMaterialId}
          showPreferredSupplierInfo={false}
          onDeleteMaterialsListMaterialSuccess={
            onDeleteMaterialsListMaterialSuccess
          }
          onUpdateMaterialsListMaterialSuccess={
            onUpdateMaterialsListMaterialSuccess
          }
        />
        <PermissionGuard
          renderIfHasPermissions={[
            MaterialsListPermissions.GET_MATERIAL_PURCHASE_RECORDS,
          ]}
        >
          <ManageableMaterialPurchaseRecordList
            materialsListMaterialId={materialsListMaterialId}
            onCreateMaterialPurchaseRecordSuccess={
              onCreateMaterialPurchaseRecordSuccess
            }
            onDeleteMaterialPurchaseRecordSuccess={
              onDeleteMaterialPurchaseRecordSuccess
            }
          />
        </PermissionGuard>
        <PermissionGuard
          renderIfHasPermissions={[
            MaterialsListPermissions.GET_MATERIAL_PURCHASE_INTENTS,
          ]}
        >
          <MaterialPurchaseIntentList
            materialsListMaterialId={materialsListMaterialId}
          />
        </PermissionGuard>
      </>
    </ResponsiveViewWrapper>
  );
};

export default PurchasableMaterialsListMaterialControl;
