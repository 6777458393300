import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import DoneIcon from '@material-ui/icons/Done';

import { actions as usersActions } from '@/api/users';

import { ConnectionControl } from '@/ui/components';

import StripeLogo from './stripe-logo.svg';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { getUserRoles } from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';
import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';
import { ContactSupportOutlined } from '@material-ui/icons';

const STRIPE_CLIENT_ID = import.meta.env.VITE_STRIPE_CLIENT_ID;

const StripeConnectionControl: FunctionComponent = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const [checkStripeStatusInterval, setCheckStripeStatusInterval] =
    useState<NodeJS.Timeout>();
  const isConnectedToStripe = useSelector((state: any) => {
    return state.users.myProfile.accounts[0].connectedToStripe;
  });
  const userRoles = useSelector(getUserRoles);
  const isAdmin = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      AccountsPermissions.UPDATE_CONNECTION,
    ]);
  }, [userRoles]);

  const [accountHasServicePlans, setAccountHasServicePlans] = useState(false);

  useEffect(() => {
    dispatch(
      servicePlansActions.getListedServicePlans.request({
        callback: (listedServicePlans) => {
          if (listedServicePlans.length > 0) {
            setAccountHasServicePlans(true);
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    isConnectedToStripe &&
      checkStripeStatusInterval &&
      clearInterval(checkStripeStatusInterval);
    setCheckStripeStatusInterval(undefined);
  }, [isConnectedToStripe, checkStripeStatusInterval]);

  const handleCheckStripeStatus = useCallback(() => {
    checkStripeStatusInterval && clearInterval(checkStripeStatusInterval);
    setCheckStripeStatusInterval(
      setInterval(() => dispatch(usersActions.getProfile()), 3000)
    );
  }, [checkStripeStatusInterval, dispatch]);

  return (
    <ConnectionControl
      disableConnection={false}
      connectionName="Stripe"
      isConnected={isConnectedToStripe}
      allowConnectDisconnect={isAdmin}
      onAddConnection={() => {
        handleCheckStripeStatus();
        window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${window.location.origin}/enableCustomerPayments`;
      }}
      onRemoveConnection={(onConnectionRemoved: () => void) =>
        dispatch(usersActions.removeStripeConnection(onConnectionRemoved))
      }
      logo={StripeLogo}
      disconnectionWarningMessage={
        <ul>
          <li>
            You will no longer be able to take payment via Stripe on your
            Proposals and Invoices.
          </li>
          {accountHasServicePlans && (
            <li>You will no longer be able to create or sell Service Plans.</li>
          )}
        </ul>
      }
    >
      <React.Fragment>
        {!isConnectedToStripe ? (
          <div>
            <div>Accept card payments with Stripe</div>
            <div>
              <div style={{ marginBottom: '10px' }}>
                Stripe is a secure payment platform, trusted by over a million
                businesses all over the world.
              </div>
              <div style={{ marginBottom: '10px' }}>
                If you already have a Stripe account you can connect to it or if
                you don‘t you can create one.
              </div>
              <div style={{ textAlign: 'left', marginBottom: '10px' }}>
                <div>
                  <DoneIcon style={{ fontSize: 15 }} /> Make it easy for
                  customers to pay fast with a button on your quotes and
                  invoices.
                </div>
                <div>
                  <DoneIcon style={{ fontSize: 15 }} /> Fast, easy setup
                </div>
                <div>
                  <DoneIcon style={{ fontSize: 15 }} /> Fees for European cards
                  are 1.4% +20p, you can find out more{' '}
                  <a
                    href="https://stripe.com/gb/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>Your Stripe account has successfully been connected to Payaca.</p>
        )}
      </React.Fragment>
    </ConnectionControl>
  );
};

export default StripeConnectionControl;
