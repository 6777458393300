import React, {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import qs from 'qs';
import { twMerge } from 'tailwind-merge';
import UntitledIcon, { TIconName } from '@payaca/untitled-icons';
import Conditional from '../conditional/Conditional';

export type TabPanel = {
  icon?: TIconName;
  label: string;
  slug: string;
  render: () => ReactNode;
};

export interface IProps {
  panels: TabPanel[];
}

const RouterTabsContext = createContext<IProps>({ panels: [] });

const RouterTabs: FC<PropsWithChildren<IProps>> = (props) => {
  const { panels, children } = props;

  return (
    <RouterTabsContext.Provider value={{ panels }}>
      {children}
    </RouterTabsContext.Provider>
  );
};

const RouterTabNav: FC<{
  defaultTabSlug?: string;
  className?: string;
  fullWidth?: boolean;
  navSuffix?: ReactNode;
}> = (props) => {
  const { className, defaultTabSlug, navSuffix, fullWidth = false } = props;

  const { panels } = useContext(RouterTabsContext);

  const history = useHistory();
  const { path, url } = useRouteMatch();
  const hasActiveTab = useRouteMatch(`${path}/:tabSlug`);
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const queryString = qs.stringify(query);

  useEffect(() => {
    if (defaultTabSlug && !hasActiveTab) {
      history.replace(
        `${url}/${defaultTabSlug}${queryString ? `?${queryString}` : ''}`
      );
    }
  }, [history, hasActiveTab, url, queryString, defaultTabSlug]);

  return (
    <div
      className={twMerge('grid justify-between gap-x-4', className)}
      style={{
        gridTemplateColumns: '1fr auto',
      }}
    >
      <nav
        className="prose horizontal-scroll-shadows flex w-full items-end gap-x-4 overflow-x-auto"
        aria-label="Tabs"
        role="tablist"
      >
        {panels.map((tab) => (
          <NavLink
            key={tab.label}
            className={
              'inline-flex cursor-pointer items-center justify-center gap-x-3 whitespace-nowrap border-b-2 border-transparent bg-transparent px-2 py-2.5 text-blue-900 hover:no-underline disabled:pointer-events-none disabled:opacity-50' +
              (fullWidth ? ' flex-[1_1_0px]' : '')
            }
            activeClassName="!border-blue-600 font-semibold"
            to={`${url}/${tab.slug}`}
            role="tab"
          >
            <Conditional condition={!!tab.icon}>
              <UntitledIcon name={tab.icon!} className="h-5 w-5" />
            </Conditional>

            {tab.label}
          </NavLink>
        ))}
      </nav>
      {navSuffix && navSuffix}
    </div>
  );
};

const RouterTabPanels: FC = () => {
  const { panels } = useContext(RouterTabsContext);

  const { path } = useRouteMatch();

  return (
    <Switch>
      {panels.map((tab) => {
        return (
          <Route key={tab.label} path={`${path}/${tab.slug}`}>
            {tab.render()}
          </Route>
        );
      })}
    </Switch>
  );
};

export default Object.assign(RouterTabs, {
  Nav: RouterTabNav,
  Panels: RouterTabPanels,
});
