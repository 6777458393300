import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Search } from 'react-iconly';

import * as listedItemsActions from '@payaca/store/listedItems/listedItemsActions';

import { GetListedItemsRequestData } from '@payaca/types/listedItemTypes';
import { SortBy } from '@payaca/types/listedItemTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

import BasicField from '@payaca/components/basicField/BasicField';
import ListedItems from '../listedItems/ListedItems';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import DropdownField from '@payaca/components/dropdownField/DropdownField';

import './SelectItemControl.sass';

type Props = {
  onSelectItem: (itemId: number) => void;
  onSelectDisabledItem: (itemId: number) => void;
  disabledItemIds: number[];
};

const SelectItemControl: FunctionComponent<Props> = ({
  onSelectItem,
  onSelectDisabledItem,
  disabledItemIds,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [searchTermField, setSearchTermField] = useState<
    'searchTerm' | 'reference'
  >('searchTerm');

  const [getListedItemsRequestData, setGetListedItemsRequestData] =
    useState<GetListedItemsRequestData>({
      pageSize: 100,
      pageNumber: 1,
      searchTerm: '',
      sortDirection: SortDirection.ASCENDING,
      sortBy: SortBy.NAME,
    });

  const requestGetListedItemsPage = useCallback(() => {
    dispatch(
      listedItemsActions.requestGetListedItemsPage(getListedItemsRequestData)
    );
  }, [getListedItemsRequestData, dispatch]);

  useEffect(() => {
    requestGetListedItemsPage();
  }, [getListedItemsRequestData]);

  const onSelectPage = useCallback((pageNumber: number) => {
    setGetListedItemsRequestData(
      (getListedItemsRequestData: GetListedItemsRequestData) => {
        return {
          ...getListedItemsRequestData,
          pageNumber: pageNumber,
        };
      }
    );
  }, []);

  const [searchTermTimeout, setSearchTermTimeout] = useState<NodeJS.Timeout>();

  const onSearchTermChange = useCallback(
    (searchTerm: string) => {
      setGetListedItemsRequestData(
        (getListedItemsRequestData: GetListedItemsRequestData) => {
          return {
            ...getListedItemsRequestData,
            pageNumber: 1,
            [searchTermField]: searchTerm,
          };
        }
      );
    },
    [searchTermField]
  );

  const onSearchTermType = useCallback(
    (value: { [key: string]: string }) => {
      searchTermTimeout && clearTimeout(searchTermTimeout);
      setSearchTermTimeout(
        setTimeout(() => {
          onSearchTermChange(value.searchTerm);
        }, 500)
      );
    },
    [searchTermTimeout, onSearchTermChange]
  );

  useEffect(() => {
    setGetListedItemsRequestData(
      (getListedItemsRequestData: GetListedItemsRequestData) => {
        if (searchTermField === 'reference') {
          return {
            ...getListedItemsRequestData,
            reference: getListedItemsRequestData.searchTerm,
            searchTerm: undefined,
            pageNumber: 1,
          };
        } else {
          return {
            ...getListedItemsRequestData,
            reference: undefined,
            searchTerm: getListedItemsRequestData.reference,
            pageNumber: 1,
          };
        }
      }
    );
  }, [searchTermField]);

  return (
    <div className="select-item-control">
      <div className="search-term-field-container">
        <BasicField
          className="search-term-field"
          name="searchTerm"
          onChange={onSearchTermType}
          iconAfter={<Search set="light" size={18} />}
          additionalInputProps={{
            placeholder: 'Search',
          }}
          styleVariant={InputStyleVariant.OUTSIZE}
        />
        <DropdownField
          label="Search by"
          styleVariant={InputStyleVariant.OUTSIZE}
          name="searchTermField"
          value={searchTermField}
          options={[
            { label: 'Description & reference', value: 'searchTerm' },
            { label: 'Reference only', value: 'reference' },
          ]}
          onChange={(value) => {
            setSearchTermField(value.searchTermField);
          }}
        />
      </div>
      <ContentPanel>
        <ListedItems
          disabledItemIds={disabledItemIds}
          onClickRow={(itemId: number) =>
            disabledItemIds.includes(itemId)
              ? onSelectDisabledItem(itemId)
              : onSelectItem(itemId)
          }
          onSelectPage={onSelectPage}
        />
      </ContentPanel>
    </div>
  );
};

export default SelectItemControl;
