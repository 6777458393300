import { FC, PropsWithChildren } from 'react';
import {
  EBtnColour,
  EBtnShapeVariant,
  EBtnVariant,
} from '../plButton/useButtonClassName';
import Button from '../plButton/Button';
import UntitledIcon from '@payaca/untitled-icons';
import SidebarCore from './SidebarCore';

export type Props = {
  className?: string;
  isOpen: boolean;
  showCloseControl?: boolean;
  onClose?: () => void;
  title?: string;
};

const Sidebar: FC<PropsWithChildren<Props>> = ({
  className,
  isOpen,
  showCloseControl = true,
  onClose,
  children,
  title,
}: PropsWithChildren<Props>): JSX.Element | null => {
  return (
    <SidebarCore
      className={
        `flex max-h-full flex-col bg-white shadow-sm flex-grow` +
        (className ? ' ' + className : '')
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      {/* header */}
      <div className="py-3 px-4 flex justify-between items-center border-b">
        <h3 className="font-semibold text-gray-800 m-0">{title}</h3>
        {showCloseControl && (
          <Button
            onClick={onClose}
            variant={EBtnVariant.Soft}
            shape={EBtnShapeVariant.PILL}
            colour={EBtnColour.Gray}
            className="p-[12px]"
          >
            <UntitledIcon name="x-close" className="w-2.5 h-2.5" />
          </Button>
        )}
      </div>

      {/* content */}
      {children}
    </SidebarCore>
  );
};

const Body: FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex-grow overflow-y-auto">{children}</div>;
};

const Footer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="p-5 bg-white border-t border-stone-200">{children}</div>
  );
};

export default Object.assign(Sidebar, {
  Body,
  Footer,
});
