import { Account } from '@payaca/types/accountTypes';
import {
  AddOnProduct,
  PublicHydratedAddOnProduct,
} from '@payaca/types/add-on-products';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { SubscriptionProduct } from '@payaca/types/subscriptionProductTypes';
import {
  AccountSubscription,
  SubscriptionPaymentPreview,
} from '@payaca/types/subscriptionTypes';
import { DeclareAsyncAction, UnpackAsyncAction } from '../types';

export interface SubscriptionState {
  products: SubscriptionProduct[] | null;
  addOnProducts: {
    [key: string]: FetchedEntity<PublicHydratedAddOnProduct>;
  };
  isGettingProducts: boolean;
  accountSubscription: AccountSubscription | null;
  isGettingAccountSubscription: boolean;
  isCreatingSubscription: boolean;
  isSubscriptionCreatedSuccessfully?: boolean;
  isUpdatingSubscription: boolean;
  isSubscriptionUpdatedSuccessfully?: boolean;
  isRestoringSubscription: boolean;
  isSubscriptionRestoredSuccessfully?: boolean;
  isUpdatingPaymentMethod: boolean;
  isPaymentMethodUpdatedSuccessfully?: boolean;
  isGettingSubscriptionPaymentPreview: boolean;
  subscriptionPaymentPreview?: SubscriptionPaymentPreview;
}

export enum SubscriptionActionTypes {
  REQUEST_GET_PRODUCTS = 'subscription.requestGetProducts',
  GET_PRODUCTS_SUCCESS = 'subscription.getProductsSuccess',
  GET_PRODUCTS_FAILURE = 'subscription.getProductsFailure',
  CLEAR_PRODUCTS = 'subscription.clearProducts',
  REQUEST_CREATE_SUBSCRIPTION = 'subscription.requestCreateSubscription',
  CREATE_SUBSCRIPTION_SUCCESS = 'subscription.createSubscriptionSuccess',
  CREATE_SUBSCRIPTION_FAILURE = 'subscription.createSubscriptionFailure',
  REQUEST_GET_ACCOUNT_SUBSCRIPTION = 'subscription.requestGetAccountSubscription',
  GET_ACCOUNT_SUBSCRIPTION_SUCCESS = 'subscription.getAccountSubscriptionSuccess',
  GET_ACCOUNT_SUBSCRIPTION_FAILURE = 'subscription.getAccountSubscriptionFailure',
  CLEAR_ACCOUNT_SUBSCRIPTION = 'subscription.clearAccountSubscription',
  REQUEST_UPDATE_SUBSCRIPTION = 'subscription.requestUpdateSubscription',
  UPDATE_SUBSCRIPTION_SUCCESS = 'subscription.updateSubscriptionSuccess',
  UPDATE_SUBSCRIPTION_FAILURE = 'subscription.updateSubscriptionFailure',
  REQUEST_RESTORE_SUBSCRIPTION = 'subscription.requestRestoreSubscription',
  RESTORE_SUBSCRIPTION_SUCCESS = 'subscription.restoreSubscriptionSuccess',
  RESTORE_SUBSCRIPTION_FAILURE = 'subscription.restoreSubscriptionFailure',
  RESTORE_ADD_ON_SUBSCRIPTION_REQUEST = 'subscription.restoreAddOnSubscription:request',
  REQUEST_UPDATE_PAYMENT_METHOD = 'subscription.requestUpdatePaymentMethod',
  UPDATE_PAYMENT_METHOD_SUCCESS = 'subscription.updatePaymentMethodSuccess',
  UPDATE_PAYMENT_METHOD_FAILURE = 'subscription.updatePaymentMethodFailure',
  REQUEST_GET_SUBSCRIPTION_PAYMENT_PREVIEW = 'subscription.requestGetSubscriptionPaymentPreview',
  GET_SUBSCRIPTION_PAYMENT_PREVIEW_SUCCESS = 'subscription.getSubscriptionPaymentPreviewSuccess',
  GET_SUBSCRIPTION_PAYMENT_PREVIEW_FAILURE = 'subscription.getSubscriptionPaymentPreviewFailure',
  CLEAR_SUBSCRIPTION_PAYMENT_PREVIEW = 'subscription.clearSubscriptionPaymentPreview',

  CANCEL_SUBSCRIPTION_REQUEST = 'subscription.cancelSubscription:request',
  CANCEL_SUBSCRIPTION_SUCCESS = 'subscription.cancelSubscription:success',
  CANCEL_SUBSCRIPTION_FAILURE = 'subscription.cancelSubscription:failure',

  GET_ADD_ON_PRODUCTS_REQUEST = 'subscription.getAddOnProducts:request',
  GET_ADD_ON_PRODUCTS_SUCCESS = 'subscription.getAddOnProducts:success',
  GET_ADD_ON_PRODUCTS_FAILURE = 'subscription.getAddOnProducts:failure',

  CREATE_ADD_ON_SUBSCRIPTION_REQUEST = 'subscription.createAddOnSubscription:request',
  UPDATE_ADD_ON_SUBSCRIPTION_REQUEST = 'subscription.updateAddOnSubscription:request',
  CANCEL_ADD_ON_SUBSCRIPTION_REQUEST = 'subscription.cancelAddOnSubscription:request',
  GET_ADD_ON_SUBSCRIPTION_PAYMENT_PREVIEW_REQUEST = 'subscription.getAddOnSubscriptionPaymentPreview:request',

  CLEAR_ADD_ON_PRODUCTS = 'subscription.clearAddOnProducts',
}

export interface SubscriptionSagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export interface CreateSubscriptionRequestData {
  stripePaymentMethodId: string;
  stripePriceId: string;
  additionalUserSeats: number;
  analytics?: {
    price?: number;
    currency?: string;
    productName?: string;
  };
}

export interface UpdateSubscriptionRequestData {
  stripeSubscriptionId: string;
  stripePaymentMethodId: string;
  stripePriceId: string;
  additionalUserSeats: number;
  analytics?: {
    price?: number;
    currency?: string;
    productName?: string;
  };
}

export interface UpdatePaymentMethodRequestData {
  stripeSubscriptionId: string;
  stripePaymentMethodId: string;
}

export interface GetSubscriptionPaymentPreviewData {
  stripeSubscriptionId: string;
  stripePriceId: string;
  additionalUserSeats: number;
}

export type AnyAction =
  | UnpackAsyncAction<CancelSubscriptionAction>
  | UnpackAsyncAction<GetAddOnProducts>;

export type CancelSubscriptionAction = DeclareAsyncAction<
  SubscriptionActionTypes,
  {
    request: {
      type: SubscriptionActionTypes.CANCEL_SUBSCRIPTION_REQUEST;
      payload: Account['id'];
      meta: (err?: Error) => void;
    };
    success: {
      type: SubscriptionActionTypes.CANCEL_SUBSCRIPTION_SUCCESS;
    };
    failure: {
      type: SubscriptionActionTypes.CANCEL_SUBSCRIPTION_FAILURE;
    };
  }
>;

export type GetAddOnProducts = DeclareAsyncAction<
  SubscriptionActionTypes,
  {
    request: {
      type: SubscriptionActionTypes.GET_ADD_ON_PRODUCTS_REQUEST;
      payload: {
        callback?: (addOnProducts: PublicHydratedAddOnProduct[]) => void;
        onErrorCallback?: () => void;
      };
    };
    success: {
      type: SubscriptionActionTypes.GET_ADD_ON_PRODUCTS_SUCCESS;
      payload: PublicHydratedAddOnProduct[];
    };
    failure: { type: SubscriptionActionTypes.GET_ADD_ON_PRODUCTS_FAILURE };
  }
>;

export type ClearAddOnProducts = DeclareAsyncAction<
  SubscriptionActionTypes,
  {
    request: {
      type: SubscriptionActionTypes.CLEAR_ADD_ON_PRODUCTS;
    };
  }
>;

export type CreateAddOnSubscription = DeclareAsyncAction<
  SubscriptionActionTypes,
  {
    request: {
      type: SubscriptionActionTypes.CREATE_ADD_ON_SUBSCRIPTION_REQUEST;
      payload: {
        addOnProductPriceId: string;
        callback?: (payload: {
          addOnSubscriptionId: string;
          stripeClientSecret?: string;
        }) => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type UpdateAddOnSubscription = DeclareAsyncAction<
  SubscriptionActionTypes,
  {
    request: {
      type: SubscriptionActionTypes.UPDATE_ADD_ON_SUBSCRIPTION_REQUEST;
      payload: {
        addOnProductPriceId: string;
        addOnSubscriptionId: string;
        callback?: (payload: {
          addOnSubscriptionId: string;
          stripeClientSecret?: string;
        }) => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type CancelAddOnSubscription = DeclareAsyncAction<
  SubscriptionActionTypes,
  {
    request: {
      type: SubscriptionActionTypes.CANCEL_ADD_ON_SUBSCRIPTION_REQUEST;
      payload: {
        addOnSubscriptionId: string;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type RestoreAddOnSubscription = DeclareAsyncAction<
  SubscriptionActionTypes,
  {
    request: {
      type: SubscriptionActionTypes.RESTORE_ADD_ON_SUBSCRIPTION_REQUEST;
      payload: {
        addOnSubscriptionPublicId: string;
        callback?: (err?: Error) => void;
      };
    };
  }
>;

export type GetAddOnSubscriptionPaymentPreview = DeclareAsyncAction<
  SubscriptionActionTypes,
  {
    request: {
      type: SubscriptionActionTypes.GET_ADD_ON_SUBSCRIPTION_PAYMENT_PREVIEW_REQUEST;
      payload: {
        addOnProductPriceId: string;
        callback?: (
          subscriptionPaymentPreview: SubscriptionPaymentPreview
        ) => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;
