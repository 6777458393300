import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import AvailableAddOnProductCard from '../availableAddOnProductCard/AvailableAddOnProductCard';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';

import { getAddOnProducts } from '@payaca/store/subscription/subscriptionActions';

import { PublicHydratedAddOnProduct } from '@payaca/types/add-on-products';

import './SelectAddOnProductsControl.sass';

const SelectAddOnProductsControl: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch();
  const [addOnProducts, setAddOnProducts] = useState<
    PublicHydratedAddOnProduct[]
  >([]);

  useEffect(() => {
    dispatch(
      getAddOnProducts.request({
        callback: setAddOnProducts,
      })
    );
  }, []);

  return (
    <div className="select-add-on-products-control">
      <h3>Available add-ons</h3>
      <ResponsiveViewWrapper
        className="add-on-products-list"
        downBreakpointXs={600}
        downBreakpointSm={900}
        upBreakpointLg={1400}
      >
        {addOnProducts.map((addOnProduct, index) => {
          return (
            <AvailableAddOnProductCard
              addOnProduct={addOnProduct}
              key={index}
            />
          );
        })}
        {/* TODO: ADDON - remove */}
        {import.meta.env.VITE_NODE_ENV === 'development' &&
          fakeAddOnProducts.map((addOnProduct, index) => {
            return (
              <AvailableAddOnProductCard
                addOnProduct={addOnProduct}
                key={index}
              />
            );
          })}
      </ResponsiveViewWrapper>
    </div>
  );
};

const fakeAddOnProducts: PublicHydratedAddOnProduct[] = [
  {
    publicId: 'xxx',
    name: 'test product 1',
    description: 'test product 1',
    prices: [
      {
        publicId: 'xxx',
        name: 'test price 1',
        description: 'test price 1',
        currencyCode: 'gbp',
        addOnProductPublicId: 'xxx',
        validFrom: new Date(),
        isHidden: false,
        billingInterval: 'month',
        billingIntervalCount: 1,
        billingScheme: 'per_unit',
        basicPrice: 0,
        tiers: null,
        //@ts-ignore
        unitAmount: 500,
      },
    ],
  },
  {
    publicId: 'xxx',
    name: 'test product 2',
    description: 'test product 2',
    prices: [
      {
        initialQuantity: 0,
        canUserSpecifyQuantity: false,
        publicId: 'xxx',
        name: 'test price 2',
        description: 'test price 2',
        currencyCode: 'gbp',
        addOnProductPublicId: 'xxx',
        validFrom: new Date(),
        isHidden: false,
        billingInterval: 'month',
        billingIntervalCount: 1,
        billingScheme: 'volume',
        basicPrice: 0,
        unitNounPlural: 'eggs',
        unitNounSingular: 'egg',
        tiers: [
          {
            description: 'test tier 1',
            unitAmount: 500,
            maxQty: 10,
            minQty: 0,
          },
          {
            description: 'test tier 2',
            unitAmount: 400,
            maxQty: 100,
            minQty: 11,
          },
          {
            description: 'test tier 3',
            unitAmount: 300,
            maxQty: null,
            minQty: 101,
          },
        ],
      },
    ],
  },
  {
    publicId: 'xxx',
    name: 'test product 3',
    description: 'test product 3',
    prices: [
      {
        initialQuantity: 0,
        canUserSpecifyQuantity: false,
        publicId: 'xxx',
        name: 'test price 3',
        description: 'test price 3',
        currencyCode: 'gbp',
        addOnProductPublicId: 'xxx',
        validFrom: new Date(),
        isHidden: false,
        billingInterval: 'month',
        billingIntervalCount: 1,
        billingScheme: 'graduated',
        basicPrice: 0,
        taxPercentage: 20,
        unitNounPlural: 'fishes',
        unitNounSingular: 'fish',
        tiers: [
          {
            description: 'test tier 4',
            flatAmount: 500,
            maxQty: 10,
            minQty: 0,
          },
          {
            description: 'test tier 5',
            flatAmount: 2000,
            maxQty: 100,
            minQty: 11,
          },
          {
            description: 'test tier 6',
            flatAmount: 5000,
            maxQty: null,
            minQty: 101,
          },
        ],
      },
    ],
  },
];

export default SelectAddOnProductsControl;
