export const calculateLineItemCalculatedPrice = (
  materialsCost: number,
  markupPercentage?: number | null,
  markupAmount?: number | null,
  price?: number | null
) => {
  if (typeof price === 'number') return price;
  if (typeof markupPercentage === 'number') {
    return materialsCost * (1 + markupPercentage / 100);
  }
  if (typeof markupAmount === 'number') {
    return materialsCost + markupAmount;
  }
  return 0;
};
