import { FC, useCallback, useMemo, useState } from 'react';
import UntitledIcon from '@payaca/untitled-icons';
import {
  EBtnColour,
  EBtnShapeVariant,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import { Popover, Transition } from '@headlessui/react';
import {
  offset,
  useDismiss,
  useFloating,
  useInteractions,
  autoUpdate,
  autoPlacement,
} from '@floating-ui/react';
import Button from '@payaca/components/plButton/Button';
import { useNotificationContext } from '../../contextProviders/NotificationContextProvider';
import useFetchNotifications, {
  useFetchUnreadNotificationCount,
} from '../../../../api/queries/notifications/useFetchNotifications';
import useMarkNotificationsAsRead from '../../../../api/mutations/notifications/useMarkNotificationsAsRead';
import ListedNotification, { TLinkedEntity } from './ListedNotification';
import Conditional from '@payaca/components/conditional/Conditional';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { useObserveIntersection } from '../../../../hooks/useObserveIntersection';

const paginationLimit = 20;

const HeaderNotificationsControl: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { unreadNotificationCount } = useNotificationContext();
  const {
    data,
    hasNextPage,
    fetchNextPage,
    refetch: refetchNotifications,
  } = useFetchNotifications({
    offset: 0,
    limit: paginationLimit,
  });
  const loaderRef = useObserveIntersection(
    () => hasNextPage && fetchNextPage()
  );

  const { refetch: refetchNotificationCount } =
    useFetchUnreadNotificationCount();
  const { mutate: markNotificationsAsReadMutation, isLoading } =
    useMarkNotificationsAsRead({
      onSuccess: () => {
        refetchNotifications();
        refetchNotificationCount();
      },
    });

  const notifications = useMemo(() => {
    return data?.pages.flatMap((page) => page.me.notifications.items) || [];
  }, [data]);

  const count = useMemo(() => {
    if (unreadNotificationCount > 99) {
      return '99+';
    }
    return unreadNotificationCount;
  }, [unreadNotificationCount]);

  const markAllNotificationsAsRead = useCallback(async () => {
    const notificationIds = notifications
      .filter((notification) => !notification.viewedAt)
      .map((notification) => notification.id);
    markNotificationsAsReadMutation(notificationIds);
  }, [notifications]);

  const { refs, floatingStyles, context } = useFloating({
    transform: false,
    whileElementsMounted: autoUpdate,
    middleware: [
      autoPlacement({
        allowedPlacements: ['bottom'],
      }),
      offset({
        mainAxis: 15,
      }),
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  return (
    <Popover>
      {({ open }: { open: boolean }) => (
        <>
          <Popover.Button
            as={Button}
            variant={EBtnVariant.Ghost}
            shape={EBtnShapeVariant.PILL}
            colour={EBtnColour.White}
            className="h-[40px] w-[40px] p-0"
            ref={refs.setReference}
            {...getReferenceProps()}
          >
            <UntitledIcon name="bell-01.3" className="text-blue-900 h-6 w-6" />
            {!!count && (
              <span className="flex absolute top-0 end-0 -mt-1.5 -me-1.5">
                <span className="animate-ping absolute inline-flex size-full rounded-full bg-red-400 opacity-75 dark:bg-red-600"></span>
                <span className="relative min-w-[18px] min-h-[18px] inline-flex justify-center items-center text-[10px] bg-cyan-700 text-white rounded-full px-1 leading-normal">
                  {count}
                </span>
              </span>
            )}
          </Popover.Button>

          <Transition
            show={open}
            appear
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Popover.Panel
              static
              className="bg-white sm:w-96 border-t border-gray-200 z-dropdown sm:border-t-0 sm:rounded-lg shadow-md sm:shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              ref={refs.setFloating}
              focus={true}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {/* header */}
              <div className="px-5 pt-3 flex justify-between items-center border-b border-gray-200 dark:border-neutral-800">
                <nav>
                  <Button
                    variant={EBtnVariant.Ghost}
                    colour={EBtnColour.Gray}
                    className="px-2.5 py-1.5 mb-2 relative inline-flex justify-center items-center gap-x-2 hover:bg-gray-100 text-gray-500 hover:text-gray-800 text-sm rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 after:absolute after:-bottom-2 after:inset-x-2.5 after:z-10 after:h-0.5 after:pointer-events-none"
                  >
                    All
                  </Button>
                </nav>
              </div>

              {/* content */}
              <div className="h-[480px] overflow-y-auto overflow-hidden [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                <ul className="p-0 divide-y divide-gray-200 dark:divide-neutral-800">
                  {notifications.map((notification) => (
                    <ListedNotification
                      key={notification.id}
                      markNotificationAsRead={() =>
                        markNotificationsAsReadMutation([notification.id])
                      }
                      {...notification}
                      linkedEntity={notification.linkedEntity as TLinkedEntity}
                    />
                  ))}
                  <Conditional condition={hasNextPage}>
                    <div ref={loaderRef}>
                      <SkeletonLoader.Notification />
                    </div>
                  </Conditional>
                </ul>
              </div>

              {/* footer */}
              <div className="cursor-pointer text-center border-t border-gray-200 dark:border-neutral-800">
                <Button
                  variant={EBtnVariant.LinkInline}
                  className="w-full !no-underline p-4"
                  onClick={() => markAllNotificationsAsRead()}
                >
                  Mark all as read
                </Button>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default HeaderNotificationsControl;
