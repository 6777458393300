import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import taskKeys from './keyFactory';

const GetTask = graphql(`
  query Task($taskId: ID!) {
    task(id: $taskId) {
      __typename
      ... on IEntity {
        id
      }
      createdBy {
        id
      }
      name
      deadline
      completionStatus
      assignee {
        id
        firstName
        lastName
        fullName
        colour
        email
        avatarUrl
      }
      project {
        id
        reference
      }
      events {
        id
        name
      }
      ... on LegacyFormTask {
        documentId
        formRevisions {
          id
        }
        formTemplate {
          id
        }
      }
      ... on FormTask {
        formRevisions {
          id
        }
        formTemplate {
          id
        }
      }
      ... on ChecklistTask {
        checklistItems {
          id
          name
        }
      }
    }
  }
`);

const useGetTask = (id?: number) => {
  return useQuery({
    enabled: !!id,
    queryKey: taskKeys.task(id!),
    queryFn: () => {
      return gqlClient.request(GetTask, {
        taskId: `${id}`,
      });
    },
  });
};

export default useGetTask;
