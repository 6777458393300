import {
  getMaterial,
  getMaterialsList,
  getMaterialsListMaterial,
  getMaterialsListMaterialsForMaterialsList,
  getRegion,
  getSupplierMaterialsForMaterial,
  getSuppliersForMaterial,
} from '@/utils/stateAccessors';
import CurrencyField from '@payaca/components/currencyField/CurrencyField';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import BasicField from '@payaca/components/basicField/BasicField';
import MaterialSelectionControl from '../materialSelectionControl/MaterialSelectionControl';
import PurchasableMaterialsListMaterialControl from '../purchasableMaterialsListMaterialControl/PurchasableMaterialsListMaterialControl';
import './CreateMaterialPurchaseRecordControl.sass';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { getPlaceholder } from '@/helpers/formHelper';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import { PersistMaterialsListMaterialRequestData } from '@payaca/store/materialsList/materialsListTypes';
import SupplierSelectionControl from '../supplierSelectionControl/SupplierSelectionControl';
import SalesTaxSettingsField from '../salesTaxSettingsField/SalesTaxSettingsField';
import { getPreferredSupplierMaterial } from '@payaca/helpers/supplierMaterialsHelper';
import { LabelStyleVariant } from '@payaca/components/fieldLabel/FieldLabel';
import {
  getRegionalDefaultTax,
  getRegionalTextString,
} from '@payaca/helpers/internationalHelper';
import MaterialPurchaseRecordSupplierSelectionControl from '../materialPurchaseRecordSupplierSelectionControl/MaterialPurchaseRecordSupplierSelectionControl';
import {
  getIsRequiredFieldValidator,
  getNumericalRangeFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { useDefaultTaxRate, useSupplier } from '@payaca/store/hooks/appState';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import { useSelector } from '@/api/state';

const isRequiredFieldValidator = getIsRequiredFieldValidator();
const positiveNumberFieldValidator = getNumericalRangeFieldValidator(
  0,
  undefined,
  'quantity'
);

type Props = {
  materialsListMaterialId: number;
  onCreateMaterialPurchaseRecordSuccess?: (
    materialPurchaseRecordId: number
  ) => void;
};

const CreateMaterialPurchaseRecordControl: FunctionComponent<Props> = ({
  materialsListMaterialId,
  onCreateMaterialPurchaseRecordSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);

  const materialsListMaterial = useSelector((state) =>
    getMaterialsListMaterial(state, materialsListMaterialId)
  );

  const supplierMaterials = useSelector((state) =>
    materialsListMaterial
      ? getSupplierMaterialsForMaterial(
          state,
          materialsListMaterial?.materialId
        )
      : []
  );

  const material = useSelector((state) =>
    materialsListMaterial
      ? getMaterial(state, materialsListMaterial?.materialId)
      : undefined
  );

  const preferredSupplierMaterial = useMemo(() => {
    return getPreferredSupplierMaterial(
      supplierMaterials,
      material?.preferredSupplierId
    );
  }, [supplierMaterials, material]);

  const preferredSupplier = useSupplier(preferredSupplierMaterial?.supplierId);

  const region = useSelector(getRegion);

  const initialRegionalSalesTax = useMemo(() => {
    return getRegionalDefaultTax(region);
  }, [region]);

  const initialFormState = useMemo(() => {
    let materialQuantity = 0;

    if (materialsListMaterial) {
      materialQuantity = Math.max(
        0,
        materialsListMaterial.outstandingMaterialQuantity -
          materialsListMaterial.purchaseIntendedMaterialQuantity
      );
    }

    return {
      materialsListId: materialsListMaterial?.materialsListId,
      materialQuantity: materialQuantity,
      materialsListMaterialId: materialsListMaterial?.id,
      supplierId: preferredSupplierMaterial?.supplierId,
      price: preferredSupplierMaterial?.price,
      isTaxIncluded: preferredSupplierMaterial
        ? preferredSupplierMaterial.isTaxIncluded
        : true,
      taxPercentage: preferredSupplierMaterial
        ? preferredSupplierMaterial.taxPercentage
        : initialRegionalSalesTax,
      taxRateId: preferredSupplierMaterial?.taxRateId,
    };
  }, [
    materialsListMaterial,
    preferredSupplierMaterial,
    initialRegionalSalesTax,
  ]);

  const fieldValidators = useMemo(() => {
    return {
      materialQuantity: [
        isRequiredFieldValidator,
        positiveNumberFieldValidator,
      ],
    };
  }, []);

  useEffect(() => {
    setIsProcessing(false);
    setIsRevealed(false);
  }, [materialsListMaterial?.materialPurchaseRecordIds?.length]);

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      setIsProcessing(true);
      dispatch(
        materialsListActions.requestPersistMaterialPurchaseRecord(
          formState as PersistMaterialsListMaterialRequestData,
          (materialPurchaseRecordId) => {
            onCreateMaterialPurchaseRecordSuccess &&
              onCreateMaterialPurchaseRecordSuccess(materialPurchaseRecordId);
          }
        )
      );
    },
    [onCreateMaterialPurchaseRecordSuccess]
  );

  const preferredSupplierCostElement = useMemo(() => {
    if (!preferredSupplierMaterial) return;

    return (
      <span>
        Available from <strong>{preferredSupplier?.name}</strong>
        {preferredSupplierMaterial?.price !== null && (
          <>
            {' '}
            for{' '}
            <strong>
              {currencyPrice(preferredSupplierMaterial.price, region)}
            </strong>{' '}
            per unit
            {preferredSupplierMaterial.isTaxIncluded && (
              <>
                {' '}
                <strong>+ tax</strong>
              </>
            )}
            {preferredSupplierMaterial.url && (
              <>
                {' '}
                - Ref:{' '}
                <a
                  href={preferredSupplierMaterial.url}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />{' '}
                  {preferredSupplierMaterial.reference}
                </a>
              </>
            )}
            {!preferredSupplierMaterial.url &&
              preferredSupplierMaterial?.reference && (
                <> - Ref: {preferredSupplierMaterial?.reference}</>
              )}
          </>
        )}{' '}
      </span>
    );
  }, [preferredSupplier, preferredSupplierMaterial]);

  const moreRequiredElement = useMemo(() => {
    if (!materialsListMaterial) return;

    const outstandingQty = Math.max(
      0,
      materialsListMaterial.outstandingMaterialQuantity -
        materialsListMaterial.purchaseIntendedMaterialQuantity
    );

    if (outstandingQty === materialsListMaterial.materialQuantity) return;

    return <small>{outstandingQty} more required</small>;
  }, [materialsListMaterial]);

  const defaultTaxRate = useDefaultTaxRate();

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      const supplierMaterial = supplierMaterials.find(
        (x) => x.supplierId === formState.supplierId
      );

      return (
        <div className="form-body">
          {materialsListMaterial?.materialId && (
            <div className="supplier-selection-container">
              <MaterialPurchaseRecordSupplierSelectionControl
                materialId={materialsListMaterial.materialId}
                label="Supplier"
                labelStyleVariant={LabelStyleVariant.LIGHT}
                selectedSupplierId={formState.supplierId}
                onChange={(selectedSupplierId) => {
                  const sm = supplierMaterials.find(
                    (x) => x.supplierId === selectedSupplierId
                  );
                  const updateObject: { [key: string]: any } = {
                    supplierId: selectedSupplierId,
                  };

                  if (sm) {
                    updateObject.price = sm.price;
                    updateObject.taxPercentage = sm.taxPercentage;
                    updateObject.isTaxIncluded = sm.isTaxIncluded;
                    updateObject.taxRateId = sm.taxRateId;
                  }

                  if (!selectedSupplierId) {
                    updateObject.price = null;
                    updateObject.taxPercentage =
                      defaultTaxRate?.percentage ?? 0;
                    updateObject.isTaxIncluded =
                      defaultTaxRate?.isIncluded ?? false;
                    updateObject.taxRateId = defaultTaxRate?.id ?? null;
                  }
                  onFieldChange(updateObject);
                }}
                enableSupplierCreation={true}
              />
            </div>
          )}

          <div className="price-field-container">
            <CurrencyField
              label="Unit price"
              name="price"
              value={formState.price || ''}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              styleVariant={InputStyleVariant.STANDARD}
              labelStyleVariant={LabelStyleVariant.LIGHT}
            />
          </div>
          <div className="tax-field-container">
            <SalesTaxSettingsField
              excludeReverseChargeOptions={true}
              styleVariant={InputStyleVariant.OUTSIZE}
              taxRateId={formState.taxRateId}
              onChange={(value: { [key: string]: any }) => {
                onFieldChange({
                  taxRateId: value.taxRateId,
                });
              }}
              labelStyleVariant={LabelStyleVariant.LIGHT}
            />
          </div>
          <div className="quantity-field-container">
            <BasicField
              label="Qty"
              name="materialQuantity"
              value={formState.materialQuantity || ''}
              onChange={(data) =>
                onFieldChange({
                  materialQuantity: Number(data.materialQuantity),
                })
              }
              onTouch={onFieldTouch}
              type={'number'}
              additionalInputProps={{ placeholder: 'Qty' }}
              styleVariant={InputStyleVariant.STANDARD}
              labelStyleVariant={LabelStyleVariant.LIGHT}
              hideSpinnerArrows={true}
            />
          </div>
          <div className="supplier-reference-container">
            <span className="label">Supplier ref</span>
            <span className="value">
              {supplierMaterial?.url && (
                <a
                  href={supplierMaterial.url}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  {supplierMaterial.reference || '—'}
                </a>
              )}
              {!supplierMaterial?.url &&
                `${supplierMaterial?.reference || '—'}`}
            </span>
          </div>

          <div className="button-container">
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              onClick={() => !isProcessing && onSubmit(formState)}
              isDisabled={!isValid}
              isProcessing={isProcessing}
            >
              <span>Record purchase</span>
            </Button>
          </div>
        </div>
      );
    },
    [onSubmit, isProcessing]
  );

  return (
    <ResponsiveViewWrapper
      className="create-material-purchase-record-control"
      downBreakpointSm={650}
      downBreakpointXs={500}
      upBreakpointLg={1000}
    >
      <>
        {!isRevealed && (
          <div className="pre-disclose-information-container">
            <div className="supplier-information-container">
              {preferredSupplierCostElement}
              {supplierMaterials.length > 1 && (
                <small>
                  Also available from {supplierMaterials.length - 1} other{' '}
                  {supplierMaterials.length - 1 === 1
                    ? 'supplier'
                    : 'suppliers'}
                </small>
              )}
            </div>
            <div className="disclose-action-container">
              <Button
                onClick={() => setIsRevealed(true)}
                styleVariant={ButtonStyleVariant.ANCHOR}
              >
                Record purchase
              </Button>
              {moreRequiredElement}
            </div>
          </div>
        )}
        {isRevealed && (
          <ValidatedForm<{ [key: string]: any }>
            fieldValidators={fieldValidators}
            renderFormContents={renderFormContents}
            initialFormState={initialFormState}
          />
        )}
      </>
    </ResponsiveViewWrapper>
  );
};

export default CreateMaterialPurchaseRecordControl;
