import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import isEqual from 'lodash.isequal';

import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import BasicField from '@payaca/components/basicField/BasicField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import TextareaField from '@payaca/components/textareaField/TextareaField';
import SelectItemModal from '@/ui/components/selectItemModal/SelectItemModal';
import { ConfirmModal } from '@/ui/components';

import {
  LineItemGroup,
  LineItemGroupLineItem,
} from '@payaca/types/lineItemGroupTypes';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getModal } from '@/helpers/modalHelper';
import {
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';

import { usePrevious } from '@/utils/customHooks';

import * as lineItemGroupActions from '@payaca/store/lineItemGroups/lineItemGroupsActions';
import * as lineItemV2Actions from '@payaca/store/lineItemsV2/lineItemsActions';

import './CreateEditLineItemGroupControl.sass';
import BodyWithSidePanelContentWrapper from '../bodyWithSidePanelContentWrapper/BodyWithSidePanelContentWrapper';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import {
  getLineItemGroup,
  getLineItemsByLineItemIds,
} from '@/utils/stateAccessors';
import { calculateLineItemGroupTotals } from '@payaca/helpers/lineItemGroupTotalsHelper';
import LineItemGroupLineItemControl from '../lineItemGroupLineItemControl/LineItemGroupLineItemControl';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import CreateArea from '@payaca/components/clickableArea/CreateArea';
import { useSelector } from '@/api/state';

interface IGroupItem {
  isMultipleChoice: boolean;
  isSelected: boolean;
  isOptional: boolean;
  [key: string]: any;
}

type Props = {
  lineItemGroupId: number;
};
const CreateEditLineItemControl: FunctionComponent<Props> = ({
  lineItemGroupId,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formState, setFormState] = useState<{ [key: string]: any }>({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [
    requiresSelectedMultiSelectItemIfNone,
    setRequiresSelectedMultiSelectItemIfNone,
  ] = useState(false);

  const [showItemSearch, setShowItemSearch] = useState<boolean>(false);
  const lineItemGroup = useSelector((state) =>
    getLineItemGroup(state, lineItemGroupId)
  );
  const currentLineItemGroup = useSelector(
    (state) => state.lineItemGroups.lineItemGroups[lineItemGroupId]
  );
  const isUpdatingLineItemGroup = useSelector(
    (state) => state.lineItemGroups.isUpdatingLineItemGroup
  );
  const isArchivingLineItemGroup = useSelector(
    (state) => state.lineItemGroups.isArchivingLineItemGroup
  );
  const archiveLineItemGroupErrorMessage = useSelector(
    (state) => state.lineItemGroups.archiveLineItemGroupErrorMessage
  );
  const isArchiveLineItemGroupSuccessful = useSelector(
    (state) => state.lineItemGroups.isArchiveLineItemGroupSuccessful
  );
  const isUpdateLineItemGroupSuccessful = useSelector(
    (state) => state.lineItemGroups.isUpdateLineItemGroupSuccessful
  );
  const updateLineItemGroupErrorMessage = useSelector(
    (state) => state.lineItemGroups.updateLineItemGroupErrorMessage
  );
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  const lineItems = useSelector((state) => {
    const lineItemIds =
      formState?.lineItemRelations?.map(
        (lineItemRelation: any) => lineItemRelation.lineItemId
      ) || [];
    return getLineItemsByLineItemIds(state, lineItemIds);
  });
  const currentLineItemGroupEntity = useMemo(
    () => currentLineItemGroup?.entity || null,
    [currentLineItemGroup]
  );

  const previousIsUpdatingLineItemGroup: any = usePrevious(
    isUpdatingLineItemGroup
  );

  useEffect(() => {
    if (
      !updateLineItemGroupErrorMessage &&
      previousIsUpdatingLineItemGroup &&
      !isUpdatingLineItemGroup &&
      isUpdateLineItemGroupSuccessful
    ) {
      // item group has successfully saved
      history.push('/items/groups');
    }
  }, [
    previousIsUpdatingLineItemGroup,
    isUpdatingLineItemGroup,
    updateLineItemGroupErrorMessage,
    isUpdateLineItemGroupSuccessful,
  ]);

  const [showDeleteGroupModal, setShowDeleteGroupModal] =
    useState<boolean>(false);

  const isFetchingLineItemGroup = useMemo(
    () =>
      lineItemGroupId && currentLineItemGroup
        ? currentLineItemGroup.isFetching
        : true,
    [currentLineItemGroup]
  );

  const getLineItemFromLineItemId = useCallback(
    (lineItemId: number) => lineItems[lineItemId],
    [lineItems]
  );

  useEffect(() => {
    if (lineItemGroupId) {
      dispatch(lineItemGroupActions.requestGetLineItemGroup(lineItemGroupId));
      dispatch(
        lineItemV2Actions.requestGetLineItemsForLineItemGroup(lineItemGroupId)
      );
    }
    return () => {
      dispatch(lineItemGroupActions.clearUpdateLineItemGroup());
      dispatch(lineItemGroupActions.clearArchiveLineItemGroup());
    };
  }, [lineItemGroupId]);

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      if (!lineItemGroupId) {
        return;
      }
      dispatch(
        lineItemGroupActions.requestUpdateLineItemGroup(lineItemGroupId, {
          id: lineItemGroupId,
          description: formState.description,
          name: formState.name,
          lineItemRelations: formState.lineItemRelations,
        })
      );
    },
    [dispatch, lineItemGroupId]
  );

  const initialFormState = useMemo(() => {
    return {
      id: currentLineItemGroupEntity?.id,
      name: currentLineItemGroupEntity?.name,
      description: currentLineItemGroupEntity?.description,
      lineItemRelations: currentLineItemGroupEntity?.lineItemRelations || [],
    };
  }, [currentLineItemGroupEntity]);

  const fieldValidators = useMemo(() => {
    return {
      name: [
        getIsRequiredFieldValidator(),
        getLengthFieldValidator({ min: 0, max: 255 }),
      ],
    };
  }, []);

  const handleMultiChoiceItemDeselect = (
    item: IGroupItem,
    formState: any,
    index: number
  ) => {
    // number of current multi choices - must always be 1
    const selectedMultiChoiceItemCount = formState.lineItemRelations.filter(
      (i: IGroupItem) => i.isMultipleChoice && i.isSelected
    ).length;

    if (
      formState.lineItemRelations[index].isMultipleChoice &&
      formState.lineItemRelations[index].isSelected &&
      !item.isMultipleChoice &&
      selectedMultiChoiceItemCount === 1
    ) {
      // Removing current item which is selected and multi choice from being multi choice - select another multi choice
      const nextMultiChoiceIndex = formState.lineItemRelations.findIndex(
        (i: IGroupItem, itemIndex: number) =>
          itemIndex !== index && i.isMultipleChoice
      );
      if (nextMultiChoiceIndex !== -1) {
        formState.lineItemRelations[nextMultiChoiceIndex] = {
          ...formState.lineItemRelations[nextMultiChoiceIndex],
          isSelected: true,
        };
      }
    } else if (
      item.isMultipleChoice &&
      item.isSelected &&
      selectedMultiChoiceItemCount === 1
    ) {
      // Setting current item to selected multi choice - unselect previously selected item
      const currentSelectedMultiChoiceIndex =
        formState.lineItemRelations.findIndex(
          (i: IGroupItem, itemIndex: number) =>
            itemIndex !== index && i.isMultipleChoice && i.isSelected
        );
      if (currentSelectedMultiChoiceIndex !== -1) {
        formState.lineItemRelations[currentSelectedMultiChoiceIndex] = {
          ...formState.lineItemRelations[currentSelectedMultiChoiceIndex],
          isSelected: false,
        };
      }
    }
    return formState;
  };

  const archiveLineItemGroup = useCallback(() => {
    dispatch(lineItemGroupActions.requestArchiveLineItemGroup(lineItemGroupId));
  }, [dispatch]);

  useEffect(() => {
    if (isArchiveLineItemGroupSuccessful && !archiveLineItemGroupErrorMessage) {
      // successfully archive line item group - return to line item groups page
      history.push('/items/groups');
    }
  }, [isArchiveLineItemGroupSuccessful, archiveLineItemGroupErrorMessage]);

  const deselectMultiSelectItemsOnItemSelect = useCallback(
    (
      formState: { [key: string]: any },
      updateValue: { [key: string]: any },
      lineItemRelation: LineItemGroupLineItem,
      index: number
    ) => {
      const newUpdateValue = updateValue;

      if (
        (lineItemRelation.isMultipleChoice ||
          newUpdateValue[`lineItemRelations[${index}].isMultipleChoice`]) &&
        newUpdateValue[`lineItemRelations[${index}].isSelected`]
      ) {
        formState.lineItemRelations.forEach(
          (lineItemRelation: LineItemGroupLineItem, liIndex: number) => {
            if (liIndex !== index && lineItemRelation.isMultipleChoice) {
              newUpdateValue[`lineItemRelations[${liIndex}].isSelected`] =
                false;
            }
          }
        );
      }

      return newUpdateValue;
    },
    []
  );

  const setSelectedMultiSelectItemIfNone = useCallback(
    (formState: { [key: string]: any }) => {
      if (!formState?.lineItemRelations) return;

      const multiSelectItems = formState.lineItemRelations.filter(
        (x: LineItemGroupLineItem) => x.isMultipleChoice
      );

      if (!multiSelectItems.length) return;

      const hasSelectedMultiSelectItem = multiSelectItems.find(
        (x: LineItemGroupLineItem) => x.isMultipleChoice && x.isSelected
      );

      if (hasSelectedMultiSelectItem) return;

      const itemToSelect = multiSelectItems.at(-1);
      const itemToSelectIndex = formState.lineItemRelations.findIndex(
        (x: LineItemGroupLineItem) => x.id === itemToSelect.id
      );
      const updateValue = {
        [`lineItemRelations[${itemToSelectIndex}].isSelected`]: true,
      };
      return updateValue;
    },
    []
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      setFormState(formState);

      const hasChangesMade = !isEqual(formState, initialFormState);
      setIsSaveDisabled(
        !isValid ||
          isFetchingLineItemGroup ||
          !formState.lineItemRelations?.length
      );

      if (requiresSelectedMultiSelectItemIfNone) {
        const updateValue = setSelectedMultiSelectItemIfNone(formState);
        if (updateValue) onFieldChange(updateValue);
        setRequiresSelectedMultiSelectItemIfNone(false);
      }

      return (
        <Fragment>
          {/* Item group name and description */}
          <div className="scrollable-content">
            <ValidatedFieldWrapper
              validationResult={validationState['name']}
              isTouched={touchedState['name'] || false}
            >
              <BasicField
                label="Group name"
                isRequired={true}
                styleVariant={InputStyleVariant.OUTSIZE}
                description="Your internal group name. This will not be seen by your customers."
                value={formState.name || ''}
                name="name"
                onChange={onFieldChange}
                onTouch={onFieldTouch}
              />
            </ValidatedFieldWrapper>
            <ValidatedFieldWrapper
              validationResult={validationState['description']}
              isTouched={touchedState['description'] || false}
            >
              <TextareaField
                label="Description"
                styleVariant={InputStyleVariant.OUTSIZE}
                description="Description for your customers. This will be shown on your estimates, quotes and invoices."
                value={formState.description || ''}
                name="description"
                onChange={onFieldChange}
                onTouch={onFieldTouch}
                rows={3}
              />
            </ValidatedFieldWrapper>

            {!!formState.lineItemRelations?.length && (
              <div className="line-item-controls-container">
                {formState.lineItemRelations.map(
                  (lineItemRelation: LineItemGroupLineItem, index: number) => {
                    return (
                      <LineItemGroupLineItemControl
                        key={`line-item-group-line-item-control-${index}`}
                        lineItemGroupLineItem={lineItemRelation}
                        fieldNamePrefix={`lineItemRelations[${index}]`}
                        onTouch={onFieldTouch}
                        onChange={(value: { [key: string]: any }) => {
                          const updateObject =
                            deselectMultiSelectItemsOnItemSelect(
                              formState,
                              value,
                              lineItemRelation,
                              index
                            );
                          onFieldChange(updateObject);
                        }}
                        onDelete={() => {
                          formState.lineItemRelations.splice(index, 1);
                          onFieldChange({
                            lineItemRelations: formState.lineItemRelations,
                          });
                          setRequiresSelectedMultiSelectItemIfNone(true);
                        }}
                      />
                    );
                  }
                )}
              </div>
            )}
          </div>
          {/* Add item to group button */}
          <div className="add-item-button-container">
            <CreateArea
              onClick={() => {
                setShowItemSearch(true);
              }}
            >
              Add item to group
            </CreateArea>
          </div>

          {/* Select items modal */}
          <SelectItemModal
            isOpen={showItemSearch}
            onClose={() => setShowItemSearch(false)}
            onSelectItem={(itemId: number) => {
              onFieldChange({
                lineItemRelations: [
                  ...formState.lineItemRelations,
                  {
                    lineItemId: itemId,
                    isMultipleChoice: false,
                    isSelected: false,
                    isOptional: false,
                    quantity: 1,
                  },
                ],
              });
              // requestGetLineItem
              dispatch(lineItemV2Actions.requestGetLineItem(itemId));
              // close modal
              setShowItemSearch(false);
            }}
            disabledItemIds={[]}
          />
          <Prompt
            // only show prompt when there are changes made and hasnt finished updating or archiving
            when={
              hasChangesMade &&
              !isUpdateLineItemGroupSuccessful &&
              !isArchiveLineItemGroupSuccessful
            }
            message="There are unsaved changes on the page, are you sure you want to leave?"
          />
        </Fragment>
      );
    },
    [
      requiresSelectedMultiSelectItemIfNone,
      archiveLineItemGroupErrorMessage,
      currentLineItemGroupEntity,
      getLineItemFromLineItemId,
      handleMultiChoiceItemDeselect,
      initialFormState,
      isArchivingLineItemGroup,
      isArchiveLineItemGroupSuccessful,
      isFetchingLineItemGroup,
      isUpdateLineItemGroupSuccessful,
      isUpdatingLineItemGroup,
      onSubmit,
      showItemSearch,
      updateLineItemGroupErrorMessage,
    ]
  );

  const lineItemGroupTotals = useMemo(() => {
    if (!lineItemGroup) return;

    return calculateLineItemGroupTotals(formState as LineItemGroup, lineItems);
  }, [formState, lineItems]);

  const sidebarContent = useMemo(() => {
    return (
      <div className="sidebar-content-outer">
        <div className="save-button-container">
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            isDisabled={isSaveDisabled}
            onClick={() => !isUpdatingLineItemGroup && onSubmit(formState)}
            isProcessing={isUpdatingLineItemGroup}
          >
            Save
          </Button>
        </div>

        <div className="sidebar-content">
          <LabelValuePair
            label="Items added"
            value={formState?.lineItemRelations?.length || 0}
            suffixLabelWith={''}
          />
          {lineItemGroupTotals && (
            <>
              <hr />
              <LabelValuePair
                label={`Total (inc tax)`}
                value={currencyPrice(lineItemGroupTotals.total, account.region)}
                suffixLabelWith={''}
              />
              {/* TODO: Only show these if has optional/multi choice items */}
              {lineItemGroupTotals.hasAnyNonRequiredItems && (
                <>
                  <LabelValuePair
                    label={`Minimum total (inc tax)`}
                    value={currencyPrice(
                      lineItemGroupTotals.minTotal,
                      account.region
                    )}
                    suffixLabelWith={''}
                  />
                  <LabelValuePair
                    label={`Maximum total (inc tax)`}
                    value={currencyPrice(
                      lineItemGroupTotals.maxTotal,
                      account.region
                    )}
                    suffixLabelWith={''}
                  />
                </>
              )}
            </>
          )}
          <hr />
          <div className="delete-button-container">
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              isDisabled={isFetchingLineItemGroup} // and does current line item group exist??
              onClick={() => setShowDeleteGroupModal(true)}
              isProcessing={isArchivingLineItemGroup}
            >
              Delete
            </Button>
          </div>
        </div>
        {(archiveLineItemGroupErrorMessage ||
          updateLineItemGroupErrorMessage) && (
          <ErrorMessage
            message={`
            Sorry, there was a problem
            ${
              archiveLineItemGroupErrorMessage ? 'deleting' : 'saving'
            } your Item
            Group. Please try again.`}
          />
        )}
      </div>
    );
  }, [
    formState,
    lineItemGroupTotals,
    isSaveDisabled,
    isUpdatingLineItemGroup,
    isFetchingLineItemGroup,
    isArchivingLineItemGroup,
    archiveLineItemGroupErrorMessage,
    updateLineItemGroupErrorMessage,
    account,
  ]);

  return (
    <ResponsiveViewWrapper
      className="create-edit-line-item-group-control"
      downBreakpointSm={850}
    >
      <BodyWithSidePanelContentWrapper
        title={lineItemGroupId ? 'Edit Item Group' : 'Create new Item Group'}
        sidebarContent={sidebarContent}
      >
        <ContentPanel>
          <ValidatedForm<{ [key: string]: any }>
            fieldValidators={fieldValidators}
            initialFormState={initialFormState}
            renderFormContents={renderFormContents}
          />
        </ContentPanel>
      </BodyWithSidePanelContentWrapper>
      <ConfirmModal
        {...getModal('DELETE_ITEM_GROUP')}
        onClose={() => {
          setShowDeleteGroupModal(false);
        }}
        secondaryAction={() => {
          setShowDeleteGroupModal(false);
        }}
        primaryAction={() => {
          archiveLineItemGroup();
          setShowDeleteGroupModal(false);
        }}
        open={showDeleteGroupModal}
      />
    </ResponsiveViewWrapper>
  );
};

export default CreateEditLineItemControl;
