// IMPORTANT!
// The following import is required to make FullCalendar work with Vite.
// It triggers side effects that are required for FullCalendar's initialisation.
// These side effects MUST occur before any FullCalendar plugins are imported.
// Vite's HMR does not guarantee the loading order of modules,
// so we add this import at the top of our app's entry point.
import '@fullcalendar/react/dist/vdom';

import './utils/sentryInit';

import '@payaca/styles/tailwind.input.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './polyfills';

import '@payaca/styles';
import App from './App';
import { store } from './api';
import * as api from './services/api';

import './i18n';

const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;

api.setStore(store);

if (
  import.meta.env.VITE_ENV !== 'staging' &&
  import.meta.env.VITE_ENV !== 'development'
) {
  console.log = () => null;
}

ReactDOM.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <App />
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

// N.B. There are no export barrels for these packages.
// You should import from the specific file you require.
// Here we specify 'index' explicitly as it's the only file
// in the packages' example content.
import { foo } from '@payaca/shared-isomorphic/index';
import { baz } from '@payaca/shared-web/index';
