import React, { FC, useCallback, useMemo } from 'react';

import { Tag, TaggableEntityType } from '@payaca/types/tagTypes';
import TagControl, {
  TagControlAlignment,
} from '@payaca/components/tagControl/TagControl';

import useAddTagsToEntity from '@/api/mutations/tag/useAddTagsToEntity';
import useRemoveTagsFromEntity from '@/api/mutations/tag/useRemoveTagsFromEntity';
import useGetMyAvailableTags from '@/api/queries/me/useGetMyAvailableTags';

type Props = {
  entityId: number;
  entityType: TaggableEntityType;
  entityTags: Tag[];
  onEntityTagsChange: () => void;
  isReadOnly?: boolean;
  alignment?: TagControlAlignment;
};
const EntityTagControl: FC<Props> = ({
  entityId,
  entityType,
  entityTags,
  onEntityTagsChange,
  isReadOnly = false,
  alignment,
}: Props): JSX.Element => {
  const { mutateAsync: addTagsToEntityMutation, isLoading: isAddingTag } =
    useAddTagsToEntity();
  const {
    mutateAsync: removeTagsFromEntityMutation,
    isLoading: isRemovingTag,
  } = useRemoveTagsFromEntity();
  const { data: allAvailableTagsOnAccount } = useGetMyAvailableTags();

  const availableTags = useMemo(() => {
    return (
      // Filter out tags that are already on the entity
      allAvailableTagsOnAccount?.filter((accountTag) => {
        return !entityTags.find((entityTag) => entityTag.id === +accountTag.id);
      }) || []
    );
  }, [entityTags, allAvailableTagsOnAccount]);

  const handleAddExistingTag = useCallback(
    async (tagId: number) => {
      addTagsToEntityMutation({
        entity: {
          id: entityId.toString(),
          type: entityType,
        },
        tags: [{ tagId: tagId.toString() }],
      }).finally(() => onEntityTagsChange());
    },
    [entityId, entityType, onEntityTagsChange]
  );

  const handleRemoveTag = useCallback(
    async (tagId: number) => {
      removeTagsFromEntityMutation({
        entity: {
          id: entityId.toString(),
          type: entityType,
        },
        tagIds: [tagId.toString()],
      }).finally(() => onEntityTagsChange());
    },
    [entityId, entityType, onEntityTagsChange]
  );

  return (
    <div className="entity-tag-control">
      <TagControl
        alignment={alignment}
        tags={entityTags}
        availableTags={availableTags.map((i) => ({
          id: +i.id,
          tagText: i.name,
          tagColour: i.colour || '',
          isSystemManaged: false,
        }))}
        isReadOnly={isReadOnly}
        isAddingTag={isAddingTag}
        isRemovingTag={isRemovingTag}
        onAddAvailableTag={handleAddExistingTag}
        onRemoveTag={handleRemoveTag}
      />
    </div>
  );
};

export default EntityTagControl;
