import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  useMaterialsList,
  useMaterialsListMaterialsForMaterialsList,
} from '@payaca/store/hooks/appState';
import Button from '@payaca/components/button/Button';
import CreatePurchaseOrderModal from '../createPurchaseOrderModal/CreatePurchaseOrderModal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import PurchaseOrderCard from '../purchaseOrderCard/PurchaseOrderCard';

import './ManageableMaterialsListPurchaseOrders.sass';
import { Deal } from '@payaca/types/dealTypes';

type Props = {
  projectId?: Deal['id'];
  materialsListId: number;
  onCreatePurchaseOrderSuccess?: () => void;
  navigateToPurchaseOrder?: (purchaseOrderId: number) => void;
};

const ManageableMaterialsListPurchaseOrders: FunctionComponent<Props> = ({
  projectId,
  materialsListId,
  onCreatePurchaseOrderSuccess,
  navigateToPurchaseOrder,
}: Props): JSX.Element | null => {
  const [showCreatePurchaseOrderModal, setShowCreatePurchaseOrderModal] =
    useState(false);

  const materialsList = useMaterialsList(materialsListId);

  const canCreatePurchaseOrder = useMemo(() => {
    if (!materialsList) return false;
    if (materialsList.isFullyPurchased) return false;
    if (materialsList.isFullyPurchaseIntended) return false;
    return true;
  }, [materialsList?.isFullyPurchased, materialsList?.isFullyPurchaseIntended]);

  if (!materialsList) return null;

  if (!canCreatePurchaseOrder && !materialsList.purchaseOrderIds?.length)
    return null;

  return (
    <div className="manageable-materials-list-purchase-orders">
      <>
        {!!materialsList.purchaseOrderIds?.length && (
          <ul className="purchase-orders-list">
            {materialsList.purchaseOrderIds.map((purchaseOrderId, i) => {
              return (
                <li key={`purchase-order-tile-${i}`}>
                  <PurchaseOrderCard
                    purchaseOrderId={purchaseOrderId}
                    onClick={() =>
                      navigateToPurchaseOrder &&
                      navigateToPurchaseOrder(purchaseOrderId)
                    }
                  />
                </li>
              );
            })}
          </ul>
        )}
        {canCreatePurchaseOrder && (
          <PermissionGuard
            renderIfHasPermissions={[
              MaterialsListPermissions.PERSIST_PURCHASE_ORDER,
            ]}
          >
            <Button onClick={() => setShowCreatePurchaseOrderModal(true)}>
              Create a Purchase Order
            </Button>
          </PermissionGuard>
        )}
        <CreatePurchaseOrderModal
          projectId={projectId}
          materialsListId={materialsListId}
          isOpen={showCreatePurchaseOrderModal}
          onClose={() => setShowCreatePurchaseOrderModal(false)}
          onCreatePurchaseOrderSuccess={() => {
            setShowCreatePurchaseOrderModal(false);
            onCreatePurchaseOrderSuccess && onCreatePurchaseOrderSuccess();
          }}
        />
      </>
    </div>
  );
};

export default ManageableMaterialsListPurchaseOrders;
