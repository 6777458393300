import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import values from 'lodash.values';

import FileUploadPersistRemoveControl from '@payaca/components/fileUploadPersistRemoveControl/FileUploadPersistRemoveControl';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';

import * as jobsActions from '@payaca/store/jobs/jobsActions';

import { actions as jobActions } from '@/api/jobs';

import {
  FileSizeLimit,
  FileType,
  FileUploadType,
  getMbFileLimit,
} from '@/helpers/fileHelper';

import { JobAttachment } from '@payaca/types/jobTypes';

import './JobAttachmentsControl.sass';
import { useSelector } from '@/api/state';

const fileSizeLimit = FileSizeLimit[FileUploadType.JOB_ATTACHMENT];
const mbFileLimit = getMbFileLimit(fileSizeLimit);

type Props = {
  jobId: number;
};

const JobAttachmentsControl: FunctionComponent<Props> = ({
  jobId,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [isFileSizeLimitExceeded, setIsFileSizeLimitExceeded] = useState(false);
  const [isFileNameLengthExceeded, setIsFileNameLengthExceeded] =
    useState(false);

  const job = useSelector((state) => {
    return state.jobsStore.jobs && state.jobsStore.jobs[jobId]?.entity;
  });

  const jobAttachments: JobAttachment[] = useSelector((state) => {
    if (!jobId) return [];
    const job = state.jobsStore.jobs && state.jobsStore.jobs[jobId]?.entity;
    const jobAttachmentIds = job?.jobAttachmentIds || [];

    const jobAttachments = jobAttachmentIds.map((jobAttachmentId: number) => {
      return state.jobsStore.jobAttachments[jobAttachmentId]?.entity;
    });

    return jobAttachments.filter(
      (x?: JobAttachment) => !!x
    ) as Array<JobAttachment>;
  });

  const onAttachmentUploadRemoveSuccess = useCallback(() => {
    dispatch(jobsActions.requestGetJob(jobId));
    dispatch(jobsActions.requestGetJobAttachmentsForJob(jobId));
  }, [dispatch, jobId]);

  const uploadAttachmentToJob = useCallback(
    async (attachmentToAdd: { file: File; fileName: string }) => {
      return new Promise<void>((resolve, reject) => {
        setIsFileSizeLimitExceeded(false);
        if (attachmentToAdd.file.size > fileSizeLimit) {
          setIsFileSizeLimitExceeded(true);
          Sentry.captureMessage(
            `File upload attempt of ${attachmentToAdd.file.size} for job attachment exceeds limit of ${fileSizeLimit}`,
            'info'
          );
          resolve();
        } else {
          dispatch(
            jobActions.addAttachmentToJob(
              jobId,
              attachmentToAdd,
              (addJobAttachmentError: any) => {
                if (!addJobAttachmentError) {
                  onAttachmentUploadRemoveSuccess();
                }
                resolve();
              }
            )
          );
        }
      });
    },
    [dispatch, jobId, onAttachmentUploadRemoveSuccess]
  );

  const removeAttachmentFromJob = useCallback(
    async (attachmentIdToRemove: any) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(
          jobActions.removeAttachmentFromJob(
            jobId,
            attachmentIdToRemove,
            (removeAttachmentError: any) => {
              if (!removeAttachmentError) {
                onAttachmentUploadRemoveSuccess();
                resolve();
              } else {
                reject();
              }
            }
          )
        );
      });
    },
    [dispatch, jobId, onAttachmentUploadRemoveSuccess]
  );

  const updateJobAttachment = useCallback(
    async (attachmentId: string, fileName: string) => {
      return new Promise<void>((resolve, reject) => {
        setIsFileNameLengthExceeded(false);

        if (fileName.length < 1 || fileName.length > 150) {
          setIsFileNameLengthExceeded(true);
        } else {
          dispatch(
            jobsActions.requestUpdateJobAttachment(
              {
                jobId,
                jobAttachmentId: attachmentId,
                fileName: fileName,
              },
              () => {
                dispatch(jobsActions.requestGetJobAttachmentsForJob(jobId));
                resolve();
              }
            )
          );
        }
      });
    },
    [dispatch, jobId, onAttachmentUploadRemoveSuccess]
  );

  const validationResult = useMemo(() => {
    const errors: string[] = [];

    if (isFileSizeLimitExceeded)
      errors.push(
        `One or more of you selected files exceeds the limit of ${mbFileLimit}`
      );

    if (isFileNameLengthExceeded)
      errors.push(`File names must be between 1 and 150 characters`);

    return {
      isValid: !errors.length,
      errors: errors,
    };
  }, [isFileSizeLimitExceeded, isFileNameLengthExceeded]);

  return (
    <div className="job-attachments-control">
      <ValidatedFieldWrapper
        isTouched={true}
        validationResult={validationResult}
      >
        <FileUploadPersistRemoveControl
          persistedFiles={
            jobAttachments
              ? jobAttachments.map((attachment) => {
                  return {
                    fileName: attachment.fileName,
                    identifier: attachment.id,
                  };
                })
              : []
          }
          persistFile={(file: File) => {
            return uploadAttachmentToJob({
              file,
              fileName: file.name.split('.')[0],
            });
          }}
          removePersistedFile={(attachmentId: number) => {
            return removeAttachmentFromJob(attachmentId);
          }}
          persistFileName={(attachmentId: any, fileName: string) => {
            return updateJobAttachment(attachmentId, fileName);
          }}
          allowFileNameModification={true}
          allowMultipleUploads={true}
          acceptFileTypes={values(FileType).map((v) => v.name[0])}
        />
      </ValidatedFieldWrapper>
    </div>
  );
};

export default JobAttachmentsControl;
