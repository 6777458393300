import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Deal } from '@payaca/types/dealTypes';
import ListedTasks, {
  ListedTasksTTask,
  ListedTasksPaginationInput,
} from '../listedTasks/ListedTasks';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';
import { useDeal } from '@payaca/store/hooks/appState';
import CreateEditTaskModal from '../createEditTaskModal/CreateEditTaskModal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';
import TaskReadCompleteDrawer from '@/ui/components/taskReadCompleteDrawer/TaskReadCompleteDrawer';
import LinkButton from '@payaca/components/plButton/LinkButton';
import { GetTasksInput } from '../../../gql/graphql';
import useGetProjectTasks from '../../../api/queries/project/useGetProjectTasks';
import {
  getTasksCompletionStatusesLocalStorageKey,
  getTasksEventRelationshipStatusesLocalStorageKey,
  getTasksTypesLocalStorageKey,
  getTasksUserAssignmentKeyLocalStorageKey,
} from '../../../helpers/localStorageKeyHelper';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { useSelector } from '../../../api/state';
import { getUserRoles } from '../../../utils/stateAccessors';

const tasksUserAssignmentKeyLocalStorageKey =
  getTasksUserAssignmentKeyLocalStorageKey();
const tasksCompletionStatusesLocalStorageKey =
  getTasksCompletionStatusesLocalStorageKey();
const tasksEventRelationshipStatusesLocalStorageKey =
  getTasksEventRelationshipStatusesLocalStorageKey();
const tasksTypesLocalStorageKey = getTasksTypesLocalStorageKey();

export const DealTasks: FC<{
  dealId: Deal['id'];
  onDealUpdateSuccess: () => void;
}> = ({ dealId, onDealUpdateSuccess }) => {
  const deal = useDeal();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const userRoles = useSelector(getUserRoles);

  const isAddingOrEditingTask = useRouteMatch<{
    taskId: string;
  }>({
    path: `${path}/:taskId`,
    exact: true,
  });
  const initialUserAssignments = userHasRequiredPermission(userRoles, [
    TasksPermissions.GET_TASKS,
  ])
    ? localStorage
        .getItem(tasksUserAssignmentKeyLocalStorageKey)
        ?.split(',')
        .filter((x) => !!x) || []
    : [];
  const initialCompletionStatuses = localStorage
    .getItem(tasksCompletionStatusesLocalStorageKey)
    ?.split(',')
    .filter((x) => x != '') || ['to do', 'in progress'];
  const initialEventRelationshipStatuses =
    localStorage
      .getItem(tasksEventRelationshipStatusesLocalStorageKey)
      ?.split(',')
      .filter((x) => x != '') || [];
  const initialTypes = localStorage
    .getItem(tasksTypesLocalStorageKey)
    ?.split(',')
    .filter((x) => x != '');

  const [getTasksPagination, setGetTasksPagination] =
    useState<ListedTasksPaginationInput>({
      limit: 100,
      offset: 0,
    });
  const [getTasksInput, setGetTasksInput] = useState<GetTasksInput>({
    searchTerm: '',
    assignedTo: initialUserAssignments,
    completionStatuses: initialCompletionStatuses,
    eventRelationshipStatuses: initialEventRelationshipStatuses,
    sortBy: 'deadlineDate',
    sortDirection: 'asc',
    types: initialTypes,
  });

  const onUpdateInputData = (inputData: GetTasksInput) => {
    if ('assignedTo' in inputData) {
      localStorage.setItem(
        tasksUserAssignmentKeyLocalStorageKey,
        inputData.assignedTo?.join(',') || ''
      );
    }
    if ('completionStatuses' in inputData) {
      localStorage.setItem(
        tasksCompletionStatusesLocalStorageKey,
        inputData.completionStatuses?.join(',') || ''
      );
    }
    if ('eventRelationshipStatuses' in inputData) {
      localStorage.setItem(
        tasksEventRelationshipStatusesLocalStorageKey,
        inputData.eventRelationshipStatuses?.join(',') || ''
      );
    }
    if ('types' in inputData) {
      localStorage.setItem(
        tasksTypesLocalStorageKey,
        inputData.types?.join(',') || ''
      );
    }
    setGetTasksInput((x) => ({
      ...x,
      ...inputData,
    }));
  };
  const {
    data: tasksData,
    isLoading: isLoadingTasks,
    refetch: refetchTasks,
  } = useGetProjectTasks(dealId, getTasksInput, getTasksPagination);

  const [isTaskPersisted, setIsTaskPersisted] = useState(false);

  useEffect(() => {
    refetchTasks();
  }, [getTasksInput, getTasksPagination]);

  const listedTasks = useMemo(() => {
    return tasksData?.project.tasks.items
      .map((task) => {
        const t = {
          id: task.id,
          name: task.name,
          deadlineDate: task.deadline,
          createdByUserId: task?.createdBy?.id,
          events:
            task?.events.map((event) => ({
              id: event.id,
              name: event.name,
            })) || [],
          assignee: task.assignee
            ? {
                id: task.assignee.id,
                firstName: task.assignee.firstName,
                lastName: task.assignee.lastName,
                email: task.assignee.email,
                colour: task.assignee.colour,
              }
            : undefined,
          completionStatus: task.completionStatus,
        };
        switch (task.__typename) {
          case 'ChecklistTask': {
            return {
              ...t,
              __typename: task.__typename,
            };
          }
          case 'FormTask': {
            return {
              ...t,
              __typename: task.__typename,
            };
          }
          case 'LegacyFormTask': {
            return {
              ...t,
              __typename: task.__typename,
            };
          }
        }
      })
      .filter((t) => !!t) as ListedTasksTTask[];
  }, [tasksData?.project.tasks.items]);

  const headerContent = useMemo(() => {
    return {
      heading: 'Tasks',
      subHeading: '',
      buttons: (
        <>
          {!deal?.archivedAt && (
            <PermissionGuard
              renderIfHasPermissions={[
                TasksPermissions.PERSIST_SELF_ASSIGNED_DEAL_TASK,
                TasksPermissions.PERSIST_TASK,
              ]}
            >
              <LinkButton to={`${url}/new`} size={EBtnSize.Small}>
                Create Task
              </LinkButton>
            </PermissionGuard>
          )}
        </>
      ),
    };
  }, [deal?.archivedAt]);

  return (
    <div>
      <ListedTasks
        getTasksInput={getTasksInput}
        setGetTasksInput={onUpdateInputData}
        getTasksPagination={getTasksPagination}
        setGetTasksPagination={setGetTasksPagination}
        listedTasks={listedTasks}
        isLoadingTasks={isLoadingTasks}
        onPersistTaskSuccess={refetchTasks}
        hideProjectAndCustomerColumn
        totalCount={tasksData?.project.tasks.totalCount || 0}
        headerContent={headerContent}
      />
      <CreateEditTaskModal
        dealId={dealId}
        isOpen={isAddingOrEditingTask?.params.taskId === 'new'}
        onClose={() => {
          history.push(url);
        }}
        onPersistTaskSuccess={() => {
          history.push(url);
          onDealUpdateSuccess();
          refetchTasks();
        }}
      />
      <TaskReadCompleteDrawer
        isOpen={
          isAddingOrEditingTask
            ? isAddingOrEditingTask?.params.taskId !== 'new'
            : false
        }
        onClose={() => {
          history.push(url);
          if (isTaskPersisted) {
            refetchTasks();
            onDealUpdateSuccess();
            setIsTaskPersisted(false);
          }
        }}
        taskId={
          isAddingOrEditingTask?.params.taskId
            ? parseInt(isAddingOrEditingTask.params.taskId)
            : undefined
        }
        onUpdateTaskSuccess={() => setIsTaskPersisted(true)}
        onDeleteTaskSuccess={() => {
          refetchTasks();
          onDealUpdateSuccess();
        }}
      />
    </div>
  );
};
