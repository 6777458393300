import React, {
  FunctionComponent,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import SubscriptionPaymentMethodControl from '../subscriptionPaymentMethodControl/SubscriptionPaymentMethodControl';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import * as subscriptionActions from '@payaca/store/subscription/subscriptionActions';
import { actions as userActions } from '@/api/users';
import SelectedProductOverview from '../selectedProductOverview/SelectedProductOverview';
import { getSubscriptionCostIncludingVat } from '@payaca/helpers/subscriptionHelper';
import { useHistory } from 'react-router-dom';

import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import SubscriptionPaymentPreview from '../subscriptionPaymentPreview/SubscriptionPaymentPreview';
import UserSeatsExceededWarning from '../userSeatsExceededWarning/UserSeatsExceededWarning';
import SubscriptionControlHeader from '../subscriptionControlHeader/SubscriptionControlHeader';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import {
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';
import { User } from '@payaca/types/userTypes';

import './UpdateSubscriptionControl.sass';
import { getRegionalPayacaVATPercentage } from '@payaca/helpers/vatHelper';
import { getCurrencyIsoCode } from '@payaca/helpers/internationalHelper';
import { getRegion } from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY;

const stripePromise = loadStripe(STRIPE_KEY || '');

type Props = {
  onUpdateSubscriptionSuccess?: (additionalUserSeats: number) => void;
  stripeProductId: string;
  stripePriceId: string;
  recurringInterval?: RecurringInterval;
  additionalUserSeats?: number;
};

const UpdateSubscriptionControl: FunctionComponent<Props> = ({
  onUpdateSubscriptionSuccess,
  stripeProductId,
  stripePriceId,
  recurringInterval = RecurringInterval.YEAR,
  additionalUserSeats,
}: Props): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [updatePaymentMethod, setUpdatePaymentMethod] = useState(false);
  const [isCreatingPaymentMethod, setIsCreatingPaymentMethod] = useState(false);
  const [
    initialPaymentPreviewInitialised,
    setInitialPaymentPreviewInitialised,
  ] = useState(false);
  const isUpdatingSubscription = useSelector(
    (state) =>
      state.subscription.isUpdatingSubscription ||
      state.subscription.isCreatingSubscription
  );
  const accountSubscription = useSelector(
    (state) => state.subscription.accountSubscription
  );
  const subscriptionPaymentPreview = useSelector(
    (state) => state.subscription.subscriptionPaymentPreview
  );
  const isGettingSubscriptionPaymentPreview = useSelector(
    (state) => state.subscription.isGettingSubscriptionPaymentPreview
  );
  const region = useSelector(getRegion);

  const bonusAdditionalUserSeats = useMemo(() => {
    if (!accountSubscription?.subscriptionInformation) return 0;
    return accountSubscription.subscriptionInformation.bonusAdditionalUserSeats;
  }, [accountSubscription]);

  const isSubscriptionUpdatedSuccessfully = useSelector(
    (state) =>
      state.subscription.isSubscriptionUpdatedSuccessfully ||
      state.subscription.isSubscriptionCreatedSuccessfully
  );
  const products = useSelector((state) => state.subscription.products);
  const product = useMemo(
    () =>
      products?.find(
        (x: SubscriptionProduct) => x.stripeId === stripeProductId
      ),
    [products, stripeProductId]
  );

  const productPrice = useMemo(() => {
    if (recurringInterval === 'month') {
      return product!.monthlyPrice; // FIXME
    } else {
      return product!.annualPrice; // FIXME
    }
  }, [product, recurringInterval]);

  const canBuyAdditionalUserSeats = useMemo(() => {
    return !!productPrice?.canBuyAdditionalUserSeats;
  }, [productPrice]);

  const monthlyAdditionalUserSeatCost = useMemo(() => {
    if (recurringInterval === 'month') {
      return productPrice?.additionalUserSeatCost;
    } else {
      return productPrice?.additionalUserSeatCost
        ? productPrice.additionalUserSeatCost / 12
        : undefined;
    }
  }, [productPrice, recurringInterval]);

  const activeUsers = useSelector((state: any) => {
    return state.users.accountUsers.filter(
      (accountUser: User) =>
        !accountUser.isDemo &&
        (!accountUser.deactivatedAt || accountUser.inviteToken)
    );
  });

  const isProcessing = useMemo(() => {
    return isUpdatingSubscription || isCreatingPaymentMethod;
  }, [isUpdatingSubscription, isCreatingPaymentMethod]);

  const requiredAdditionalUserSeats = useMemo(() => {
    return Math.max(
      (activeUsers.length || 0) -
        bonusAdditionalUserSeats -
        (product?.numberOfUserSeats ?? 0),
      0
    );
  }, [activeUsers, bonusAdditionalUserSeats]);

  const initialFormState = useMemo(() => {
    return {
      recurringInterval: recurringInterval,
      productId: stripeProductId,
      priceId: stripePriceId,
      additionalUserSeats: canBuyAdditionalUserSeats
        ? additionalUserSeats === undefined
          ? requiredAdditionalUserSeats
          : additionalUserSeats
        : 0,
      subscriptionId:
        accountSubscription?.subscriptionInformation?.stripeSubscriptionId,
      paymentMethodId:
        accountSubscription?.subscriptionInformation?.stripePaymentMethodId,
    };
  }, [
    accountSubscription,
    canBuyAdditionalUserSeats,
    stripeProductId,
    stripePriceId,
    recurringInterval,
    additionalUserSeats,
    requiredAdditionalUserSeats,
  ]);

  const paymentMethodDescription = useMemo(() => {
    if (!accountSubscription) return null;

    const subscriptionInformation = accountSubscription.subscriptionInformation;

    return `${subscriptionInformation.stripePaymentMethodBrand}   •••• ${subscriptionInformation.stripePaymentMethodLast4}   ${subscriptionInformation.stripePaymentMethodExpiryMonth}/${subscriptionInformation.stripePaymentMethodExpiryYear}`;
  }, [accountSubscription]);

  const [numPurchasedAdditionalUserSeats, setNumPurchasedAdditionalUserSeats] =
    useState(0);

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      setNumPurchasedAdditionalUserSeats(formState.additionalUserSeats ?? 0);
      if (formState.subscriptionId) {
        const updateSubscriptionRequestData = {
          stripePriceId: formState.priceId,
          stripePaymentMethodId: formState.paymentMethodId,
          stripeSubscriptionId: formState.subscriptionId,
          additionalUserSeats: formState.additionalUserSeats,
          analytics: {
            price: productPrice?.basicCost || 0,
            currency: getCurrencyIsoCode(region),
            productName: product!.name, // FIXME
          },
        };

        dispatch(
          subscriptionActions.requestUpdateSubscription(
            updateSubscriptionRequestData
          )
        );
      } else {
        const createSubscriptionRequestData = {
          stripePriceId: formState.priceId,
          stripePaymentMethodId: formState.paymentMethodId,
          additionalUserSeats: formState.additionalUserSeats,
          analytics: {
            price: productPrice?.basicCost || 0,
            currency: getCurrencyIsoCode(region),
            productName: product!.name, // FIXME
          },
        };

        dispatch(
          subscriptionActions.requestCreateSubscription(
            createSubscriptionRequestData
          )
        );
      }

      setIsSubmitted(true);
    },
    [
      dispatch,
      productPrice,
      region,
      product,
      setNumPurchasedAdditionalUserSeats,
    ]
  );

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  const taxRatePercentage = useMemo(
    () => getRegionalPayacaVATPercentage(account.region),
    [account]
  );

  const calculateCostIncludingVat = useCallback(
    (
      productId: string,
      recurringInterval: RecurringInterval,
      additionalUserSeats: number
    ) => {
      const product = products?.find(
        (x: SubscriptionProduct) => x.stripeId === productId
      );

      if (!product) return;
      return getSubscriptionCostIncludingVat(
        product,
        recurringInterval,
        additionalUserSeats,
        taxRatePercentage
      );
    },
    [products, taxRatePercentage]
  );

  useEffect(() => {
    if (
      isSubmitted &&
      !isUpdatingSubscription &&
      isSubscriptionUpdatedSuccessfully
    ) {
      onUpdateSubscriptionSuccess?.(numPurchasedAdditionalUserSeats);
    }
  }, [
    isSubmitted,
    isUpdatingSubscription,
    isSubscriptionUpdatedSuccessfully,
    onUpdateSubscriptionSuccess,
  ]);

  useEffect(() => {
    dispatch(userActions.getAccountUsers());
    dispatch(subscriptionActions.clearSubscriptionPaymentPreview());

    return () => {
      dispatch(subscriptionActions.clearSubscriptionPaymentPreview());
    };
  }, []);

  const subscriptionPaymentPreviewElement = useMemo(() => {
    return (
      <SubscriptionPaymentPreview
        subscriptionPaymentPreview={subscriptionPaymentPreview! /* FIXME */}
        isLoading={isGettingSubscriptionPaymentPreview}
      />
    );
  }, [subscriptionPaymentPreview, isGettingSubscriptionPaymentPreview]);

  const updateSubscriptionPaymentPreview = useCallback(
    (
      stripeSubscriptionId: string,
      stripePriceId: string,
      additionalUserSeats: number
    ) => {
      dispatch(
        subscriptionActions.requestGetSubscriptionPaymentPreview({
          stripeSubscriptionId: stripeSubscriptionId,
          stripePriceId: stripePriceId,
          additionalUserSeats: additionalUserSeats,
        })
      );
    },
    [dispatch, subscriptionPaymentPreview]
  );

  useEffect(() => {
    if (initialFormState?.subscriptionId && !initialPaymentPreviewInitialised) {
      updateSubscriptionPaymentPreview(
        initialFormState.subscriptionId,
        initialFormState.priceId,
        initialFormState.additionalUserSeats
      );
      // we only want this initial preview to generate onComponentMount
      // there is a separate update flow for formState changes
      setInitialPaymentPreviewInitialised(true);
    }
  }, [initialFormState, updateSubscriptionPaymentPreview]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      const selectedUserSeats = product
        ? product.numberOfUserSeats + formState.additionalUserSeats
        : undefined;

      const activeUsersExceedsSelectedUserSeats =
        selectedUserSeats + bonusAdditionalUserSeats <
        (activeUsers?.length || 0);

      const getSubscriptionPaymentMethodControl = (buttonCopy: string) => (
        <Elements stripe={stripePromise}>
          <SubscriptionPaymentMethodControl
            paymentAmount={calculateCostIncludingVat(
              formState.productId,
              formState.recurringInterval,
              formState.additionalUserSeats
            )}
            onPaymentMethodChange={(paymentMethod) => {
              setIsCreatingPaymentMethod(false);
              if (paymentMethod) {
                onSubmit({
                  ...formState,
                  paymentMethodId: paymentMethod?.id,
                });
              }
            }}
            accountRegion={account.region}
            renderActions={(
              handleCreatePaymentMethod: () => void,
              applePayButton?: JSX.Element
            ) => {
              return (
                <React.Fragment>
                  {applePayButton}
                  <Button
                    onClick={
                      !isProcessing
                        ? () => {
                            setIsCreatingPaymentMethod(true);
                            handleCreatePaymentMethod();
                          }
                        : undefined
                    }
                    isProcessing={isProcessing}
                    styleVariant={ButtonStyleVariant.OUTSIZE}
                  >
                    {buttonCopy}
                  </Button>
                </React.Fragment>
              );
            }}
          />
        </Elements>
      );

      return (
        <React.Fragment>
          <SubscriptionControlHeader
            selectedProduct={product! /* FIXME */}
            recurringInterval={formState.recurringInterval}
          />
          <div className="form-section">
            {formState.productId && (
              <div className="selected-product-overview-container">
                <SelectedProductOverview
                  additionalUserSeats={formState.additionalUserSeats}
                  recurringInterval={formState.recurringInterval}
                  taxRatePercentage={taxRatePercentage}
                  selectedProduct={product! /* FIXME */}
                  selectedPrice={productPrice! /* FIXME */}
                  enableEmbeddedUserSeatControl={true}
                  onChangeAdditionalUserSeats={(
                    additionalUserSeats: number
                  ) => {
                    formState.subscriptionId &&
                      updateSubscriptionPaymentPreview(
                        formState.subscriptionId,
                        formState.priceId,
                        additionalUserSeats
                      );
                    onFieldChange({
                      additionalUserSeats: additionalUserSeats,
                    });
                  }}
                />
              </div>
            )}
            {subscriptionPaymentPreviewElement}
          </div>

          {activeUsersExceedsSelectedUserSeats && (
            <div className="form-section">
              <UserSeatsExceededWarning
                selectedUserSeats={selectedUserSeats}
                bonusAdditionalUserSeats={bonusAdditionalUserSeats}
                activeUsersCount={activeUsers.length}
              />
            </div>
          )}
          {!activeUsersExceedsSelectedUserSeats && formState.productId && (
            <div className="form-section">
              {!formState.subscriptionId && (
                <>
                  <h3 className="form-section-header">
                    Provide a payment method
                  </h3>
                  <div className="add-payment-method-container">
                    {getSubscriptionPaymentMethodControl('Create subscription')}
                  </div>
                </>
              )}
              {!!formState.subscriptionId && (
                <div className="review-update-payment-method-container">
                  <h3 className="form-section-header">
                    Review your payment method
                  </h3>
                  {!updatePaymentMethod && (
                    <div className="review-payment-method-container">
                      <p>
                        Would you like to continue using your current payment
                        method
                        <span>
                          <strong>{paymentMethodDescription}</strong> ?
                        </span>
                      </p>
                      <div className="actions-container">
                        <Button
                          styleVariant={ButtonStyleVariant.OUTSIZE}
                          onClick={() => setUpdatePaymentMethod(true)}
                        >
                          Change payment method
                        </Button>
                        <Button
                          styleVariant={ButtonStyleVariant.OUTSIZE}
                          isProcessing={isProcessing}
                          onClick={
                            !isProcessing
                              ? () => onSubmit(formState)
                              : undefined
                          }
                        >
                          Update subscription
                        </Button>
                      </div>
                    </div>
                  )}
                  {updatePaymentMethod && (
                    <div className="update-payment-method-container">
                      {getSubscriptionPaymentMethodControl(
                        'Update subscription'
                      )}
                    </div>
                  )}
                </div>
              )}
              {isSubscriptionUpdatedSuccessfully === false && isSubmitted && (
                <p className="error-message failed-update-error-message">
                  Failed to {formState.subscriptionId ? 'update' : 'create'}{' '}
                  your subscription. Please check that the payment method you
                  have provided is correct.
                </p>
              )}
            </div>
          )}
        </React.Fragment>
      );
    },
    [
      monthlyAdditionalUserSeatCost,
      product,
      bonusAdditionalUserSeats,
      history,
      activeUsers,
      isSubmitted,
      isProcessing,
      updatePaymentMethod,
      paymentMethodDescription,
      isSubscriptionUpdatedSuccessfully,
      subscriptionPaymentPreviewElement,
      onSubmit,
      calculateCostIncludingVat,
      updateSubscriptionPaymentPreview,
    ]
  );

  return (
    <div className="update-subscription-control">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
      />
    </div>
  );
};

export default UpdateSubscriptionControl;
