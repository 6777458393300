import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import get from 'lodash.get';
import { useDispatch } from 'react-redux';

import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import AddBacsModal from '../addBacsModal/AddBacsModal';

import { actions as appActions } from '@/api/app';
import { actions as userActions } from '@/api/users';

import { getModal } from '@/helpers/modalHelper';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

import { getUserRoles } from '@/utils/stateAccessors';

import './PaymentOptionsControl.sass';
import { useSelector } from '@/api/state';

type Props = {
  onChange: any;
  showBACSPaymentOption: boolean;
  showStripePaymentOption: boolean;
};

const PaymentOptionsControl: FC<Props> = ({
  onChange,
  showBACSPaymentOption,
  showStripePaymentOption,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [showAddBacsDetails, setShowAddBacsDetails] = useState(false);
  const userRoles = useSelector(getUserRoles);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const isConnectedToStripe = useMemo(
    () => account && account.connectedToStripe,
    [account]
  );

  const hasAddedBankInformation = useMemo(
    () =>
      account &&
      account.accountNameRaw &&
      account.accountNumberRaw &&
      account.sortCodeRaw,
    [account]
  );

  const interval = useRef<any>(null);

  useEffect(() => {
    return () => clearInterval(interval.current);
  }, []);

  const setCheckStripeStatusInterval = () => {
    clearInterval(interval.current);
    interval.current = setInterval(() => checkStripeStatus(), 3000);
  };

  const checkStripeStatus = () => {
    dispatch(
      userActions.getProfile((err: any, response: any) => {
        const connectedToStripe = get(response, [
          'accounts',
          0,
          'connectedToStripe',
        ]);

        if (connectedToStripe) {
          onChange({
            showStripePaymentOption: true,
          });
          clearInterval(interval.current);
        }
      })
    );
  };

  const checkBacsDetails = useCallback(() => {
    if (hasAddedBankInformation) {
      onChange({
        showBACSPaymentOption: true,
      });
    }
  }, [hasAddedBankInformation]);

  const stripeLabel = useMemo(() => {
    return (
      <div className="stripe-label">
        <span>Card payment - Stripe</span>
        <Tooltip text="Enables your customer to make online card payments directly into your Stripe account. Fees of 1.4% +20p apply for Visa, Mastercard and other European cards. Volume discounts may be available." />
      </div>
    );
  }, []);

  const onBankDetailsChange = useCallback(
    (showBACSPaymentOptionValue: boolean) => {
      if (hasAddedBankInformation) {
        onChange({
          showBACSPaymentOption: showBACSPaymentOptionValue,
        });
      } else {
        setShowAddBacsDetails(true);
      }
    },
    [hasAddedBankInformation, onChange]
  );

  const onStripeChange = useCallback(
    (showStripePaymentOptionValue: boolean) => {
      if (isConnectedToStripe) {
        onChange({
          showStripePaymentOption: showStripePaymentOptionValue,
        });
      } else {
        setCheckStripeStatusInterval();
        dispatch(
          appActions.showModal(
            getModal('ACCEPT_PAYMENTS_WITH_STRIPE', {
              onClose: () => dispatch(appActions.hideModal()),
              tertiaryAction: () => dispatch(appActions.hideModal()),
            })
          )
        );
      }
    },
    [isConnectedToStripe, onChange, dispatch]
  );

  return (
    <div className="payment-options-control">
      <FieldLabel label="Payment options" />
      <div className="payment-checkbox-options-wrapper">
        <CheckboxField
          name="stripe"
          label={stripeLabel}
          onChange={(value: { [key: string]: any }) =>
            onStripeChange(value.stripe)
          }
          value={!!showStripePaymentOption}
          isDisabled={
            !isConnectedToStripe &&
            !userHasRequiredPermission(userRoles, [
              AccountsPermissions.ADD_CONNECTION,
            ])
          }
        />

        <CheckboxField
          name="bankDetails"
          label={
            <div className="bacs-label">
              <span>Bank details</span>
              <Tooltip text="Displays your bank details to enable a client to make a bank transfer." />
            </div>
          }
          onChange={(value: { [key: string]: any }) =>
            onBankDetailsChange(value.bankDetails)
          }
          value={!!showBACSPaymentOption}
          isDisabled={
            !hasAddedBankInformation &&
            !userHasRequiredPermission(userRoles, [
              AccountsPermissions.UPDATE_ACCOUNT,
            ])
          }
        />
        <AddBacsModal
          isOpen={showAddBacsDetails}
          onClose={() => {
            setShowAddBacsDetails(false);
            checkBacsDetails();
          }}
        />
      </div>
    </div>
  );
};

export default PaymentOptionsControl;
