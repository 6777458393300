import React, { FunctionComponent, useRef, useState } from 'react';

import SelectSubscriptionControl from '../selectSubscriptionControl/SelectSubscriptionControl';

import {
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';
import { Nullish } from '@payaca/utilities/types';

import './CreateSubscriptionPageContent.sass';

type Props = {
  onCreateSubscriptionSuccess?: (info: {
    subscriptionProduct: Nullish<SubscriptionProduct>;
    recurringInterval: RecurringInterval;
    additionalUserSeats: number;
  }) => void;
};

const CreateSubscriptionPageContent: FunctionComponent<Props> = ({
  onCreateSubscriptionSuccess,
}: Props): JSX.Element => {
  const [isSubscriptionPlansFocused, setIsSubscriptionPlansFocused] =
    useState(false);

  const subscriptionPlansContainerElementRef = useRef<HTMLDivElement>(null);

  return (
    <div className={`create-subscription-page-content`}>
      <div
        ref={subscriptionPlansContainerElementRef}
        className={`subscription-plans-container-element ${
          isSubscriptionPlansFocused ? 'focused' : ''
        }`}
      >
        <SelectSubscriptionControl
          onUpdateSubscriptionSuccess={onCreateSubscriptionSuccess}
        />
      </div>
    </div>
  );
};

export default CreateSubscriptionPageContent;
