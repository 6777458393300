import { FC, useContext, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import moment, { Moment } from 'moment-timezone';
import omit from 'lodash.omit';

import DropdownField from '@payaca/components/dropdownField/DropdownField';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import TimeField from '@payaca/components/timeField/TimeField';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';

import { useFormState } from '../../../hooks/useFormState';
import { useAccount } from '../../../utils/storeHooks';

import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import { actions as usersActions } from '@/api/users';

import { BusinessHours, Days } from '@payaca/types/scheduleTypes';
import {
  defaultBusinessHours,
  getBusinessHoursStartEndTimes,
} from '@payaca/helpers/scheduleHelper';

import './CompanySettingsSchedule.sass';
import { ScheduledEventColourCoding } from '@payaca/types/scheduledEventsTypes';
import { capitaliseFirstLetter } from '@payaca/utilities/stringUtilities';
import { Account } from '@payaca/types/accountTypes';
import Card from '@payaca/components/plCard/Card';
import { ScheduledEventRemindersConfigForm } from './ScheduledEventRemindersConfigForm';

type FormState = {
  businessHours: BusinessHours;
  scheduledEventColourCoding: Account['scheduledEventColourCoding'];
};

type FormValidationResult = Partial<
  Record<keyof FormState, FieldValidationResult>
>;

type Props = {
  readOnly: boolean;
};
const CompanySettingsSchedule: FC<Props> = ({
  readOnly,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const account = useAccount();
  const clearDynamicFeedbackMessage = useRef<() => void>();
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);

  const initialState: FormState = {
    businessHours: account.businessHours || defaultBusinessHours,
    scheduledEventColourCoding: account.scheduledEventColourCoding,
  };

  const saveFormData = useMemo(
    () => async (state: FormState) => {
      clearDynamicFeedbackMessage.current?.();
      return new Promise<void>((resolve, reject) => {
        dispatch(
          usersActions.updateBusinessAccount(
            account.id,
            state,
            (err: unknown, response: any) => {
              if (err) {
                clearDynamicFeedbackMessage.current =
                  showDynamicFeedbackMessage({
                    title: `We couldn't save your changes`,
                    isCancellable: true,
                    feedbackLevel: FeedbackLevel.ERROR,
                  });
                reject(err);
              } else {
                clearDynamicFeedbackMessage.current =
                  showDynamicFeedbackMessage({
                    title: 'Your changes have been saved',
                    lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
                    feedbackLevel: FeedbackLevel.SUCCESS,
                  });
                resolve();
              }
            }
          )
        );
      });
    },
    []
  );

  const { formState, updateFormFields, formValidationResult } = useFormState<
    FormState,
    FormValidationResult
  >(
    initialState,
    {},
    {
      autoSaveFn: saveFormData,
    }
  );

  const { businessHoursStart, businessHoursEnd } = useMemo(() => {
    return getBusinessHoursStartEndTimes(formState.businessHours);
  }, [
    initialState /** required to trigger change when saving hours */,
    formState.businessHours,
  ]);

  const dateWithoutTime = new Date().toJSON().slice(0, 10);

  const updateBusinessHours = (time: Moment, isStartTime: boolean) => {
    // update start/end time of selected business days
    const updatedBusinessHours = Object.keys(formState.businessHours).reduce(
      (acc: any, curr) => {
        if (isStartTime) {
          let startTime = time;
          let endTime;
          const latestStartTime = moment(`${dateWithoutTime} 23:00`);
          if (moment(startTime).isAfter(latestStartTime)) {
            // trying to update start time to after 11pm - fix times
            startTime = latestStartTime;
            endTime = moment(`${dateWithoutTime} 23:59`);
          } else if (
            moment(startTime).isSameOrAfter(moment(acc[curr].end, 'HH:mm'))
          ) {
            // trying to update start time to same or after end time - update end time
            endTime = moment(startTime).add(1, 'hours');
          }

          acc[curr].start = moment(startTime).format('HH:mm');
          if (endTime) {
            acc[curr].end = moment(endTime).format('HH:mm');
          }
        } else {
          let endTime = time;
          let startTime;
          // updating end time
          const earliestEndTime = moment(`${dateWithoutTime} 01:00`);
          if (moment(endTime).isBefore(earliestEndTime)) {
            // trying to update end time to before 1am - fix times
            endTime = earliestEndTime;
            startTime = moment(`${dateWithoutTime} 00:00`);
          } else if (
            moment(endTime).isSameOrBefore(moment(acc[curr].start, 'HH:mm'))
          ) {
            // trying to update end time to before or after start time - update start time
            startTime = moment(endTime).subtract(1, 'hours');
          }
          acc[curr].end = moment(endTime).format('HH:mm');
          if (startTime) {
            acc[curr].start = moment(startTime).format('HH:mm');
          }
        }
        return acc;
      },
      formState.businessHours
    );

    updateFormFields({
      businessHours: updatedBusinessHours,
    });
  };

  const renderWorkingDays = useMemo(() => {
    return Object.values(Days).map((day, i) => {
      const dayBusinessHours = formState.businessHours[day as Days];
      const isDaySelected = !!(
        dayBusinessHours &&
        dayBusinessHours.start === businessHoursStart &&
        dayBusinessHours.end === businessHoursEnd
      );
      return (
        <DaySelection
          dayName={day}
          isSelected={isDaySelected}
          key={i}
          isReadOnly={readOnly}
          onClick={() => {
            if (isDaySelected) {
              // remove day from business hours
              updateFormFields({
                businessHours: omit(formState.businessHours, day),
              });
            } else {
              // add day to business hours
              const updatedBusinessHours = {
                ...formState.businessHours,
                [day]: {
                  start: businessHoursStart,
                  end: businessHoursEnd,
                },
              };
              updateFormFields({
                businessHours: updatedBusinessHours,
              });
            }
          }}
        />
      );
    });
  }, [businessHoursStart, businessHoursEnd, formState.businessHours]);

  const scheudledEventColourCodingOptions = useMemo(() => {
    const options: { label: string; value: string | null }[] = Object.values(
      ScheduledEventColourCoding
    ).map((value) => ({
      label: capitaliseFirstLetter(value),
      value: value as string,
    }));
    options.push({ label: 'None', value: null });
    return options;
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <Card>
        <Card.Body className="flex flex-col gap-2">
          <div>
            <h2>Event Reminders</h2>
            <p className="mb-4">
              Set up reminders to inform Event attendees before the Event
              starts.
            </p>
          </div>
          <ScheduledEventRemindersConfigForm />
        </Card.Body>
      </Card>

      <Card>
        <Card.Body className="flex flex-col gap-2">
          <div>
            <h2>Business hours</h2>
            <p>This allows you to hide non-working hours from your Schedule.</p>
          </div>
          <div className="flex flex-row gap-4">
            <TimeField
              isRequired={true}
              label="Start time"
              isDisabled={readOnly}
              value={
                moment(
                  `${new Date().toJSON().slice(0, 10)} ${businessHoursStart}`
                ) ?? undefined
              }
              name="start"
              onChange={(change: { [key: string]: Moment }) => {
                // update start time of selected business days
                updateBusinessHours(change.start, true);
              }}
              styleVariant={InputStyleVariant.OUTSIZE}
            />
            <TimeField
              isDisabled={readOnly}
              isRequired={true}
              label="End time"
              value={
                moment(
                  `${new Date().toJSON().slice(0, 10)} ${businessHoursEnd}`
                ) ?? undefined
              }
              name="end"
              onChange={(change: { [key: string]: Moment }) => {
                // update end time of selected business days
                updateBusinessHours(change.end, false);
              }}
              styleVariant={InputStyleVariant.OUTSIZE}
              additionalPickerProps={{
                minTime: businessHoursStart,
              }}
            />
          </div>
          <div>
            <FieldLabel label="Working days" />
            <div className="business-hours-day-selection-wrapper">
              {renderWorkingDays}
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body className="flex flex-col gap-2">
          <h2>Scheduled Event colour</h2>
          <div className="flex">
            <DropdownField
              name="scheduledEventColourCoding"
              label="Enable Scheduled Event colour coding by"
              options={scheudledEventColourCodingOptions}
              value={formState.scheduledEventColourCoding}
              onChange={(change) =>
                updateFormFields({
                  scheduledEventColourCoding: change.scheduledEventColourCoding,
                })
              }
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const DaySelection = ({
  dayName,
  isSelected,
  onClick,
  isReadOnly,
}: {
  dayName: string;
  isSelected: boolean;
  onClick?: () => void;
  isReadOnly?: boolean;
}) => {
  return (
    <div
      className={`day-selection${isSelected ? ' selected' : ''}`}
      onClick={!isReadOnly ? onClick : undefined}
    >
      {dayName.slice(0, 1).toUpperCase()}
    </div>
  );
};

export default CompanySettingsSchedule;
