import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { actions as usersActions } from '@/api/users';

import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import UserIndicator from '@payaca/components/userIndicator/UserIndicator';
import TaskChecklistCompletionControl from '../taskChecklistCompletionControl/TaskChecklistCompletionControl';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import TaskFormCompletionControl from '../taskFormCompletionControl/TaskFormCompletionControl';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Task } from '@payaca/types/taskTypes';

import { getDocument, getUserRoles } from '@/utils/stateAccessors';

import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

import { requestUpdateTaskCompletion } from '@payaca/store/tasks/tasksActions';
import { UpdateTaskCompletionRequestData } from '@payaca/store/tasks/tasksTypes';

import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';

import './ViewCompleteTaskControl.sass';
import { requestGetDocumentForTask } from '@payaca/store/documents/documentActions';
import { useSelector } from '@/api/state';
import { isValidHtml, parseHtmlToReact } from '@payaca/utilities/htmlUtilities';
import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';

type Props = {
  task: Task;
  onUpdateTaskCompletionSuccess?: () => void;
};
const ViewCompleteTaskControl: FunctionComponent<Props> = ({
  task,
  onUpdateTaskCompletionSuccess,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const region = useSelector(
    (state: any) => state.users.myProfile.accounts[0].region
  );

  const accountUsers: any[] = useSelector(
    (state: any) => state.users.accountUsers
  );

  const document = useSelector((state) => {
    return task?.documentId && getDocument(state, task.documentId);
  });

  useEffect(() => {
    if (task && task.documentId && !document) {
      dispatch(requestGetDocumentForTask(task.id));
    }
  }, [task?.documentId, !!document]);

  const isUpdatingTaskCompletion = useSelector((state) => {
    return state.tasks.isUpdatingTaskCompletion;
  });

  useEffect(() => {
    dispatch(usersActions.getAccountUsers());
  }, []);

  const userRoles = useSelector(getUserRoles);

  const userHasCompleteTaskPermission = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      TasksPermissions.COMPLETE_SELF_ASSIGNED_TASK,
      TasksPermissions.COMPLETE_TASK,
    ]);
  }, [userRoles]);

  const initialFormState = useMemo(() => {
    return {
      id: task.id,
      isCompleted: !!task.completedAt,
      checklistItems: task.checklistItems.map((x) => {
        return {
          id: x.id,
          isCompleted: !!x.completedAt,
        };
      }),
    };
  }, [task]);

  const assignedToUser = useMemo(() => {
    if (!task.assignedToUserId) return;
    return accountUsers.find((x) => x.id === task.assignedToUserId);
  }, [task, accountUsers]);

  const assignedToUserName = useMemo(() => {
    if (!assignedToUser) return;
    return `${assignedToUser.firstname} ${assignedToUser.lastname}`;
  }, [assignedToUser]);

  const updateTaskCompletion = useCallback(
    (updateTaskCompletionRequestData: UpdateTaskCompletionRequestData) => {
      dispatch(
        requestUpdateTaskCompletion(
          updateTaskCompletionRequestData,
          onUpdateTaskCompletionSuccess
        )
      );
    },
    [dispatch, onUpdateTaskCompletionSuccess]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <React.Fragment>
          <div className="form-body">
            {document ? (
              <TaskFormCompletionControl
                task={task}
                document={document}
                includeTaskIdInUrl
              />
            ) : (
              <TaskChecklistCompletionControl
                onChange={onFieldChange}
                task={task}
                formState={formState as any}
                isEditable={userHasCompleteTaskPermission}
              />
            )}

            {task.deadlineDate && (
              <div>
                <LabelValuePair
                  value={moment(task.deadlineDate).format(
                    getInternationalMomentDateFormatByRegion(
                      DateFormats.SHORT,
                      region
                    )
                  )}
                  label="Deadline date"
                />
              </div>
            )}

            {assignedToUserName && (
              <PermissionGuard
                renderIfHasPermissions={[AccountsPermissions.GET_USERS]}
              >
                <div className="user-assignment">
                  <LabelValuePair
                    value={
                      <div className="flex-container flex-center">
                        <UserIndicator
                          user={{
                            firstName: assignedToUser.firstname,
                            lastName: assignedToUser.lastname,
                            userColour: assignedToUser.userColour,
                            emailAddress: assignedToUser.email,
                          }}
                          isUserInformationHoverPopperEnabled={false}
                        />
                        <span>{assignedToUserName}</span>
                      </div>
                    }
                    label="Assignee"
                  />
                </div>
              </PermissionGuard>
            )}

            {task.notes && (
              <div className="notes-container">
                <label>
                  <strong>Notes:</strong>
                </label>
                {isValidHtml(task.notes) ? (
                  parseHtmlToReact(task.notes)
                ) : (
                  <MarkdownLabel markdown={task.notes} />
                )}
              </div>
            )}
          </div>
          {!document && ( // save button not necessary on document task
            <PermissionGuard
              renderIfHasPermissions={[
                TasksPermissions.COMPLETE_SELF_ASSIGNED_TASK,
                TasksPermissions.COMPLETE_TASK,
              ]}
            >
              <div className="actions-container">
                <Button
                  styleVariant={ButtonStyleVariant.OUTSIZE}
                  isProcessing={isUpdatingTaskCompletion}
                  onClick={() =>
                    !isUpdatingTaskCompletion &&
                    updateTaskCompletion(
                      formState as UpdateTaskCompletionRequestData
                    )
                  }
                >
                  Save
                </Button>
              </div>
            </PermissionGuard>
          )}
        </React.Fragment>
      );
    },
    [
      assignedToUserName,
      task.notes,
      isUpdatingTaskCompletion,
      updateTaskCompletion,
      userHasCompleteTaskPermission,
      document,
    ]
  );

  return (
    <div className="view-complete-task-control">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
      />
    </div>
  );
};

export default ViewCompleteTaskControl;
