import React, { FC } from 'react';
import Button, { IButtonProps } from '../../plButton/Button';
import UntitledIcon from '@payaca/untitled-icons';
import DropdownCore, { TDropdownItem } from '../../plDropdown/DropdownCore';
import { Menu } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import NumberOfSelectedFilters from './NumberOfSelectedFilters';

export interface IProps extends IButtonProps {
  filters: TDropdownItem[];
}

const FilterDropdown: FC<IProps> = (props) => {
  const { filters, ...buttonProps } = props;

  const numOfSelectedFilters = filters.filter(
    (filter) => filter.selected
  ).length;

  return (
    <Menu>
      <Float placement="bottom-end" portal>
        <Menu.Button as={Button} {...buttonProps}>
          <UntitledIcon name="filter-lines.3" className="h-3.5 w-3.5" />
          Filter
          {numOfSelectedFilters > 0 && (
            <NumberOfSelectedFilters
              numberOfSelectedFilters={numOfSelectedFilters}
            />
          )}
        </Menu.Button>

        <DropdownCore items={filters} />
      </Float>
    </Menu>
  );
};

export default FilterDropdown;
