import { forwardRef, Fragment, PropsWithChildren } from 'react';
import {
  Dialog,
  DialogPanelProps,
  Transition,
  TransitionEvents,
} from '@headlessui/react';

export interface ISidebarCoreProps extends TransitionEvents {
  isOpen: boolean;
  onClose?: () => void;
  className?: string;
  disableBackdropClick?: boolean;
  panelProps?: DialogPanelProps<'div'>;
}

const SidebarCore = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ISidebarCoreProps>
>((props, ref) => {
  const {
    className,
    children,
    isOpen,
    onClose,
    disableBackdropClick,
    panelProps,
    ...rest
  } = props;

  return (
    <Transition show={isOpen} appear as={Fragment} {...rest}>
      <Dialog onClose={disableBackdropClick ? () => null : () => onClose?.()}>
        {/*Backdrop*/}
        <Transition.Child
          enter="transition-opacity ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as={Fragment}
        >
          <div
            className="z-sidebarBackdrop fixed inset-0 bg-gray-900 bg-opacity-30 dark:bg-opacity-80"
            aria-hidden="true"
          />
        </Transition.Child>

        <Transition.Child
          enter="transition duration-300 ease-out"
          enterFrom="transform translate-x-full opacity-0"
          enterTo="transform translate-100 opacity-100"
          leave="transition duration-300 ease-out"
          leaveFrom="transform translate-100 opacity-100"
          leaveTo="transform translate-x-full opacity-0"
          as={Fragment}
        >
          <div className="!z-sidebar sm:w-[400px] flex flex-col bg-white border-s fixed inset-y-0 end-0 transition-all duration-300 transform size-full">
            <Dialog.Panel ref={ref} className={className} {...panelProps}>
              {children}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
});

export default SidebarCore;
