import React, { FunctionComponent, useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import Modal from '@payaca/components/modal/Modal';
import UpdateSubscriptionControl from '../updateSubscriptionControl/UpdateSubscriptionControl';
import './UpdateSubscriptionModal.sass';
import { RecurringInterval } from '@payaca/types/subscriptionProductTypes';

type Props = {
  isOpen: boolean;
  recurringInterval?: RecurringInterval;
  stripeProductId: string;
  stripePriceId: string;
  additionalUserSeats?: number;
  onClose: () => void;
  onUpdateSubscriptionSuccess?: (additionalUserSeats: number) => void;
};

const UpdateSubscriptionModal: FunctionComponent<Props> = ({
  isOpen,
  recurringInterval = RecurringInterval.YEAR,
  stripeProductId,
  stripePriceId,
  additionalUserSeats,
  onClose,
  onUpdateSubscriptionSuccess,
}: Props): JSX.Element => {
  const [subscriptionIsUpdated, setSubscriptionIsUpdated] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setSubscriptionIsUpdated(false);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title={''}
      size="sm"
      onClose={onClose}
      className="update-subscription-modal"
    >
      {!subscriptionIsUpdated && (
        <UpdateSubscriptionControl
          recurringInterval={recurringInterval}
          onUpdateSubscriptionSuccess={(additionalUserSeats) => {
            setSubscriptionIsUpdated(true);
            onUpdateSubscriptionSuccess?.(additionalUserSeats);
          }}
          stripeProductId={stripeProductId}
          stripePriceId={stripePriceId}
          additionalUserSeats={additionalUserSeats}
        />
      )}
      {subscriptionIsUpdated && (
        <div className="subscription-update-success-message">
          <FontAwesomeIcon icon={faCheck} />
          <p>Success!</p>
        </div>
      )}
    </Modal>
  );
};

export default UpdateSubscriptionModal;
