import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import PurchasableMaterialsListMaterialControl from '../purchasableMaterialsListMaterialControl/PurchasableMaterialsListMaterialControl';
import './ManageableMaterialsList.sass';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import {
  useMaterialsList,
  useMaterialsListMaterialsForMaterialsList,
} from '@payaca/store/hooks/appState';
import { MaterialsListMaterial } from '@payaca/types/materialsListTypes';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import ManageableMaterialsListPurchaseOrders from '../manageableMaterialsListPurchaseOrders/ManageableMaterialsListPurchaseOrders';
import { useHistory } from 'react-router';
import ClearMaterialsListControl from '../clearMaterialsListControl/ClearMaterialsListControl';
import MaterialsListMaterialControl from '../materialsListMaterialControl/MaterialsListMaterialControl';
import SearchMaterialsDrawer from '../searchMaterialsDrawer/SearchMaterialsDrawer';
import { createMaterialsListMaterials } from '@payaca/store/materialsList/materialsListActions';

type Props = {
  materialsListId: number;
  dealContextId?: number;
  enablePurchaseBehaviour?: boolean;
};

const ManageableMaterialsList: FunctionComponent<Props> = ({
  materialsListId,
  dealContextId,
  enablePurchaseBehaviour = true,
}: Props): JSX.Element | null => {
  const history = useHistory();
  const [
    showCreateMaterialsListMaterialControl,
    setShowCreateMaterialsListMaterialControl,
  ] = useState(false);
  const dispatch = useDispatch();

  const materialsListMaterials =
    useMaterialsListMaterialsForMaterialsList(materialsListId);

  const materialsList = useMaterialsList(materialsListId);

  const sortedMaterialsList = useMemo(() => {
    return materialsListMaterials.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }, [materialsListMaterials]);

  const getMaterialsList = useCallback(() => {
    dispatch(materialsListActions.requestGetMaterialsList(materialsListId));
  }, [materialsListId]);

  const getMaterialsListWithRelatedEntities = useCallback(() => {
    dispatch(
      materialsListActions.requestGetMaterialsListWithRelatedEntities(
        materialsListId
      )
    );
  }, [materialsListId]);

  const renderMaterialsListMaterialControl = useCallback(
    (materialsListMaterialId: number) => {
      return enablePurchaseBehaviour ? (
        <PurchasableMaterialsListMaterialControl
          materialsListMaterialId={materialsListMaterialId}
          onDeleteMaterialsListMaterialSuccess={getMaterialsList}
          onCreateMaterialPurchaseRecordSuccess={getMaterialsList}
          onUpdateMaterialsListMaterialSuccess={getMaterialsList}
          onDeleteMaterialPurchaseRecordSuccess={getMaterialsList}
        />
      ) : (
        <MaterialsListMaterialControl
          materialsListMaterialId={materialsListMaterialId}
          onDeleteMaterialsListMaterialSuccess={getMaterialsList}
          onUpdateMaterialsListMaterialSuccess={getMaterialsList}
          showPreferredSupplierInfo={true}
        />
      );
    },
    [getMaterialsList]
  );

  if (!materialsList)
    return (
      <div className="manageable-materials-list-loader-container">
        <MiniLoader />
      </div>
    );

  return (
    <div className="manageable-materials-list">
      <PermissionGuard
        renderIfHasPermissions={[
          MaterialsListPermissions.PERSIST_MATERIALS_LIST_MATERIAL,
        ]}
      >
        <div>
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => setShowCreateMaterialsListMaterialControl(true)}
          >
            Search & add Materials
          </Button>
          <SearchMaterialsDrawer
            isOpen={showCreateMaterialsListMaterialControl}
            onClose={() => setShowCreateMaterialsListMaterialControl(false)}
            onAddMaterials={(materialIds) => {
              dispatch(
                createMaterialsListMaterials.request({
                  materialsListId: materialsListId,
                  materialsToCreate: materialIds.map((materialId) => ({
                    materialId,
                    quantity: 1,
                  })),
                  callback: () => {
                    getMaterialsListWithRelatedEntities();
                  },
                })
              );
            }}
            disabledMaterialIds={materialsListMaterials.map(
              (mlm) => mlm.materialId
            )}
          />
        </div>
      </PermissionGuard>
      {enablePurchaseBehaviour && (
        <PermissionGuard
          renderIfHasPermissions={[
            MaterialsListPermissions.GET_PURCHASE_ORDERS,
          ]}
        >
          <ManageableMaterialsListPurchaseOrders
            projectId={dealContextId}
            materialsListId={materialsListId}
            onCreatePurchaseOrderSuccess={getMaterialsListWithRelatedEntities}
            navigateToPurchaseOrder={(purchaseOrderId) =>
              history.push(
                `/purchaseOrders/${purchaseOrderId}?projectId=${dealContextId}`
              )
            }
          />
        </PermissionGuard>
      )}
      {!!materialsListMaterials?.length && (
        <PermissionGuard
          renderIfHasPermissions={[
            MaterialsListPermissions.GET_MATERIALS_LIST_MATERIALS,
          ]}
        >
          <ul className="materials-list-materials">
            <li>
              <hr />
            </li>
            {sortedMaterialsList.map(
              (materialsListMaterial: MaterialsListMaterial, i: number) => {
                return (
                  <>
                    <li
                      key={`materials-list-material-control-${materialsListMaterial.id}`}
                    >
                      {renderMaterialsListMaterialControl(
                        materialsListMaterial.id
                      )}
                    </li>
                    <li
                      key={`materials-list-material-control-divider-${materialsListMaterial.id}`}
                    >
                      <hr />
                    </li>
                  </>
                );
              }
            )}
          </ul>
        </PermissionGuard>
      )}
      <div className="materials-list-management-actions">
        {!!materialsListMaterials?.length && (
          <ClearMaterialsListControl materialsListId={materialsListId} />
        )}
      </div>
    </div>
  );
};

export default ManageableMaterialsList;
