import {
  useMaterialPurchaseIntentsForMaterialsListMaterial,
  useMaterialsListMaterial,
} from '@payaca/store/hooks/appState';
import React, { FunctionComponent, useMemo } from 'react';
import MaterialPurchaseIntent from '../materialPurchaseIntent/MaterialPurchaseIntent';
import './MaterialPurchaseIntentList.sass';

type Props = {
  materialsListMaterialId: number;
};

const MaterialPurchaseIntentList: FunctionComponent<Props> = ({
  materialsListMaterialId,
}: Props): JSX.Element | null => {
  const materialsListMaterial = useMaterialsListMaterial(
    materialsListMaterialId
  );

  const materialPurchaseIntents =
    useMaterialPurchaseIntentsForMaterialsListMaterial(materialsListMaterialId);

  const sortedFilteredMaterialPurchaseIntents = useMemo(() => {
    const filteredMaterialPurchaseIntents = materialPurchaseIntents.filter(
      (x) => {
        return !x.voidedAt && !x.confirmedAt;
      }
    );

    return filteredMaterialPurchaseIntents.sort((a, b) =>
      a.createdAt > b.createdAt ? 1 : -1
    );
  }, [materialPurchaseIntents]);

  if (!materialsListMaterial) return null;
  if (!sortedFilteredMaterialPurchaseIntents.length) return null;

  return (
    <div className="material-purchase-intent-list">
      <ul className="material-purchase-intents">
        {sortedFilteredMaterialPurchaseIntents.map(
          (materialPurchaseIntent, index) => {
            return (
              <li
                key={`material-purchase-intent-${materialPurchaseIntent.id}-${index}`}
              >
                <MaterialPurchaseIntent
                  materialPurchaseIntentId={materialPurchaseIntent?.id}
                />
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};

export default MaterialPurchaseIntentList;
