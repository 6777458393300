import {
  ComponentProps,
  ComponentPropsWithRef,
  FC,
  forwardRef,
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldContext } from '../plField/Field';
import UntitledIcon, { TIconName } from '@payaca/untitled-icons';

export enum InputStyleVariant {
  BASIC = 'basic',
  GREY = 'grey',
  UNDERLINE = 'underline',
}

export enum InputSizeVariant {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum InputShapeVariant {
  PILL = 'pill',
  ROUNDED = 'rounded',
}

export type TProps = ComponentPropsWithRef<'input'> & {
  sizeVariant?: InputSizeVariant;
  shapeVariant?: InputShapeVariant;
  styleVariant?: InputStyleVariant;
  leadingElement?: ReactNode;
  className?: string;
};

/**
 * A basic text input component. It passes all input element props through to the underlying input element,
 * unmodified. This makes it suitable for uncontrolled inputs (e.g. react-hook-form)
 */
const RawInput: FunctionComponent<TProps> = forwardRef<
  HTMLInputElement,
  TProps
>(
  (
    {
      sizeVariant = InputSizeVariant.MD,
      shapeVariant = InputShapeVariant.ROUNDED,
      styleVariant = InputStyleVariant.BASIC,
      leadingElement,
      disabled,
      className,
      style,
      type = 'text',
      ...rest
    },
    ref
  ) => {
    const { id, name, validationState } = useContext(FieldContext);

    const isUnderline = styleVariant === InputStyleVariant.UNDERLINE;

    const borderClasses = useMemo(() => {
      if (isUnderline) {
        return 'border-0 border-b-2';
      }

      return 'border';
    }, [styleVariant]);

    const borderColourClasses = useMemo(() => {
      switch (validationState?.isValid) {
        case true:
          return 'border-teal-500';
        case false:
          return 'border-red-500';
        default:
          return styleVariant === InputStyleVariant.GREY
            ? 'border-transparent'
            : 'border-gray-200';
      }
    }, [validationState?.isValid, styleVariant, isUnderline]);

    const sizeClasses = useMemo(() => {
      switch (sizeVariant) {
        case InputSizeVariant.SM:
          return isUnderline ? 'pe-0 py-2' : 'py-2 px-3';
        case InputSizeVariant.MD:
          return isUnderline ? 'pe-0 py-3' : 'py-3 px-4';
        case InputSizeVariant.LG:
          return isUnderline ? 'pe-0 py-3 sm:py-5' : 'py-3 px-4 sm:p-5';
      }
    }, [sizeVariant]);

    const shapeClasses = useMemo(() => {
      if (isUnderline) {
        return '';
      }

      switch (shapeVariant) {
        case InputShapeVariant.PILL:
          return 'rounded-full';
        default:
          return 'rounded-lg';
      }
    }, [shapeVariant, styleVariant]);

    const backgroundColourClasses = useMemo(() => {
      switch (styleVariant) {
        case InputStyleVariant.GREY:
          return 'bg-gray-100';
        default:
          return 'bg-white';
      }
    }, [styleVariant]);

    const [leadingItemWidth, setLeadingItemWidth] = useState<number>();

    const isMounted = useIsMounted();

    const leadingItemRef = useCallback(
      (node: any) => {
        if (!node) return;

        setTimeout(() => {
          if (isMounted) {
            setLeadingItemWidth(node.offsetWidth);
          }
        }, 0);
      },
      [isMounted, leadingElement]
    );

    return (
      <div
        className={`relative overflow-hidden focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500 ${backgroundColourClasses} border-solid  ${borderClasses} ${
          disabled ? 'pointer-events-none opacity-70' : ''
        } ${borderColourClasses} ${shapeClasses} ${className || ''}`}
      >
        <input
          disabled={disabled}
          className={`border-0 bg-transparent ${sizeClasses}  block w-full text-base focus-visible:outline-0 disabled:pointer-events-none disabled:opacity-50`}
          name={name}
          id={id}
          type={type}
          style={{
            ...style,
            paddingLeft: leadingItemWidth
              ? `calc(${
                  isUnderline ? '0.667rem' : '1.33rem'
                } + ${leadingItemWidth}px)`
              : undefined,
          }}
          ref={ref}
          {...rest}
        />
        {leadingElement && (
          <div
            ref={leadingItemRef}
            className={`pointer-events-none absolute inset-y-0 start-0 flex items-center ${
              isUnderline ? 'ps-2' : 'ps-4'
            } peer-disabled:pointer-events-none peer-disabled:opacity-50`}
          >
            {leadingElement}
          </div>
        )}
      </div>
    );
  }
);
RawInput.displayName = 'RawInput';

export const InputIcon: FunctionComponent<{ name: TIconName }> = ({ name }) => {
  return <UntitledIcon name={name} className="h-4 w-4" />;
};

export default RawInput;

const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  });
  return isMounted;
};
