import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import ResponsiveViewWrapper from '../responsiveViewWrapper/ResponsiveViewWrapper';
import Checkbox from '../checkbox/Checkbox';
import ImageBlock from '../imageBlock/ImageBlock';
import QuickActionsElement from '../quickActionsTableCell/QuickActionsElement';

import './EntityCard.sass';
import { Collapse } from '@material-ui/core';
import Button from '../button/Button';
import { ButtonStyleVariant } from '../button/enums';
import RadioButton from '../radioButton/RadioButton';

export interface Props {
  quickActionsConfig?: {
    recordId: any;
    quickActions: {
      actionName: string;
      actionBehaviour: (recordId: any) => void;
      isActionProcessing?: boolean;
    }[];
    renderDisabledIfNoActions?: boolean;
  };
  imageConfig?: {
    imageSrc?: string;
    renderBlockIfNoImageSrc?: boolean;
  };
  selectionConfig?: {
    isSelected: boolean;
    isDisabled?: boolean;
    onSelectionChange?: (isSelected?: boolean) => void;
    isRadioButton?: boolean;
  };
  className?: string;
  onClick?: () => void;
  clickEntityPrompt?: string;
  expandableFooterConfig?: {
    expandPrompt?: string;
    collapsePrompt?: string;
    content: React.ReactNode;
  };
  breakpointConfig?: {
    downBreakpointSm?: number;
    downBreakpointXs?: number;
  };
}

const EntityCard: FC<PropsWithChildren<Props>> = ({
  children,
  quickActionsConfig,
  imageConfig,
  selectionConfig,
  className,
  onClick,
  clickEntityPrompt,
  expandableFooterConfig,
  breakpointConfig,
}) => {
  const [expandFooter, setExpandFooter] = useState(false);

  const isClickable = useMemo(() => {
    return !!onClick;
  }, [onClick]);

  return (
    <div
      className={`entity-card${className ? ` ${className}` : ''} ${
        isClickable ? 'clickable' : ''
      }`}
    >
      <div
        tabIndex={isClickable ? 0 : undefined}
        className="entity-card-click-wrapper"
        onClick={onClick}
        role={isClickable ? 'button' : undefined}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            onClick && onClick();
          }
        }}
      >
        <ResponsiveViewWrapper
          downBreakpointSm={breakpointConfig?.downBreakpointSm || 700}
          downBreakpointXs={breakpointConfig?.downBreakpointXs || 500}
          className="entity-card-inner"
        >
          <>
            {selectionConfig && (
              <div className="checkbox-wrapper">
                {selectionConfig.isRadioButton ? (
                  <RadioButton
                    isDisabled={selectionConfig.isDisabled}
                    isSelected={selectionConfig.isSelected}
                    onClick={() =>
                      selectionConfig.onSelectionChange &&
                      selectionConfig.onSelectionChange(
                        selectionConfig.isSelected
                      )
                    }
                  />
                ) : (
                  <Checkbox
                    isDisabled={selectionConfig.isDisabled}
                    isChecked={selectionConfig.isSelected}
                    onChange={() =>
                      selectionConfig.onSelectionChange &&
                      selectionConfig.onSelectionChange(
                        selectionConfig.isSelected
                      )
                    }
                  />
                )}
              </div>
            )}
            {(imageConfig?.imageSrc ||
              imageConfig?.renderBlockIfNoImageSrc) && (
              <div className="image-block-wrapper">
                <ImageBlock
                  imageSrc={imageConfig.imageSrc}
                  renderBlockIfNoImageSrc={
                    !!imageConfig.renderBlockIfNoImageSrc
                  }
                />
              </div>
            )}
            <div className="body-content-wrapper">{children}</div>
            {quickActionsConfig && (
              <div className="quick-actions-wrapper">
                <QuickActionsElement
                  recordId={quickActionsConfig.recordId}
                  quickActions={quickActionsConfig.quickActions}
                  renderDisabledIfNoActions={
                    quickActionsConfig.renderDisabledIfNoActions
                  }
                />
              </div>
            )}
            {clickEntityPrompt && (
              <div className="entity-card-inner-footer">
                {clickEntityPrompt}
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            )}
          </>
        </ResponsiveViewWrapper>
      </div>
      {expandableFooterConfig && (
        <div className="expandable-footer-container">
          <Collapse in={expandFooter}>
            <div className="footer-content">
              {expandableFooterConfig.content}
            </div>
          </Collapse>
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => setExpandFooter((x) => !x)}
          >
            {expandFooter
              ? expandableFooterConfig.collapsePrompt || 'Show less'
              : expandableFooterConfig.expandPrompt || 'Show more'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EntityCard;
