import React, { useMemo, useRef, useState } from 'react';
import Avatar, {
  AvatarBorderColourVariant,
  AvatarSizeVariant,
} from '../plAvatar/Avatar';
import Tooltip, { TooltipPositionVariant } from '../plTooltip/Tooltip';

type Props = {
  user: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    userColour?: string;
  };
  sizeVariant?: AvatarSizeVariant;
  enableUserInfoTooltip?: boolean;
  tooltipPositionVariant?: TooltipPositionVariant;
  borderColourVariant?: AvatarBorderColourVariant;
};

const UserIndicator = ({
  user,
  sizeVariant,
  enableUserInfoTooltip,
  tooltipPositionVariant = TooltipPositionVariant.TOP,
  borderColourVariant,
}: Props): JSX.Element => {
  const userInitials = useMemo(() => {
    const initialsArray: string[] = [];
    if (user.firstName?.length) initialsArray.push(user.firstName[0]);
    if (user.lastName?.length) initialsArray.push(user.lastName[0]);

    return initialsArray.join('').toUpperCase();
  }, [user]);

  const tooltipContent = useMemo(() => {
    if (!enableUserInfoTooltip) return;

    return (
      <div className="flex flex-col">
        <strong>
          {user.firstName} {user.lastName}
        </strong>
        {user.emailAddress}
      </div>
    );
  }, [user, enableUserInfoTooltip]);

  if (enableUserInfoTooltip) {
    return (
      <Tooltip
        tooltipContent={tooltipContent}
        positionVariant={tooltipPositionVariant}
      >
        <Avatar
          sizeVariant={sizeVariant}
          placeholderInitials={userInitials}
          colour={user.userColour}
          borderColourVariant={borderColourVariant}
        />
      </Tooltip>
    );
  }

  return (
    <Avatar
      sizeVariant={sizeVariant}
      placeholderInitials={userInitials}
      colour={user.userColour}
      borderColourVariant={borderColourVariant}
    />
  );
};

export default UserIndicator;
