import React, { useRef, useState } from 'react';
import {
  useFloating,
  arrow,
  FloatingArrow,
  useInteractions,
  useHover,
  offset,
  useRole,
  flip,
  autoUpdate,
} from '@floating-ui/react';
import { createPortal } from 'react-dom';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';

export enum TooltipPositionVariant {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export type TooltipOwnProps = {
  enabled?: boolean;
  positionVariant?: TooltipPositionVariant;
  showArrow?: boolean;
  tooltipContent: React.ReactNode;
};

// https://www.npmjs.com/package/react-polymorphic-box#-usage
export type TooltipProps<E extends React.ElementType> =
  PolymorphicComponentProps<E, TooltipOwnProps>;

const defaultElement = 'div';

const ARROW_HEIGHT = 7;
const GAP = 3;

const Tooltip = <E extends React.ElementType = typeof defaultElement>({
  children,
  className,
  positionVariant = TooltipPositionVariant.TOP,
  showArrow = false,
  enabled = true,
  tooltipContent,
  ...restProps
}: TooltipProps<E>) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);
  const { refs, context, floatingStyles } = useFloating({
    placement: positionVariant,
    middleware: [
      flip({
        // https://floating-ui.com/docs/flip#fallbackaxissidedirection
        fallbackAxisSideDirection: 'start',
      }),
      arrow({
        element: arrowRef,
      }),
      offset(showArrow ? ARROW_HEIGHT + GAP : GAP),
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    role,
  ]);

  return (
    <>
      <Box
        as={defaultElement}
        className={'inline-block' + (className ? ' ' + className : '')}
        ref={refs.setReference}
        {...getReferenceProps(restProps)}
      >
        {children}
      </Box>
      {enabled &&
        isOpen &&
        createPortal(
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className="z-tooltip max-w-prose rounded-lg bg-gray-900 px-2 py-1 text-sm font-medium text-white shadow-sm dark:bg-slate-700"
          >
            {tooltipContent}
            {showArrow && (
              <FloatingArrow
                ref={arrowRef}
                context={context}
                className="fill-gray-900 shadow-sm dark:fill-slate-700"
              />
            )}
          </div>,
          document.body
        )}
    </>
  );
};

export default Tooltip;
