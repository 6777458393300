import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import './CollapsibleSidebar.sass';
import Button from '../plButton/Button';
import UntitledIcon from '@payaca/untitled-icons';
import {
  EBtnColour,
  EBtnShapeVariant,
  EBtnSize,
  EBtnVariant,
} from '../plButton/useButtonClassName';

export enum CollapseDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

type Props = {
  isCollapsed: boolean;
  isHidden: boolean;
  config?: {
    collapsedWidthPx: number;
    expandedWidthPx: number;
  };
  collapseDirection?: CollapseDirection;
  onToggleCollapse?: () => void;
};

const CollapsibleSidebar: FunctionComponent<PropsWithChildren<Props>> = ({
  isCollapsed,
  isHidden,
  config = {
    collapsedWidthPx: 100,
    expandedWidthPx: 250,
  },
  children,
  collapseDirection = CollapseDirection.LEFT,
  onToggleCollapse,
}: PropsWithChildren<Props>): JSX.Element => {
  const sidebarInnerWidth = useMemo(() => {
    return isCollapsed ? config.collapsedWidthPx : config.expandedWidthPx;
  }, [config, isCollapsed]);

  return (
    <div
      className={`collapsible-sidebar overflow-hidden ${
        isCollapsed ? 'collapsed' : ''
      } collapse-direction-${collapseDirection}`}
      style={{
        width: isHidden ? 0 : sidebarInnerWidth,
      }}
    >
      <div className="collapsible-sidebar-wrapper">
        <div
          className="collapsible-sidebar-inner"
          style={{
            width: sidebarInnerWidth,
          }}
        >
          {children}
        </div>
      </div>
      <div className="expand-collapse-control">
        <Button
          size={EBtnSize.XSmall}
          variant={EBtnVariant.Ghost}
          colour={EBtnColour.Black}
          shape={EBtnShapeVariant.PILL}
          onClick={onToggleCollapse}
          className="ml-1"
        >
          <UntitledIcon
            className="h-4 w-4"
            name={isCollapsed ? 'chevron-right' : 'chevron-left'}
          />
        </Button>
      </div>
    </div>
  );
};

export default CollapsibleSidebar;
