import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash.isequal';

import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as jobsActions from '@payaca/store/jobs/jobsActions';

import { Deal } from '@payaca/types/dealTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { UpdateJobContentRequestData } from '@payaca/types/jobContentRequestTypes';
import { JobContent } from '@payaca/types/jobContentTypes';
import { Job, ProposalSummaryMessage } from '@payaca/types/jobTypesV2';

import { getDealByJobId, getJob, getJobContent } from '@/utils/stateAccessors';
import { usePrevious } from '@payaca/hooks/usePrevious';

import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import BasicField from '@payaca/components/basicField/BasicField';
import PaymentOptionsControl from '../paymentOptionsControl/PaymentOptionsControl';
import DealPaymentOptionsControl from '../dealPaymentOptionsControl/DealPaymentOptionsControl';
import JobTotalsAndValueModifiersControl from './JobTotalsAndValueModifiersControl';
import DealPriceDetailControl from '../dealPriceDetailControl/DealPriceDetailControl';
import DealMaterialsListDrawer from '../dealMaterialsListDrawer/DealMaterialsListDrawer';

import {
  getInitialUpdateJobContentRequestDataFromBaseJobContent,
  getInitialUpdateJobRequestDataFromBaseJob,
  getJobRequiresUpdate,
} from '@payaca/helpers/jobHelperV2';
import { isQuoteOrEstimate } from '@payaca/helpers/jobStatusHelper';

import { useSelector } from '@/api/state';

import './JobSidePanel.sass';
import ProgressToInvoiceModal from '../progressToInvoiceModal/ProgressToInvoiceModal';
import { PartialUpdateJobRequestData } from '@payaca/types/jobRequestTypes';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import { useAccount } from '../../../utils/storeHooks';
import { PaymentSchedule } from '@payaca/types/payment-schedule';
import { AccountRegions } from '@payaca/types/accountTypes';
import { getPaymentScheduleStagesWithValues } from '@payaca/helpers/paymentScheduleHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import Button from '@payaca/components/plButton/Button';

import LegacyButton from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Modal from '@payaca/components/plModal/Modal';
import Input from '@payaca/components/plInput/Input';
import { getLengthFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import Field from '@payaca/components/plField/Field';

type Props = {
  jobId: number;
};

const JobSidePanel: FC<Props> = ({ jobId }: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const account = useAccount();

  const [showMaterialsListDrawer, setShowMaterialsListDrawer] = useState(false);
  const [
    showProposalSummaryMessagesModal,
    setShowProposalSummaryMessagesModal,
  ] = useState(false);

  const [
    showConfirmProgressToInvoiceModal,
    setShowConfirmProgressToInvoiceModal,
  ] = useState(false);

  const job: Job | undefined = useSelector((state) => {
    return getJob(state, jobId);
  });

  const deal: Deal | undefined = useSelector((state) => {
    return getDealByJobId(state, jobId);
  });

  const jobContent: JobContent | undefined = useSelector((state) => {
    if (!job?.jobContentId) return;
    return getJobContent(state, job.jobContentId);
  });

  const [isSaveRequired, setIsSaveRequired] = useState(false);

  const isUpdatingJob: boolean = useSelector((state) => {
    return state.jobsStore.isUpdatingJob;
  });

  const isFetchingJob: boolean = useSelector((state) => {
    return state.jobsStore.jobs && state.jobsStore.jobs[jobId]?.isFetching;
  });

  const isUpdatingJobContent: boolean = useSelector((state) => {
    return state.jobContent.isUpdatingJobContent;
  });

  const isFetchingJobContent: boolean = useSelector((state) => {
    if (!job?.jobContentId) return false;
    return (
      state.jobContent.jobContents &&
      state.jobContent.jobContents[job.jobContentId]?.isFetching
    );
  });

  const accountHasStripeConnection: boolean = useSelector((state: any) => {
    return state.users.myProfile.accounts[0].connectedToStripe;
  });

  const isModifyingJobLineItemsOrJobLineItemGroups: boolean = useSelector(
    (state) => {
      return (
        state.jobContent.isUpdatingJobLineItem ||
        state.jobContent.isDeletingJobLineItem ||
        state.jobContent.isCreatingJobLineItem ||
        state.jobContent.isCreatingJobLineItemGroup ||
        state.jobContent.isUpdatingJobLineItemGroup ||
        state.jobContent.isDeletingJobLineItemGroup ||
        state.jobContent.isAddingLineItemGroupToJobContent
      );
    }
  );
  const previousIsModifyingJobLineItemsOrJobLineItemGroups = usePrevious(
    isModifyingJobLineItemsOrJobLineItemGroups
  );

  const requiresUpdateJob = useCallback(
    (updateJobRequestData: PartialUpdateJobRequestData) => {
      if (!job) return false;
      if (isFetchingJob || isUpdatingJob) return true;

      return getJobRequiresUpdate(job, updateJobRequestData);
    },
    [isFetchingJob, isUpdatingJob, job]
  );

  const jobIsProposition = useMemo(() => {
    if (!job) return;
    return isQuoteOrEstimate(job.status);
  }, [job?.status]);

  const onJobUpdateSuccess = useCallback(() => {
    dispatch(jobsActions.requestGetJob(jobId));
  }, [dispatch, jobId]);

  const onJobContentUpdateSuccess = useCallback(() => {
    if (!job?.jobContentId) return;
    dispatch(jobContentActions.requestGetJobContent(job.jobContentId));
    dispatch(
      jobContentActions.requestGetJobLineItemsForJobContent(job.jobContentId)
    );
  }, [job?.jobContentId]);

  const initialFormState = useMemo(() => {
    const initialUpdateJobData = job
      ? getInitialUpdateJobRequestDataFromBaseJob(job)
      : undefined;

    const initialUpdateJobContentData = jobContent
      ? getInitialUpdateJobContentRequestDataFromBaseJobContent(jobContent)
      : {};

    return {
      job: {
        dueInOrValidForDays: initialUpdateJobData?.dueInOrValidForDays,
        showBacsPaymentOption: initialUpdateJobData?.showBacsPaymentOption,
        showStripePaymentOption: initialUpdateJobData?.showStripePaymentOption,
        autoProgressToInvoice: initialUpdateJobData?.autoProgressToInvoice,
      },
      jobContent: initialUpdateJobContentData,
    };
  }, [
    job?.id,
    jobContent?.markupAmount,
    jobContent?.markupPercentage,
    jobContent?.minimumDepositAmount,
    jobContent?.minimumDepositPercentage,
    jobContent?.depositAmount,
    jobContent?.depositPercentage,
    jobContent?.discountAmount,
    jobContent?.discountPercentage,
    job?.status,
  ]);

  const requiresUpdateJobContent = useCallback(
    (updateJobContentRequestData: { [key: string]: any }) => {
      if (!jobContent) return false;
      if (isFetchingJobContent || isUpdatingJobContent) return true;
      return !isEqual(
        updateJobContentRequestData,
        getInitialUpdateJobContentRequestDataFromBaseJobContent(jobContent)
      );
    },
    [isFetchingJobContent, isUpdatingJobContent, jobContent]
  );

  const updateJob = useCallback(
    (formState: { [key: string]: any }) => {
      const updateJobContentRequestData =
        formState.jobContent as UpdateJobContentRequestData;

      if (formState.job && requiresUpdateJob(formState.job)) {
        dispatch(
          jobsActions.partialUpdateJob.request({
            jobId: jobId,
            data: formState.job,
            callback: onJobUpdateSuccess,
          })
        );
      }
      if (
        updateJobContentRequestData &&
        updateJobContentRequestData.jobContentId
      ) {
        if (requiresUpdateJobContent(formState.jobContent)) {
          dispatch(
            jobContentActions.requestUpdateJobContent(
              updateJobContentRequestData,
              onJobContentUpdateSuccess
            )
          );
        }
      }
    },
    [
      job,
      dispatch,
      onJobUpdateSuccess,
      onJobContentUpdateSuccess,
      requiresUpdateJobContent,
    ]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      if (isSaveRequired) {
        updateJob(formState);
        setIsSaveRequired(false);
      }

      if (!job) return <></>;

      return (
        <div className="job-side-panel-form-contents">
          <BasicField
            label={
              jobIsProposition ? 'Valid for (days)' : 'Invoice due in (days)'
            }
            name="dueInOrValidForDays"
            value={formState.job?.dueInOrValidForDays}
            type="number"
            onChange={(value: { [key: string]: any }) => {
              const updateObject: { [key: string]: any } = {
                'job.dueInOrValidForDays': null,
              };
              if (
                !isNaN(Number(value.dueInOrValidForDays)) &&
                value.dueInOrValidForDays !== ''
              ) {
                updateObject['job.dueInOrValidForDays'] = Math.round(
                  Number(value.dueInOrValidForDays)
                );
              }
              onFieldChange(updateObject);
            }}
            changeTimeoutMs={2000}
            onBlur={() => {
              setIsSaveRequired(true);
            }}
            onChangeTimeout={() => {
              setIsSaveRequired(true);
            }}
          />
          {/* jli totals */}
          <div>
            <JobTotalsAndValueModifiersControl
              fieldNamePrefix={'jobContent'}
              jobId={job?.id}
              updateJobFormState={formState}
              onChange={(value: { [key: string]: any }) => {
                onFieldChange(value);
                setIsSaveRequired(true);
              }}
            />
            <LegacyButton
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={() => setShowProposalSummaryMessagesModal(true)}
            >
              {job.summaryMessages?.length
                ? 'See summary alert'
                : 'Add summary alert'}
            </LegacyButton>
          </div>

          {/* payment options */}
          {deal && deal?.version > 1 ? (
            <DealPaymentOptionsControl dealId={deal.id} />
          ) : (
            <PaymentOptionsControl
              onChange={(value: { [key: string]: any }) => {
                const updateObject: { [key: string]: any } = {};

                if ('showBACSPaymentOption' in value) {
                  updateObject['job.showBacsPaymentOption'] =
                    value.showBACSPaymentOption;
                }
                if ('showStripePaymentOption' in value) {
                  updateObject['job.showStripePaymentOption'] =
                    value.showStripePaymentOption;
                }

                onFieldChange(updateObject);
                setIsSaveRequired(true);
              }}
              showBACSPaymentOption={formState.job?.showBacsPaymentOption}
              showStripePaymentOption={formState.job?.showStripePaymentOption}
            />
          )}
        </div>
      );
    },
    [
      deal,
      job,
      updateJob,
      isUpdatingJob,
      accountHasStripeConnection,
      isSaveRequired,
    ]
  );

  return (
    <div className="job-side-panel">
      {/* Job error goes here */}
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
      />
      {deal && (
        <DealPriceDetailControl
          dealId={deal.id}
          canHideVat={!!jobIsProposition}
        />
      )}
      <ProgressToInvoiceModal
        proposalId={jobId}
        isOpen={showConfirmProgressToInvoiceModal}
        onClose={() => setShowConfirmProgressToInvoiceModal(false)}
      />
      {job?.dealId && (
        <DealMaterialsListDrawer
          isOpen={showMaterialsListDrawer}
          onClose={() => setShowMaterialsListDrawer(false)}
          dealId={job.dealId}
        />
      )}
      <SummaryMessagesModal
        isOpen={showProposalSummaryMessagesModal}
        onClose={() => setShowProposalSummaryMessagesModal(false)}
        summaryMessages={job?.summaryMessages || []}
        onChange={(summaryMessages) => {
          updateJob({
            job: {
              summaryMessages,
            },
          });
        }}
      />
    </div>
  );
};
export default JobSidePanel;

const SummaryMessagesModal: FC<{
  summaryMessages: Job['summaryMessages'];
  isOpen: boolean;
  onClose: () => void;
  onChange: (summaryMessages: Job['summaryMessages']) => void;
}> = ({ summaryMessages, isOpen, onClose, onChange }) => {
  const fieldValidators = useMemo(() => {
    return {
      'summaryMessages[0].message': [
        getLengthFieldValidator({ min: 0, max: 125 }),
      ],
    };
  }, []);

  const initialFormState = useMemo(() => {
    const message = {
      message: '',
      level: 'alert',
      ...summaryMessages?.[0],
    };

    return {
      summaryMessages: [message],
    };
  }, [summaryMessages]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Payment summary alert">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => {
          return (
            <>
              <Modal.Body>
                <Field
                  name="summaryMessages[0].message"
                  validationState={
                    validationState?.['summaryMessages[0].message']?.isValid ===
                      false && touchedState?.['summaryMessages[0].message']
                      ? {
                          isValid: false,
                          validationMessages:
                            validationState?.['summaryMessages[0].message']
                              ?.errors,
                        }
                      : undefined
                  }
                >
                  <p className="mb-2">
                    This text will show immediately below the &#34;Total payable
                    amount&#34; in the summary box on the right.
                  </p>
                  <Input
                    value={formState.summaryMessages[0].message}
                    onChange={(value) => {
                      onFieldChange({
                        ['summaryMessages[0].message']: value,
                      });
                    }}
                    onBlur={() => onFieldTouch?.('summaryMessages[0].message')}
                  />
                </Field>
              </Modal.Body>
              <Modal.Footer>
                <Modal.Footer.Actions>
                  <Button
                    disabled={!isValid}
                    onClick={() => {
                      onClose();
                      onChange(
                        formState.summaryMessages[0].message.length
                          ? formState.summaryMessages
                          : null
                      );
                    }}
                  >
                    Save
                  </Button>
                </Modal.Footer.Actions>
              </Modal.Footer>
            </>
          );
        }}
        fieldValidators={fieldValidators}
        initialFormState={initialFormState}
      ></ValidatedForm>
    </Modal>
  );
};
