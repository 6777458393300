import {
  takeEvery,
  call,
  put,
  race,
  delay,
  takeLatest,
} from 'redux-saga/effects';

import { Action, PayloadAction } from 'typesafe-actions';

import {
  SagaConfig,
  ActionType,
  PersistMaterialRequestData,
  PersistSupplierMaterialRequestData,
  PersistLineItemMaterialRequestData,
} from './materialsTypes';

import {
  archiveMaterialsFailure,
  archiveMaterialsSuccess,
  clearLineItemMaterials,
  clearMaterialCategories,
  clearMaterialSuppliers,
  clearMaterials,
  clearSupplierMaterials,
  deleteLineItemMaterialFailure,
  deleteLineItemMaterialSuccess,
  deleteSupplierMaterialFailure,
  deleteSupplierMaterialSuccess,
  getLineItemMaterialFailure,
  getLineItemMaterialSuccess,
  getMaterialCategoriesFailure,
  getMaterialCategoriesSuccess,
  getMaterialSuppliersFailure,
  getMaterialSuppliersSuccess,
  getMaterialFailure,
  getMaterialSuccess,
  getSupplierMaterialFailure,
  getSupplierMaterialSuccess,
  persistLineItemMaterialFailure,
  persistLineItemMaterialSuccess,
  persistMaterialFailure,
  persistMaterialSuccess,
  persistSupplierMaterialFailure,
  persistSupplierMaterialSuccess,
  getSupplierMaterialsForMaterialSuccess,
  persistLineItemMaterialsFailure,
  persistLineItemMaterialsSuccess,
} from './materialsActions';

import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const materialsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);
  function* handlePersistMaterial(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_MATERIAL,
      {
        persistMaterialRequestData: PersistMaterialRequestData;
        callback?: (materialId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          persistMaterial,
          action.payload.persistMaterialRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistMaterialFailure(new Error('Persist material timed out.'))
        );
      } else {
        yield put(persistMaterialSuccess());
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(persistMaterialFailure(error as Error));
    }
  }

  const persistMaterial = async (
    persistMaterialRequestData: PersistMaterialRequestData
  ) => {
    const response = await req.post(`/materials`, persistMaterialRequestData);

    return await response.json();
  };

  function* handlePersistSupplierMaterial(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_SUPPLIERMATERIAL,
      {
        persistSupplierMaterialRequestData: PersistSupplierMaterialRequestData;
        callback?: (supplierMaterialId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          persistSupplierMaterial,
          action.payload.persistSupplierMaterialRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistSupplierMaterialFailure(
            new Error('Persist supplier material timed out.')
          )
        );
      } else {
        yield put(persistSupplierMaterialSuccess());
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(persistSupplierMaterialFailure(error as Error));
    }
  }

  const persistSupplierMaterial = async (
    persistSupplierMaterialRequestData: PersistSupplierMaterialRequestData
  ) => {
    const response = await req.post(
      `/supplier_materials`,
      persistSupplierMaterialRequestData
    );

    return await response.json();
  };

  function* handleGetMaterial(
    action: PayloadAction<
      ActionType.REQUEST_GET_MATERIAL,
      {
        materialId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getMaterial, action.payload.materialId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get material timed out.';
        yield put(
          getMaterialFailure(action.payload.materialId, new Error(errorMessage))
        );
      } else {
        yield put(getMaterialSuccess(response.id, response));
      }
    } catch (error: any) {
      yield put(getMaterialFailure(action.payload.materialId, error));
    }
  }

  const getMaterial = async (materialId: number) => {
    const response = await req.get(`/materials/${materialId}`);
    return response.json();
  };

  function* handleGetMaterialCategories(
    action: Action<ActionType.REQUEST_GET_MATERIALCATEGORIES>
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getMaterialCategories),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get material categories timed out.';
        yield put(getMaterialCategoriesFailure(new Error(errorMessage)));
      } else {
        yield put(getMaterialCategoriesSuccess(response));
      }
    } catch (error: any) {
      yield put(getMaterialCategoriesFailure(error));
    }
  }

  const getMaterialCategories = async () => {
    const response = await req.get(`/materials/categories`);
    return response.json();
  };

  function* handleGetMaterialSuppliers(
    action: Action<ActionType.REQUEST_GET_MATERIALSUPPLIERS>
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getMaterialSuppliers),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get material suppliers timed out.';
        yield put(getMaterialSuppliersFailure(new Error(errorMessage)));
      } else {
        yield put(getMaterialSuppliersSuccess(response));
      }
    } catch (error: any) {
      yield put(getMaterialSuppliersFailure(error));
    }
  }

  const getMaterialSuppliers = async () => {
    const response = await req.get(`/materials/suppliers`);
    return response.json();
  };

  function* handleDeleteSupplierMaterial(
    action: PayloadAction<
      ActionType.REQUEST_DELETE_SUPPLIERMATERIAL,
      {
        supplierMaterialId: number;
        callback: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          deleteSupplierMaterial,
          action.payload.supplierMaterialId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Delete supplierMaterial timed out.';
        yield put(deleteSupplierMaterialFailure(new Error(errorMessage)));
      } else {
        yield put(deleteSupplierMaterialSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(deleteSupplierMaterialFailure(error));
    }
  }

  const deleteSupplierMaterial = async (supplierMaterialId: number) => {
    return req.del(`/supplier_materials/${supplierMaterialId}`);
  };

  function* handleGetSupplierMaterial(
    action: PayloadAction<
      ActionType.REQUEST_GET_SUPPLIERMATERIAL,
      {
        supplierMaterialId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getSupplierMaterial, action.payload.supplierMaterialId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get supplierMaterial timed out.';
        yield put(
          getSupplierMaterialFailure(
            action.payload.supplierMaterialId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(getSupplierMaterialSuccess(response.id, response));
      }
    } catch (error: any) {
      yield put(
        getSupplierMaterialFailure(action.payload.supplierMaterialId, error)
      );
    }
  }

  const getSupplierMaterial = async (supplierMaterialId: number) => {
    const response = await req.get(`/supplier_materials/${supplierMaterialId}`);
    return response.json();
  };

  function* handleGetLineItemMaterial(
    action: PayloadAction<
      ActionType.REQUEST_GET_LINEITEMMATERIAL,
      {
        lineItemMaterialId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getLineItemMaterial, action.payload.lineItemMaterialId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get lineItemMaterial timed out.';
        yield put(
          getLineItemMaterialFailure(
            action.payload.lineItemMaterialId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(getLineItemMaterialSuccess(response.id, response));
      }
    } catch (error: any) {
      yield put(
        getLineItemMaterialFailure(action.payload.lineItemMaterialId, error)
      );
    }
  }

  const getLineItemMaterial = async (lineItemMaterialId: number) => {
    const response = await req.get(
      `/line_item_materials/${lineItemMaterialId}`
    );
    return response.json();
  };

  function* handleDeleteLineItemMaterial(
    action: PayloadAction<
      ActionType.REQUEST_DELETE_LINEITEMMATERIAL,
      {
        lineItemMaterialId: number;
        callback: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          deleteLineItemMaterial,
          action.payload.lineItemMaterialId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Delete lineItemMaterial timed out.';
        yield put(deleteLineItemMaterialFailure(new Error(errorMessage)));
      } else {
        yield put(deleteLineItemMaterialSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(deleteLineItemMaterialFailure(error));
    }
  }

  const deleteLineItemMaterial = async (lineItemMaterialId: number) => {
    return req.del(`/line_item_materials/${lineItemMaterialId}`);
  };

  function* handlePersistLineItemMaterial(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_LINEITEMMATERIAL,
      {
        persistLineItemMaterialRequestData: PersistLineItemMaterialRequestData;
        callback?: (err: string | null, lineItemMaterialId?: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          persistLineItemMaterial,
          action.payload.persistLineItemMaterialRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistLineItemMaterialFailure(
            new Error('Persist lineItem material timed out.')
          )
        );
      } else {
        yield put(persistLineItemMaterialSuccess());
        action.payload.callback && action.payload.callback(null, response);
      }
    } catch (error: any) {
      yield put(persistLineItemMaterialFailure(error as Error));
      action.payload.callback && action.payload.callback(error.message);
    }
  }

  const persistLineItemMaterial = async (
    persistLineItemMaterialRequestData: PersistLineItemMaterialRequestData
  ) => {
    const response = await req.post(
      `/line_item_material`,
      persistLineItemMaterialRequestData
    );

    return await response.json();
  };

  function* handlePersistLineItemMaterials(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_LINEITEMMATERIALS,
      {
        persistLineItemMaterialsRequestData: PersistLineItemMaterialRequestData[];
        callback?: (err: string | null, lineItemMaterialId?: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          persistLineItemMaterials,
          action.payload.persistLineItemMaterialsRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistLineItemMaterialsFailure(
            new Error('Persist lineItem material timed out.')
          )
        );
      } else {
        yield put(persistLineItemMaterialsSuccess());
        action.payload.callback && action.payload.callback(null, response);
      }
    } catch (error: any) {
      yield put(persistLineItemMaterialsFailure(error as Error));
      action.payload.callback && action.payload.callback(error.message);
    }
  }

  const persistLineItemMaterials = async (
    persistLineItemMaterialsRequestData: PersistLineItemMaterialRequestData[]
  ) => {
    const response = await req.post(
      `/line_item_materials`,
      persistLineItemMaterialsRequestData
    );

    return await response.json();
  };

  function* handleGetSupplierMaterialsForMaterial(
    action: PayloadAction<
      ActionType.REQUEST_GET_SUPPLIERMATERIALS_FOR_MATERIAL,
      {
        materialId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getSupplierMaterialsForMaterial,
          action.payload.materialId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getSupplierMaterialsForMaterial request timed out');
      }

      yield put(getSupplierMaterialsForMaterialSuccess(response));
    } catch (error: any) {}
  }

  const getSupplierMaterialsForMaterial = async (materialId: number) => {
    const response = await req.get(
      `/supplier_materials/material/${materialId}`
    );
    return response.json();
  };

  function* handleArchiveMaterials(
    action: PayloadAction<
      ActionType.REQUEST_ARCHIVE_MATERIALS,
      {
        materialIds: number[];
        onArchiveSuccess?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(archiveMaterials, action.payload.materialIds),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          archiveMaterialsFailure(new Error('Archive materials timed out.'))
        );
      } else {
        action.payload.onArchiveSuccess && action.payload.onArchiveSuccess();
        yield put(archiveMaterialsSuccess());
      }
    } catch (error: any) {
      yield put(archiveMaterialsFailure(error));
    }
  }

  const archiveMaterials = async (materialIds: number[]) => {
    const response = await req.put('/materials/bulk_archive', {
      materialIds,
    });
    return response;
  };

  function* handleLogout() {
    yield put(clearMaterials());
    yield put(clearSupplierMaterials());
    yield put(clearLineItemMaterials());
    yield put(clearMaterialCategories());
    yield put(clearMaterialSuppliers());
  }

  return function* () {
    yield takeLatest(
      ActionType.REQUEST_PERSIST_MATERIAL,
      handlePersistMaterial
    );
    yield takeEvery(ActionType.REQUEST_GET_MATERIAL, handleGetMaterial);
    yield takeEvery(
      ActionType.REQUEST_ARCHIVE_MATERIALS,
      handleArchiveMaterials
    );

    yield takeLatest(
      ActionType.REQUEST_PERSIST_SUPPLIERMATERIAL,
      handlePersistSupplierMaterial
    );
    yield takeEvery(
      ActionType.REQUEST_GET_SUPPLIERMATERIAL,
      handleGetSupplierMaterial
    );
    yield takeEvery(
      ActionType.REQUEST_GET_SUPPLIERMATERIALS_FOR_MATERIAL,
      handleGetSupplierMaterialsForMaterial
    );
    yield takeEvery(
      ActionType.REQUEST_DELETE_SUPPLIERMATERIAL,
      handleDeleteSupplierMaterial
    );

    yield takeLatest(
      ActionType.REQUEST_PERSIST_LINEITEMMATERIAL,
      handlePersistLineItemMaterial
    );
    yield takeLatest(
      ActionType.REQUEST_PERSIST_LINEITEMMATERIALS,
      handlePersistLineItemMaterials
    );
    yield takeEvery(
      ActionType.REQUEST_GET_LINEITEMMATERIAL,
      handleGetLineItemMaterial
    );
    yield takeEvery(
      ActionType.REQUEST_DELETE_LINEITEMMATERIAL,
      handleDeleteLineItemMaterial
    );
    yield takeLatest(
      ActionType.REQUEST_GET_MATERIALCATEGORIES,
      handleGetMaterialCategories
    );
    yield takeLatest(
      ActionType.REQUEST_GET_MATERIALSUPPLIERS,
      handleGetMaterialSuppliers
    );

    yield takeEvery('auth.logout', handleLogout);
  };
};

export default materialsSagaCreator;
