import React, { FunctionComponent, useMemo } from 'react';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getProductPriceCostExcludingVat } from '@payaca/helpers/subscriptionHelper';

import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import QuantityControl from '../quantityControl/QuantityControl';

import {
  ProductPrice,
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';

import { useSelector } from '@/api/state';

import './SelectedProductOverview.sass';

type Props = {
  recurringInterval: RecurringInterval;
  selectedProduct: SubscriptionProduct;
  selectedPrice: ProductPrice;
  additionalUserSeats: number;
  taxRatePercentage: number;
  enableEmbeddedUserSeatControl?: boolean;
  onChangeAdditionalUserSeats?: (additionalUserSeats: number) => void;
};

const SelectedProductOverview: FunctionComponent<Props> = ({
  recurringInterval,
  selectedProduct,
  selectedPrice,
  additionalUserSeats,
  taxRatePercentage,
  enableEmbeddedUserSeatControl = false,
  onChangeAdditionalUserSeats,
}: Props): JSX.Element | null => {
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const hasAnyBaseUserSeats = useMemo(() => {
    return !!selectedProduct.numberOfUserSeats;
  }, [selectedProduct.numberOfUserSeats]);

  const costExcludingVat = useMemo(() => {
    if (!selectedPrice) return null;

    return (
      getProductPriceCostExcludingVat(selectedPrice, additionalUserSeats) ||
      null
    );
  }, [additionalUserSeats, additionalUserSeats]);

  const vat = useMemo(() => {
    if (!costExcludingVat) return 0;
    return costExcludingVat * (taxRatePercentage / 100);
  }, [costExcludingVat, taxRatePercentage]);

  const costIncludingVat = useMemo(() => {
    if (!costExcludingVat) return null;
    return costExcludingVat + vat;
  }, [costExcludingVat, vat]);

  const userSeatsElement = useMemo(() => {
    const numberOfUserSeats = selectedProduct?.numberOfUserSeats || 0;
    const additionalUserSeatCost = selectedPrice?.additionalUserSeatCost || 0;
    const totalNumberOfUserSeats = numberOfUserSeats + additionalUserSeats;
    const tooltipCopy = `${numberOfUserSeats} user spaces are provided as part of your plan. Additional spaces cost ${currencyPrice(
      additionalUserSeatCost,
      account.region
    )} + VAT per space per ${recurringInterval}`;
    if (!selectedPrice?.canBuyAdditionalUserSeats) {
      return (
        <LabelValuePair
          label="Users"
          value={`${
            numberOfUserSeats >= 999 ? 'Unlimited' : numberOfUserSeats
          }`}
        />
      );
    }
    if (
      selectedPrice?.canBuyAdditionalUserSeats &&
      !enableEmbeddedUserSeatControl
    ) {
      return (
        <div className="label-value-pair">
          <span className="label">User spaces:</span>
          <span style={{ marginRight: '1rem' }}>
            {`${totalNumberOfUserSeats}${
              additionalUserSeats
                ? ` (${numberOfUserSeats}${
                    additionalUserSeats
                      ? ` + ${additionalUserSeats} additional`
                      : ''
                  })`
                : ''
            }`}
          </span>
          <span className="value">
            {currencyPrice(
              additionalUserSeats * additionalUserSeatCost,
              account.region
            )}
          </span>
        </div>
      );
    }
    if (
      selectedPrice?.canBuyAdditionalUserSeats &&
      enableEmbeddedUserSeatControl
    ) {
      return (
        <div>
          {!hasAnyBaseUserSeats && (
            <div className="label-value-pair">
              <span className="label">
                Cost
                {selectedPrice.additionalUserSeatCost &&
                  ' with 1 user included'}
                :
              </span>
              <span className="value flex-shrink">
                {currencyPrice(
                  selectedPrice.basicCost +
                    (selectedPrice.additionalUserSeatCost || 0),
                  account.region
                )}
              </span>
            </div>
          )}
          {hasAnyBaseUserSeats && (
            <div className="label-value-pair user-seats-included">
              <span className="label">
                Users included:
                <Tooltip text={tooltipCopy} />
              </span>
              <span className="user-seat-count">{numberOfUserSeats}</span>
              <span className="value flex-shrink">
                {currencyPrice(selectedPrice?.basicCost || 0, account.region)}
              </span>
            </div>
          )}
          <div className="label-value-pair">
            <span className="label">{'Add additional Users'}:</span>
            <div className="quantity-control-wrapper">
              <QuantityControl
                value={additionalUserSeats - 1}
                onChange={(userSeats: number) => {
                  onChangeAdditionalUserSeats &&
                    onChangeAdditionalUserSeats(userSeats + 1);
                }}
                minimumValue={0}
              />
            </div>
            <span className="value">
              {currencyPrice(
                (additionalUserSeats - 1) * additionalUserSeatCost,
                account.region
              )}
            </span>
          </div>
        </div>
      );
    }
  }, [
    selectedProduct?.numberOfUserSeats,
    selectedPrice?.additionalUserSeatCost,
    selectedPrice?.canBuyAdditionalUserSeats,
    selectedPrice?.basicCost,
    enableEmbeddedUserSeatControl,
    additionalUserSeats,
    account,
  ]);

  if (!selectedProduct || !selectedPrice) {
    return null;
  }

  const discountedCostIncludingVat =
    (costIncludingVat ?? 0) *
    (1 - (selectedProduct.discountPercentage ?? 0) / 100);
  const discountDurationInYears = Math.ceil(
    (selectedProduct.discountDurationInMonths ?? 0) / 12
  );

  return (
    <div className="selected-product-overview">
      <div>
        <div className="user-seats">{userSeatsElement}</div>
      </div>
      <div>
        <LabelValuePair
          label="Subtotal"
          value={currencyPrice(costExcludingVat || 0, account.region)}
        />
        <LabelValuePair
          label={`VAT (${taxRatePercentage}%)`}
          value={currencyPrice(vat, account.region)}
        />
      </div>

      {costIncludingVat && (
        <div className="total">
          {selectedProduct.discountPercentage &&
            (recurringInterval === 'month' ? (
              <LabelValuePair
                label={`Total cost ${
                  selectedProduct.discountDurationInMonths
                    ? `for the first ${
                        selectedProduct.discountDurationInMonths > 1
                          ? `${selectedProduct.discountDurationInMonths} `
                          : ''
                      }month${
                        selectedProduct.discountDurationInMonths > 1 ? 's' : ''
                      }`
                    : 'recurring monthly'
                } (including ${selectedProduct.discountPercentage}% discount)`}
                value={currencyPrice(
                  discountedCostIncludingVat,
                  account.region
                )}
              />
            ) : (
              <LabelValuePair
                label={`Total cost ${
                  selectedProduct.discountDurationInMonths
                    ? `for the first ${
                        discountDurationInYears > 1
                          ? `${discountDurationInYears} `
                          : ''
                      }year${discountDurationInYears > 1 ? 's' : ''}`
                    : 'recurring annually'
                } (including ${selectedProduct.discountPercentage}% discount)`}
                value={currencyPrice(
                  discountedCostIncludingVat,
                  account.region
                )}
              />
            ))}

          <LabelValuePair
            label={`${
              selectedProduct.discountPercentage &&
              typeof selectedProduct.discountDurationInMonths === 'number'
                ? 'And thereafter'
                : 'Total cost'
            } recurring ${
              recurringInterval === 'month' ? 'monthly' : 'annually'
            }`}
            value={currencyPrice(costIncludingVat, account.region)}
          />
        </div>
      )}
    </div>
  );
};

export default SelectedProductOverview;
