import React, { FunctionComponent } from 'react';
import Button from '@payaca/components/button/Button';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import BasicField from '@payaca/components/basicField/BasicField';

import './QuantityControl.sass';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  isDisabled?: boolean;
  value: number;
  minimumValue?: number;
  onChange: (value: number) => void;
  showInputField?: boolean;
};

const QuantityControl: FunctionComponent<Props> = ({
  isDisabled = false,
  value,
  minimumValue = 0,
  onChange,
  showInputField = false,
}: Props): JSX.Element => {
  return (
    <div className="quantity-control flex-container flex-center">
      <Button
        isOutlined={true}
        isDisabled={value <= minimumValue || isDisabled}
        onClick={() => onChange(value - 1)}
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
      {showInputField ? (
        <BasicField
          name="quantity"
          type="number"
          value={value}
          onChange={(value) => onChange(parseInt(value.quantity))}
        />
      ) : (
        <span>{value}</span>
      )}
      <Button
        isOutlined={true}
        isDisabled={isDisabled}
        onClick={() => onChange(value + 1)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </div>
  );
};

export default QuantityControl;
