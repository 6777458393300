import { action } from 'typesafe-actions';

import {
  LineItemMaterial,
  Material,
  MaterialCategory,
  SupplierMaterial,
} from '@payaca/types/materialTypes';

import {
  ActionType,
  PersistLineItemMaterialRequestData,
  PersistMaterialRequestData,
  PersistSupplierMaterialRequestData,
} from './materialsTypes';

export const requestPersistMaterial = (
  persistMaterialRequestData: PersistMaterialRequestData,
  callback?: (materialId: number) => void
) =>
  action(ActionType.REQUEST_PERSIST_MATERIAL, {
    persistMaterialRequestData,
    callback,
  });

export const persistMaterialSuccess = () =>
  action(ActionType.PERSIST_MATERIAL_SUCCESS);

export const persistMaterialFailure = (error: Error) =>
  action(ActionType.PERSIST_MATERIAL_FAILURE, null, null, error);

export const requestPersistSupplierMaterial = (
  persistSupplierMaterialRequestData: PersistSupplierMaterialRequestData,
  callback?: (supplierMaterialId: number) => void
) =>
  action(ActionType.REQUEST_PERSIST_SUPPLIERMATERIAL, {
    persistSupplierMaterialRequestData,
    callback,
  });

export const persistSupplierMaterialSuccess = () =>
  action(ActionType.PERSIST_SUPPLIERMATERIAL_SUCCESS);

export const persistSupplierMaterialFailure = (error: Error) =>
  action(ActionType.PERSIST_SUPPLIERMATERIAL_FAILURE, null, null, error);

export const requestGetMaterial = (materialId: number) =>
  action(ActionType.REQUEST_GET_MATERIAL, {
    materialId,
  });

export const getMaterialSuccess = (materialId: number, material: Material) =>
  action(ActionType.GET_MATERIAL_SUCCESS, {
    materialId,
    material,
  });

export const getMaterialFailure = (materialId: number, error: Error) =>
  action(
    ActionType.GET_MATERIAL_FAILURE,
    {
      materialId,
    },
    null,
    error
  );

export const requestGetSupplierMaterialsForMaterial = (materialId: number) =>
  action(ActionType.REQUEST_GET_SUPPLIERMATERIALS_FOR_MATERIAL, {
    materialId,
  });

export const getSupplierMaterialsForMaterialSuccess = (
  supplierMaterials: Array<SupplierMaterial>
) =>
  action(
    ActionType.GET_SUPPLIERMATERIALS_FOR_MATERIAL_SUCCESS,
    supplierMaterials
  );

export const requestGetSupplierMaterial = (supplierMaterialId: number) =>
  action(ActionType.REQUEST_GET_SUPPLIERMATERIAL, {
    supplierMaterialId,
  });

export const getSupplierMaterialSuccess = (
  supplierMaterialId: number,
  supplierMaterial: SupplierMaterial
) =>
  action(ActionType.GET_SUPPLIERMATERIAL_SUCCESS, {
    supplierMaterialId,
    supplierMaterial,
  });

export const getSupplierMaterialFailure = (
  supplierMaterialId: number,
  error: Error
) =>
  action(
    ActionType.GET_SUPPLIERMATERIAL_FAILURE,
    {
      supplierMaterialId,
    },
    null,
    error
  );

export const requestDeleteSupplierMaterial = (
  supplierMaterialId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_DELETE_SUPPLIERMATERIAL, {
    supplierMaterialId,
    callback,
  });

export const deleteSupplierMaterialSuccess = () =>
  action(ActionType.DELETE_SUPPLIERMATERIAL_SUCCESS);

export const deleteSupplierMaterialFailure = (error: Error) =>
  action(ActionType.DELETE_SUPPLIERMATERIAL_FAILURE, null, null, error);

export const requestArchiveMaterials = (
  materialIds: number[],
  onArchiveSuccess?: () => void
) =>
  action(ActionType.REQUEST_ARCHIVE_MATERIALS, {
    materialIds,
    onArchiveSuccess,
  });

export const archiveMaterialsSuccess = () =>
  action(ActionType.ARCHIVE_MATERIALS_SUCCESS);

export const archiveMaterialsFailure = (error: Error) =>
  action(ActionType.ARCHIVE_MATERIALS_FAILURE, null, null, error);

export const clearArchiveMaterials = () =>
  action(ActionType.CLEAR_ARCHIVE_MATERIALS);

export const requestPersistLineItemMaterial = (
  persistLineItemMaterialRequestData: PersistLineItemMaterialRequestData,
  callback?: (err: string | null, supplierMaterialId?: number) => void
) =>
  action(ActionType.REQUEST_PERSIST_LINEITEMMATERIAL, {
    persistLineItemMaterialRequestData,
    callback,
  });

export const persistLineItemMaterialSuccess = () =>
  action(ActionType.PERSIST_LINEITEMMATERIAL_SUCCESS);

export const persistLineItemMaterialFailure = (error: Error) =>
  action(ActionType.PERSIST_LINEITEMMATERIAL_FAILURE, null, null, error);

export const requestPersistLineItemMaterials = (
  persistLineItemMaterialsRequestData: PersistLineItemMaterialRequestData[],
  callback?: (
    err: string | null,
    lineItemMaterialIds?: LineItemMaterial['id'][]
  ) => void
) =>
  action(ActionType.REQUEST_PERSIST_LINEITEMMATERIALS, {
    persistLineItemMaterialsRequestData,
    callback,
  });
export const persistLineItemMaterialsSuccess = () =>
  action(ActionType.PERSIST_LINEITEMMATERIALS_SUCCESS);

export const persistLineItemMaterialsFailure = (error: Error) =>
  action(ActionType.PERSIST_LINEITEMMATERIALS_FAILURE, null, null, error);

export const requestGetLineItemMaterial = (lineItemMaterialId: number) =>
  action(ActionType.REQUEST_GET_LINEITEMMATERIAL, {
    lineItemMaterialId,
  });

export const getLineItemMaterialSuccess = (
  lineItemMaterialId: number,
  lineItemMaterial: SupplierMaterial
) =>
  action(ActionType.GET_LINEITEMMATERIAL_SUCCESS, {
    lineItemMaterialId,
    lineItemMaterial,
  });

export const getLineItemMaterialFailure = (
  lineItemMaterialId: number,
  error: Error
) =>
  action(
    ActionType.GET_LINEITEMMATERIAL_FAILURE,
    {
      lineItemMaterialId,
    },
    null,
    error
  );

export const requestDeleteLineItemMaterial = (
  lineItemMaterialId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_DELETE_LINEITEMMATERIAL, {
    lineItemMaterialId,
    callback,
  });

export const deleteLineItemMaterialSuccess = () =>
  action(ActionType.DELETE_LINEITEMMATERIAL_SUCCESS);

export const deleteLineItemMaterialFailure = (error: Error) =>
  action(ActionType.DELETE_LINEITEMMATERIAL_FAILURE, null, null, error);

export const clearMaterials = () => action(ActionType.CLEAR_MATERIALS);

export const clearSupplierMaterials = () =>
  action(ActionType.CLEAR_SUPPLIERMATERIALS);

export const clearLineItemMaterials = () =>
  action(ActionType.CLEAR_LINEITEMMATERIALS);

export const requestGetMaterialCategories = () =>
  action(ActionType.REQUEST_GET_MATERIALCATEGORIES);

export const getMaterialCategoriesSuccess = (
  materialCategories: MaterialCategory[]
) =>
  action(ActionType.GET_MATERIALCATEGORIES_SUCCESS, {
    materialCategories,
  });

export const getMaterialCategoriesFailure = (error: Error) =>
  action(ActionType.GET_MATERIALCATEGORIES_FAILURE, null, null, error);

export const clearMaterialCategories = () =>
  action(ActionType.CLEAR_MATERIALCATEGORIES);

export const requestGetMaterialSuppliers = () =>
  action(ActionType.REQUEST_GET_MATERIALSUPPLIERS);

export const getMaterialSuppliersSuccess = (
  materialSuppliers: MaterialCategory[]
) =>
  action(ActionType.GET_MATERIALSUPPLIERS_SUCCESS, {
    materialSuppliers,
  });

export const getMaterialSuppliersFailure = (error: Error) =>
  action(ActionType.GET_MATERIALSUPPLIERS_FAILURE, null, null, error);

export const clearMaterialSuppliers = () =>
  action(ActionType.CLEAR_MATERIALSUPPLIERS);
