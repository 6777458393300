import { Upload, UploadAttachableEntityType } from '@payaca/types/uploadTypes';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { DeclareAsyncAction } from '../types';

export interface State {
  isPersistingUpload: boolean;
  isDeletingUpload: boolean;

  uploads: { [key: number]: FetchedEntity<Upload> };
}

export enum ActionType {
  REQUEST_PERSIST_UPLOAD = 'uploads.requestPersistUpload',
  PERSIST_UPLOAD_SUCCESS = 'uploads.persistUploadSuccess',
  PERSIST_UPLOAD_FAILURE = 'uploads.persistUploadFailure',

  REQUEST_PERSIST_UPLOAD_AND_LINK_TO_ENTITY = 'uploads.requestPersistUploadAndLinkToEntity',
  PERSIST_UPLOAD_AND_LINK_TO_ENTITY_SUCCESS = 'uploads.persistUploadAndLinkToEntityUploadSuccess',
  PERSIST_UPLOAD_AND_LINK_TO_ENTITY_FAILURE = 'uploads.persistUploadAndLinkToEntityUploadFailure',

  REQUEST_DELETE_UPLOAD = 'uploads.requestDeleteUpload',
  DELETE_UPLOAD_SUCCESS = 'uploads.deleteUploadSuccess',
  DELETE_UPLOAD_FAILURE = 'uploads.deleteUploadFailure',

  REQUEST_GET_UPLOADS_FOR_ENTITY = 'uploads.requestGetUploadsForEntity',
  GET_UPLOADS_WITH_ROLE_FOR_ENTITY_REQUEST = 'uploads.getUploadsWithRoleForEntity:request',

  GET_UPLOADS_FOR_ENTITY_SUCCESS = 'uploads.getUploadsForEntitySuccess',

  REQUEST_GET_UPLOAD = 'uploads.requestGetUpload',
  GET_UPLOADS_REQUEST = 'uploads.getUploads:request',
  GET_UPLOADS_SUCCESS = 'uploads.getUploads:success',
  GET_UPLOAD_SUCCESS = 'uploads.getUploadSuccess',
  GET_UPLOAD_FAILURE = 'uploads.getUploadFailure',
  CLEAR_UPLOADS = 'uploads.clearUploads',

  PERSIST_UPLOADS_AND_LINK_TO_ENTITY_REQUEST = 'uploads.persistUploadsAndLinkToEntity:request',
  PERSIST_UPLOADS_AND_LINK_TO_ENTITY_SUCCESS = 'uploads.persistUploadsAndLinkToEntity:success',
  PERSIST_UPLOADS_AND_LINK_TO_ENTITY_FAILURE = 'uploads.persistUploadsAndLinkToEntity:failure',

  LINK_UPLOADS_TO_ENTITY_REQUEST = 'uploads.linkUploadsToEntity:request',
  RENAME_UPLOAD_REQUEST = 'uploads.renameUpload:request',
  UNLINK_UPLOAD = 'uploads.unlinkUpload:request',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export interface PersistUploadRequestData {
  file: File;
  fileName?: string;
}

export interface PersistUploadAndLinkToEntityRequestData
  extends PersistUploadRequestData {
  entityType: UploadAttachableEntityType;
  entityId: number;
  entityRole?: string;
}

export type UnlinkUploadFromEntity = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UNLINK_UPLOAD;
      payload: {
        uploadId: Upload['id'];
        entity: {
          type: UploadAttachableEntityType;
          id: number;
        };
        deleteUploadIfOrphaned?: boolean;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type PersistUploadsAndLinkToEntity = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.PERSIST_UPLOADS_AND_LINK_TO_ENTITY_REQUEST;
      payload: {
        files: {
          uri: string;
          originalName: string;
          fileName: string;
          size: number;
          type: string;
        }[];
        entityType: UploadAttachableEntityType;
        entityId: number;
        callback?: (
          uploads: { id: number; fileName: string; url: string }[]
        ) => void;
        onErrorCallback?: () => void;
      };
    };
    success: { type: ActionType.PERSIST_UPLOADS_AND_LINK_TO_ENTITY_SUCCESS };
    failure: { type: ActionType.PERSIST_UPLOADS_AND_LINK_TO_ENTITY_FAILURE };
  }
>;

export type LinkUploadsToEntity = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.LINK_UPLOADS_TO_ENTITY_REQUEST;
      payload: {
        payload: {
          uploadIds: number[];
          uploadRole?: string;
          entityType: UploadAttachableEntityType;
          entityId: number;
        };
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type RenameUpload = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.RENAME_UPLOAD_REQUEST;
      payload: {
        payload: {
          uploadId: Upload['id'];
          fileName: string;
        };
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type GetUploadsWithRoleForEntity = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_UPLOADS_WITH_ROLE_FOR_ENTITY_REQUEST;
      payload: {
        entityType: UploadAttachableEntityType;
        entityId: number;
        uploadRole?: string;
        callback?: (uploads: Upload[]) => void;
        onErrorCallback?: () => void;
      };
    };
    success: {
      type: ActionType.GET_UPLOADS_SUCCESS;
      payload: {
        uploads: Upload[];
      };
    };
  }
>;

export type GetUploads = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_UPLOADS_REQUEST;
      payload: {
        uploadIds: number[];
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
    success: {
      type: ActionType.GET_UPLOADS_SUCCESS;
      payload: {
        uploads: Upload[];
      };
    };
  }
>;
