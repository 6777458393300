import React, { FunctionComponent, useCallback, useMemo } from 'react';

import {
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';
import SubscriptionProductSelectionControl from '../subscriptionProductSelectionControl/SubscriptionProductSelectionControl';

import './SubscriptionProductsSelectionControl.sass';
import Button from '@payaca/components/button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '@payaca/components/button/enums';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { useSelector } from '@/api/state';
import { Nullish } from '@payaca/utilities/types';

type Props = {
  recurringInterval: RecurringInterval;
  enableEmbeddedUserSeatControl?: boolean;
  selectedPriceId?: string;
  additionalUserSeats: number;
  onChange: (value: { [key: string]: any }) => void;
  onUpdateExistingSubscription?: () => void;
};

const SubscriptionProductsSelectionControl: FunctionComponent<Props> = ({
  recurringInterval,
  enableEmbeddedUserSeatControl = false,
  selectedPriceId,
  additionalUserSeats,
  onChange,
  onUpdateExistingSubscription,
}: Props): JSX.Element => {
  const productPriceKey = useMemo<'annualPrice' | 'monthlyPrice'>(
    () =>
      recurringInterval === RecurringInterval.YEAR
        ? 'annualPrice'
        : 'monthlyPrice',
    [recurringInterval]
  );

  const subscriptionProducts = useSelector((state) => {
    return state.subscription.products
      ?.filter((product: SubscriptionProduct) => !!product[productPriceKey])
      .sort((a: SubscriptionProduct, b: SubscriptionProduct) =>
        (a[productPriceKey] || 0) > (b[productPriceKey] || 0) ? 1 : -1
      );
  });

  const selectedSubscriptionProduct = useMemo(() => {
    return subscriptionProducts?.find(
      (product) => product[productPriceKey]?.stripeId === selectedPriceId
    );
  }, [subscriptionProducts, selectedPriceId, productPriceKey]);

  const renderSubscriptionProduct = useCallback(
    (
      productName: string,
      productNotes: Nullish<string>,
      badge?: JSX.Element
    ) => {
      if (!subscriptionProducts) return null;
      const subscriptionProduct = subscriptionProducts.find(
        (product: SubscriptionProduct) => product.name === productName
      );
      if (!subscriptionProduct) return null;

      const isSelected =
        selectedPriceId === subscriptionProduct[productPriceKey]?.stripeId;

      let buttonTitle = 'Select plan';

      if (isSelected) {
        buttonTitle = 'Selected';
      }

      const features: { label: string; icon?: IconDefinition }[] = [];
      let productDetail;
      let isLimitedAvailability = false;

      switch (true) {
        case productName.includes('Solo'):
          features.push({ label: '1 user' });
          break;
        case productName.includes('Team'):
          features.push({ label: '2-5 users' });
          features.push({ label: 'Forms & automation' });
          break;
        case productNotes?.includes('Business v2'):
          features.push(
            { label: 'Everything on Pro' },
            { label: 'Build custom automations' },
            { label: 'Unlimited SMS sends per month' },
            { label: 'Dispatch management' },
            { label: 'Custom reporting' },
            { label: 'Priority support' },
            { label: 'Custom user permissions' },
            { label: 'Custom form builds (additional costs apply)' }
          );
          break;
        case productName.includes('Business'):
          features.push({ label: '6-20 users' });
          features.push({
            label: 'Development support building custom forms and automation',
          });
          features.push({ label: 'Priority support' });
          break;
        case productName.includes('Pro'):
          productDetail = <strong>All core features</strong>;
          features.push({ label: 'Branded quotes & invoices' });
          features.push({
            label: 'Integrate Stripe, Quickbooks, Xero & Zapier',
          });
          features.push({ label: 'Embeddable lead capture forms' });
          features.push({ label: 'Sales CRM & Custom pipelines' });
          features.push({ label: 'Templated automations for common tasks' });
          features.push({ label: '100 SMS sends per month' });
          features.push({ label: 'Manage suppliers, costs, and materials' });
          features.push({ label: 'Permissions for admin and field staff' });
          features.push({ label: 'Scheduling and calendar' });
          features.push({ label: 'Task tracking' });
          features.push({ label: 'Job forms and images' });
          features.push({ label: 'Push notifications' });
          break;
        case productName.includes('PHA'):
          productDetail = (
            <>
              <div>
                <strong>Payaca Heating Accelerator by Tommy Lee-Zmuda</strong>
              </div>
            </>
          );
          features.push({ label: 'Pre-built customer journey pipelines' });
          features.push({ label: 'Email & SMS templates done for you' });
          features.push({ label: 'Product ranges with images' });
          features.push({ label: 'Custom training & support' });
          features.push({ label: 'Interactive live streams' });
          isLimitedAvailability = true;
          break;
      }

      return (
        <SubscriptionProductSelectionControl
          key={productName}
          subscriptionProduct={subscriptionProduct}
          recurringInterval={recurringInterval}
          features={features}
          productDetail={productDetail}
          onSelect={() =>
            onChange({
              productId: subscriptionProduct.stripeId,
              priceId:
                recurringInterval === 'month'
                  ? subscriptionProduct.monthlyPrice?.stripeId
                  : subscriptionProduct.annualPrice?.stripeId,
              additionalUserSeats: 0,
              subscriptionProduct,
            })
          }
          isSelected={
            selectedPriceId === subscriptionProduct[productPriceKey]?.stripeId
          }
          isLimitedAvailability={isLimitedAvailability}
          renderCta={(isSelected: boolean, onSelect: () => void) => {
            return (
              <Button
                styleVariant={ButtonStyleVariant.OUTSIZE}
                colourVariant={
                  isSelected
                    ? ButtonColourVariant.SECONDARY
                    : ButtonColourVariant.PRIMARY
                }
                isDisabled={isSelected}
                onClick={isSelected ? onUpdateExistingSubscription : onSelect}
              >
                {buttonTitle}
              </Button>
            );
          }}
        />
      );
    },
    [
      subscriptionProducts,
      selectedSubscriptionProduct,
      onChange,
      recurringInterval,
      additionalUserSeats,
      selectedPriceId,
      enableEmbeddedUserSeatControl,
      onUpdateExistingSubscription,
    ]
  );

  return (
    <div className="subscription-products-selection-control">
      <div className="products-container">
        {subscriptionProducts?.map((product) =>
          renderSubscriptionProduct(product.name, product.notes)
        )}
      </div>
    </div>
  );
};

export default SubscriptionProductsSelectionControl;
