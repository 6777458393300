import React, { FunctionComponent, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import Modal from '@payaca/components/modal/Modal';
import UpdateSubscriptionAdditionalUserSeatsControl from '../updateSubscriptionAdditionalUserSeatsControl/UpdateSubscriptionAdditonalUserSeatsControl';

import './UpdateSubscriptionAdditionalUserSeatsModal.sass';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUpdateSubscriptionSuccess?: (additionalUserSeats: number) => void;
};

const UpdateSubscriptionAdditionalUserSeatsModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onUpdateSubscriptionSuccess,
}: Props): JSX.Element => {
  const [subscriptionIsUpdated, setSubscriptionIsUpdated] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setSubscriptionIsUpdated(false);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      size="sm"
      onClose={onClose}
      className="update-subscription-additional-user-seats-modal"
    >
      {!subscriptionIsUpdated && (
        <UpdateSubscriptionAdditionalUserSeatsControl
          onUpdateSubscriptionSuccess={(additionalUserSeats) => {
            setSubscriptionIsUpdated(true);
            onUpdateSubscriptionSuccess?.(additionalUserSeats);
          }}
        />
      )}
      {subscriptionIsUpdated && (
        <div className="subscription-update-success-message">
          <FontAwesomeIcon icon={faCheck} />
          <p>Success!</p>
        </div>
      )}
    </Modal>
  );
};

export default UpdateSubscriptionAdditionalUserSeatsModal;
