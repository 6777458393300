import UntitledIcon, { TIconName } from '@payaca/untitled-icons';
import React, { FunctionComponent, useMemo } from 'react';
import tinycolor from 'tinycolor2';

export enum AvatarSizeVariant {
  XS = 'xs',
  SM = 'sm',
  BASE = 'base',
  LG = 'lg',
}

export enum AvatarStyleVariant {
  CIRCLE = 'pill',
  ROUNDED = 'rounded',
}

export enum AvatarBorderColourVariant {
  WHITE = 'white',
  BLUE = 'blue',
}

type Props = {
  sizeVariant?: AvatarSizeVariant;
  styleVariant?: AvatarStyleVariant;
  borderColourVariant?: AvatarBorderColourVariant;
  imgSrc?: string;
  imgDescription?: string;
  placeholderInitials?: string;
  iconName?: TIconName;
  colour?: string;
};

const Avatar: FunctionComponent<Props> = ({
  sizeVariant = AvatarSizeVariant.BASE,
  styleVariant = AvatarStyleVariant.CIRCLE,
  borderColourVariant = AvatarBorderColourVariant.WHITE,
  imgSrc,
  imgDescription,
  placeholderInitials,
  colour,
  iconName,
}) => {
  const { sizeClasses, textSizeClasses } = useMemo(() => {
    switch (sizeVariant) {
      case AvatarSizeVariant.XS:
        return { sizeClasses: 'h-8 w-8', textSizeClasses: 'text-xs' };
      case AvatarSizeVariant.SM:
        return {
          sizeClasses: 'h-[3.167rem] w-[3.167rem]',
          textSizeClasses: 'text-sm',
        };
      case AvatarSizeVariant.LG:
        return {
          sizeClasses: 'h-[5.17rem] w-[5.17rem]',
          textSizeClasses: 'text-lg',
        };
      default:
        return {
          sizeClasses: 'h-[3.83rem] w-[3.83rem]',
          textSizeClasses: 'text-base',
        };
    }
  }, [sizeVariant]);

  const styleClasses = useMemo(() => {
    switch (styleVariant) {
      case AvatarStyleVariant.ROUNDED:
        return 'rounded-lg';
      default:
        return 'rounded-full';
    }
  }, [styleVariant]);

  const borderColourClasses = useMemo(() => {
    switch (borderColourVariant) {
      case AvatarBorderColourVariant.BLUE:
        return 'ring-blue-500';
      default:
        return 'ring-white';
    }
  }, [borderColourVariant]);

  const readableTextColour = useMemo(() => {
    if (!colour) return undefined;

    return tinycolor
      .mostReadable(colour, ['#263e59', 'white'], {
        includeFallbackColors: true,
      })
      .toHexString();
  }, [colour]);

  if (imgSrc)
    return (
      <img
        className={`inline-block cursor-default ${sizeClasses} ${styleClasses} ring-2 ${borderColourClasses} `}
        src={imgSrc}
        alt={imgDescription}
      />
    );

  if (iconName) {
    return (
      <span
        style={{ backgroundColor: colour, color: readableTextColour }}
        className={`cursor-default ring-2 ${borderColourClasses} inline-flex items-center justify-center ${sizeClasses} ${styleClasses} rounded-full bg-gray-600 text-white`}
      >
        <UntitledIcon className="h-[60%] w-[60%]" name={iconName} />
      </span>
    );
  }

  if (placeholderInitials) {
    return (
      <span
        style={{ backgroundColor: colour }}
        className={`shrink-0 cursor-default ring-2 ${borderColourClasses} inline-flex items-center justify-center  ${sizeClasses} ${styleClasses} rounded-full bg-gray-600`}
      >
        <span
          style={{ color: readableTextColour }}
          className={`${textSizeClasses} font-medium uppercase leading-none text-white`}
        >
          {placeholderInitials}
        </span>
      </span>
    );
  }

  return (
    <span
      style={{ backgroundColor: colour, color: readableTextColour }}
      className={`cursor-default ring-2 ${borderColourClasses} inline-block ${sizeClasses} bg-gray-100 text-gray-300 ${styleClasses} overflow-hidden`}
    >
      <div className="flex h-full w-full items-center justify-center">
        <UntitledIcon className="h-[80%] w-[80%]" name="user-03" />
      </div>
    </span>
  );
};

export default Avatar;
