import {
  getActiveJobContentByDealId,
  getDeal,
  getJobContentsByDealId,
  getJobsByDealId,
} from '@/utils/stateAccessors';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import './PopulateDealMaterialsListControl.sass';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { useSelector } from '@/api/state';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import { singularPlural } from '@payaca/utilities/stringUtilities';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';

type Props = {
  dealId: number;
  buttonStyleVariant?: ButtonStyleVariant;
};

const PopulateDealMaterialsListControl: FunctionComponent<Props> = ({
  dealId,
  buttonStyleVariant,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const [syncResponse, setSyncResponse] = useState<
    | {
        createdMaterialsListMaterialsCount: number;
        updatedMaterialsListMaterialsCount: number;
        deletedMaterialsListMaterialsCount: number;
      }
    | undefined
  >();

  const [isPopulating, setIsPopulating] = useState(false);

  const deal = useSelector((state) => getDeal(state, dealId));

  const handleSyncAutopopulatedMaterialsListMaterials = useCallback(() => {
    setIsPopulating(true);
    setSyncResponse(undefined);

    dispatch(
      materialsListActions.syncAutopopulatedMaterialsListMaterialsFromDeal.request(
        {
          dealId,
          callback: (response) => {
            setSyncResponse(response);
            if (
              !response.createdMaterialsListMaterialsCount &&
              !response.updatedMaterialsListMaterialsCount &&
              !response.deletedMaterialsListMaterialsCount
            ) {
              setIsPopulating(false);
            } else {
              dispatch(requestGetDeal(dealId));
              deal?.materialsListId &&
                dispatch(
                  materialsListActions.requestGetMaterialsListWithRelatedEntities(
                    deal.materialsListId,
                    () => setIsPopulating(false)
                  )
                );
            }
          },
        }
      )
    );
  }, [dealId, deal?.materialsListId]);

  const shouldShowControl = useMemo(() => {
    let shouldShow = false;

    if (deal?.propositionIds?.length) {
      shouldShow = true;
    } else if (deal?.version == 1 && deal.invoiceIds?.length) {
      shouldShow = true;
    }

    return shouldShow;
  }, [deal]);

  if (!shouldShowControl) return null;

  return (
    <PermissionGuard
      renderIfHasPermissions={[
        MaterialsListPermissions.POPULATE_MATERIALS_LIST_FROM_DEAL,
      ]}
    >
      <div className="populate-deal-materials-list-control">
        <>
          {deal?.requiresSyncAutopopulatedMaterialsListMaterials ? (
            <FeedbackBlock feedbackLevel={FeedbackLevel.ALERT}>
              <div className="materials-list-sync-warning">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <span>Materials list is out of sync with proposed Items.</span>
                <Button
                  styleVariant={buttonStyleVariant}
                  onClick={() =>
                    !isPopulating &&
                    handleSyncAutopopulatedMaterialsListMaterials()
                  }
                  isProcessing={isPopulating}
                >
                  Update now
                </Button>
              </div>
            </FeedbackBlock>
          ) : (
            <Button
              styleVariant={buttonStyleVariant}
              onClick={() =>
                !isPopulating && handleSyncAutopopulatedMaterialsListMaterials()
              }
              isProcessing={isPopulating}
            >
              Update from proposed Items
            </Button>
          )}
        </>

        {syncResponse && (
          <>
            {!syncResponse.createdMaterialsListMaterialsCount &&
            !syncResponse.updatedMaterialsListMaterialsCount &&
            !syncResponse.deletedMaterialsListMaterialsCount ? (
              <FeedbackBlock
                feedbackLevel={FeedbackLevel.ALERT}
                isDismissable={true}
                onDismissed={() => setSyncResponse(undefined)}
              >
                No changes were made to the Materials required. You can still
                add Materials manually.
              </FeedbackBlock>
            ) : (
              <FeedbackBlock
                feedbackLevel={FeedbackLevel.SUCCESS}
                isDismissable={true}
                onDismissed={() => setSyncResponse(undefined)}
                className="sync-response-feedback-block"
              >
                The following changes were made to the Materials required:
                <ul>
                  {!!syncResponse.createdMaterialsListMaterialsCount && (
                    <li>
                      {singularPlural(
                        syncResponse.createdMaterialsListMaterialsCount,
                        'Material ',
                        'Materials '
                      )}
                      added
                    </li>
                  )}
                  {!!syncResponse.updatedMaterialsListMaterialsCount && (
                    <li>
                      {singularPlural(
                        syncResponse.updatedMaterialsListMaterialsCount,
                        'Material ',
                        'Materials '
                      )}{' '}
                      updated
                    </li>
                  )}
                  {!!syncResponse.deletedMaterialsListMaterialsCount && (
                    <li>
                      {singularPlural(
                        syncResponse.deletedMaterialsListMaterialsCount,
                        'Material ',
                        'Materials '
                      )}{' '}
                      removed
                    </li>
                  )}
                </ul>
              </FeedbackBlock>
            )}
          </>
        )}
      </div>
    </PermissionGuard>
  );
};

export default PopulateDealMaterialsListControl;
