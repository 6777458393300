export enum MaterialsListPermissions {
  GET_MATERIAL_PURCHASE_RECORDS = 'materialsList.getMaterialPurchaseRecords',
  PERSIST_MATERIAL_PURCHASE_RECORD = 'materialsList.persistMaterialPurchaseRecord',
  DELETE_MATERIAL_PURCHASE_RECORD = 'materialsList.deleteMaterialPurchaseRecord',

  GET_MATERIALS_LISTS = 'materialsList.getMaterialsLists',
  PERSIST_MATERIALS_LIST = 'materialsList.persistMaterialsLists',
  POPULATE_MATERIALS_LIST_FROM_DEAL = 'materialsList.populateMaterialsListFromDeal',
  CLEAR_MATERIALS_LIST = 'materialsList.clearMaterialsList',

  GET_MATERIALS_LIST_MATERIALS = 'materialsList.getMaterialsListMaterials',
  PERSIST_MATERIALS_LIST_MATERIAL = 'materialsList.persistMaterialsListMaterial',
  DELETE_MATERIALS_LIST_MATERIAL = 'materialsList.deleteMaterialsListMaterial',

  GET_PURCHASE_ORDERS = 'materialsList.getPurchaseOrder',
  PERSIST_PURCHASE_ORDER = 'materialsList.persistPurchaseOrder',
  SEND_PURCHASE_ORDER = 'materialsList.sendPurchaseOrder',
  VOID_PURCHASE_ORDER = 'materialsList.voidPurchaseOrder',
  CONFIRM_PURCHASE_ORDER = 'materialsList.confirmPurchaseOrder',

  GET_SELF_ASSIGNED_EVENT_DEAL_PURCHASE_ORDERS = 'materialsList.getSelfAssignedEventDealPurchaseOrders',

  PERSIST_ISOLATED_PURCHASE_ORDER = 'materialsList.persistIsolatedPurchaseOrder',
  SEND_ISOLATED_PURCHASE_ORDER = 'materialsList.sendIsolatedPurchaseOrder',
  VOID_ISOLATED_PURCHASE_ORDER = 'materialsList.voidIsolatedPurchaseOrder',
  CONFIRM_ISOLATED_PURCHASE_ORDER = 'materialsList.confirmIsolatedPurchaseOrder',

  GET_MATERIAL_PURCHASE_INTENTS = 'materialsList.getMaterialPurchaseIntents',
  PERSIST_MATERIAL_PURCHASE_INTENT = 'materialsList.persistMaterialPurchaseIntent',
  DELETE_MATERIAL_PURCHASE_INTENT = 'materialsList.deleteMaterialPurchaseIntent',
}
