import React, { FC, useMemo } from 'react';

import DropdownField from '@payaca/components/dropdownField/DropdownField';
import SearchField from '../searchField/SearchField';

import { SortBy } from '@payaca/types/listedItemTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

import './ListedItemsSearchFilterControl.sass';

const sortByOptions = [
  {
    label: 'Ref (A → Z)',
    value: { sortBy: SortBy.NAME, sortDirection: SortDirection.ASCENDING },
  },
  {
    label: 'Ref (Z → A)',
    value: { sortBy: SortBy.NAME, sortDirection: SortDirection.DESCENDING },
  },
  {
    label: 'Description (A → Z)',
    value: {
      sortBy: SortBy.DESCRIPTION,
      sortDirection: SortDirection.ASCENDING,
    },
  },
  {
    label: 'Description (Z → A)',
    value: {
      sortBy: SortBy.DESCRIPTION,
      sortDirection: SortDirection.DESCENDING,
    },
  },
  {
    label: 'Price (Cheapest → most expensive)',
    value: { sortBy: SortBy.PRICE, sortDirection: SortDirection.ASCENDING },
  },
  {
    label: 'Price (Most expensive → cheapest)',
    value: { sortBy: SortBy.PRICE, sortDirection: SortDirection.DESCENDING },
  },
];
type Props = {
  onChange: (value: { [key: string]: any }) => void;
  sortDirection?: SortDirection;
  sortBy?: SortBy;
  initSearchTerm?: string;
};

const ListedItemsSearchFilterControl: FC<Props> = ({
  onChange,
  sortDirection,
  sortBy,
  initSearchTerm,
}: Props): JSX.Element => {
  const value = useMemo(() => {
    return JSON.stringify({ sortBy, sortDirection });
  }, [sortBy, sortDirection]);

  return (
    <div className="listed-items-search-filter-control">
      {/* search term filter */}
      <div className="search-field">
        <SearchField
          onSearchTermChange={(searchTerm) => onChange({ searchTerm })}
          placeholder="Search items"
          initSearchTerm={initSearchTerm}
        />
      </div>

      {/* sort by control */}
      <div className="sort-by-order">
        <DropdownField
          options={sortByOptions.map((x) => ({
            ...x,
            value: JSON.stringify(x.value),
          }))}
          value={value}
          name="sortOrder"
          onChange={(value) => {
            const parsedValue = JSON.parse(value.sortOrder);
            onChange({
              sortBy: parsedValue.sortBy,
              sortDirection: parsedValue.sortDirection,
            });
          }}
        />
      </div>
    </div>
  );
};

export default ListedItemsSearchFilterControl;
