import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as customerActions from '@payaca/store/customer/customerActions';
import { useDispatch } from 'react-redux';
import AuthenticatedPageWrapper from '@/ui/pages/pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import useGetCustomers from '@/api/queries/customers/useGetCustomers';
import { GetCustomersInput, PaginationInput } from '@/gql/graphql';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import XeroLogo from '../../../assets/images/xero.svg';
import QuickbooksLogo from '../../../assets/images/quickbooks.svg';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { CustomersPermissions } from '@payaca/permissions/customers/customers.permissions';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Modal from '@payaca/components/plModal/Modal';
import useDeleteCustomer from '@/api/mutations/customers/useDeleteCustomer';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import BulkUploadModal from '@/ui/components/bulkUploadDownloadModals/BulkUploadModal';
import { useHashFragment } from '@/utils/customHooks';
import CreateCustomerModal from '@/ui/components/createCustomerModal/CreateCustomerModal';
import useCreateCustomer from '@/api/mutations/customers/useCreateCustomer';
import CreateCustomerModalMutation from '@/ui/components/createCustomerModalMutation/CreateCustomerModalMutation';

export interface IProps {
  isCreatingCustomer?: boolean;
}

const ListedCustomersPage: FC<IProps> = (props) => {
  const { isCreatingCustomer = false } = props;
  const history = useHistory();
  const [showExportDataModal, setShowExportDataModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showBulkUploadModal, toggleBulkUploadModal] =
    useHashFragment('#bulk-upload');
  const [requiresPersistSearchTerm, setRequiresPersistSearchTerm] =
    useState<boolean>(false);
  const [deleteCustomerId, setDeleteCustomerId] = useState<string | null>(null);

  const [pagination, setPagination] = useState<PaginationInput>({
    offset: 0,
    limit: 20,
  });

  const [input, setInput] = useState<GetCustomersInput>({
    searchTerm: null,
  });

  /**
   * Queries
   */
  const {
    customers,
    isLoading,
    refetch: refetchCustomers,
  } = useGetCustomers(pagination, input);

  useEffect(() => {
    if (requiresPersistSearchTerm) {
      setInput((x) => ({ ...x, searchTerm }));
      setRequiresPersistSearchTerm(false);
    }
  }, [requiresPersistSearchTerm, searchTerm]);

  const anyCustomersSynced = useMemo(() => {
    return customers?.items.some(
      (x) =>
        x.integrationSyncStatus?.xero || x.integrationSyncStatus?.quickbooks
    );
  }, [customers]);

  return (
    <AuthenticatedPageWrapper
      className="bg-gray-50"
      title={
        <div className="flex flex-grow flex-row gap-4">
          <h1 className="flex-grow">Customers</h1>
          <PermissionGuard
            renderIfHasPermissions={[CustomersPermissions.BULK_EXPORT]}
          >
            <Button
              variant={EBtnVariant.Link}
              onClick={() => {
                setShowExportDataModal(true);
              }}
            >
              Export data
            </Button>
          </PermissionGuard>
          <PermissionGuard
            renderIfHasPermissions={[CustomersPermissions.ADD_CUSTOMER]}
          >
            <Button onClick={() => history.push(`/customers/new`)}>
              Create Customer
            </Button>
          </PermissionGuard>
        </div>
      }
    >
      <div className="p-4 pt-0">
        <ManageableItemsList>
          <ManageableItemsList.ActionBar>
            <ManageableItemsList.ActionBar.SearchInput
              value={searchTerm}
              onChange={setSearchTerm}
              onChangeTimeout={() => setRequiresPersistSearchTerm(true)}
              onBlur={() => setRequiresPersistSearchTerm(true)}
              changeTimeoutMs={300}
            />
          </ManageableItemsList.ActionBar>
          <ManageableItemsList.Table
            itemActions={[
              {
                label: 'Delete',
                onClick: (item) => {
                  setDeleteCustomerId(item.id);
                },
              },
            ]}
            uniqueKey="id"
            items={customers?.items || []}
            isLoading={isLoading}
          >
            <ManageableItemsList.Table.Column
              header="Customer"
              field="name"
              className="max-w-[300px] truncate"
              render={(name, customer) => {
                return <Link to={`/customers/${customer.id}`}>{name}</Link>;
              }}
            />
            <ManageableItemsList.Table.Column
              header="Primary Email"
              field="primaryContact"
              render={(primaryContact, customer) => {
                return <span>{primaryContact?.email}</span>;
              }}
            />
            <ManageableItemsList.Table.Column
              header="Primary Phone"
              field="primaryContact"
              render={(primaryContact, customer) => {
                return <span>{primaryContact?.phone}</span>;
              }}
            />
            <ManageableItemsList.Table.Column
              header="Billing Address"
              field="billingAddress"
              className="max-w-[400px] truncate"
              render={(billingAddress) => {
                return <span>{billingAddress?.fullLocalAddress}</span>;
              }}
            />
            {anyCustomersSynced && (
              <ManageableItemsList.Table.Column
                header="Import"
                field="integrationSyncStatus"
                render={(integrationSyncStatus) => {
                  return (
                    <div className="flex flex-row gap-2">
                      {integrationSyncStatus?.xero && (
                        <img alt="Xero" src={XeroLogo} className="h-6 w-6" />
                      )}
                      {integrationSyncStatus?.quickbooks && (
                        <img
                          alt="Quickbooks"
                          src={QuickbooksLogo}
                          className="h-6 w-6"
                        />
                      )}
                    </div>
                  );
                }}
              />
            )}
          </ManageableItemsList.Table>
          <ManageableItemsList.PaginationBar
            pageSize={customers?.limit || 20}
            currentPage={
              Math.ceil((customers?.offset || 0) / (customers?.limit || 20)) + 1
            }
            totalItems={customers?.totalCount || 0}
            onPageChange={(page) =>
              setPagination((x) => ({
                ...x,
                offset: (page - 1) * (pagination?.limit || 20),
              }))
            }
          />
        </ManageableItemsList>
      </div>
      <ExportCustomersDataModal
        isOpen={showExportDataModal}
        onClose={() => {
          setShowExportDataModal(false);
        }}
      />
      <BulkUploadModal
        isOpen={showBulkUploadModal}
        onClose={toggleBulkUploadModal}
        title="Upload Customers"
        dataType="customers"
      ></BulkUploadModal>
      {deleteCustomerId && (
        <ArchiveCustomerModal
          customerId={deleteCustomerId}
          isOpen={!!deleteCustomerId}
          onClose={() => setDeleteCustomerId(null)}
          onSuccess={() => {
            refetchCustomers();
          }}
        />
      )}

      <CreateCustomerModalMutation
        isOpen={isCreatingCustomer}
        onClose={() => history.push('/customers')}
        mutationOptions={{
          onSuccess: (data) => {
            history.push(`/customers/${data.createCustomer.id}`);
          },
        }}
      />
    </AuthenticatedPageWrapper>
  );
};

export default ListedCustomersPage;

const ExportCustomersDataModal: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();

  const submit = useCallback(() => {
    if (isProcessing) return;
    setIsProcessing(true);
    dispatch(
      customerActions.exportCustomersData.request({
        callback: () => {
          setIsSubmitted(true);
          setIsProcessing(false);
        },
      })
    );
  }, [isProcessing]);

  if (isSubmitted) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} title="Export Customers data">
        <Modal.Body>
          <p>
            Your export has started. Check your emails for your Customer data.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            <Button isProcessing={isProcessing} onClick={onClose}>
              Ok
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Export Customers data">
      <Modal.Body>
        <p>Your exported Customer data will be sent to you in an email.</p>
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button isProcessing={isProcessing} onClick={submit}>
            Yes, export
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

const ArchiveCustomerModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  customerId: string;
  onSuccess?: () => void;
}> = ({ isOpen, onClose, customerId, onSuccess }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const { mutateAsync: mutateDeleteCustomer } = useDeleteCustomer();

  const handleArchive = useCallback(async () => {
    setIsProcessing(true);
    await mutateDeleteCustomer({
      customerId,
    })
      .then((result) => {
        onSuccess?.();

        onClose();
      })
      .catch(() => {
        setErrorMessage('An error occurred while deleting this customer.');
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, [customerId, onSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Delete a Customer'}>
      <Modal.Body>
        <p>Are you sure you want to delete this Customer?</p>
      </Modal.Body>
      <Modal.Footer>
        {errorMessage && (
          <Alert colour={EAlertColour.SOFT_RED} className="mb-4">
            {errorMessage}
          </Alert>
        )}
        <Modal.Footer.Actions>
          <Button
            colour={EBtnColour.Red}
            isProcessing={isProcessing}
            onClick={() => !isProcessing && handleArchive()}
          >
            {'Yes, delete'}
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};
