import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';

import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import Button from '@payaca/components/button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '@payaca/components/button/enums';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Modal from '@payaca/components/modal/Modal';

import * as lineItemV2Actions from '@payaca/store/lineItemsV2/lineItemsActions';
import * as lineItemActions from '@payaca/store/lineItems/lineItemsActions';

import EditItemControl from '../../components/viewEditItem/EditItemControl';

import { useTranslation } from '@/i18n';
import { useSelector } from '@/api/state';

import { getLineItem } from '@/utils/stateAccessors';

import './ItemPage.sass';

type Props = {
  lineItemId: number;
};
const ItemPage: FC<Props> = ({ lineItemId }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useTranslation();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [isDeletingLineItem, setIsDeletingLineItem] = useState(false);
  const [showConfirmDeleteItemModal, setShowConfirmDeleteItemModal] =
    useState(false);

  useEffect(() => {
    if (lineItemId && !hasLoaded) {
      dispatch(
        lineItemV2Actions.requestGetLineItem(
          lineItemId,
          () => {
            setHasLoaded(true);
          },
          (error) => {
            if (error?.error === 'LINE_ITEM_DOES_NOT_EXIST') {
              // item is archived - redirect to items page
              history.push('/items');
            }
          }
        )
      );
      dispatch(
        lineItemV2Actions.requestGetLineItemWithRelatedEntities(lineItemId)
      );
    }
  }, [hasLoaded, lineItemId]);

  const lineItem = useSelector((state) => getLineItem(state, lineItemId));

  const deleteLineItem = useCallback(() => {
    if (lineItemId) {
      setIsDeletingLineItem(true);
      dispatch(
        lineItemActions.requestDeleteLineItems([lineItemId], () => {
          setIsDeletingLineItem(false);
          history.goBack();
        })
      );
    }
  }, [lineItemId]);

  return (
    <>
      <Helmet
        title={translate('pages.item.title', {
          reference: lineItem?.name || '',
        })}
      />
      <AuthenticatedPageWrapper
        className="item-page"
        title={
          <>
            <div className="flex-grow flex-container flex-center">
              <h1>Item you sell</h1>
            </div>
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={() => setShowConfirmDeleteItemModal(true)}
              isProcessing={isDeletingLineItem}
              colourVariant={ButtonColourVariant.RED}
            >
              Delete Item
            </Button>
          </>
        }
        previousPageNavigationConfig={{ route: '/items' }}
      >
        {!lineItem ? (
          <div className="loading-container">
            <MiniLoader />
          </div>
        ) : (
          <EditItemControl lineItemId={lineItemId} />
        )}
      </AuthenticatedPageWrapper>
      <Modal
        isOpen={showConfirmDeleteItemModal}
        onClose={() => setShowConfirmDeleteItemModal(false)}
        title={`Are you sure you want to delete this Item?`}
        actions={
          <>
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              onClick={() => {
                if (!isDeletingLineItem) {
                  deleteLineItem();
                }
              }}
              isProcessing={isDeletingLineItem}
            >
              Delete
            </Button>
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={() => setShowConfirmDeleteItemModal(false)}
            >
              Cancel
            </Button>
          </>
        }
      ></Modal>
    </>
  );
};
export default ItemPage;
