import { action } from 'typesafe-actions';

import {
  LineItem,
  LineItemAttachment,
  LineItemAttachmentPayload,
  LineItemPayload,
} from '@payaca/types/lineItemTypes';
import { ActionType } from './lineItemsTypes';

// CLEAR LINE ITEMS
export const clearLineItems = () => {
  return action(ActionType.CLEAR_LINE_ITEMS);
};

// CLEAR CURRENT LINE ITEM
export const clearCurrentLineItem = () => {
  return action(ActionType.CLEAR_CURRENT_LINE_ITEM);
};

// STORE CURRENT LINE ITEM
export const storeCurrentLineItem = (lineItem: LineItemPayload) => {
  return action(ActionType.STORE_CURRENT_LINE_ITEM, { lineItem });
};

// UPDATE CURRENT LINE ITEM
export const updateCurrentLineItem = (lineItemUpdates: any) => {
  return action(ActionType.UPDATE_CURRENT_LINE_ITEM, { lineItemUpdates });
};

// INITIALISE CURRENT LINE ITEM
export const initialiseNewLineItem = () => {
  return action(ActionType.INITIALISE_NEW_LINE_ITEM);
};

// GETTING LINE ITEM
export const requestGetLineItem = (
  lineItemId: number,
  callback?: (lineItem: LineItem) => void
) => {
  return action(ActionType.REQUEST_GET_LINE_ITEM, { lineItemId, callback });
};
export const getLineItemSuccess = (lineItem: LineItem) => {
  return action(ActionType.GET_LINE_ITEM_SUCCESS, {
    lineItem,
  });
};
export const getLineItemFailure = (error: Error, errorMessage: string) => {
  return action(
    ActionType.GET_LINE_ITEM_FAILURE,
    { errorMessage },
    null,
    error
  );
};

// DELETING LINE ITEM
export const requestDeleteLineItems = (
  lineItemIds: number[],
  callback?: () => void
) => {
  return action(ActionType.REQUEST_DELETE_LINE_ITEMS, {
    lineItemIds,
    callback,
  });
};
export const deleteLineItemsSuccess = (lineItem: LineItem) => {
  return action(ActionType.DELETE_LINE_ITEMS_SUCCESS, {
    lineItem,
  });
};
export const deleteLineItemsFailure = (error: Error, errorMessage: string) => {
  return action(
    ActionType.DELETE_LINE_ITEMS_FAILURE,
    { errorMessage },
    null,
    error
  );
};
// CREATING/UPDATING LINE ITEM ATTACHMENTS
export const requestCreateUpdateLineItemAttachments = (
  lineItemId: number,
  attachments: LineItemAttachmentPayload | LineItemAttachment[],
  callback?: () => void,
  errorCallback?: (error: string) => void
) => {
  return action(ActionType.REQUEST_CREATE_UPDATE_LINE_ITEM_ATTACHMENTS, {
    lineItemId,
    attachments,
    callback,
    errorCallback,
  });
};
export const createUpdateLineItemAttachmentsSuccess = (
  lineItemAttachments: any[]
) => {
  return action(ActionType.CREATE_UPDATE_LINE_ITEM_ATTACHMENTS_SUCCESS, {
    lineItemAttachments,
  });
};
export const createUpdateLineItemAttachmentsFailure = (
  error: Error,
  errorMessage: string
) => {
  return action(
    ActionType.CREATE_UPDATE_LINE_ITEM_ATTACHMENTS_FAILURE,
    { errorMessage },
    null,
    error
  );
};
