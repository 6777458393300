import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { getLineItem } from '@/utils/stateAccessors';

import * as listedLineItemMaterialsActions from '@payaca/store/listedMaterialLineItems/listedMaterialILinetemsActions';

import { GetListedMaterialLineItemsRequestData } from '@payaca/store/listedMaterialLineItems/listedMaterialLineItemsTypes';
import { ListedMaterialLineItem } from '@payaca/types/listedMaterialLineItemTypes';

import LineItemMaterialControl from '../lineItemMaterialControl/LineItemMaterialControl';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';
import EmptyState from '../emptyState/EmptyState';

import { useSelector } from '@/api/state';

import { getRegion } from '../../../utils/stateAccessors';

import { currencyPrice } from '@payaca/helpers/financeHelper';

import './LineItemLineItemMaterials.sass';

type Props = {
  lineItemId: number;
  onLineItemUpdateSuccess: () => void;
  requestGetListedLineItemMaterialsPage: () => void;
  setGetListedLineItemMaterialsRequestData: any;
};
const LineItemLineItemMaterials: FC<Props> = ({
  lineItemId,
  onLineItemUpdateSuccess,
  requestGetListedLineItemMaterialsPage,
  setGetListedLineItemMaterialsRequestData,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const region = useSelector(getRegion);

  const listedLineItemMaterialsPage = useSelector((state) => {
    return state.listedMaterialLineItems.listedMaterialLineItemsPage;
  });
  const isGettingListedLineItemMaterialsPage = useSelector(
    (state) =>
      state.listedMaterialLineItems.isGettingListedMaterialLineItemsPage
  );

  const paginationControl = useMemo(() => {
    if (!listedLineItemMaterialsPage) return null;
    return (
      <PaginationControl
        pageNumber={listedLineItemMaterialsPage.pageNumber}
        pageSize={listedLineItemMaterialsPage.pageSize}
        totalItemCount={listedLineItemMaterialsPage.totalItemCount}
        onSelectPage={(pageNumber) => {
          setGetListedLineItemMaterialsRequestData(
            (
              getListedLineItemMaterialsRequestData: GetListedMaterialLineItemsRequestData
            ) => ({
              ...getListedLineItemMaterialsRequestData,
              pageNumber: pageNumber,
            })
          );
        }}
        allowPageSizeSelection={false}
      />
    );
  }, [listedLineItemMaterialsPage]);

  const lineItem = useSelector((state) => getLineItem(state, lineItemId));

  useEffect(() => {
    return () => {
      dispatch(
        listedLineItemMaterialsActions.clearListedMaterialLineItemsPage()
      );
    };
  }, []);

  if (!lineItem) return null;

  return (
    <div className="line-item-line-item-materials">
      {isGettingListedLineItemMaterialsPage && (
        <div className="loader-container">
          <MiniLoader />
        </div>
      )}
      {!isGettingListedLineItemMaterialsPage &&
        !listedLineItemMaterialsPage?.items?.length && (
          <EmptyState
            configName={'line-item-materials'}
            arrowIndication={false}
          />
        )}
      <div className="line-item-materials-list">
        {listedLineItemMaterialsPage?.items?.map(
          (listedLineItemMaterial: ListedMaterialLineItem, index: number) => {
            return (
              <LineItemMaterialControl
                key={`listed-line-item-material-${listedLineItemMaterial.id}-${index}`}
                lineItemMaterial={listedLineItemMaterial}
                material={listedLineItemMaterial.material}
                suppliers={listedLineItemMaterial.suppliers}
                supplierMaterials={listedLineItemMaterial.supplierMaterials}
                onDeleteLineItemMaterialSuccess={() => {
                  requestGetListedLineItemMaterialsPage();
                  onLineItemUpdateSuccess && onLineItemUpdateSuccess();
                }}
                onPersistLineItemMaterialSuccess={() => {
                  requestGetListedLineItemMaterialsPage();
                  onLineItemUpdateSuccess && onLineItemUpdateSuccess();
                }}
              />
            );
          }
        )}
        {paginationControl}
      </div>
      <LabelValuePair
        label={`Total (ex tax)`}
        value={
          typeof lineItem.predictedCostExcludingTax !== 'number'
            ? '-'
            : currencyPrice(lineItem.predictedCostExcludingTax, region)
        }
        className="line-item-materials-list-total"
      />
    </div>
  );
};

export default LineItemLineItemMaterials;
