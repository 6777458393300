import { ChangeEventHandler, FC, FocusEventHandler, useRef } from 'react';
import RawInput, {
  TProps as TRawProps,
  InputIcon,
  InputShapeVariant,
  InputSizeVariant,
  InputStyleVariant,
} from './RawInput';

export type TProps = Omit<TRawProps, 'onChange' | 'onTouch'> & {
  onChange?: (value: string) => void;
  onTouch?: () => void;
  changeTimeoutMs?: number;
  onChangeTimeout?: (value: string) => void;
};

const Input: FC<TProps> = ({
  onChange,
  onTouch,
  onChangeTimeout,
  changeTimeoutMs = 1000,
  onFocus,
  type = 'text',
  ...rest
}) => {
  const changeTimeout = useRef<number | null>(null);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange?.(e.target.value);

    if (changeTimeout?.current) {
      clearTimeout(changeTimeout.current);
    }
    changeTimeout.current = (setTimeout as any)(() => {
      onChangeTimeout && onChangeTimeout(e.target.value);
    }, changeTimeoutMs);
  };

  const handleTouch: FocusEventHandler<HTMLInputElement> = (e) => {
    onTouch?.();
    // Normal on focus event
    onFocus?.(e);
  };

  return (
    <RawInput
      type={type}
      onChange={handleChange}
      onFocus={handleTouch}
      {...rest}
    />
  );
};

export default Input;

export { InputIcon, InputShapeVariant, InputSizeVariant, InputStyleVariant };
